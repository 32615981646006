
export class MvcMessages {

    protected constructor() {}

    static ERROR_NO_KEY: 'A unique key must be supplied for each Facade constructed.';

    static ERROR_KEY_EXISTS: 'A $ACTOR_NAME$ instance for this key already exists.';

}
