import { LogLevel } from '../../enum/LogLevel';
import { ModelName } from '../../enum/ModelName';
import { PlayerDom } from '../../enum/PlayerDom';
import { ProxyName } from '../../enum/ProxyName';
import { ServiceName } from '../../enum/ServiceName';
import { NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface } from '../../iface';
import { DomReference } from '../../iface/DomReference';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ActivityProxy } from '../../model/ActivityProxy';
import { PlayerDomCreator } from '../command-helpers/PlayerDomCreator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class CreateDomCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        const cfg: StrAnyDict = notification.body.playerOptions;
        const container: DomReference = cfg.container;
        const sysSvc = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);

        if (sysSvc.isWebMaf || !sysSvc.global || !sysSvc.document || !container) {
            return;
        }

        let c = <HTMLElement>(
            typeof container === 'string' ? sysSvc.document.querySelector(container) : container
        );

        let skipEls: PlayerDom[] = [];
        if (playerOpts.useNativeControls) {
            // Skip creation of these containers (not needed in a native control display)
            skipEls = [PlayerDom.CC_CONTAINER, PlayerDom.PANEL_CONTAINER];
        }

        const dc = new PlayerDomCreator(c, sysSvc.document);
        const domColl: PlayerDomInterface = dc.create(skipEls, playerOpts);
        const domProxy = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);

        domProxy.setModel(domColl);
        dc.destroy();

        domProxy.showAdContainer(false);

        if (playerOpts.useNativeControls) {
            domProxy.setVideoAttribute('controls', '');
        }

        playerOpts.playsInline !== false && domProxy.setVideoAttribute('playsinline', '');

        domProxy.setVideoAttribute('data-player-id', this.facade.appId);

        this.facade.registerProxy(new ActivityProxy(ProxyName.ActivityProxy, { container: c, document: sysSvc.document, idleDelay: playerOpts.idleDelay }));

        this.log(LogLevel.INFO, 'Player DOM created.');
    }
}
