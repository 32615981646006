import { PlayerOptionsInterface } from '../..';
import { Util } from '../../core/Util';
import { PlayerDom } from '../../enum/PlayerDom';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';


export class PlayerDomCreator {

    static createVideoElement() {
        const video = document.createElement("video");
        video.style.height = '100%';
        video.style.width = '100%';
        video.style.backgroundColor = '#000';
        video.dataset.elementId = Util.uid8();
        return video;
    }

    private document: HTMLDocument;
    private main: HTMLElement;

    constructor(container: HTMLElement, document: HTMLDocument) {
        this.document = document;
        this.main = container;
    }

    destroy(): void {
        this.main = null;
        this.document = null;
    }

    create(skipEls: PlayerDom[], playerOptions: PlayerOptionsInterface): PlayerDomInterface {
        const coll: StrAnyDict = {
            [PlayerDom.MAIN_CONTAINER]: this.main
        };
        const main = this.main;
        const s: StrAnyDict = {
            position: 'absolute',
            width: '100%',
            height: '100%',
            padding: 0,
            margin: 0,
            overflow: 'hidden'
        };
        const roles: PlayerDom[] = [
            PlayerDom.AD_CONTAINER,
            PlayerDom.PANEL_CONTAINER,
            PlayerDom.CC_CONTAINER,
            PlayerDom.VIDEO_CONTAINER,
        ];

        const style = main.style;
        style.position = style.position || 'relative';
        style.padding = style.padding || '0';
        style.margin = style.margin || '0';
        style.width = style.width || '100%';
        style.height = style.height || '100%';
        style.backgroundColor = style.backgroundColor || '#000';
        style.overflow = style.overflow || 'hidden';
        main.classList.add('cvp-container');
        main.classList.add('avia-container');

        // containers
        let refNode = main.hasChildNodes() ? main.firstChild : null;

        roles.forEach((role) => {
            if (Util.includes(skipEls, role)) {
                return;
            }

            const el = this.div(role, s);
            coll[role] = el;

            if (refNode) {
                main.insertBefore(el, refNode);
            }
            else {
                main.appendChild(el);
            }
            refNode = el;
        });

        // video element
        if (playerOptions.overrides?.reuseVideoElement !== false) {
            const v = PlayerDomCreator.createVideoElement();

            coll[PlayerDom.VIDEO_CONTAINER].appendChild(v);
            coll[PlayerDom.VIDEO_EL] = v;
        }

        // ad click element
        coll[PlayerDom.AD_CLICK_EL] = this.createAdClickEl();
        main.appendChild(coll[PlayerDom.AD_CLICK_EL]);

        return <PlayerDomInterface>{
            [PlayerDom.MAIN_CONTAINER]: coll[PlayerDom.MAIN_CONTAINER],
            [PlayerDom.VIDEO_EL]: coll[PlayerDom.VIDEO_EL],
            [PlayerDom.VIDEO_CONTAINER]: coll[PlayerDom.VIDEO_CONTAINER],
            [PlayerDom.CC_CONTAINER]: coll[PlayerDom.CC_CONTAINER],
            [PlayerDom.AD_CONTAINER]: coll[PlayerDom.AD_CONTAINER],
            [PlayerDom.AD_CLICK_EL]: coll[PlayerDom.AD_CLICK_EL],
            [PlayerDom.PANEL_CONTAINER]: coll[PlayerDom.PANEL_CONTAINER]
        };
    }

    private createAdClickEl(): HTMLElement {
        const style = {
            display: 'none',
            padding: '4px 8px',
            fontSize: '12px',
            background: '#dedede',
            lineHeight: '14px',
            color: '#000',
            position: 'absolute',
            right: '2px',
            top: '2px',
            textAlign: 'center'
        };
        const el = this.div('adClickEl', style);

        el.innerHTML = 'Learn More';

        return el;
    }

    private div(role: string, style: StrAnyDict): HTMLElement {
        const el = this.document.createElement('div');

        el.setAttribute('data-role', role);
        Util.assign(el.style, style);

        return el;
    }
}
