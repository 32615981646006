import { Util } from '../core/Util';
import { ProxyName } from '../enum/ProxyName';
import { QualityMode } from '../enum/QualityMode';
import { AudioTrackInterface } from '../iface/AudioTrackInterface';
import { AudioTracksInterface } from '../iface/AudioTracksInterface';
import { QualityInfoInterface } from '../iface/QualityInfoInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { TextTracksInterface } from '../iface/TextTracksInterface';
import { ThumbnailCueInterface } from '../iface/ThumbnailCueInterface';
import { Proxy } from '../mvc/Proxy';
import { ThumbnailTrackSurface } from '../playback/surface/ThumbnailTrackSurface';
import { Utils } from '../util/Utils';
import { ModelCollectionProxy } from './ModelCollectionProxy';
import { ContentPlaybackState } from './vo/ContentPlaybackState';


export class ContentPlaybackStateProxy extends Proxy {

    isReady: boolean = false;
    contentPlaybackState!: ContentPlaybackState;
    thumbnailTrack: ThumbnailTrackSurface;

    private pTextTracks: Record<string, TextTrackInterface>;
    private pUserQualityCategory: string | null = null;

    constructor(name: string, data?: any) {
        super(name, data);
    }

    override onRegister(): void {

        super.onRegister();

        const mcp = <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy);
        this.contentPlaybackState = mcp.getModel(ContentPlaybackState.modelName);

        this.contentPlaybackState.qualityInfo = <QualityInfoInterface>{
            mode: QualityMode.AUTO,
            quality: null,
            qualities: []
        };

        this.contentPlaybackState.textTracks = <TextTracksInterface>{
            track: null,
            tracks: []
        };

        this.contentPlaybackState.audioTracks = <AudioTracksInterface>{
            track: null,
            tracks: []
        };

        this.pTextTracks = {};

        this.isReady = true;
    }

    override onRemove(): void {
        this.contentPlaybackState = null;
        this.pTextTracks = null;
        this.thumbnailTrack = null;
        super.onRemove();
    }

    processQualityProfile(qualities: QualityInterface[]): void {
        this.tagQualities(qualities);
        this.contentPlaybackState.qualityInfo.qualities = qualities;
    }

    updateQualityProfile(minIndex: number, maxIndex: number, arr: QualityInterface[]): void {
        // based on capping we will recompile the capped bitrate profile each time min or max changes, user or capped player size based. 
        const profile = arr.slice(minIndex, maxIndex + 1);
        this.processQualityProfile(profile);
    }

    updateAudioTracks(track: AudioTrackInterface, tracks?: AudioTrackInterface[]) {
        const info = this.contentPlaybackState.audioTracks;
        info.track = track;

        if (tracks) {
            info.tracks = tracks;
        }
    }

    addTextTrack(track: TextTrackInterface) {
        this.contentPlaybackState.textTracks.tracks.push(track);
        this.pTextTracks[track.id] = track;
    }

    getTextTrackId(track: TextTrack): string {
        const id = Util.entries(this.pTextTracks)
            .filter(entry => entry[1] == track)
            .map(entry => entry[0]);

        return id[0];
    }

    getTextTrackById(id: string): TextTrackInterface {
        return this.pTextTracks[id];
    }

    tagQualities(qia: QualityInterface[]): void {
        qia.forEach(q => {
            if (!q.category) {
                q.category = [
                    `${String(Utils.getQualityCategoryForVideoHeight(q.height))}`,
                    `${q.height}p`,
                    `${Math.round(q.bitrate / 1000)} kbps`,
                ];
            }
        });
    }

    getThumbnail(time: number): ThumbnailCueInterface {
        return this.thumbnailTrack?.getCueAt(time);
    }

    get textTrackInfo(): TextTracksInterface {
        return this.contentPlaybackState.textTracks;
    }

    set textTrack(value: TextTrackInterface) {
        this.contentPlaybackState.textTracks.track = value;
    }

    get textTrack(): TextTrackInterface {
        return this.contentPlaybackState.textTracks.track;
    }

    get isAbrSwitchingAvailable(): boolean {
        return this.manifestQualities ? this.manifestQualities.length > 1 : false;
    }

    set userQualityCategory(q: string) {
        this.pUserQualityCategory = q;
    }
    get userQualityCategory() {
        return this.pUserQualityCategory;
    }

    set qualitySwitchingMode(mode: QualityMode) {
        this.contentPlaybackState.qualityInfo.mode = mode;
    }
    get qualitySwitchingMode(): QualityMode {
        return this.contentPlaybackState.qualityInfo.mode;
    }

    set manifestQualities(value: QualityInterface[]) {
        this.contentPlaybackState.manifestQualities = value;
    }
    get manifestQualities(): QualityInterface[] {
        return this.contentPlaybackState.manifestQualities;
    }

    set maxBandwidth(value: number) {
        this.contentPlaybackState.maxBandwidth = isNaN(this.contentPlaybackState.maxBandwidth)
            ? value : Math.max(value, this.contentPlaybackState.maxBandwidth);
    }

    get model(): ContentPlaybackState {
        return this.contentPlaybackState;
    }

    get minIndex(): number {
        return 0;
    }

    get maxIndex(): number {
        const qualities = this.contentPlaybackState.qualityInfo.qualities;
        return (qualities) ? this.contentPlaybackState.qualityInfo.qualities.length - 1 : 0;
    }

    get minBitrate(): number {
        return this.contentPlaybackState.qualityInfo.qualities[this.minIndex].bitrate;
    }

    get maxBitrate(): number {
        return this.contentPlaybackState.qualityInfo.qualities[this.maxIndex].bitrate;
    }

    get qualityInfo(): QualityInfoInterface {
        return this.contentPlaybackState.qualityInfo;
    }

    set quality(value: QualityInterface) {
        this.contentPlaybackState.qualityInfo.quality = value;
    }
}
