import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface, PlayerDomProxyInterface } from '../iface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { AbstractPresentationMediator } from '../view/AbstractPresentationMediator';
import { TimerMediator } from '../view/TimerMediator';
import { LogAwareSimpleCommand } from "./LogAwareSimpleCommand";

export class PresentationStateChangeCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        switch (notification.name) {
            case NotificationName.RESOURCE_COMPLETE:
                // The resource truly reached completion so advance the playlist, which will trigger <PresentationMediatorInterface>.close()
                const pl = <PlaylistInterface>this.getProxy(ProxyName.Playlist);
                pl && pl.next();

                return;

            case NotificationName.RESOURCE_END:
            case NotificationName.RESOURCE_INTERRUPTED:
                // <PresentationMediatorInterface>.close() has completed, kill the resource
                (<TimerMediator>this.facade.retrieveMediator(MediatorName.TIMER_MEDIATOR)).killTimer();

                this.sendNotification(NotificationName.KILL_RESOURCE_PRESENTATION);

                const uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI);
                uim && uim.resetUi();
                break;

            case NotificationName.CONTENT_BUFFERING:
            case NotificationName.AD_BUFFERING:
                const dom = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
                const main = dom?.getMain();
                main?.setAttribute('aria-busy', notification.body.value.toString());
                break;

            case NotificationName.AD_BREAK_START:
                // VTG-2223 - XBOX was the only platform showing symptoms, but change may work on others so not device specific targeting. 
                const pres = this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR) as AbstractPresentationMediator;
                pres?.adapter?.clearCue();
                break;
        }

        this.sendNotification(NotificationName.APP_EVENT, {
            notificationName: notification.name,
            data: notification.body
        });
    }
}
