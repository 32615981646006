import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { NotificationInterface } from '../iface';
import { EventInterface } from '../iface/EventInterface';
import { Timer } from '../util/Timer';
import { LogAwareMediator } from './LogAwareMediator';


export class TimerMediator extends LogAwareMediator {

    static INTERVAL = 100;

    private timer: Timer;

    constructor(name: string) {
        super(name);
        this.timer = new Timer(TimerMediator.INTERVAL);
        this.timer.on(Timer.TIC_EVENT, (e: EventInterface) => this.onTic(e));
    }

    killTimer(): void {
        this.timer?.stop();
    }

    override listNotificationInterests(): string[] {
        return [
            NotificationName.RESOURCE_START
        ];
    }

    handleNotification(notification: NotificationInterface) {

        switch (notification.name) {
            case NotificationName.RESOURCE_START:
                this.timer?.start();
                break;
        }
    }

    override onRegister(): void {
        super.onRegister();
    }

    override onRemove() {
        this.timer.destroy();
        this.timer = null;
        super.onRemove();
    }

    onTic(e: EventInterface) {
        this.facade.sendNotification(NotificationName.TIMER_TIC, { count: e.data.count }, NotificationType.INTERNAL);
    }
}
