import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackKind } from '../../enum/TextTrackKind';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { MetadataCuepointInterface } from '../../iface/MetadataCuepointInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { RangeInterface } from '../../iface/RangeInterface';
import { ResourcePlaybackAbrInterface } from '../../iface/ResourcePlaybackAbrInterface';
import { TextTrackInterface } from '../../iface/TextTrackInterface';
import { Playback } from '../enum/Playback';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { ShakaRobustness } from '../enum/ShakaRobustness';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { Category, Code, EmsgInfo, ErrorNS, LanguageRole, NetworkingEngine, Player, PlayerConfiguration, Request, Response, shaka, TimelineRegionInfo, Track } from '../interface/ShakaInterface';
import { Html5VideoSurface } from '../surface/Html5VideoSurface';
import { BaseHtml5Adapter } from './BaseHtml5Adapter';


export class ShakaAdapter extends BaseHtml5Adapter {

    protected override pType: PlaybackAdapterType = PlaybackAdapterType.SHAKA;

    private shaka: shaka = (<any>window).shaka;
    private player!: Player;
    private playerConfig!: PlayerConfiguration;
    private networkEngine: NetworkingEngine;
    private audioTracks!: LanguageRole[];
    private textTracks: Track[] = [];
    private pTextTrack: TextTrack;
    private currentTextTrack: Track;
    private currentTextTrackMode: TextTrackMode;
    private variant: Track;
    private pSegmentDuration: number = NaN;
    private playerEventMap = [
        this.mapEvent('error', this.onError),
        this.mapEvent('streaming', this.onManifestParsed),
        this.mapEvent('variantchanged', this.onBitrateChanged),
        this.mapEvent('adaptation', this.onBitrateChanged),
        this.mapEvent('emsg', this.onEmsg),
        this.mapEvent('trackschanged', this.onTracksChanged),
        this.mapEvent('variantchanged', this.onVariantChanged),
        this.mapEvent('adaptation', this.onAdaptation),
        //TODO this.mapEvent('manifestparsed', this.onManifestParsed),  Understand why are we not using this event?
        this.mapEvent('drmsessionupdate', this.onDrmSessionUpdate),
        this.mapEvent('texttrackvisibility', this.onTextTrackVisibility),
        this.mapEvent('timelineregionenter', this.onTimelineRegionEnter),
    ];
    private onCueChangeHandler: EventListenerOrEventListenerObject = (e: TrackEvent) => this.onCueChange(e);
    private audioSwitching: boolean = false;
    private renderTextTrackNatively: boolean = true;
    private cleanUpVtt: boolean = false;

    constructor(config: PlaybackAdapterConfigInterface) {
        super(config);

        this.logger.log(LogLevel.INFO, 'ShakaAdapter created');
        this.updateAudioTracks = Util.debounce(this.updateAudioTracks.bind(this), 25);
        this.updateTextTracks = Util.debounce(this.updateTextTracks.bind(this), 25);
        this.resize = Util.debounce(this.resize.bind(this), 250);
    }

    ////////////////////
    // Public Methods
    ////////////////////

    override createVideoSurface() {
        return new Html5VideoSurface(this.config, false);
    }

    override initialize(): void {
        super.initialize();

        // Shaka polyfill for VTTCue can cause issues when hlsjs is loaded after Shaka. See VTG-2189
        if (typeof VTTCue == 'undefined') {
            this.cleanUpVtt = true;
        }

        // player initialization
        this.shaka.polyfill.installAll();

        this.player = new this.shaka.Player(this.videoSurface.video);
        // @ts-ignore (static members are not allowed in interfaces)
        this.logger.log(LogLevel.INFO, `Shaka version: ${this.shaka.Player.version}`);
        this.playerConfig = this.player.getConfiguration();
        this.addEvents(this.player, this.playerEventMap);

        // retry config
        let retry = this.playerConfig.streaming.retryParameters;
        retry.maxAttempts = ShakaRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS;
        retry.baseDelay = ShakaRobustness.FATAL_ERROR_RECOVERY_DELAY;
        retry.backoffFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_BACKOFF;
        retry.fuzzFactor = ShakaRobustness.FATAL_ERROR_RECOVERY_FUZZ;

        retry = this.playerConfig.manifest.retryParameters;
        retry.maxAttempts = ShakaRobustness.MANIFEST_RETRY_ATTEMPTS;
        retry.baseDelay = ShakaRobustness.MANIFEST_RETRY_INTERVAL;

        // network config
        this.networkEngine = this.player.getNetworkingEngine();
        this.networkEngine.registerRequestFilter(this.onRequest.bind(this));
        this.networkEngine.registerResponseFilter(this.onResponse.bind(this));

        // drm config
        const drm = this.config.resource.location.drm;
        if (drm.widevine && drm.widevine.url) {
            this.playerConfig.drm.servers['com.widevine.alpha'] = drm.widevine.url;
        }

        if (drm.playready && drm.playready.url) {
            this.playerConfig.drm.servers['com.microsoft.playready'] = drm.playready.url;
        }

        // abr config
        const abrConfig: ResourcePlaybackAbrInterface = this.playback.abr;
        const abr = this.playerConfig.abr;

        if (!isNaN(abrConfig.minBitrate)) {
            abr.restrictions.minBandwidth = abrConfig.minBitrate;
        }

        if (!isNaN(abrConfig.maxBitrate)) {
            abr.restrictions.maxBandwidth = abrConfig.maxBitrate;
        }

        if (!isNaN(abrConfig.startBitrate)) {
            abr.defaultBandwidthEstimate = abrConfig.startBitrate * 1.15;
        }

        // performance settings
        const settings = this.config.performanceSettings;
        if (settings.forwardBufferLength != null) {
            this.playerConfig.streaming.bufferingGoal = settings.forwardBufferLength;
        }
        if (settings.backBufferLength != null) {
            this.playerConfig.streaming.bufferBehind = settings.backBufferLength;
        }

        this.renderTextTrackNatively = this.config.playerOptions.renderTextTrackNatively;
        if (this.renderTextTrackNatively == false) {
            this.playerConfig.streaming.alwaysStreamText = true;
        }

        // advanced video and audio codec settings
        const pbk = this.playback;
        const enableAdvancedCodecs = pbk.enableAdvancedCodecs;

        // TODO  - accept an empty array?
        const userVideoCodecs = !Util.isEmpty(pbk.preferredVideoCodecs) ? pbk.preferredVideoCodecs : null;
        const userAudioCodecs = !Util.isEmpty(pbk.preferredAudioCodecs) ? pbk.preferredAudioCodecs : null;

        if (enableAdvancedCodecs) {
            this.playerConfig.preferredVideoCodecs = userVideoCodecs || ['hev', 'hvc', 'dvhe'];
            this.playerConfig.preferredAudioCodecs = userAudioCodecs || ['ec-3', 'ac-3'];
        }

        this.playerConfig = this.mergeStreamingConfigs(this.playerConfig, this.config.resource.overrides);

        this.configure();
    }

    override resize(): void {
        if (!this.playback.abr.capQualityToScreenSize) {
            return;
        }

        const low = this.getVariantTracks()[0];
        if (!low) {
            return;
        }

        let { clientWidth, clientHeight } = this.videoSurface.video;

        if (clientWidth < low.width || clientHeight < low.height) {
            clientWidth = low.width;
            clientHeight = low.height;
        }

        const { restrictions } = this.playerConfig;
        if (restrictions.maxWidth === clientWidth && restrictions.maxHeight === clientHeight) {
            return;
        }

        restrictions.maxWidth = clientWidth;
        restrictions.maxHeight = clientHeight;
        this.configure();
    }

    override destroy(): Promise<void> {
        this.removeEvents(this.player, this.playerEventMap);

        if (this.pTextTrack) {
            this.pTextTrack.removeEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChangeHandler);
        }

        return this.player.destroy()
            .then(() => {
                this.player = null;
                this.networkEngine = null;
                this.playerConfig = null;
                this.shaka = null;
                this.textTracks = null;
                this.audioTracks = null;
                this.variant = null;
                this.pTextTrack = null;
                this.currentTextTrack = null;

                if (this.cleanUpVtt) {
                    delete window.VTTCue;
                }

                return super.destroy();
            });
    }

    override suspend(): void {
        this.playerConfig.streaming.bufferingGoal = 1;
        this.playerConfig.streaming.rebufferingGoal = 1;
    }

    override resume(): void {
        this.playerConfig.streaming.bufferingGoal = 10;
        this.playerConfig.streaming.rebufferingGoal = 10;
    }

    override clearCue(): void {
        Util.clearCue(this.pTextTrack, this.videoSurface.video.currentTime);
    }

    ////////////////////
    // Accessors
    ////////////////////
    override get seekable(): RangeInterface {
        return this.player.seekRange();
    }

    set autoQualitySwitching(value: boolean) {
        this.playerConfig.abr.enabled = value;
        this.configure();
    }
    get autoQualitySwitching(): boolean {
        return this.playerConfig.abr.enabled;
    }

    set currentIndex(index: number) {
        const track = this.getVariantTracks()[index];
        this.player.selectVariantTrack(track, true);
    }
    get currentIndex(): number {
        const index = Util.findIndex(this.getVariantTracks(), this.isActive);
        return index;
    }

    override get segmentDuration(): number {
        return this.pSegmentDuration;
    }

    get manifestQualities(): QualityInterface[] {
        return this.getVariantTracks().map((item: Track, index: number): QualityInterface => ({
            index,
            bitrate: item.bandwidth,
            width: item.width,
            height: item.height,
            codec: item.codecs
        }));
    }

    set maxBitrate(value: number) {
        if (isNaN(value)) {
            value = Infinity;
        }
        this.playerConfig.abr.restrictions.maxBandwidth = value;
        this.configure();
        this.constrainAbr();
    }
    get maxBitrate(): number {
        return this.playerConfig.abr.restrictions.maxBandwidth;
    }

    set minBitrate(value: number) {
        if (isNaN(value)) {
            value = -Infinity;
        }
        this.playerConfig.abr.restrictions.minBandwidth = value;
        this.configure();
        this.constrainAbr();
    }
    get minBitrate(): number {
        return this.playerConfig.abr.restrictions.minBandwidth;
    }

    override set audioTrack(track: AudioTrackInterface) {
        if (!track) {
            this.logger.log(LogLevel.INFO, 'ShakaAdapter: Audio track is null, will not set.');

            return;
        }
        this.audioSwitching = true;
        this.player.selectAudioLanguage(track.lang, track.type);
    }

    override get droppedVideoFrames(): number {
        const stats = this.player.getStats();
        return stats.droppedFrames;
    }

    override get framerate(): number {
        const track = this.variant;
        return track ? track.frameRate : Number.NaN;
    }

    override set textTrackMode(mode: TextTrackMode) {
        this.currentTextTrackMode = mode;

        if (this.renderTextTrackNatively) {
            this.player.setTextTrackVisibility(mode != TextTrackMode.DISABLED);

            // NOTE: The textTrack mode needs to be overridden because Shaka uses showing/hidden, but the player uses showing/disabled. 
            this.pTextTrack.mode = mode;
        }
        else {
            this.onTextTrackVisibility();
        }
    }

    override set textTrack(track: TextTrackInterface) {
        // NOTE: We need ts-ignore here because the object being passed in is a Shaka text track, not a DOM text track
        //@ts-ignore
        if (this.currentTextTrack && track.id == this.currentTextTrack.id) {
            return;
        }

        //@ts-ignore
        this.currentTextTrack = track;
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, this.currentTextTrack);
        this.player.selectTextTrack(<any>track);
    }

    override get fragmentType(): string {
        const track = this.getVariantTrack();
        return track ? track.mimeType : '';
    }

    ////////////////////
    // Protected Methods
    ////////////////////

    protected override loadMediaUrl(): Promise<void> {
        const startTime = this.playback.startTime;
        return this.player.load(this.mediaUrl, (isNaN(startTime) || startTime < 0) ? 0 : startTime)
            .then(() => {
                this.pIsLiveStream = this.player.isLive();

                this.loadedMetadata();

                // TODO: The player config only allows for a single side car file, with only a URL.
                //       This should be updated to accept an array of track objects.
                const textTrackUrl = this.config.resource.location.textTrackUrl;
                if (!Util.isEmpty(textTrackUrl)) {
                    const mime = Util.getMimeType(textTrackUrl);
                    this.player.addTextTrack(textTrackUrl, 'en', TextTrackKind.CAPTIONS, mime);
                }
            })
            // Shaka does not dispatch an error event when a manifest parse error occurs. Force one here:
            .catch((detail: any) => {
                throw this.createPlayerError(detail);
            });
    }

    ////////////////////
    // Event Handlers
    ////////////////////
    private onError(e: any): void {
        const { code, message, data, fatal } = this.createPlayerError(e.detail);
        this.throwError(code, message, data, fatal);
    }

    private onManifestParsed(e: any): void {
        this.pSegmentDuration = this.player.getManifest().presentationTimeline.getMaxSegmentDuration();
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onBitrateChanged(e: any) {
        this.constrainAbr();
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: this.currentIndex });
    }

    private onRequest(type: number, request: Request): void {
        const drm = this.config.resource.location.drm;

        if (type === this.shaka.net.NetworkingEngine.RequestType.LICENSE && drm.enabled) {
            if (drm.widevine && drm.widevine.header) {
                Util.assign(request.headers, drm.widevine.header);
            }

            if (drm.playready && drm.playready.header) {
                Util.assign(request.headers, drm.playready.header);
            }
        }
    }

    private onResponse(type: number, response: Response): void {
        if (this.multiCdnHeaderPresent) {
            const cdn = response.headers[Playback.MULTI_CDN];
            this.multiCdnHeaderPresent = (cdn != null);
            if (this.multiCdnHeaderPresent) {
                this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn });
            }
        }

        if (type === this.shaka.net.NetworkingEngine.RequestType.SEGMENT) {
            const bandwidth = this.player.getStats().estimatedBandwidth;
            this.emit(PlaybackAdapterEvents.FRAGMENT_LOADED, { bandwidth });
        }
    }

    private onEmsg(e: any): void {
        const emsg: EmsgInfo = e.detail;
        const cue: MetadataCuepointInterface = {
            id: emsg.schemeIdUri,
            info: emsg.value,
            data: emsg.messageData
        };
        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, cue);
    }

    private onTracksChanged(e: any): void {
        this.resize();

        this.updateAudioTracks();
        this.updateTextTracks();
    }

    private onAdaptation(e: any): void {
        this.variant = this.getVariantTrack();
    }

    private onVariantChanged(e: any): void {
        if (this.audioSwitching) {
            this.audioSwitching = false;
            this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[this.getAudioTrackIndex()] });
        }

        this.variant = this.getVariantTrack();
    }

    private onDrmSessionUpdate(e: any): void {
        this.emit(PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED, { keysystem: this.player.keySystem() });
    }

    private onTextTrackVisibility(): void {
        if (!this.currentTextTrackMode) {
            return;
        }
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode: this.currentTextTrackMode });
    }

    private onCueChange(e: Event): void {
        if (this.renderTextTrackNatively || !this.config.textTrackSettings.enabled) {
            return;
        }
        const t = e.target as TextTrack;
        this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues: t.activeCues });
    }

    private onTimelineRegionEnter(e: any) {
        if (this.videoSurface.video.seeking) {
            return;
        }

        const info: TimelineRegionInfo = e.detail;
        const cue: MetadataCuepointInterface = {
            id: info.schemeIdUri,
            info: info.value || info.eventElement.getAttribute('messageData'),
            data: <any>info
        };

        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, cue);
    }

    ////////////////////
    // Private Methods
    ////////////////////
    private isActive(track: Track): boolean {
        return track.active;
    }

    private getAudioTrackIndex(): number {
        const { language, audioRoles } = this.getVariantTracks()[this.currentIndex];
        const role = audioRoles.join(',');
        return Util.findIndex(this.audioTracks, (item: any) => item.language == language && item.role == role);
    }

    private configure(): void {
        this.player.configure(this.playerConfig);
    }

    private constrainAbr(): void {
        const maxIndex = Util.getIndexForBitrate(this.manifestQualities, this.maxBitrate, false);
        this.checkAbrConstraints(maxIndex);
    }

    private getVariantTracks(): Track[] {
        return this.player.getVariantTracks().sort((a: Track, b: Track): number => a.bandwidth - b.bandwidth);
    }

    private getVariantTrack(): Track {
        return Util.find(this.player.getVariantTracks(), this.isActive);
    }

    private updateAudioTracks(): void {
        //since this fires on bitrate changes... do we want to check if the lang and roles has changed and only then process and emit?
        this.audioTracks = this.player.getAudioLanguagesAndRoles();
        this.normalizedAudioTracks = this.normalizeAudioTracks(this.audioTracks, {
            type: 'role',
            lang: 'language',
            label: 'language'
        });

        // Filter tracks labeled 'und' that are sometimes inserted during ad content
        const isUnd = (track: AudioTrackInterface) => track.lang === 'und';
        const tracks = this.normalizedAudioTracks.filter(track => !isUnd(track));
        let track = this.normalizedAudioTracks[this.getAudioTrackIndex()];

        if (isUnd(track)) {
            const idx = this.normalizedAudioTracks.length > track.index + 1 ? track.index + 1 : track.index;
            track = this.normalizedAudioTracks[idx];
            this.audioTrack = track;
        }

        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, { tracks, track });
    }

    private updateTextTracks(): void {
        this.player.getTextTracks().forEach(track => {
            const hasTrack = this.textTracks.some(t => t.id == track.id);
            if (!hasTrack) {
                this.textTracks.push(track);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
            }
        });

        const available = !this.currentTextTrack;
        const active = Util.find(this.textTracks, this.isActive);

        if (available) {
            this.pTextTrack = Util.find(this.videoSurface.video.textTracks, (textTrack: TextTrack) => textTrack.label == 'Shaka Player TextTrack');
            this.pTextTrack.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChangeHandler);

            const track = active || Util.findDefaultTrack(this.textTracks as any, this.config.textTrackSettings.language) as any;
            if (track) {
                //@ts-ignore
                this.currentTextTrack = track;
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, this.currentTextTrack);
            }
        }

        if (active && active != this.currentTextTrack) {
            //@ts-ignore
            this.textTrack = active;
        }

        // re-apply the text track visibility to workaround issues where
        // visibility was enabled during a period with no text tracks.
        if (this.renderTextTrackNatively) {
            this.player.setTextTrackVisibility(this.config.textTrackSettings.enabled);
        }
    }

    private createPlayerError(error: any) {
        const Error: ErrorNS = this.shaka.util.Error;
        const Category: Category = Error.Category;
        const Code: Code = Error.Code;

        const toError = (code: string, message: string, data: any, fatal: boolean = true) => {
            // Shaka Player errors do not have a string based message, so find the key associated with numeric error code.
            for (const key in Code) {
                const value = Code[key];
                if (value == error.code) {
                    message += ` : ${key} / ${value}`;
                    break;
                }
            }

            return { code, message, data, fatal };
        };

        switch (error.category) {
            case Category.NETWORK:
                return toError(ErrorCode.SHAKA_NETWORK_ERROR, AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR, error);

            case Category.MANIFEST:
                return toError(ErrorCode.SHAKA_PARSE_ERROR, AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR, error);

            case Category.MEDIA:
                const code = (error.code == Code.VIDEO_ERROR && error.data[0] == MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) ? ErrorCode.SHAKA_SRC_NOT_SUPPORTED : ErrorCode.SHAKA_MEDIA_ERROR;
                return toError(code, AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR, error);

            case Category.DRM:
                return toError(ErrorCode.SHAKA_DRM_ERROR, AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR, error);

            default:
                return toError(ErrorCode.UNSPECIFIED_SHAKA_ERROR, AppResources.messages.UNSPECIFIED_ERROR, error, false);
        };
    }
}
