import { NotificationInterface } from '../iface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { AbstractSimpleCommand } from '../mvc/AbstractSimpleCommand';


export abstract class LogAwareSimpleCommand extends AbstractSimpleCommand {

    protected constructor() {
        super();
    }

    abstract override execute(notification: NotificationInterface): void;

    get logger(): LoggerInterface {
        return this.facade.logger;
    }

    log(...args: any[]): void {
        this.logger.log.apply(this.logger, args);
    }
}
