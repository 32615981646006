import { StrAnyDict } from '../../../iface/StrAnyDict';
import { dai } from '../dai';


export class Ad implements dai.Ad {

    private data: dai.AdResponse;
    private podInfo: dai.AdPodInfo;

    constructor(adResponse: dai.AdResponse, podInfo: dai.AdPodInfo) {
        this.data = adResponse;
        this.podInfo = podInfo;
    }

    getAdId(): string {
        return this.data.ad_id;
    }

    getAdPodInfo(): dai.AdPodInfo {
        return this.podInfo;
    }

    getAdSystem(): string | null {
        return this.data.advertiser || null;
    }

    getAdvertiserName(): string | null {
        return this.data.advertiser || null;
    }

    getApiFramework(): string {
        return null;
    }

    getCreativeAdId(): string {
        return this.data.creative_ad_id;
    }

    getCreativeId(): string {
        return this.data.creative_id;
    }

    getDealId(): string | null {
        return this.data.deal_id || null;
    }

    getDescription(): string {
        return this.data.description;
    }

    getDuration(): number {
        return this.data.duration;
    }

    getTitle(): string {
        return this.data.title;
    }

    getDaiWrapperData(): Record<string, string> {
        let adId = null;
        let creativeId = null;
        let creativeAdId = null;

        if (this.data.wrappers && this.data.wrappers[0]) {
            const w0 = this.data.wrappers[0];
            adId = w0.ad_id;
            creativeId = w0.creative_id;
            creativeAdId = w0.creative_ad_id;
        }

        return { adId, creativeId, creativeAdId };
    }

    getWrapperAdIds(): string[] {
        return this.getWrapperPropArray('ad_id');
    }

    getWrapperCreativeIds(): string[] {
        return this.getWrapperPropArray('creative_id');
    }

    getWrapperCreativeAdIds(): string[] {
        return this.getWrapperPropArray('creative_ad_id');
    }

    getWrapperAdSystems(): string[] {
        return this.getWrapperPropArray('system');
    }

    getCompanionAds(): dai.CompanionAd[] | null {
        return null;
    }

    private getWrapperPropArray(propName: string): string[] {
        const out = [],
            wrappers = this.data.wrappers || null;

        if (!wrappers) {
            return null;
        }

        let item: string, w;
        for (let i = 0, n = wrappers.length; i < n; i++) {
            w = <StrAnyDict>wrappers[i];
            item = w[propName];
            item && item != '' && out.push(item);
        }

        return out;
    }
}
