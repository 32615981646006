import { AdTechnology } from '../../enum/AdTechnology';
import { AppResources } from '../../app/AppResources';
import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { AdContext } from '../../enum/AdContext';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { AdBreakScheduleItemInterface } from '../../iface/AdBreakScheduleItemInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { ResourceAdInterface } from '../../iface/ResourceAdInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { AdBreakCollectionInterface } from './AdBreakCollectionInterface';
import { AdBreakSchedule } from './AdBreakSchedule';
import {
    ImaAdErrorInfoInterface,
    ImaAdInfoInterface,
    ImaAdPlayerEventInterface,
    ImaAdPlayerOptions,
    ImaAdServiceInterface,
    ImaAdsLoaderInterface,
    ImaAdsRenderingSettingsInterface
} from './iface';
import { ima } from './ima';
import { ImaAdPlayerInterface } from './ImaAdPlayerInterface';
import { ImaAdsLoader } from './ImaAdsLoader';
import AdErrorEvent = ima.AdErrorEvent;


interface AdProgressDataInterface {
    lastRecordedTime: number;
    pollTzero: number;
    addTzero: number;
    timeoutHandle: any;
    stallThreshold: number;
    adId: string | null;
    duration: number;
    pollInterval: number;
}

export class ImaAdPlayer extends Emitter implements ImaAdPlayerInterface {

    static event: ImaAdPlayerEventInterface = {
        CONTENT_PAUSE_REQUESTED: 'contentPauseRequested',
        CONTENT_RESUME_REQUESTED: 'contentResumeRequested',
        AD_CLICK_ELEMENT_VISIBILITY_REQUESTED: 'adClickElementVisibilityRequested',
        AD_CUEPOINTS_AVAILABLE: 'adCuepointsAvailable',
        AD_INIT_ERROR: 'adInitError',
        AD_BREAK_START: 'adBreakStart',
        AD_BREAK_METADATA: 'adBreakMetadata',
        AD_LOADED: 'adLoaded',
        AD_START: 'adStarted',
        AD_PROGRESS: 'adProgress',
        AD_FIRST_QUARTILE: 'adFirstQuartile',
        AD_MIDPOINT: 'adMidpoint',
        AD_THIRD_QUARTILE: 'adThirdQuartile',
        AD_COMPLETE: 'adComplete',
        ALL_ADS_COMPLETED: 'allAdsCompleted',
        AD_BREAK_COMPLETE: 'adBreakComplete',
        AD_BREAK_DISCARDED: 'adBreakDiscarded',
        AD_BUFFERING: 'adBuffering',
        AD_PAUSED: 'paused',
        AD_RESUMED: 'resumed',
        AD_SKIPPED: 'adSkipped',
        AD_CLICK: 'adClick',
        AD_ERROR: 'adError',
        AD_VOLUME_CHANGED: 'adVolumeChanged',
        AD_MUTED: 'adMuted',
        AD_STALLED: 'adStalled',
        RAW_AD_SCHEDULE_AVAILABLE: 'rawAdScheduleAvailable'
    };

    private readonly adCallUrl: string;
    private readonly adsLoaderEventMap: Record<string, EventHandler>;
    private adsLoader: ImaAdsLoaderInterface;
    private adService: ImaAdServiceInterface;
    private sdk: ima.Sdk;
    private presentationContainer: HTMLElement;
    private adEventHandler: (e: ima.AdEvent) => void;
    private errorEventHandler: (e: ima.AdErrorEvent) => void;
    private pIsFullscreen: boolean = false;
    private inProgressAd: ImaAdInfoInterface | null = null;
    private inProgressBreakId: string = null;
    private isPaused: boolean = false;

    private renderingSettings!: ima.AdsRenderingSettings;
    private adsManager!: ima.AdsManager;
    private adBreakSchedule: AdBreakSchedule;
    private breakStartReleased: boolean = false;
    private pendingSeekedBreakId: string = null;
    private markedAsPlayed: string[] = null;
    private logger: LoggerInterface = null;
    private adProgressData: AdProgressDataInterface = {
        lastRecordedTime: 0,
        addTzero: null,
        pollTzero: null,
        timeoutHandle: null,
        stallThreshold: 5,
        adId: null,
        duration: null,
        pollInterval: 2000,
    };

    constructor(options: ImaAdPlayerOptions) {
        super(options);

        this.adCallUrl = options.adCallUrl;
        this.presentationContainer = options.presentationContainer;
        this.adService = options.adService;
        this.sdk = this.adService.sdk;
        this.adsLoader = this.adService.adsLoader;
        this.adProgressData.stallThreshold = options.stallThreshold || this.adProgressData.stallThreshold;

        this.logger = options.logger;

        delete this.opts.presentationContainer;

        this.adsLoaderEventMap = {};
        const iae = ImaAdsLoader.event;
        this.adsLoaderEventMap[iae.ADS_LOADER_ERROR] = (e: EventInterface) => this.hAdsLoaderError(e);
        this.adsLoaderEventMap[iae.ADS_MANAGER_LOADED] = (e: EventInterface) => this.hAdsMgrLoaded(e);

        this.adEventHandler = (e: ima.AdEvent) => this.hAdEvent(e);
        this.errorEventHandler = (e: ima.AdErrorEvent) => this.hAdsManagerAdError(e);
        this.initialize();
    }

    override destroy(): void {
        clearTimeout(this.adProgressData.timeoutHandle);

        this.listenToAdsLoader(false);
        this.listenToAdsManager(false);

        this.adsManager?.destroy();
        this.adsLoader?.contentComplete();

        this.adBreakSchedule && this.adBreakSchedule.destroy();

        this.presentationContainer = null;
        this.adsManager = null;
        this.sdk = null;
        this.adsLoader = null;
        this.adEventHandler = null;
        this.adBreakSchedule = null;
        this.renderingSettings = null;
        this.logger = null;

        super.destroy();
    }

    get adBreaks(): AdBreakCollectionInterface {
        return this.adBreakSchedule ? this.adBreakSchedule.adBreaks : null;
    }

    set contentBitrate(br: number) {
        if (this.renderingSettings && this.adsManager) {
            this.renderingSettings.bitrate = br;
            this.adsManager.updateAdsRenderingSettings(this.renderingSettings);
        }
    }

    set volume(value: number) {
        this.adsManager && this.adsManager.setVolume(Util.clampValue(value, 0, 1));
    }

    set isFullscreen(value: boolean) {
        this.pIsFullscreen = value;
    }

    pauseAd(): void {
        clearTimeout(this.adProgressData.timeoutHandle);
        this.isPaused = true;
        this.adsManager?.pause();
    }

    resumeAd(): void {
        clearTimeout(this.adProgressData.timeoutHandle);
        this.isPaused = false;
        this.adsManager?.resume();
    }

    mute(): void {
        this.volume = 0;
    }

    unmute(volume: number) {
        this.volume = volume;
    }

    hasPostRoll(): boolean {
        return !!(this.adBreakSchedule?.hasPostRoll());
    }

    hasMidRolls(): boolean {
        return !!(this.adBreakSchedule?.hasMidRolls());
    }

    startAds(): void {
        const rect: DOMRect = this.getRect(),
            view = this.pIsFullscreen ? this.sdk.ViewMode.FULLSCREEN : this.sdk.ViewMode.NORMAL;

        try {
            this.adsManager.init(rect.width, rect.height, view);
            this.adsManager.start();
        }
        catch (adError) {
            // IMA Doc says: if error thrown here, play content - no ads will play
            this.log('AdsManager init failure.');
            this.emit(ImaAdPlayer.event.AD_INIT_ERROR, {
                code: ErrorCode.IMA_INIT_FAILURE,
                message: adError.getMessage ? adError.getMessage() : AppResources.messages.UNSPECIFIED_ERROR
            });
        }
    }

    markAsPlayed(ids: string[]): void {
        if (!this.markedAsPlayed) {
            this.markedAsPlayed = ids;
        }
        else {
            this.markedAsPlayed = this.markedAsPlayed.concat(ids);
        }
    }

    getBreakForSeekTime(requestedSeekTime: number): AdBreakScheduleItemInterface | null {
        if (this.adBreakSchedule && this.adBreakSchedule.hasMidRolls()) {
            const b: AdBreakScheduleItemInterface = this.adBreakSchedule.getBreakForContentSeekTime(requestedSeekTime);
            if (b && (!this.markedAsPlayed || this.markedAsPlayed.indexOf(b.breakId) === -1)) {
                this.pendingSeekedBreakId = b.breakId;

                return b;
            }
        }

        return null;
    }

    cancelPendingBreak(id: string): void {
        this.pendingSeekedBreakId === id && (this.pendingSeekedBreakId = null);
    }

    isUsingContentVideoElement(): boolean {
        return this.adsManager && this.adsManager.isCustomPlaybackUsed();
    }

    isUsingCustomClickTracker(): boolean {
        return !!(this.adsManager && this.adsManager.isCustomClickTrackingUsed());
    }

    setContentComplete(): void {
        this.adsLoader?.contentComplete();
    }

    updateSize(isFullscreen: boolean): void {
        const r: DOMRect = this.getRect();

        if (this.adsManager) {
            const vm = isFullscreen ? this.sdk.ViewMode.FULLSCREEN : this.sdk.ViewMode.NORMAL;
            this.adsManager.resize(r.width, r.height, vm);
        }
    }

    //////////
    // PRIVATE
    private hAdsManagerAdError(e: AdErrorEvent) {
        const err: ImaAdErrorInfoInterface = this.adService.parseAdError(e);

        this.log("AdsManager ERROR", err);
        this.emit(ImaAdPlayer.event.AD_ERROR, {
            code: ErrorCode.IMA_AD_ERROR,
            message: (err && err.message) || AppResources.messages.UNSPECIFIED_ERROR,
            data: err
        });
    }

    private hAdEvent(e: ima.AdEvent): void {
        const t = this.sdk.AdEvent.Type,
            ad: ima.Ad = e.getAd(),
            adData: any = e.getAdData(),
            myEvt: ImaAdPlayerEventInterface = ImaAdPlayer.event;

        clearTimeout(this.adProgressData.timeoutHandle);
        let restartPoll: boolean = this.opts.enableStalledAdCheck !== false;

        // e.type != 'adProgress' && console.log('%cIMA AD EVENT: ' + e.type, 'color: red');

        // Only set 'restartPoll' to FALSE in case blocks below as needed.

        switch (e.type) {
            case t.CONTENT_PAUSE_REQUESTED:
                restartPoll = false;
                this.pendingSeekedBreakId = null;
                this.emit(myEvt.CONTENT_PAUSE_REQUESTED);
                break;

            case t.CONTENT_RESUME_REQUESTED:
                this.log("IMA SDK requested content resume.");
                restartPoll = false;
                this.pendingSeekedBreakId = null;
                this.processBreakComplete();
                break;

            case t.LOADED:
                restartPoll = false;
                this.processAdLoadOrStart(ad, adData, t.LOADED);
                break;

            case t.STARTED:
                restartPoll = false;
                if (this.inProgressBreakId && !this.breakStartReleased) {
                    this.breakStartReleased = true;
                    this.emit(ImaAdPlayer.event.AD_BREAK_START, { adBreakId: this.inProgressBreakId });
                }
                this.processAdLoadOrStart(ad, adData, t.STARTED);
                this.checkForCustomClickTracking();
                this.beginPollForProgress(this.inProgressAd);
                break;

            case t.AD_PROGRESS:
                this.processProgress();
                break;

            case t.FIRST_QUARTILE:
                this.processQuartile(t.FIRST_QUARTILE, myEvt.AD_FIRST_QUARTILE);
                break;

            case t.MIDPOINT:
                this.processQuartile(t.MIDPOINT, myEvt.AD_MIDPOINT);
                break;

            case t.THIRD_QUARTILE:
                this.processQuartile(t.THIRD_QUARTILE, myEvt.AD_THIRD_QUARTILE);
                break;

            case t.COMPLETE:
                restartPoll = false;
                this.processAdComplete(t.COMPLETE);
                break;

            case t.ALL_ADS_COMPLETED:
                restartPoll = false;
                this.processAllComplete();
                break;

            case t.AD_BUFFERING:
                this.emit(myEvt.AD_BUFFERING);
                break;

            case t.CLICK:
                restartPoll = false;
                this.adsManager.pause();
                this.emit(myEvt.AD_CLICK, { ad: this.inProgressAd });
                break;

            case t.PAUSED:
                restartPoll = false;
                this.isPaused = true;
                this.emit(myEvt.AD_PAUSED);
                break;

            case t.RESUMED:
                this.isPaused = false;
                this.emit(myEvt.AD_RESUMED);
                break;

            case t.SKIPPED:
                restartPoll = false;
                this.processSkip(t.SKIPPED);
                break;

            case t.LOG:
                restartPoll = false;
                this.processLogEvent(adData);
                break;

            case t.USER_CLOSE:
                restartPoll = false;
                this.killAd();
                break;

            case t.VOLUME_CHANGED:
            case t.VOLUME_MUTED:
                this.processVolumeChange(e.type);
                break;
        }

        if (!this.isPaused && restartPoll) {
            this.adProgressData.timeoutHandle = setTimeout(
                () => this.checkAdProgress(),
                this.adProgressData.pollInterval
            );
        }
    }

    private checkForCustomClickTracking(): void {
        if (this.isUsingCustomClickTracker()) {
            this.emit(ImaAdPlayer.event.AD_CLICK_ELEMENT_VISIBILITY_REQUESTED);
        }
    }

    private beginPollForProgress(ad: ImaAdInfoInterface): void {
        const o = this.opts as ImaAdPlayerOptions;
        if (o.enableStalledAdCheck !== true) {
            return;
        }

        const apd = this.adProgressData;
        clearTimeout(apd.timeoutHandle);

        apd.duration = ad.adDuration;
        apd.adId = ad.adId;
        apd.pollTzero = Date.now();
        apd.timeoutHandle = setTimeout(() => this.checkAdProgress(), apd.pollInterval);
    }

    private checkAdProgress(): void {
        const apd = this.adProgressData,
            currT = apd.duration - this.adsManager.getRemainingTime(),
            now = Date.now();

        clearTimeout(apd.timeoutHandle);

        if (this.isPaused) {
            return;
        }

        if (apd.pollTzero === null) {
            apd.pollTzero = Date.now();
        }

        if (currT > apd.lastRecordedTime) {
            apd.lastRecordedTime = currT;
            apd.pollTzero = now;
            apd.timeoutHandle = setTimeout(() => this.checkAdProgress(), apd.pollInterval);
        }
        else if (((now - apd.pollTzero) * 0.001) > apd.stallThreshold) {
            const adInfo = this.inProgressAd ? Util.assign({}, this.inProgressAd) : null;
            this.log('Ad stalled; discarding break');
            this.emit(ImaAdPlayer.event.AD_STALLED, {
                code: ErrorCode.AD_STALLED,
                message: AppResources.messages.AD_STALLED,
                data: { adInfo: adInfo }
            });
            this.killAd();
            this.emit(ImaAdPlayer.event.AD_BREAK_DISCARDED);
            this.adsManager && this.adsManager.discardAdBreak(); // yields a content_resume request from the SDK)

            this.processBreakComplete();
        }
    }

    ////////////////////
    // Event processors - process IMA events, dispatch an ImaAdPlayer.event

    // Process ad break for both start and loaded (some info may only be avail at start, vs load)
    private processAdLoadOrStart(ad: ima.Ad, adData: StrAnyDict, evt: string): void {
        const isStart = evt === this.sdk.AdEvent.Type.STARTED;
        const abi: AdBreakInfoInterface = this.adService.assembleAdBreakInfo(ad, AdTechnology.CSAI);
        const ai: ImaAdInfoInterface = this.adService.assembleAdInfo(ad, adData, isStart);
        const idx = abi.adBreakPosition;
        const id = idx === 0 ? 'pre_0' : idx === -1 ? 'post_0' : 'mid_' + idx;

        if (this.markedAsPlayed && this.markedAsPlayed.indexOf(id) >= 0) {
            this.adBreakSchedule.markPlayed(id);
            this.adsManager.discardAdBreak();

            return;
        }

        if (isStart) {
            this.inProgressAd = ai;
            this.adService.trackAd(ai);
            this.adService.trackAdEvent({
                context: AdContext.IMA,
                eventName: evt,
                volume: this.adsManager.getVolume()
            });

            this.emit(ImaAdPlayer.event.AD_BREAK_METADATA, abi);
            this.emit(ImaAdPlayer.event.AD_START, ai);

        }
        else {
            if (!this.adBreakSchedule.hasPlayed(id)) {
                this.adBreakSchedule.markPlayed(id);
                this.inProgressBreakId = id;
            }
            this.emitAdLoaded(ai);
        }
    }

    private emitAdLoaded(adInfo: ImaAdInfoInterface): void {
        this.emit(ImaAdPlayer.event.AD_LOADED, adInfo);
    }

    private processProgress(): void {
        if (!this.inProgressAd) {
            return;
        }
        const dur = this.inProgressAd.adDuration,
            tRem = this.adsManager && this.adsManager.getRemainingTime();

        if (isNaN(dur) || isNaN(tRem) || tRem < 0) {
            return;
        }

        this.emit(ImaAdPlayer.event.AD_PROGRESS, {
            currentTime: Math.min(dur, Math.max(0, dur - tRem)),
            duration: dur
        });
    }

    private processQuartile(imaEvent: string, localEvent: string): void {
        this.emit(localEvent, { ad: this.inProgressAd });

        this.adService.trackAdEvent({
            context: AdContext.IMA,
            eventName: imaEvent,
            volume: this.adsManager.getVolume()
        });
    }

    private processAdComplete(imaEvt: string): void {
        this.adService.trackAdEvent({
            context: AdContext.IMA,
            eventName: imaEvt,
            volume: this.adsManager.getVolume()
        });

        this.killAd();
    }

    private processSkip(imaEvt: string) {
        this.emit(ImaAdPlayer.event.AD_SKIPPED, {
            ad: this.inProgressAd
        });

        this.adService.trackAdEvent({
            context: AdContext.IMA,
            eventName: imaEvt,
            volume: this.adsManager.getVolume()
        });

        this.killAd();
    }

    private processBreakComplete() {
        if (this.inProgressAd) {
            this.killAd();
        }
        if (this.inProgressBreakId) {
            this.emit(ImaAdPlayer.event.AD_BREAK_COMPLETE, { adBreakId: this.inProgressBreakId });
            this.inProgressBreakId = null;
        }
        this.breakStartReleased = false;
        this.emit(ImaAdPlayer.event.CONTENT_RESUME_REQUESTED);
    }

    private processVolumeChange(imaEvtType: string) {
        this.adService.trackAdEvent({
            context: AdContext.IMA,
            eventName: imaEvtType,
            volume: this.adsManager.getVolume()
        });
    }

    private processAllComplete() {
        if (this.inProgressBreakId) {
            this.processBreakComplete();
        }
        this.emit(ImaAdPlayer.event.ALL_ADS_COMPLETED);
    }

    private processLogEvent(adData: any): void {
        if (this.pendingSeekedBreakId) {
            this.log("IMA SDK LOG event; break is pending.");
            // r. 1.6.x = omit marking played without an error present
            // this.adBreakSchedule.markPlayed(this.pendingSeekedBreakId);
            // this.pendingSeekedBreakId = null;
        }
        if (adData && adData.adError) {
            // if an error is found here, there may be a subsequent
            // contentPauseRequest but not a resume, and presentation
            // will stall unless ad break is discarded
            this.log("LOG event; Ad data has error: msg = " + adData.adError.getMessage() || 'no message available');

            this.emit(ImaAdPlayer.event.AD_ERROR, {
                code: ErrorCode.IMA_AD_ERROR,
                message: adData.adError.getMessage() || AppResources.messages.UNSPECIFIED_ERROR,
                data: {
                    errorCode: adData.adError.getErrorCode()
                }
            });
            this.log('LOG event; Discarding Ad break');
            this.emit(ImaAdPlayer.event.AD_BREAK_DISCARDED);
            this.adsManager && this.adsManager.discardAdBreak();
        }
    }

    // end Event Processors
    ///////////////////////
    private killAd(): void {
        this.adService.untrackAd();
        this.emit(ImaAdPlayer.event.AD_COMPLETE, { ad: this.inProgressAd });
        this.inProgressAd = null;

        const apd = this.adProgressData;
        clearTimeout(apd.timeoutHandle);
        apd.pollTzero = null;
        apd.lastRecordedTime = null;
    }

    private requestAds(): void {
        const ar = new this.sdk.AdsRequest(),
            r = this.getRect();
        ar.adTagUrl = this.adCallUrl;
        ar.linearAdSlotWidth = r.width;
        ar.linearAdSlotHeight = r.height;
        ar.nonLinearAdSlotWidth = r.width;
        ar.nonLinearAdSlotHeight = r.height;

        ar.setAdWillAutoPlay(this.opts.initialPlayState.autoplay);
        ar.setAdWillPlayMuted(this.opts.initialPlayState.muted);

        // async; yields AdsManagerLoaded event (see hAdsMgrLoaded)
        this.adsLoader.requestAds(ar);
    }

    // Ads Mgr Loaded
    private hAdsMgrLoaded(e: EventInterface): void {
        const amle = <ima.AdsManagerLoadedEvent>e.data;

        this.renderingSettings = this.getRenderingSettings();

        this.adsManager = amle.getAdsManager(this.opts.playheadInterface, this.renderingSettings);
        this.createAdBreakSchedule(this.adsManager.getCuePoints());
        this.listenToAdsManager(true);

        this.startAds();
    }

    private getRenderingSettings(): ima.AdsRenderingSettings {
        const rs: ima.AdsRenderingSettings = new this.sdk.AdsRenderingSettings(),
            sets: ResourceAdInterface = this.opts.ad,
            defs: ImaAdsRenderingSettingsInterface = this.adService.defaultRenderingSettings,
            sTimeout = sets.ima.loadVideoTimeoutMs,
            sPreLoadAds = sets.ima.enableAdPreloading;

        rs.bitrate = defs.bitrate;
        rs.enablePreloading = Util.isBoolean(sPreLoadAds) ? sPreLoadAds : defs.enablePreloading;
        rs.loadVideoTimeout = !isNaN(sTimeout) ? sTimeout : defs.loadVideoTimeout;
        rs.restoreCustomPlaybackStateOnAdBreakComplete = defs.restoreCustomPlaybackStateOnAdBreakComplete;

        return rs;
    }

    private createAdBreakSchedule(cuePoints: number[]): void {
        if (Util.isEmpty(cuePoints)) {
            cuePoints = [0];
        }

        this.adBreakSchedule = new AdBreakSchedule(cuePoints);

        this.emit(ImaAdPlayer.event.RAW_AD_SCHEDULE_AVAILABLE, {
            cuepoints: cuePoints
        });

        this.emit(ImaAdPlayer.event.AD_CUEPOINTS_AVAILABLE, {
            cuepoints: cuePoints
        });
    }

    private hAdsLoaderError(e: EventInterface): void {
        this.emit(ImaAdPlayer.event.AD_ERROR, {
            code: ErrorCode.IMA_AD_ERROR,
            message: e.data.message,
            data: e.data
        });

        this.log("AdsLoader ERROR", e.data);

        this.emit(ImaAdPlayer.event.CONTENT_RESUME_REQUESTED);
    }

    private listenToAdsManager(flag: boolean): void {
        if (!this.adsManager) {
            return;
        }

        const m = flag ? 'addEventListener' : 'removeEventListener',
            events: string[] = this.getEventInterests();
        let event;

        for (let i = 0, n = events.length; i < n; i++) {
            event = this.sdk.AdEvent.Type[events[i]];
            (<any>this.adsManager)[m](event, this.adEventHandler);
        }

        (<any>this.adsManager)[m](this.sdk.AdErrorEvent.Type.AD_ERROR, this.errorEventHandler);
    }

    private listenToAdsLoader(flag: boolean): void {
        const map = this.adsLoaderEventMap,
            e = [ImaAdsLoader.event.ADS_MANAGER_LOADED, ImaAdsLoader.event.ADS_LOADER_ERROR];

        if (!this.adsLoader) { return; }

        let i = e.length;
        while (i--) {
            (<any>this.adsLoader)[flag ? 'on' : 'off'](e[i], map[e[i]]);
        }
    }

    private getEventInterests(): string[] {
        return [
            'CONTENT_PAUSE_REQUESTED',
            'CONTENT_RESUME_REQUESTED',
            'LOADED',
            'STARTED',
            'AD_PROGRESS',
            'FIRST_QUARTILE',
            'MIDPOINT',
            'THIRD_QUARTILE',
            'COMPLETE',
            'ALL_ADS_COMPLETED',
            'AD_BUFFERING',
            'CLICK',
            'PAUSED',
            'RESUMED',
            'SKIPPED',
            'LOG',
            'USER_CLOSE'
        ];
    }

    private getRect(): DOMRect {
        return <DOMRect>this.presentationContainer.getBoundingClientRect();
    }

    private initialize(): void {
        this.listenToAdsLoader(true);
        this.requestAds();
    }

    private log(msg: string, obj?: any) {
        this.logger?.log(LogLevel.DEBUG, `ImaAdPlayer: ${msg}`);
        if (obj) {
            for (const q in obj) {
                this.logger?.log(LogLevel.DEBUG, `${q}: ${obj[q]}`);
            }
        }
    }
}
