import { Util } from "../../core/Util";

export class WebVTTParser {

  /**
   * Parses a single WebVTT caption into a CaptionCue.
   *
   * @param   {String}        txt   The text of the caption
   * @return  {CaptionCue}          The CaptionCue
   */
  static parseCaption(caption: string, id = 0, style: Record<string, string> = {}) {
    // split up the caption parts
    const parts = caption.trim().split("\n");
    const timecode = /([0-9:.]+)\s*-->\s*([0-9:.]+)([^\n]*)/;
    const setting = /\s*([A-Za-z]+)\s*:\s*([\w\-%]+)/g;

    try {
      if (!timecode.test(parts[0])) {
        id = parseInt(parts.shift());
      }
      const times = parts[0].match(timecode).slice(1);
      const settings = times[2];
      const startTime = Util.hmsToSec(times[0]);
      const endTime = Util.hmsToSec(times[1]);
      const text = parts.slice(1).join("\n");

      // parse the settings if they exists, and apply them to the cue
      if (settings != null) {
        settings.replace(setting, (match, key, value) => style[key] = value);
      }

      // create generic cue
      return { id, startTime, endTime, text, ...style};
    } 
    catch (error) {
      // Should we log these errors?
      return null;
    }
  }

  /**
   * Parses a WebVTT file into CaptionCue objects and attaches them to a given track.
   *
   * @param  txt   The text of the webvtt file
   * @return The populated caption track
   */
  static parse(txt: string) {
    // replace windows line breaks
    txt = txt.replace(/\r/g, '');

    // split up the caption chunks
    const captions = txt.split("\n\n");
    
    // parse each multi-line chunk
    const ignore = /^(WEBVTT|NOTE|STYLE)/;
    const cues = captions.reduce((acc, caption) => {
      if (!(ignore.test(caption) || !caption)) {
        const cue = this.parseCaption(caption, acc.length);
        if (cue) {
          acc.push(cue);
        }
      }
      return acc;
    }, []);
    
    return cues;
  }
}
