import { Util } from '../../core/Util';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Generator } from './Generator';


export class ConfigurablePropertyContainer {

    private readonly pData: StrAnyDict; // private data
    private readonly defaultValues: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.pData = {};
        this.defaultValues = {};

        Generator.generateModel(this, config, this.pData, this.defaultValues);
    }

    /**
     * Returns a cloned, "read-only" object
     * (i.e, modifying values on this object has no effect on model)
     */
    get data(): StrAnyDict {
        return Util.assign({}, this.pData);
    }

    reset() {
        for (let q in this.defaultValues) {
            this.pData[q] = this.defaultValues[q];
        }
    }
}
