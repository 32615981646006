import { request } from '../../core/Request';
import { XhrResponseType } from '../../enum/XhrResponseType';
import { CmsDataRetrieverCallback, CmsDataRetrieverOptions } from '../../iface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { StrStrDict } from '../../iface/StrStrDict';


// Will make an XHR call upon instantiation.
// See CmsDataRetrieverOptions; either a uriTemplate and id, or a url may be supplied.
// A responseType and callback are required. 
export class CmsDataRetriever {

    constructor(options: CmsDataRetrieverOptions) {
        const errRecovery = options.errorRecovery || null;
        let errorMsg: string | null = null;

        if (options.url) {
            this.retrieveResource(options.url, options.responseType, options.callback, errRecovery, options.timeout);
        }
        else if (options.uriTemplate && options.tokenMap) {
            const uri = this.replaceUriTokens(options.uriTemplate, options.tokenMap);
            this.retrieveResource(uri, options.responseType, options.callback, errRecovery, options.timeout);
        }
        else {
            errorMsg = 'Invalid options passed to CmsDataRetriever.';
        }

        if (errorMsg) {
            options.callback && options.callback(null, {
                status: 0,
                error: true,
                url: 'n/a',
                message: errorMsg
            });
        }
    }

    private replaceUriTokens(uri: string, tokenMap: StrStrDict): string {
        let u: string = uri;

        if (tokenMap) {
            for (const q in tokenMap) {
                u = u.replace(q, tokenMap[q]);
            }
        }
        return u;
    }

    private retrieveResource(
        url: string,
        responseType: XhrResponseType,
        callback: CmsDataRetrieverCallback,
        errorRecovery: ErrorRecoveryInterface = null,
        timeout: number,
    ): void {
        request({
            responseType, // REM: Use XhrResponseType.TEXT for MPX SMIL! (is converted to Document by AsyncDataRequest)
            timeout,
            url,
            errorRecovery,
        })
            .then(response => callback?.(response, null))
            .catch(error => callback?.(null, error));
    }
}
