
export enum PlaybackAdapterEvents {
    ABR_QUALITY_LOADED = 'abrQualityLoaded',
    ABR_QUALITY_SWITCHED = 'abrQualitySwitched',
    ABR_QUALITY_SWITCHING = 'abrQualitySwitching',
    ABR_CONSTRAINTS_CHANGE = 'maxLevelChanged',//todo rename string to match. 
    FRAGMENT_LOADED = 'fragmentLoaded',
    FRAGMENT_PARSED = 'fragmentParsed',
    MANIFEST_LOADED = 'manifestLoaded',
    MANIFEST_PARSED = 'manifestParsed',
    LOADED_METADATA = 'loadedMetdata',
    DRM_KEYSYSTEM_CREATED = 'drmKeySystemCreated',
    AUDIO_TRACK_UPDATED = 'audioTrackUpdated',
    AUDIO_TRACK_CHANGE = 'audioTrackChange',
    MULTI_CDN = 'multiCdn',
    ERROR = 'adapterError',
}
