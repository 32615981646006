import { event_map } from '../app/event_map';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { PlayerEvent } from '../enum/PlayerEvent';
import { AdErrorEvent } from '../event/AdErrorEvent';
import { PlayerErrorEvent } from '../event/PlayerErrorEvent';
import { NotificationInterface } from '../iface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { AppMediator } from '../view/AppMediator';
import { LogAwareSimpleCommand } from "./LogAwareSimpleCommand";


export class HandleErrorCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const appMed = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION);

        if (!appMed) {
            return;
        }

        const name = <NotificationName>notification.name;
        const playerEvt = event_map[name];
        const body = notification.body as ErrorInfoInterface;
        const isAdError = this.isAdRelatedError(name);
        const evt = isAdError ?
            new AdErrorEvent(body.code, body.message, body.data, body.fatal) :
            new PlayerErrorEvent(playerEvt, body.code, body.message, body.data, body.fatal);

        this.logger.error(body.message, LogLevel.ERROR);

        if (body.fatal) {
            const fe = new PlayerErrorEvent(PlayerEvent.FATAL_ERROR, body.code, body.message, body.data, body.fatal);
            appMed.sendErrorEvent(fe);
        }

        appMed.sendErrorEvent(evt);

        const e = new PlayerErrorEvent(PlayerEvent.ERROR, body.code, body.message, body.data, body.fatal);
        appMed.sendErrorEvent(e);
    }

    isAdRelatedError(name: NotificationName): boolean {
        return name === NotificationName.AD_ERROR ||
            name === NotificationName.AD_STALLED;
    }
}
