/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {
    ServiceContainerInterface,
    ServiceContainerMap,
    ServiceInterface,
    ServiceMap
} from "../iface";

import {MvcMessages} from "../mvc/MvcMessages";
import { Logger } from "../core/Logger";

export class ServiceContainer implements ServiceContainerInterface {

    protected static serviceContainerMap: ServiceContainerMap = {};

    private serviceMap: ServiceMap;
    private readonly multiCoreKey: string;

    constructor(key: string) {
        if(ServiceContainer.serviceContainerMap[key]) {
            Logger.error(MvcMessages.ERROR_KEY_EXISTS.replace('$ACTOR_NAME$', 'ServiceContainer'));
            return;
        }

        this.multiCoreKey = key;
        this.serviceMap = {};
    }

    static getInstance (key: string): ServiceContainerInterface {
        if (!key) return null;

        if(this.serviceContainerMap[key] == null) {
            this.serviceContainerMap[key] = new ServiceContainer(key);
        }

        return this.serviceContainerMap[key];
    }

    static removeServiceContainer(key: string): void {
        this.serviceContainerMap[key] && this.serviceContainerMap[key].destroy();
        delete this.serviceContainerMap[key];
    }

    destroy(): void {
        for (let q in this.serviceMap) {
            this.removeService(q);
        }
        this.serviceMap = null;
    }

    registerService(service: ServiceInterface): void  {
        this.serviceMap[service.name] = service;
        service.onRegister(this.multiCoreKey);
    }

    retrieveService(serviceName: string): ServiceInterface {
        return this.serviceMap[serviceName] || null;
    }

    hasService(serviceName: string): boolean {
        return this.serviceMap[serviceName] != null;
    }

    removeService(serviceName: string): void {
        if (this.serviceMap[serviceName]) {
            this.serviceMap[serviceName].destroy();
        }
        delete this.serviceMap[serviceName];
    }

}
