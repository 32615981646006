import { HookType } from '../enum/HookType';
import { ProxyName } from '../enum/ProxyName';
import { HookError } from '../error/HookError';
import { HookInterface } from '../iface/HookInterface';
import { Proxy } from '../mvc/Proxy';

export const hook_config = {
    [HookType.EVENT]: {
        multiple: true
    },
    [HookType.AD_BREAK]: {
        multiple: false
    },
    [HookType.AD]: {
        multiple: false
    }
}

export class HookProxy extends Proxy {

    private hookMap: Record<string, HookInterface[]> = {};

    constructor(data?: any) {
            super(ProxyName.HookProxy, data);
    }

    override onRemove() {
        this.hookMap = null;
        super.onRemove();
    }

    registerHook(type: HookType, hook: HookInterface): void {
        if (!this.hookMap[type]) {
            this.hookMap[type] = [];
        }

        if (hook_config[type].multiple === false && this.hookMap[type].length) {
            // hook type disallows multiple hooks
            return;
        }

        this.hookMap[type].push(hook);
    }

    removeHook(type: HookType, hook: HookInterface): void {
        if (!this.hookMap[type]) {
            return;
        }

        this.hookMap[type] = this.hookMap[type].filter(h => h !== hook);
    }

    async applyHook<T>(type: HookType, data: T): Promise<T | null> {
        const hooks = this.hookMap[type];

        if (!hooks || hooks.length === 0) {
            return data;
        }

        let cancelled = false;
        const cancel = () => cancelled = true;
        const exec = async (value: T, index = 0): Promise<T> => {
            if (index >= hooks.length) {
                return value;
            }

            const transform = hooks[index];
            if (typeof transform !== 'function') {
                throw new HookError(type, 'Hook must be a valid function');
            }

            const context = { type, value, cancel };

            try {
                await transform(context);
            }
            catch (error) {
                throw new HookError(type, error.toString(), error);
            }

            return (cancelled) ? null : exec(context.value, ++index);
        };

        return exec(data);
    }
}
