import { AdTechnology } from '../../enum/AdTechnology';
import { AppResources } from '../../app/AppResources';
import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { AdBreakType } from '../../enum/AdBreakType';
import { ErrorCode } from '../../enum/ErrorCode';
import { VideoMetadataInterface } from '../../iface';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { AdBreakScheduleItemInterface } from '../../iface/AdBreakScheduleItemInterface';
import { AdCuePointInterface } from '../../iface/AdCuePointInterface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { AdBreakSchedule } from './AdBreakSchedule';
import { dai } from './dai';
import { DaiAdServiceInterface, DaiStreamManagerEventInterface, ImaAdInfoInterface } from './iface';


export class DaiStreamManager extends Emitter {

    static event: DaiStreamManagerEventInterface = {
        MEDIA_URL_AVAILABLE: 'mediaUrlAvailable',
        STREAM_ID_AVAILABLE: 'streamIdAvailable',
        AD_PERIOD_STARTED: 'adPeriodStarted',
        AD_CUEPOINTS_AVAILABLE: 'adCuepointsAvailable',
        AD_BREAK_START: 'adBreakStarted',
        AD_BREAK_METADATA: 'adBreakMetadata',
        AD_BREAK_COMPLETE: 'adBreakEnded',
        AD_ERROR: 'adError',
        AD_START: 'adStarted',
        AD_COMPLETE: 'adComplete',
        AD_PROGRESS: 'adProgress',
        AD_CLICK: 'adClick',
        AD_FIRST_QUARTILE: 'firstQuartile',
        AD_MIDPOINT: 'midpoint',
        AD_THIRD_QUARTILE: 'thirdQuartile',
        RAW_AD_SCHEDULE_AVAILABLE: 'rawAdScheduleAvailable',
    };

    private mediaUrl!: string;
    private adService: DaiAdServiceInterface;
    private mgr: dai.StreamManager;

    private sdk: dai.Sdk;
    private streamInfo: StrAnyDict = {};
    private adBreakSchedule: AdBreakSchedule = null;
    private pStreamDuration: number = NaN;
    private pContentDuration: number = NaN;
    private breakMetadataPending: boolean = false;

    private cuePoints: dai.CuePoint[];
    private cuesReleased: boolean = false;
    private adBreakInProgress: boolean = false;
    private isLiveStream: boolean;
    private breakCount: number = 0;
    private unlockedBreaks: number[] = [];
    private rawScheduleReleased: boolean = false;
    private isIgnoringEvents: boolean = false;

    private pendingCues: dai.CuePoint[];
    private streamEventHandler: (e: dai.StreamEvent) => void;
    private omEnabled: boolean = true;

    constructor(adService: DaiAdServiceInterface, enableOm: boolean) {
        super(null);

        this.cuePoints = [];
        this.adService = adService;
        this.sdk = this.adService.sdk;
        this.mgr = this.adService.getStreamManager();
        this.omEnabled = enableOm;

        this.streamEventHandler = (e: dai.StreamEvent) => { this.hStreamEvent(e); };

        this.initialize();
    }

    override destroy() {
        this.listenToStreamManager(false);
        this.cuePoints = null;
        this.adService = null;
        this.sdk = null;
        this.adBreakSchedule = null;
        this.mgr = null;
    }

    getCuePoints(): dai.CuePoint[] {
        return this.cuePoints || [];
    }

    getCuePointsInContentTime(): AdCuePointInterface[] {
        const pts = this.cuePoints,
            out = <AdCuePointInterface[]>[];

        if (!pts) {
            return out;
        }
        for (let i = 0, n = pts.length; i < n; i++) {
            const p = pts[i],
                d = p.end - p.start,
                s = this.contentTimeForStreamTime(p.start);

            out.push(<AdCuePointInterface>{
                streamTimeStart: p.start,
                streamTimeEnd: p.start + d,
                start: s,
                duration: d,
                played: p.played
            });
        }

        return out;
    }

    ignoreAdEvents(flag: boolean): void {
        this.isIgnoringEvents = flag;
    }

    setUnlockedBreaks(breakTimes: number[]): void {
        this.unlockedBreaks = breakTimes;
    }

    getUnlockedBreaks(): number[] {
        return this.unlockedBreaks;
    }

    addUnlockedBreak(time: number): void {
        this.unlockedBreaks.push(time);
    }

    hasPostRoll(): boolean {
        return !!(this.adBreakSchedule?.hasPostRoll());
    }

    checkForAdPeriod(streamTime: number, suppressEvent?: boolean): boolean {
        let n = this.cuePoints.length, q, diffStart, diff1Ok, diff2Ok;

        for (let i = 0; i < n; i++) {
            q = this.cuePoints[i];
            diffStart = streamTime - q.start;
            diff1Ok = diffStart > -0.3 && diffStart < 2;
            diff2Ok = (q.end - streamTime) >= 3;

            if (this.isUnlockedBreak(q) && diff1Ok && diff2Ok) {
                this.emit(DaiStreamManager.event.AD_PERIOD_STARTED, {
                    streamResumeTime: q.end
                });
                return true;
            }
        }

        return false;
    }

    isUnlockedBreak(q: dai.CuePoint) {
        let i = this.unlockedBreaks.length;
        while (i--) {
            if (Math.floor(this.unlockedBreaks[i]) === Math.floor(q.start)) {
                return true;
            }
        }
        return false;
    }

    getPermittedSeekTime(requestedSeekTime: number): number {
        if (this.adBreakSchedule && this.adBreakSchedule.hasMidRolls()) {
            const streamTime = this.mgr.streamTimeForContentTime(requestedSeekTime),
                b: AdBreakScheduleItemInterface = this.adBreakSchedule.getBreakForContentSeekTime(streamTime);

            if (b) {
                let i = this.unlockedBreaks.length;

                while (i--) {
                    if (Math.floor(this.unlockedBreaks[i]) === Math.floor(b.streamStartTime)) {
                        b.hasPlayed = true;
                        return requestedSeekTime;
                    }
                }

                return b.startTime;
            }
        }

        return requestedSeekTime;
    }

    set streamDuration(d: number) {
        this.pStreamDuration = d;
        this.pContentDuration = this.mgr.contentTimeForStreamTime(d);

        if (this.pendingCues && !this.cuesReleased) {
            this.updateBreakSchedule(this.pendingCues);
            this.releaseCues(this.pendingCues);
            this.pendingCues = null;
        }
    }

    get streamDuration(): number {
        return this.pStreamDuration || NaN;
    }

    get contentDuration(): number {
        return this.pContentDuration || NaN;
    }

    get streamId(): string {
        return this.streamInfo.streamId;
    }

    onTimedMetadata(md: VideoMetadataInterface): void {
        const obj: dai.StreamMetadataInterface = {
            TXXX: md.TXXX || md.msg
        };

        this.mgr.onTimedMetadata(obj);
    }

    contentTimeForStreamTime(t: number): number {
        return this.mgr.contentTimeForStreamTime(t);
    }

    streamTimeForContentTime(t: number): number {
        return this.mgr.streamTimeForContentTime(t);
    }

    requestVODStream(requestObject: dai.VODStreamRequest, er: ErrorRecoveryInterface = null) {
        const req = new this.sdk.VODStreamRequest(<dai.VODStreamRequest>requestObject);

        // issue/83; enabling OM
        this.enableOmFullAccess(req);

        this.isLiveStream = false;
        this.mgr.requestStream(req, er);
    }

    requestLiveStream(requestObject: dai.LiveStreamRequest, er: ErrorRecoveryInterface = null): void {
        const req = new this.sdk.LiveStreamRequest(requestObject);

        // issue/83; enabling OM
        this.enableOmFullAccess(req);

        this.isLiveStream = true;
        this.mgr.requestStream(req, er);
    }

    private enableOmFullAccess(req: dai.VODStreamRequest | dai.LiveStreamRequest) {
        if (!this.adService.usesDaiApi && this.omEnabled) {
            /** @ts-ignore */
            req.omidAccessModeRules = {};
            /** @ts-ignore */
            req.omidAccessModeRules[this.sdk.OmidAccessMode?.FULL] = [new RegExp('.*')];
        }
    }

    private hStreamEvent(e: dai.StreamEvent): void {
        if (!this.sdk || !this.adService || this.isIgnoringEvents) {
            return;
        }

        //e.type != this.sdk.StreamEvent.Type.AD_PROGRESS && console.log(`%cSTREAM MGR evt: ${e.type}`, 'color: #880; font-size: 14px; font-weight: bold');

        const evtType = e.type,
            gamType = this.sdk.StreamEvent.Type,
            myEvent = DaiStreamManager.event;

        switch (evtType) {
            case gamType.LOADED:
                this.handleStreamLoaded(e);
                break;

            case gamType.STREAM_INITIALIZED:
                const d = e.getStreamData();
                this.streamInfo.streamId = d.streamId;
                this.emit(myEvent.STREAM_ID_AVAILABLE, { streamId: d.streamId });
                break;

            case gamType.AD_PERIOD_STARTED:
                // not used for now - manual checking done instead to normalize 
                // GAM SDK/custom SDK+DAI_API behavior.
                // if (e.data && e.data.streamResumeTime) {
                //     this.emit(myEvent.AD_PERIOD_STARTED, e.data);
                // }
                break;

            case gamType.CUEPOINTS_CHANGED:
                const cues = e.getStreamData().cuepoints;
                if (!this.rawScheduleReleased) {
                    this.emit(myEvent.RAW_AD_SCHEDULE_AVAILABLE, { schedule: this.getRawSchedule(cues) });
                    this.rawScheduleReleased = true;
                }
                this.updateBreakSchedule(cues);
                if (!isNaN(this.contentDuration) && !this.cuesReleased) {
                    this.releaseCues(cues);
                }
                else {
                    this.pendingCues = cues;
                }
                break;

            case gamType.AD_BREAK_STARTED:
                this.adBreakInProgress = true;
                this.breakMetadataPending = true;
                this.emit(myEvent.AD_BREAK_START);

                break;

            case gamType.STARTED:
                const ad = e.getAd();
                this.handleAdStart(ad, gamType.STARTED);
                break;

            case gamType.AD_PROGRESS:
                if (this.adBreakInProgress) {
                    const pData = e.getStreamData().adProgressData;
                    this.emit(myEvent.AD_PROGRESS, pData);
                }
                break;

            case gamType.FIRST_QUARTILE:
                if (this.adBreakInProgress) {
                    this.trackQuartile(gamType.FIRST_QUARTILE);
                    this.emit(myEvent.AD_FIRST_QUARTILE);
                }
                break;

            case gamType.MIDPOINT:
                if (this.adBreakInProgress) {
                    this.trackQuartile(gamType.MIDPOINT);
                    this.emit(myEvent.AD_MIDPOINT);
                }
                break;

            case gamType.THIRD_QUARTILE:
                if (this.adBreakInProgress) {
                    this.trackQuartile(gamType.THIRD_QUARTILE);
                    this.emit(myEvent.AD_THIRD_QUARTILE);
                }
                break;

            case gamType.COMPLETE:
                if (this.adBreakInProgress) {
                    this.handleAdComplete(gamType.COMPLETE);
                }
                break;

            case gamType.AD_BREAK_ENDED:
            case gamType.AD_PERIOD_ENDED:
                this.adBreakInProgress && this.emit(myEvent.AD_BREAK_COMPLETE);
                this.updateUnlockedBreaks();
                this.adBreakInProgress = false;
                break;

            case gamType.CLICK:
                this.adBreakInProgress && this.emit(myEvent.AD_CLICK);
                break;

            case gamType.ERROR:
                this.adBreakInProgress = false;
                this.emitError(e.getStreamData().errorMessage, e.data ? e.data.code : null);
                break;
        }
    }

    private handleAdComplete(evt: string): void {
        if (!this.adService.usesDaiApi) {
            this.adService.trackAdEvent({
                context: this.adService.getAdContext(),
                eventName: evt,
                volume: this.adService.videoInterface.volume
            });
            this.adService.untrackAd();
        }

        this.emit(DaiStreamManager.event.AD_COMPLETE);
    }

    private trackQuartile(evt: string): void {
        !this.adService.usesDaiApi && this.adService.trackAdEvent({
            context: this.adService.getAdContext(),
            eventName: evt,
            volume: this.adService.videoInterface.volume
        });
    }

    private handleAdStart(ad: dai.Ad, evt: string): void {
        const abi: AdBreakInfoInterface = this.adService.assembleAdBreakInfo(ad, AdTechnology.SSAI),
            ai: ImaAdInfoInterface = this.adService.assembleAdInfo(ad, { mediaUrl: this.mediaUrl }),
            myEvent = DaiStreamManager.event;

        if (this.breakMetadataPending || !this.adBreakInProgress) {
            this.breakMetadataPending = false;
            this.adBreakInProgress = true;

            if (this.isLiveStream) {
                abi.adBreakPosition = ++this.breakCount;
                abi.adBreakType = AdBreakType.MID;
            }
            this.emit(myEvent.AD_BREAK_METADATA, abi);
        }

        if (!this.adService) {
            return;
        }

        if (!this.adService.usesDaiApi) {
            this.adService.trackAd(ai);
            this.adService.trackAdEvent({
                context: this.adService.getAdContext(),
                eventName: evt,
                volume: this.adService.videoInterface.volume
            });
        }

        this.emit(myEvent.AD_START, ai);
    }

    private handleStreamLoaded(e: dai.StreamEvent): void {
        const data = e.getStreamData(),
            assetUrl = data.url;

        if (!Util.isString(assetUrl) || assetUrl == '') {
            this.emitError(AppResources.messages.DAI_MISSING_ASSET_URL);

            return;
        }

        this.mediaUrl = assetUrl;

        this.streamInfo.streamId = data.streamId;

        this.emit(DaiStreamManager.event.STREAM_ID_AVAILABLE, { streamId: data.streamId });

        this.emit(DaiStreamManager.event.MEDIA_URL_AVAILABLE, {
            mediaUrl: assetUrl
        });
    }

    private updateUnlockedBreaks(): void {
        const b = this.adBreakSchedule?.adBreaks;

        if (b) {
            for (const q in b) {
                if (q === 'pre') this.updateUnlockedScheduleItems([b[q]]);
                else this.updateUnlockedScheduleItems(b[q]);
            }
        }
    }

    private updateUnlockedScheduleItems(ba: AdBreakScheduleItemInterface[]): void {
        let i = ba ? ba.length : 0;
        while (i--) {
            const b = ba[i];
            if (b && b.hasPlayed && this.unlockedBreaks.indexOf(b.startTime) === -1) {
                this.addUnlockedBreak(b.startTime);
            }
        }
    }

    private getRawSchedule(cues: AdCuePointInterface[]): AdCuePointInterface[] {
        const out: AdCuePointInterface[] = [];

        for (let i = 0, n = cues.length; i < n; i++) {
            const q = cues[i];
            out[i] = {
                adTechnology: AdTechnology.SSAI,
                start: this.mgr.contentTimeForStreamTime(q.start),
                streamTimeStart: q.start,
                end: this.mgr.contentTimeForStreamTime(q.end),
                streamTimeEnd: q.end,
                played: false
            };
        }

        return out;
    }

    private releaseCues(cues?: dai.CuePoint[]): void {
        const out = [],
            qlist = cues || this.cuePoints;

        let i = qlist.length;

        while (i--) {
            // TODO - note that "Universal" Ad Presentation Mediator handles time conversion
            // make sure to eliminate call to contentTimeForStreamTime() in adapter implementation
            out.unshift(this.contentTimeForStreamTime(qlist[i].start));
        }

        this.cuesReleased = true;


        this.emit(DaiStreamManager.event.AD_CUEPOINTS_AVAILABLE, {
            cuepoints: out
        });
    }

    private emitError(msg: string, code?: ErrorCode): void {
        this.emit(DaiStreamManager.event.AD_ERROR, {
            message: msg,
            code: code || ErrorCode.DAI_DATA_ERROR,
            streamId: this.streamInfo.streamId || null
        });
    }

    private listenToStreamManager(flag: boolean): void {
        const m = flag ? 'addEventListener' : 'removeEventListener',
            evts: string[] = this.getEventInterests();

        let evt;
        if (!this.sdk) {
            return;
        }
        for (let i = 0, n = evts.length; i < n; i++) {
            evt = this.sdk.StreamEvent.Type[evts[i]];
            (<any>this.mgr)[m](evt, this.streamEventHandler);
        }
    }

    private getEventInterests(): string[] {
        return [
            'LOADED', 'STREAM_INITIALIZED', 'CUEPOINTS_CHANGED',
            'AD_BREAK_STARTED', 'AD_BREAK_ENDED',
            'STARTED', 'COMPLETE',
            'AD_PERIOD_STARTED',
            'AD_PERIOD_ENDED',
            'AD_PROGRESS',
            'FIRST_QUARTILE', 'MIDPOINT', 'THIRD_QUARTILE',
            'CLICK',
            'ERROR',
        ];
    }

    private updateBreakSchedule(cues: dai.CuePoint[]): void {
        const valid = cues && cues.length;

        this.cuePoints = [];

        if (valid && this.contentDuration) {
            let c: dai.CuePoint;
            for (let i = 0, n = cues.length; i < n; i++) {
                c = cues[i];
                this.cuePoints.push({
                    adTechnology: AdTechnology.SSAI,
                    start: c.start,
                    played: c.played,
                    end: c.end
                });
            }

            !this.adBreakSchedule && (this.adBreakSchedule = new AdBreakSchedule(null, cues, this.contentDuration));

        }

        valid && this.adBreakSchedule && this.adBreakSchedule.updateBreaks(cues);
    }

    initialize(): void {
        this.listenToStreamManager(true);
    }
}
