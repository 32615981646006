import { Emitter } from '../../core/Emitter';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ImaAdErrorInfoInterface, ImaAdServiceInterface, ImaAdsLoaderEventInterface, ImaAdsLoaderInterface, ImaAdsLoaderOptions, ImaSdkSettingsInterface } from './iface';
import { ima } from './ima';


export class ImaAdsLoader extends Emitter implements ImaAdsLoaderInterface {

    static event: ImaAdsLoaderEventInterface = {
        ADS_MANAGER_LOADED: 'adsManagerLoaded',
        ADS_LOADER_ERROR: 'adsLoaderError'
    };

    private eMap: Record<string, (e: any) => void> = {
        errorHandler: null,
        adsMgrLoadedHandler: null
    };

    private adsLoader!: ima.AdsLoader;
    private adService: ImaAdServiceInterface;
    private sdk: ima.Sdk;
    private initialized: boolean = false;
    private omEnabled: boolean = true;

    constructor(options: ImaAdsLoaderOptions) {
        super(options);

        this.adService = this.opts.adService;
        this.sdk = this.adService.sdk;
        this.omEnabled = this.opts.enableOm;

        this.eMap.errorHandler = (e: ima.AdErrorEvent) => this.hError(e);
        this.eMap.adsMgrLoadedHandler = (e: ima.AdsManagerLoadedEvent) => this.hAdsMgrLoaded(e);
    }

    override destroy(): void {

        if (this.adsLoader) {
            this.adsLoader.removeEventListener(
                this.sdk.AdErrorEvent.Type.AD_ERROR,
                this.eMap.errorHandler
            );

            this.adsLoader.removeEventListener(
                this.sdk.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
                this.eMap.adsMgrLoadedHandler
            );

            this.adsLoader.destroy();
        }

        this.adsLoader = null;
        this.adService = null;
        this.sdk = null;

        super.destroy();
    }

    /**
     * Initialize may only be called if playback is permitted, i.e,
     * if autoplay is enabled or a valid user gesture has occurred.
     * Returns false if the SDK has become undefined.
     */
    initialize(): boolean {
        const o = <ImaAdsLoaderOptions>this.opts,
            sdk = this.sdk;

        if (!sdk || !sdk.AdsLoader) {
            return false;
        }

        if (this.initialized) {
            return true;
        }

        const adc: ima.AdDisplayContainer = new sdk.AdDisplayContainer(
            o.adContainer,
            o.video,
            o.adClickEl
        );
        adc.initialize();

        this.adsLoader = new sdk.AdsLoader(adc);

        const imaSettings: ima.ImaSdkSettings = this.adsLoader.getSettings();
        this.populateSdkSettings(imaSettings);

        this.adsLoader.addEventListener(
            sdk.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
            this.eMap.adsMgrLoadedHandler
        );

        this.adsLoader.addEventListener(sdk.AdErrorEvent.Type.AD_ERROR, this.eMap.errorHandler);

        this.initialized = true;

        return true;
    }

    requestAds(adsRequest: ima.AdsRequest, userRequestContext?: StrAnyDict) {
        const sdkObj = this.sdk as any;

        if (this.omEnabled) {
            /** @ts-ignore */
            adsRequest.omidAccessModeRules = {};
            /** @ts-ignore */
            adsRequest.omidAccessModeRules[sdkObj.OmidAccessMode?.FULL] = [new RegExp('.*')];
        }

        this.adsLoader && this.adsLoader.requestAds(adsRequest);
    }

    getSdkVersion() {
        return this.adsLoader.getVersion();
    }

    contentComplete(): void {
        this.adsLoader && this.adsLoader.contentComplete();
    }

    getSettings() {
        return this.adsLoader ? this.adsLoader.getSettings() : null;
    }

    private hAdsMgrLoaded(e: ima.AdsManagerLoadedEvent): void {
        this.emit(ImaAdsLoader.event.ADS_MANAGER_LOADED, e);
    }

    private hError(e: ima.AdErrorEvent): void {
        const adErrorInfo: ImaAdErrorInfoInterface = this.parseAdErrorEvent(e);

        this.emit(ImaAdsLoader.event.ADS_LOADER_ERROR, adErrorInfo);
    }

    private parseAdErrorEvent(e: ima.AdErrorEvent): ImaAdErrorInfoInterface {
        return this.adService.parseAdError(e);
    }

    private populateSdkSettings(settings: ima.ImaSdkSettings): void {
        const cbsImaSettings: ImaSdkSettingsInterface = this.opts.sdkSettings,
            vpaidMode = this.sdk.ImaSdkSettings.VpaidMode[cbsImaSettings.vpaidMode];

        settings.setVpaidMode(vpaidMode);
        settings.setDisableCustomPlaybackForIOS10Plus(cbsImaSettings.disableCustomPlaybackForIOS10Plus);
        settings.setPlayerType(cbsImaSettings.playerType);
        settings.setPlayerVersion(cbsImaSettings.playerVersion);
    }
}
