import { apiAccessor, apiCollection, apiMethod } from '../app/ApiDecorators';
import { request } from '../core/Request';
import { Util } from '../core/Util';
import { LocalizationId } from '../enum/LocalizationId';
import { LogLevel } from '../enum/LogLevel';
import { ProxyName } from '../enum/ProxyName';
import { Entry } from '../iface/Entry';
import { LanguageTagInterface } from '../iface/LanguageTagInterface';
import { LocaleData } from '../iface/LocaleData';
import { LocalizationData } from '../iface/LocalizationData';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { StrStrDict } from '../iface/StrStrDict';
import { Proxy } from '../mvc/Proxy';


export class LocalizationProxy extends Proxy implements LocalizationInterface {

	private static pExternalData: Record<string, Promise<StrAnyDict>> = {};

	static load(url: string): Promise<StrStrDict> {
		if (this.pExternalData[url] == null) {
			this.pExternalData[url] = request({ url });
		}
		return this.pExternalData[url];
	}

	static LocalizationData: LocalizationData = {
		en: {
			[LocalizationId.MEDIA_PLAYER]: 'Media Player'
		},
		es: {
			[LocalizationId.MEDIA_PLAYER]: 'Reproductor multimedia'
		}
	};

	private pLanguage: string;
	private pLanguageTag: LanguageTagInterface;
	private pLocalizationData: LocalizationData[] = [];
	private pLocale: Record<string, StrStrDict> = {};

	constructor(name: ProxyName, language: string) {
		super(name, {});
		this.pLanguage = language;
		this.registerLocalizationData(LocalizationProxy.LocalizationData);
	}

	override onRemove() {
		this.pLocale = null;
		this.pLocalizationData = null;
		this.pLanguageTag = null;
		this.pLanguage = null;
	}

	getApi(): LocalizationInterface {
		return apiCollection({}, this) as LocalizationInterface;
	}

	@apiAccessor(true)
	get defaultLanguage(): string {
		return (navigator && navigator.language) ? navigator.language : 'en';
	}

	@apiAccessor(true)
	get language(): string {
		return this.pLanguage || this.defaultLanguage;
	}

	@apiMethod()
	get languageTag(): LanguageTagInterface {
		return this.pLanguageTag;
	}

	@apiMethod()
	changeLanguage(language: string = this.defaultLanguage): Promise<LocaleData> {
		this.pLanguage = language;
		this.pLanguageTag = Util.parseLanguageTag(this.language);
		const tag = new RegExp(`^${this.pLanguageTag.language}`, 'i');
		const load = ([k, v]: Entry): Promise<Entry> => {
			//@ts-ignore
			return LocalizationProxy
				.load(v)
				.then(data => [k, data])
				.catch(error => {
					// Errors loading localization files should not halt playback
					this.facade.log(LogLevel.ERROR, `Could not load locale file ${v}`, error);
					return [k, {}];
				});
		};

		// create an array of entry arrays
		const data = this.pLocalizationData.map(data => {
			const locale = Util.entries(data)
				// only operate on data for the current language
				.filter((e: Entry) => tag.test(e[0]))

				// start loading external data, or return the entry array
				.map((e: Entry) => (typeof e[1] == 'string') ? load(e) : e);

			return Promise.all(locale);
		});

		// wait for all external data to finish loading
		return Promise.all(data)
			.then(entries => {
				// convert entry arrays back into objects
				const objs = entries.map(entry => Util.fromEntries(entry));

				// Merge into a single object
				const data = Util.merge(...objs);

				// Cache the data and return the final object
				return this.pLocale = data;
			});
	}

	@apiMethod()
	registerLocalizationData(data: LocalizationData): void {
		if (Util.isEmpty(data)) {
			return;
		}
		this.pLocalizationData.push(data);
	}

	@apiMethod()
	localize(messageId: string, context?: any): string {
		const message = this.lookup(messageId);
		return Util.template(message, context);
	}

	private lookup(messageId: string, language: string = this.language): string {
		const find = (lang: string): string => {
			const locale = (this.pLocale[lang] || {}) as StrStrDict;
			return locale[messageId];
		};
		return find(language) || find(this.pLanguageTag.language) || messageId;
	}
}
