import { LogLevel } from '../../enum/LogLevel';
import { MediatorName } from '../../enum/MediatorName';
import { ModelName } from '../../enum/ModelName';
import { NotificationName } from '../../enum/NotificationName';
import { NotificationType } from '../../enum/NotificationType';
import { PlaybackState } from '../../enum/PlaybackState';
import { ProxyName } from '../../enum/ProxyName';
import { StreamType } from '../../enum/StreamType';
import { VideoMode } from '../../enum/VideoMode';
import { NotificationInterface, ProgressDataInterface } from '../../iface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { PresentationStateInterface } from '../../iface/PresentationStateInterface';
import { ContentPlaybackStateProxy } from '../../model/ContentPlaybackStateProxy';
import { LocalizationProxy } from '../../model/LocalizationProxy';
import { FullscreenMediator } from '../../view/FullscreenMediator';
import { LogAwareMediator } from '../../view/LogAwareMediator';
import { cvui } from './cvui';
import { UiEnumCollectionInterface } from './UiEnumCollectionInterface';
import { UiMediatorInterface } from './UiMediatorInterface';

/**
 * @deprecated
 */
export class UiMediator extends LogAwareMediator implements UiMediatorInterface {

  private enums!: UiEnumCollectionInterface;
  private fsm!: FullscreenMediator;
  private seekRedirectPending: boolean = false;
  private ccEnabled: boolean = false;
  private ccAvail: boolean = false;
  private fullScreenAccessRestricted = false;
  private increment: number = 10;
  private isPlayingLive: boolean = false;
  private controlsVisible: boolean = false;
  private adErrorOccurred: boolean = false;
  private suppressAdView: boolean;
  private timeout: number;

  constructor(name: string, viewControl?: any) {
    super(name, viewControl);
  }

  override onRegister() {
    const service = this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy;
    this.ui.setLocalizationService(service);

    const getThumbnail = (time: number) => {
      const cps = this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
      return cps.getThumbnail(time);
    };
    this.ui.setThumbnailService({ getThumbnail });
  }

  override onRemove(): void {
    clearTimeout(this.timeout);
    this.viewControl && this.viewControl.destroy();
    this.viewControl = null;
    this.enums = null;
    this.fsm = null;
    super.onRemove();
  }

  get viewController(): cvui.ViewControllerInterface {
    return this.ui;
  }

  get controlNames(): cvui.ControlName {
    return this.enums.ControlName;
  }

  set adViewSuppressed(flag: boolean) {
    this.suppressAdView = flag;
  }

  get adViewSuppressed(): boolean {
    return this.suppressAdView;
  }


  uiIsStandardView(): boolean {
    return this.ui.isStandardView();
  }

  setSeekNotice(str: string, dir: number): void {
    this.ui.setSeekNotice(str, dir);
  }

  setFullScreenAccessRestricted(flag: boolean): void {
    this.fullScreenAccessRestricted = flag;
  }

  killUi(): void {
    this.viewControl && this.viewControl.destroy();
    this.viewControl = null;
  }

  initialize(enumCollection: UiEnumCollectionInterface): void {
    this.enums = enumCollection;
    this.init();
  }

  enableControl(name: string): void {
    this.ui.enableControl(name);
  }

  disableControl(name: string): void {
    this.ui.disableControl(name);
  }

  enableFullscreen(): void {
    if (!this.fullScreenAccessRestricted && this.isFullScreenAvailable()) {
      this.enableControl(this.controlNames.FULLSCREEN_TOGGLE);
    }
  }

  disableFullscreen(): void {
    this.disableControl(this.controlNames.FULLSCREEN_TOGGLE);
  }

  setMuteState(flag: boolean): void {
    this.ui.setMuteState(flag);
  }

  forceControlsVisible(flag: boolean): void {
    this.ui.forceControlsVisible(flag);
  }

  setSeekable(flag: boolean): void {
    this.ui.setSeekable(flag);
  }

  engageSeek(): void {
    this.ui.forceControlsVisible(true);
    this.ui.beginSeekMove();
  }

  disengageSeek(): void {
    this.ui.endSeekMove();
    this.timeout = setTimeout(() => this.ui.forceControlsVisible(false), 500);
  }

  moveSeekBy(inc: number): void {
    this.ui.moveSeekBy(inc);
  }

  setVolume(value: number): void {
    if (!isNaN(value) && value >= 0 && value <= 1) {
      this.ui.setVolume(value);
    }
  }

  setTransportType(type: StreamType) {
    // TODO: Remove this once UI is updated to accept StreamType enum
    const num = (type == StreamType.LIVE) ? 1 : (type == StreamType.DVR) ? 2 : 0;
    this.ui.setTransportType(num);
  }

  setIsPlayingLive(isLive: boolean) {
    this.isPlayingLive = isLive;
    this.ui.setIsPlayingLive(isLive);
  }

  setClosedCaptionState(state: boolean): void {
    this.ui.setClosedCaptionState(state);
  }

  updateProgress(pdi: ProgressDataInterface): void {
    const { playheadTime, duration } = pdi;

    this.ui.updateProgress({
      currentTime: playheadTime,
      duration: duration
    });

    const cn: cvui.ControlName = this.controlNames;
    if (this.isPlayingLive || playheadTime + this.increment >= duration) {
      this.ui.disableControl(cn.SEEK_FORWARD_BUTTON);
    }
    else {
      this.ui.enableControl(cn.SEEK_FORWARD_BUTTON);
    }

    if (playheadTime <= this.increment) {
      this.ui.disableControl(cn.SEEK_BACK_BUTTON);
    }
    else {
      this.ui.enableControl(cn.SEEK_BACK_BUTTON);
    }
  }

  setClickCatcherState(state: PlaybackState): void {
    const s = state === PlaybackState.PLAYING ? this.enums.PlayState.PLAYING : this.enums.PlayState.PAUSED;
    this.ui.setClickCatcherState(s);
  }

  hideClickCatcher(flag: boolean) {
    this.ui.hideClickCatcher(flag);
  }

  displayPoster(flag: boolean): void {
    this.ui.showDecorator(this.enums.DecoratorName.POSTER, flag);
  }

  activatePoster(): void {
    this.ui.activatePoster();
  }

  showUnmutePrompt(): void {
    this.ui.showDecorator(this.enums.DecoratorName.UNMUTE_PROMPT, true);
  }

  displaySpinner(flag: boolean): void {
    this.ui.showDecorator(this.enums.DecoratorName.SPINNER, flag);
  }

  setAccVolumeLevelIndicator(val: number): void {
    this.ui.setAccVolumeLevelIndicator(val);
  }

  setVideoMode(m: VideoMode): void {
    if (m == VideoMode.AD && this.adViewSuppressed) {
      this.ui.setVideoMode(VideoMode.CONTENT);
      return;
    }

    this.ui.setVideoMode(m);
  }

  resetUi(): void {
    this.hideClickCatcher(true);
    this.displayPoster(false);
    this.ui.setChapterMarkers([]);
    this.ui.setVideoMode(VideoMode.IDLE);
  }

  handleNotification(notification: NotificationInterface): void {
    const n = notification.name,
      data = notification.body;

    if (!this.ui) {
      return;
    }

    switch (n) {
      case NotificationName.ENABLE_UI:
        this.enableFullscreen();
        this.ui.activateControls();
        break;

      case NotificationName.DISABLE_UI:
        this.ui.setVideoMode(VideoMode.IDLE);
        this.ui.inactivateControls();
        break;

      case NotificationName.VIDEO_LOAD_START:
        this.displaySpinner(true);
        break;

      case NotificationName.VIDEO_LOAD_COMPLETE:
        this.displaySpinner(false);
        break;

      case NotificationName.CONTENT_TIME_UPDATE:
        if (this.seekRedirectPending) {
          return;
        }
        this.ui.updateProgress({
          currentTime: data.contentTime,
          duration: data.contentDuration
        });
        break;

      case NotificationName.AD_CUEPOINTS_AVAILABLE:
        this.ui.setChapterMarkers(data.cuepoints);
        break;

      case NotificationName.AD_TIME_UPDATE:
        if (!this.adViewSuppressed) {
          this.ui.updateProgress({
            currentTime: data.currentTime,
            duration: data.duration
          });
        }

        break;

      case NotificationName.TEXT_TRACK_AVAILABLE:
        this.ccAvail = true;
        this.ui.enableControl(this.controlNames.CC_TOGGLE);
        if (this.ccEnabled) {
          this.ui.setClosedCaptionState(true);
          this.activateCaptions(true);
        }
        break;

      case NotificationName.TEXT_TRACK_DISPLAY_MODE_CHANGE:
        this.doCaptionCheckOnControlVisChange();
        break;

      case NotificationName.SEEK:
      case NotificationName.SEEK_FORWARD:
      case NotificationName.SEEK_BACK:
      case NotificationName.CONTENT_SEEKING:
        this.displaySpinner(true);
        if (this.seekRedirectPending) {
          return;
        }
        this.ui.setSeekComplete();
        break;

      case NotificationName.SEEK_REDIRECT_START:
        this.seekRedirectPending = true;
        break;

      case NotificationName.SEEK_REDIRECT_COMPLETE:
        this.seekRedirectPending = false;
        break;

      case NotificationName.CONTENT_SEEKED:
        this.displaySpinner(false);

        if (this.seekRedirectPending) {
          return;
        }
        this.ui.setSeekComplete();

        if (this.adErrorOccurred) {
          this.adErrorOccurred = false;
          this.ui.setVideoMode(VideoMode.CONTENT);
          this.ccAvail && this.ui.enableControl(this.controlNames.CC_TOGGLE);
        }
        break;

      case NotificationName.CONTENT_BUFFERING:
        this.displaySpinner(data.value);
        break;

      case NotificationName.FULLSCREEN_AVAILABLE:
        this.enableFullscreen();
        break;

      case NotificationName.FULLSCREEN_CHANGE:
        this.ui.setFullscreenState(notification.body.isFullscreen);
        break;

      case NotificationName.PRESENTATION_SIZE_CHANGE:
        this.ui.updateSize();
        break;

      case NotificationName.CONTENT_PLAYING:
        this.ui.hideClickCatcher(false);
        this.displaySpinner(false);
        this.setPlayState(PlaybackState.PLAYING);
        break;

      case NotificationName.CONTENT_PAUSED:
        this.displaySpinner(false);
        this.setPlayState(PlaybackState.PAUSED);
        break;

      case NotificationName.AD_BREAK_START:
        this.displaySpinner(false);
        this.ui.hideClickCatcher(true);
        this.ui.hidePanelsForAdPlay(true);
        this.setPlayState(PlaybackState.PLAYING);
        this.ui.setVideoMode(VideoMode.AD);
        break;

      case NotificationName.AD_PAUSED:
        this.setPlayState(PlaybackState.PAUSED);
        this.ui.forceControlsVisible(true);
        break;

      case NotificationName.AD_PLAYING:
        this.displaySpinner(false);
        this.setPlayState(PlaybackState.PLAYING);
        this.ui.forceControlsVisible(false);
        break;

      case NotificationName.CONTENT_START:
      case NotificationName.CONTENT_SEGMENT_START:
        this.ui.setVideoMode(VideoMode.CONTENT);
        this.ccAvail && this.ui.enableControl(this.controlNames.CC_TOGGLE);
        break;

      case NotificationName.AD_ERROR:
      case NotificationName.AD_STALLED:
        this.adErrorOccurred = true;
        break;

      case NotificationName.AD_BREAK_COMPLETE:
      case NotificationName.CONTENT_SEGMENT_END:
      case NotificationName.RESOURCE_INTERRUPTED:
      case NotificationName.CONTENT_COMPLETE:
        this.ui.resetProgress();
        this.ui.setVideoMode(VideoMode.IDLE);

        if (n === NotificationName.AD_BREAK_COMPLETE) {
          this.ui.hidePanelsForAdPlay(false);
        }
        else {
          this.ui.disableControl(this.controlNames.CC_TOGGLE);
        }

        if (n == NotificationName.RESOURCE_INTERRUPTED || n === NotificationName.CONTENT_COMPLETE) {
          this.ccAvail = false;
        }
        break;

      case NotificationName.READY:
      case NotificationName.LANGUAGE_CHANGE:
        this.ui.updateText();
        break;

      case NotificationName.STREAM_TYPE_CHANGE:
        this.setTransportType(data.value);
        break;

      case NotificationName.CONTENT_IS_LIVE_CHANGED:
        this.setIsPlayingLive(data.value);
        break;

      default: break;
    }
  }

  override listNotificationInterests(): string[] {
    return [
      NotificationName.AD_CUEPOINTS_AVAILABLE,
      NotificationName.ENABLE_UI,
      NotificationName.DISABLE_UI,
      NotificationName.SEEK_REDIRECT_START,
      NotificationName.SEEK_REDIRECT_COMPLETE,
      NotificationName.SEEK,
      NotificationName.SEEK_FORWARD,
      NotificationName.SEEK_BACK,
      NotificationName.CONTENT_SEEKED,
      NotificationName.CONTENT_BUFFERING,
      NotificationName.CONTENT_PLAYING,
      NotificationName.CONTENT_PAUSED,
      NotificationName.AD_PLAYING,
      NotificationName.AD_PAUSED,
      NotificationName.AD_ERROR,
      NotificationName.AD_STALLED,
      NotificationName.FULLSCREEN_AVAILABLE,
      NotificationName.FULLSCREEN_CHANGE,
      NotificationName.PRESENTATION_SIZE_CHANGE,
      NotificationName.RESOURCE_INTERRUPTED,
      NotificationName.AD_BREAK_START,
      NotificationName.CONTENT_START,
      NotificationName.CONTENT_COMPLETE,
      NotificationName.CONTENT_SEGMENT_START,
      NotificationName.AD_BREAK_COMPLETE,
      NotificationName.CONTENT_SEGMENT_END,
      NotificationName.AD_TIME_UPDATE,
      NotificationName.CONTENT_TIME_UPDATE,
      NotificationName.TEXT_TRACK_AVAILABLE,
      NotificationName.TEXT_TRACK_DISPLAY_MODE_CHANGE,
      NotificationName.VIDEO_LOAD_START,
      NotificationName.VIDEO_LOAD_COMPLETE,
      NotificationName.LANGUAGE_CHANGE,
      NotificationName.READY,
      NotificationName.STREAM_TYPE_CHANGE,
      NotificationName.CONTENT_IS_LIVE_CHANGED,
    ];
  }

  private setPlayState(state: string): void {
    switch (state) {
      case 'waiting':
        state = 'buffering';
        break;

      case 'stopped':
        state = 'idle';
        break;
    }

    this.ui.setPlayState(state);
  }

  private get ui(): cvui.ViewControllerInterface {
    return <cvui.ViewControllerInterface>this.viewControl;
  }

  private hVisibilityEvent(e: EventInterface): void {
    const ps: PresentationStateInterface = <PresentationStateInterface>this.getModel(ModelName.PresentationState);

    if (ps.isCurrentVideoAd) {
      return;
    }

    const ce: cvui.ControlEvent = this.enums.ControlEvent;
    let name: string | null = null;

    switch (e.type) {
      case ce.BEFORE_CONTROLS_VISIBLE:
        this.updateControlsVisible(true);
        name = NotificationName.BEFORE_CONTROLS_VISIBLE;
        break;

      case ce.CONTROLS_VISIBLE:
        name = NotificationName.CONTROLS_VISIBLE;
        break;

      case ce.BEFORE_CONTROLS_HIDDEN:
        name = NotificationName.BEFORE_CONTROLS_HIDDEN;
        break;

      case ce.CONTROLS_HIDDEN:
        this.updateControlsVisible(false);
        name = NotificationName.CONTROLS_HIDDEN;
        break;
    }

    name && this.sendNotification(name);
  }

  /** All action event handling is consolidated here */
  // TODO: create interface/enum for action constants
  private hActionEvent(e: EventInterface): void {
    const action = e.data.action;

    let note = null,
      body = null;

    switch (action) {
      case 'mute':
        note = NotificationName.MUTE;
        break;

      case 'unmute':
        note = NotificationName.UNMUTE;
        break;

      case 'volume':
        note = NotificationName.VOLUME_CHANGE;
        body = { value: e.data.value };
        break;

      case 'pause':
        note = NotificationName.PAUSE;
        break;

      case 'stop':
        note = NotificationName.STOP;
        break;

      case 'play':
        note = NotificationName.PLAY;
        break;

      case 'posterClick':
        note = NotificationName.PLAY_ON_USER_GESTURE;
        break;

      case 'ccEnabled':
      case 'ccDisabled':
        const enabled = action === 'ccEnabled';
        this.activateCaptions(enabled);
        break;

      case 'seek':
        note = NotificationName.SEEK;
        body = { value: e.data.value };
        break;

      case 'seekForward':
        note = NotificationName.SEEK_FORWARD;
        body = { value: e.data.value || this.increment };
        break;

      case 'seekBack':
        note = NotificationName.SEEK_BACK;
        body = { value: e.data.value || this.increment };
        break;

      case 'enterFullscreen':
        note = NotificationName.ENTER_FULLSCREEN_REQUEST;
        break;

      case 'exitFullscreen':
        note = NotificationName.EXIT_FULLSCREEN_REQUEST;
        break;

      case 'goLive':
        note = NotificationName.SEEK_TO_LIVE;
        break;

      case 'settings':
        note = NotificationName.USER_SETTINGS_REQUEST;
        break;

      default:
        break;
    }

    note && this.sendNotification(note, body, NotificationType.UI);
  }

  private doCaptionCheckOnControlVisChange(): void {
    // only request offset if captions avail the view is currently 
    // full content view (not compact or ad view)
    if (this.ui.isStandardView() && this.ccAvail) {
      this.sendNotification(
        NotificationName.TT_OFFSET_REQUEST,
        { controlsVisible: this.controlsVisible },
        NotificationType.UI
      );
    }
  }

  private updateControlsVisible(controlsVisible: boolean) {
    this.controlsVisible = controlsVisible;
    this.doCaptionCheckOnControlVisChange();
  }

  private hGestureEvent(e: EventInterface): void {
    const presentation = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
    if (presentation.isCurrentVideoAd) {
      return;
    }
    const n = e.type === this.enums.ControlEvent.MOUSE_ENTER_PRESENTATION ?
      NotificationName.MOUSE_ENTER_PRESENTATION :
      NotificationName.MOUSE_LEAVE_PRESENTATION;

    this.sendNotification(n, null, NotificationType.UI);
  }

  private activateCaptions(flag: boolean): void {
    this.ccEnabled = flag;
    this.sendNotification(NotificationName.SWITCH_TEXT_MODE, { value: flag }, NotificationType.UI);
  }

  private isFullScreenAvailable(): boolean {
    return this.fsMediator && this.fsMediator.isFullScreenPermitted();
  }

  private get fsMediator(): FullscreenMediator {
    if (!this.fsm) {
      this.fsm = <FullscreenMediator>this.facade.retrieveMediator(MediatorName.FULLSCREEN);
    }

    return this.fsm;
  }

  private init(): void {
    const ce: cvui.ControlEvent = this.enums.ControlEvent,
      cn: cvui.ControlName = this.controlNames,
      actionHandler: EventHandler = (e: EventInterface) => this.hActionEvent(e),
      visibilityHandler: EventHandler = (e: EventInterface) => this.hVisibilityEvent(e),
      gestureHandler: EventHandler = (e: EventInterface) => this.hGestureEvent(e);

    // visibility event handling
    const list: string[] = [
      ce.BEFORE_CONTROLS_VISIBLE, ce.CONTROLS_VISIBLE,
      ce.BEFORE_CONTROLS_HIDDEN, ce.CONTROLS_HIDDEN
    ];
    let i = list.length;

    while (i--) {
      this.ui.on(list[i], visibilityHandler);
    }

    // action event handling
    this.ui.on(ce.ACTION, actionHandler);
    this.ui.on(ce.MOUSE_ENTER_PRESENTATION, gestureHandler);
    this.ui.on(ce.MOUSE_LEAVE_PRESENTATION, gestureHandler);

    this.ui.disableControl(cn.CC_TOGGLE);
    this.disableFullscreen();

    this.ui.setVolume(0.35);

    this.ui.setVideoMode(VideoMode.IDLE);

    this.log(LogLevel.INFO, 'UI created.');
  }
}
