export enum PlayStation {    
    //player states
    BUFFERING = 'buffering',
    END_OF_STREAM = 'endOfStream',
    NOT_READY = 'notReady',
    OPENING = 'opening',    
    PAUSED = 'paused',
    PLAYING = 'playing',
    STOPPED = 'stopped',
    DISPLAYING_VIDEO = 'DisplayingVideo',
    UNKNOWN = 'unknown',    
    READY = 'ready',    

    //Access Messages
    //PLAYER_MESSAGE = 'playerMessage', //Deprecated Please use playerTimedEvent instead.
    PLAYER_TIMED_EVENT = 'playerTimedEvent',
    NETWORK_STATUS_CHANGE = 'networkStatusChange',
    CONTENT_AVAILABLE = 'contentAvailable',
    PLAYER_STATUS_CHANGE = 'playerStatusChange',    
    PLAYER_STREAMING_ERROR = 'playerStreamingError',    
    PLAYER_ERROR = 'playerError',
    PLAYER_SUBTITLE = 'playerSubtitle', //This event is deprecated and will be removed at a later date. Please use playerTimedEvent instead.
    GET_AUDIO_TRACKS = 'getAudioTracks',
    GET_VIDEO_REP_COUNT = 'getRepresentationsCount',
    GET_VIDEO_REP_INFO = 'getRepresentationInfo',
    GET_SUBTITLE_TRACKS = 'getSubtitleTracks',
    GET_PLAYBACK_TIME = 'getPlaybackTime',    
    GET_SEEK_WINDOW = 'getSeekWindow',    
}

