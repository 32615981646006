import { AdBreakType } from '../../enum/AdBreakType';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { Validator } from '../vo/Validator';


export const ad_break_info_config: StrAnyDict = {

    adBreakDuration: {
        type: 'number',
        defaultValue: -1,
        setterTransformer: (val: number): number => {
            return typeof val !== 'number' ? -1 : val;
        }
    },

    adTotal: {
        type: 'number',
        defaultValue: -1,
        setterTransformer: (val: number): number => {
            return typeof val !== 'number' ? -1 : val;
        }
    },

    adBreakPosition: {
        type: 'number',
        defaultValue: -1,
        setterTransformer: (val: number): number => {
            return typeof val !== 'number' ? -1 : val;
        }
    },

    adBreakType: {
        type: 'string',
        defaultValue: null,
        setterTransformer: (val: string): string => {
            return (val === AdBreakType.MID || val === AdBreakType.POST || val === AdBreakType.PRE)
                ? val : null;
        }
    },

    adServerName: {
        type: 'string',
        defaultValue: null,
        validator: Validator.notEmpty
    }
};
