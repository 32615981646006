export enum NativePlugin {
    /**
     * Will prompt for a replay of last played resource at playlist end
     */
    REPLAY = 'replay',

    /**
     * Debugging and diagnostic panel
     */
    DIAGNOSTIC = 'diagnostic',

    /**
     * Player UI
     */
    UI = 'ui',

    /**
     * Google Ad Manager
     */
    GAM = 'gam',
}
