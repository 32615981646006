import { Util } from '../../core/Util';
import { AdTagParametersInterface } from '../../iface/AdTagParametersInterface';
import { ResourceAdDaiInterface } from '../../iface/ResourceAdDaiInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { AdParamAssembler } from './AdParamAssembler';
import { dai } from './dai';
import { AdPlaybackContextInterface, AdRequestVersionInfoInterface } from './iface';


export class DaiStreamRequestAssembler extends AdParamAssembler {

    private static FILTER_OUT_PREMIUM_AUDIO = 'codec:ac-3,codec:ec-3';

    constructor() {
        super();
    }

    assembleStreamRequest(r: ResourceConfigurationInterface, info: AdPlaybackContextInterface, skipEncode: boolean = false, versionInfo: AdRequestVersionInfoInterface): dai.StreamRequest {
        const stdParamsObj: AdTagParametersInterface = this.assembleStdParams(r, info),
            daiParams: ResourceAdDaiInterface = r.ad.dai;

        if (daiParams.filterOutPremiumAudioCodecs) {
            stdParamsObj['dai-excl'] = DaiStreamRequestAssembler.FILTER_OUT_PREMIUM_AUDIO;
        }

        const atp: StrAnyDict = Util.assign({}, stdParamsObj);
        atp.cust_params = this.queryStrFromObj(r.ad.adTagParameters.cust_params, skipEncode ? [] : []);

        delete atp.output;

        for (const key in versionInfo) {
            if (key.indexOf('imafw_') < 0) {
                atp[key] = versionInfo[key];
            }
        }

        const obj = <dai.StreamRequest>{
            apiKey: daiParams.apiKey,
            authToken: daiParams.authToken || null,
            assetKey: daiParams.assetKey || null,
            streamActivityMonitorId: daiParams.streamActivityMonitorId || null,
            adTagParameters: atp,
            videoId: daiParams.daiVideoId || null,
            contentSourceId: daiParams.contentSourceId || null
        };

        if (Util.isString(daiParams.format)) {
            obj.format = daiParams.format;
        }

        return obj;
    }
}
