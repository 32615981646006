import { AppResources } from '../app/AppResources';
import { PluginServices } from '../app/PluginServices';
import { buildInfo } from '../core/BuildInfo';
import { Util } from '../core/Util';
import { ScriptLoader } from '../dataservice/ScriptLoader';
import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NativePlugin } from '../enum/NativePlugin';
import { NotificationName } from '../enum/NotificationName';
import { PluginPriority } from '../enum/PluginPriority';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { LoadPluginsNotificationInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface } from '../iface';
import { PluginConfigInterface } from '../iface/PluginConfigInterface';
import { PluginInterface } from '../iface/PluginInterface';
import { PluginOptionsInterface } from '../iface/PluginOptionsInterface';
import { PluginServicesInterface } from '../iface/PluginServicesInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { LocalizationProxy } from '../model/LocalizationProxy';
import { PlayerOptions } from '../model/vo/PlayerOptions';
import { Utils } from '../util/Utils';
import { AppMediator } from './AppMediator';
import { LogAwareMediator } from './LogAwareMediator';


export class PluginMediator extends LogAwareMediator {
    private plugins: StrAnyDict = {};
    private pendingLoads: PluginConfigInterface[] = null;
    private services: PluginServicesInterface = null;
    private diagnosticPlugin: any = null;
    private diagnosticPluginVisible: boolean = false;

    constructor(name: string) {
        super(name);
    }

    override onRemove(): void {
        Object.keys(this.plugins).forEach(name => {
            try {
                this.killPlugin(name);
            }
            catch (error) {
                this.logger.warn(`Error destroying plugin: ${name}`, error);
            }
        });

        this.plugins = null;
        this.pendingLoads = null;
        this.services = null;
        this.diagnosticPlugin = null;

        super.onRemove();
    }

    removePlugin(name: string): void {
        this.killPlugin(name);
    }

    getPlugin(name: string): any {
        return this.plugins[name] || null;
    }

    async loadPlugin(cfg: PluginConfigInterface): Promise<PluginInterface> {
        const n = await this.createPlugin(cfg);
        if (cfg.name == NativePlugin.DIAGNOSTIC) {
            this.registerDiagnosticPlugin(cfg.name);
        }
        return n;
    }

    toggleDiagnosticPlugin() {
        if (!this.diagnosticPlugin) {
            const doNothing = this.createDiagnosticPlugin();
            if (doNothing) {
                return;
            }
        }

        this.diagnosticPluginVisible = !this.diagnosticPluginVisible;
        this.diagnosticPluginVisible ? this.diagnosticPlugin.show() : this.diagnosticPlugin.hide();
    }

    override listNotificationInterests(): string[] {
        return [
            NotificationName.LOAD_PLUGINS,
            NotificationName.AD_BREAK_START,
            NotificationName.CONTENT_START,
            NotificationName.REMOVE_PLUGIN,
        ];
    }

    handleNotification(notification: NotificationInterface): void {
        switch (notification.name) {

            case NotificationName.LOAD_PLUGINS:
                this.loadPlugins(<LoadPluginsNotificationInterface>notification.body);
                break;

            case NotificationName.REMOVE_PLUGIN:
                this.removePlugin(notification.body.name);
                break;

            // intentional fall-thru
            case NotificationName.AD_BREAK_START:
            case NotificationName.CONTENT_START:
                this.loadPendingPlugins();
                break;
        }
    }

    createPluginServices(): PluginServicesInterface {
        const am = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION);
        const uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI);

        return new PluginServices({
            player: am.getAppApi(),
            viewController: uim ? uim.viewController : null,
            buildInfo,
            system: <SystemServiceInterface>this.facade.retrieveService(ServiceName.System),
            domProxy: <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy),
            localization: (<LocalizationProxy>this.facade.retrieveProxy(ProxyName.LocalizationProxy)).getApi(),
            playerOptions: this.playerOptions,
            dispatch: (data: StrAnyDict) => {
                am.dispatchPluginEvent(data);
            },
            logger: this.logger,
        });
    }

    private registerDiagnosticPlugin(name: string) {
        this.diagnosticPlugin = this.plugins[name];
        this.diagnosticPluginVisible = true;
    }

    private get playerOptions() {
        return this.getModel<PlayerOptions>(ModelName.PlayerOptions).data;
    }

    private initPluginServices(): void {
        if (this.services) {
            return;
        }

        this.services = this.createPluginServices();
    }

    private createDiagnosticPlugin(): boolean {
        const dCfg = Utils.getNativePluginConfig(NativePlugin.DIAGNOSTIC, this.playerOptions);
        if (!dCfg) {
            return true;
        }

        if (this.plugins[dCfg.name]) {
            this.registerDiagnosticPlugin(dCfg.name);
            return false;
        }

        this.initPluginServices();
        this.createPlugin(dCfg).then(() => this.registerDiagnosticPlugin(dCfg.name));

        return true;
    }

    private createPlugins(plugins: PluginConfigInterface[], callback?: () => void): Promise<any> {
        return Promise
            .all(plugins.map(plugin => this.createPlugin(plugin)))
            .then(() => callback?.());
    }

    private async createPlugin(cfg: PluginConfigInterface): Promise<any> {
        if (cfg.url) {
            try {
                await ScriptLoader.load({
                    url: cfg.url,
                    errorRecovery: this.playerOptions.networkErrorRecovery,
                    timeout: (cfg.loadTimeout != null) ? cfg.loadTimeout : this.playerOptions.networkTimeout,
                });
            }
            catch (error) {
                this.log(LogLevel.ERROR, AppResources.messages.PLUGIN_LOAD_ERROR, error);
                return;
            }
        }

        const name = cfg.name;
        const opts = Util.assign({ name }, cfg.options) as PluginOptionsInterface;
        let plugin;

        if (cfg.factory) {
            plugin = await cfg.factory(this.services, opts);
            if (Util.isFunction(plugin)) {
                plugin = { destroy: plugin };
            }
        }
        else {
            const ref: any = cfg.classRef ? cfg.classRef : Utils.classFromQualifiedName(cfg.qualifiedClassName);
            if (ref) {
                plugin = (Util.isFunction(ref.onRegister)) ? ref : new ref();
                await plugin.onRegister?.(this.services.player, opts, this.services);
            }
        }

        if (!plugin) {
            return;
        }

        this.plugins[name] = plugin;

        this.log(LogLevel.INFO, `Created plugin '${name}'`);
        return plugin;
    }

    private killPlugin(name: string): void {
        const plugin = this.plugins[name];

        if (!plugin) {
            return;
        }

        if (Util.isFunction(plugin.destroy)) {
            plugin.destroy();
        }

        delete this.plugins[name];
    }

    private loadPendingPlugins(): void {
        if (!this.pendingLoads) {
            return;
        }

        this.createPlugins(this.pendingLoads);
        this.pendingLoads = null;
    }

    private loadPlugins(obj: LoadPluginsNotificationInterface): void {
        const plugins = obj.plugins || this.playerOptions.plugins;
        const ps = this.getModel(ModelName.PresentationState) as PresentationStateInterface;
        const notStarted = !ps.started;
        const callback = obj.callback;

        if (!plugins?.length) {
            callback?.();
            return;
        }

        this.initPluginServices();

        const low = plugins.filter(plugin => plugin.priority === PluginPriority.LOW);
        const high = plugins.filter(plugin => plugin.priority !== PluginPriority.LOW);

        if (notStarted) {
            this.pendingLoads = low;
        }

        const queue = (notStarted) ? high : low.concat(high);

        this.createPlugins(queue, callback);
    }
}
