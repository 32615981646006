import { AutoplayInfoInterface } from '../../iface/AutoplayInfoInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { PresentationStateInterface } from '../../iface/PresentationStateInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class PresentationState implements PresentationStateInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'PresentationState'; }

    get data(): PresentationStateInterface { return <PresentationStateInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set skipAutoplayCheck(value: boolean) { this.model.skipAutoplayCheck = value; }
    get skipAutoplayCheck(): boolean { return this.model.skipAutoplayCheck; }

    set streamTime(value: number) { this.model.streamTime = value; }
    get streamTime(): number { return this.model.streamTime; }

    set streamDuration(value: number) { this.model.streamDuration = value; }
    get streamDuration(): number { return this.model.streamDuration; }

    set autoplaySupport(value: AutoplayInfoInterface) { this.model.autoplaySupport = value; }
    get autoplaySupport(): AutoplayInfoInterface { return this.model.autoplaySupport; }

    set isAutoplay(value: boolean) { this.model.isAutoplay = value; }
    get isAutoplay(): boolean { return this.model.isAutoplay; }

    set isCurrentVideoAd(value: boolean) { this.model.isCurrentVideoAd = value; }
    get isCurrentVideoAd(): boolean { return this.model.isCurrentVideoAd; }

    set isFullscreen(value: boolean) { this.model.isFullscreen = value; }
    get isFullscreen(): boolean { return this.model.isFullscreen; }

    set isMuted(value: boolean) { this.model.isMuted = value; }
    get isMuted(): boolean { return this.model.isMuted; }

    set isMuteAtPlayStart(value: boolean) { this.model.isMuteAtPlayStart = value; }
    get isMuteAtPlayStart(): boolean { return this.model.isMuteAtPlayStart; }

    set started(value: boolean) { this.model.started = value; }
    get started(): boolean { return this.model.started; }

    set userHasUnmuted(value: boolean) { this.model.userHasUnmuted = value; }
    get userHasUnmuted(): boolean { return this.model.userHasUnmuted; }

    set volume(value: number) { this.model.volume = value; }
    get volume(): number { return this.model.volume; }

    set usesSsai(value: boolean) { this.model.usesSsai = value; }
    get usesSsai(): boolean { return this.model.usesSsai; }

    set streamId(value: string) { this.model.streamId = value; }
    get streamId(): string { return this.model.streamId; }

    set suspended(value: boolean) { this.model.suspended = value; }
    get suspended(): boolean { return this.model.suspended; }
}
