import { Util } from '../../core/Util';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { DestroyInterface } from '../../iface/DestroyInterface';
import { EventInterface } from '../../iface/EventInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { WebMafVideoSurface } from '../surface/WebMafVideoSurface';
import { BasePlaybackAdapter } from './BasePlaybackAdapter';
export class PlayStationAdapter extends BasePlaybackAdapter {

    protected override pType = PlaybackAdapterType.PLAY_STATION;

    private psEventMap = [
        {
            type: PlaybackAdapterEvents.MANIFEST_PARSED,
            callback: (e: EventInterface) => this.onManifestParsed(e)
        },
        {
            type: PlaybackAdapterEvents.ABR_QUALITY_LOADED,
            callback: (e: EventInterface) => this.onBubble(e)
        },
        {
            type: PlaybackAdapterEvents.AUDIO_TRACK_UPDATED,
            callback: (e: EventInterface) => this.onBubble(e)
        }
    ];

    constructor(config: PlaybackAdapterConfigInterface) {
        super(config);
        this.log(this.pType, this.videoSurface.video.version, this.videoSurface.video.TTYLevel, this.videoSurface.metrics);
    }

    override createVideoSurface() {
        return new WebMafVideoSurface(this.config);
    }

    override createMetadataSurface(): DestroyInterface {
        return null; // doesn't apply to WebMAF
    }

    ////////////////////
    //Public Methods
    ////////////////////
    override initialize(): void {
        super.initialize();

        //set options
        const opts = this.playback;
        const surface: WebMafVideoSurface = <WebMafVideoSurface>this.videoSurface;

        !isNaN(opts.abr.startBitrate) && (surface.startingBitrate = opts.abr.startBitrate);
        !isNaN(opts.abr.minBitrate) && (surface.minBitrate = opts.abr.minBitrate);
        !isNaN(opts.abr.maxBitrate) && (surface.maxBitrate = opts.abr.maxBitrate);
        !isNaN(opts.startTime) && (surface.startTime = opts.startTime);

        this.addEvents(this.videoSurface, this.psEventMap);
    }

    protected override loadMediaUrl(): Promise<void> {
        const drm = this.config.resource.location.drm;
        const token = drm.aes.header?.authorization?.trim().replace(/^Bearer /i,'');

        const loadAes = '{"command":"load", "contentUri":"' + this.mediaUrl + '","licenseUri":"", "customData":"&ls_session=' + token + '","sourceType":' + 0 + '}';
        const loadDrm = '{"command":"load", "contentUri":"' + this.mediaUrl + '", "licenseUri":"' + drm.playready.url + '","customData": "","sourceType":' + 0 + '}';

        this.videoSurface.src = (!Util.isEmpty(drm.aes.header)) ? loadAes : loadDrm;
        return super.loadMediaUrl();
    }

    override destroy(): Promise<void> {
        this.removeEvents(this.videoSurface, this.psEventMap);
        this.videoSurface.destroy();
        return super.destroy();
    }

    override play(): void {
        this.videoSurface.play();
    }

    override pause(): void {
        this.videoSurface.pause();
    }

    override seek(position: number): void {
        this.videoSurface.seek(position);
    }
    ////////////////////
    //Accessors
    ////////////////////

    override set audioTrack(track: AudioTrackInterface) {
        (<WebMafVideoSurface>this.videoSurface).audioTrack = track;
    }

    override get type(): PlaybackAdapterType {
        return this.pType;
    }

    override get time(): number {
        return this.videoSurface.time;
    }

    override get duration(): number {
        return this.videoSurface.duration;
    }

    override get droppedVideoFrames(): number {
        return this.videoSurface.metrics.droppedFrame; //TODO check name
    }
    override get buffering(): boolean {
        return this.videoSurface.buffering;
    }

    set currentIndex(index: number) {
        //setRepresentationEnable
        //setRepresentationDisable
    }
    // get currentIndex(): number {        
    // }

    get currentBitrate(): number {
        return this.videoSurface.metrics.bitrate; //TODO check this works
    }

    set autoQualitySwitching(value: boolean) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get autoBitrateSwitchEnabled(): boolean {

    // }

    set minBitrate(value: number) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get minBitrateAllowed(): number {

    // }

    set maxBitrate(value: number) {
        //setAdaptiveStreamingParameters
        //setFixVideoRepresentations
    }
    // get maxBitrateAllowed(): number {    
    // }

    get manifestQualities(): QualityInterface[] {
        return (<WebMafVideoSurface>this.videoSurface).masterBitrateProfile.map(this.normalizeQuality);
    }

    get liveStreamInfo(): LiveStreamInfoInterface {
        //getSeekWindow!
        return this.liveStreamInfoVO;
    }

    protected override handleVideoSurfaceError(e: EventInterface) {
        const { data, message, code, fatal } = e.data;
        this.throwError(code, message, data, fatal);
    }

    private onManifestParsed(e: EventInterface) {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onBubble(e: EventInterface) {
        this.emit(e.type, e.data);
    }
}
