import { Emitter } from "../../../core/Emitter";
import { AudioTrackInterface } from "../../../iface/AudioTrackInterface";
import { AudioTracksInterface } from "../../../iface/AudioTracksInterface";
import { ErrorInfoInterface } from "../../../iface/ErrorInfoInterface";
import { MetadataCuepointInterface } from '../../../iface/MetadataCuepointInterface';
import { PlaybackAdapterDelegateInterface } from '../../../iface/PlaybackAdapterDelegateInterface';
import { QualityInfoInterface } from '../../../iface/QualityInfoInterface';
import { QualityInterface } from "../../../iface/QualityInterface";
import { TextCuepointInterface } from '../../../iface/TextCuepointInterface';
import { TextTrackInterface } from '../../../iface/TextTrackInterface';
import { TextTracksInterface } from '../../../iface/TextTracksInterface';
import { PlaybackAdapterEvents } from "../../../playback/enum/PlaybackAdapterEvents";
import { TextTrackSurfaceEvents } from "../../../playback/enum/TextTrackSurfaceEvents";
import { VideoSurfaceEvents } from "../../../playback/enum/VideoSurfaceEvents";

export class PlaybackAdapterDelegate extends Emitter implements PlaybackAdapterDelegateInterface {

    qualities: QualityInterface[];

    protected pManifestParsed = false;

    constructor() {
        super();
    }

    readonly qualityChange = (quality: QualityInterface) => {
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, quality);
    };

    readonly qualityInfoChange = (qualityInfo: QualityInfoInterface) => {
        this.qualities = qualityInfo.qualities;

        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: qualityInfo.qualities });

        this.emit(PlaybackAdapterEvents.ABR_CONSTRAINTS_CHANGE, {
            minIndex: 0,
            maxIndex: qualityInfo.qualities.length - 1,
            manifestQualities: qualityInfo.qualities
        });
    };

    readonly error = (err: ErrorInfoInterface) => {
        this.emit(PlaybackAdapterEvents.ERROR, err);
    };

    readonly audioTrackChange = (newTrack: AudioTrackInterface) => {
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: newTrack });
    };

    readonly audioTrackInfoChange = (audioTrackInfo: AudioTracksInterface) => {
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, audioTrackInfo);
    };

    readonly cdnChange = (cdn: string) => {
        this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn });
    };

    readonly drmKeySystemCreated = (keySystem: string) => {
        this.emit(PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED, { keysystem: keySystem });
    };

    readonly timeUpdate = () => {
        this.emit(VideoSurfaceEvents.TIME_UPDATE);
    };

    readonly playing = () => {
        this.emit(VideoSurfaceEvents.PLAYING);
    };

    readonly paused = () => {
        this.emit(VideoSurfaceEvents.PAUSE);
    };

    readonly seeking = () => {
        this.emit(VideoSurfaceEvents.SEEKING);
    };

    readonly seeked = () => {
        this.emit(VideoSurfaceEvents.SEEKED);
    };

    readonly ended = () => {
        this.emit(VideoSurfaceEvents.ENDED);
    };

    readonly durationChange = () => {
        this.emit(VideoSurfaceEvents.DURATION_CHANGE);
    };

    readonly volumeChange = (volume: number, muted: boolean) => {
        // requires a change if kept - i.e., 
        // adapter should send volume and mute state.
        this.emit(VideoSurfaceEvents.VOLUME_CHANGE, { volume, muted });
    };

    ///////////////
    // text track 
    readonly metadataCuepoint = (data: MetadataCuepointInterface) => {
        this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, data);
    };

    readonly textCuepoints = (activeCues: TextCuepointInterface[]) => {
        this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, activeCues);
    };

    readonly textTrackDisplayModeChange = (mode: TextTrackMode) => {
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode });
    };

    readonly textTrackInfoChange = (textTrackInfo: TextTracksInterface) => {
        textTrackInfo.tracks?.forEach(track => this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track));
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
    };

    readonly textTrackChange = (textTrack: TextTrackInterface) => {
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, textTrack);
    };
}
