import { Emitter } from '../../../core/Emitter';
import { LogLevel } from '../../../enum/LogLevel';
import { AudioTrackInterface } from '../../../iface/AudioTrackInterface';
import { LiveStreamInfoInterface } from '../../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../../iface/PlaybackAdapterConfigInterface';
import { PlaybackAdapterCoreInterface } from '../../../iface/PlaybackAdapterCoreInterface';
import { PlaybackAdapterInterface } from '../../../iface/PlaybackAdapterInterface';
import { TextTrackInterface } from '../../../iface/TextTrackInterface';
import { PlaybackAdapterEvents } from '../../../playback/enum/PlaybackAdapterEvents';
import { TextTrackSurfaceEvents } from '../../../playback/enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../../../playback/enum/VideoSurfaceEvents';
import { Util } from '../../../util';
import { PlaybackAdapterDelegate } from './PlaybackAdapterDelegate';

/**
 * Wraps an external playback adapter so that is appears as a legacy playback adapter 
 */
export class PlaybackAdapterWrapper extends Emitter implements PlaybackAdapterCoreInterface {
  private lastKnownTimes: any = {
    buffer: NaN,
  };

  private pBuffering: boolean = false;
  private pPaused: boolean = false;
  private pSeeking: boolean = false;
  private pConstraints: any = {};
  private logger: LoggerInterface;

  constructor(public config: PlaybackAdapterConfigInterface, public adapter: PlaybackAdapterInterface, public delegate: PlaybackAdapterDelegate) {
    super();
    this.logger = config.logger;

    this.dispatchEvt = this.dispatchEvt.bind(this);
    Util.values(VideoSurfaceEvents).forEach(event => this.delegate.on(event, this.dispatchEvt));
    Util.values(PlaybackAdapterEvents).forEach(event => this.delegate.on(event, this.dispatchEvt));
    Util.values(TextTrackSurfaceEvents).forEach(event => this.delegate.on(event, this.dispatchEvt));
  }

  override destroy(): Promise<void> {
    super.destroy();
    return Promise.resolve(this.adapter.destroy());
  }

  get type(): string {
    return this.adapter.getType();
  }

  get time(): number {
    return this.adapter.getCurrentTime();
  }

  get duration(): number {
    return this.adapter.getDuration();
  }

  get liveStreamInfo(): LiveStreamInfoInterface {
    return this.adapter.getLiveStreamInfo();
  }

  get isLiveStream(): boolean {
    return this.adapter.getIsLiveStream();
  };

  get droppedVideoFrames(): number {
    return this.adapter.getMetrics?.().droppedVideoFrames;
  }

  get framerate(): number {
    return this.adapter.getMetrics?.().framerate;
  }

  get buffering(): boolean {
    if (!this.pPaused) {
      const threshold = (this.lastKnownTimes.buffer + 0.001);
      const currentTime = this.adapter.getCurrentTime();
      this.lastKnownTimes.buffer = currentTime;
      const stalled = currentTime < threshold;
      this.pBuffering = stalled && !this.pSeeking;
    }

    return this.pBuffering;
  };

  get bufferLength(): number {
    return this.adapter.getBufferLength();
  }

  set currentIndex(index: number) {
    this.adapter.setQuality(this.delegate.qualities[index]);
  }

  set autoQualitySwitching(value: boolean) {
    this.adapter.setAutoQualitySwitching(value);
  }

  set maxBitrate(value: number) {
    this.pConstraints.maxBitrate = value;
    this.adapter.setMaxBitrate(value);
  }

  set minBitrate(value: number) {
    this.pConstraints.minBitrate = value;
    this.adapter.setMinBitrate(value);
  }

  set textTrackMode(value: TextTrackMode) {
    this.adapter.setTextTrackMode(value);
  }

  set textTrack(value: TextTrackInterface) {
    this.adapter.setTextTrack(value);
  }

  set audioTrack(value: AudioTrackInterface) {
    this.adapter.setAudioTrack(value);
  }

  initialize(): void { }

  load(): Promise<any> {
    return this.adapter.load().then((result = {}) => {
      const metadata = Util.merge({
        manifest: {
          mimeType: Util.getResourceMimeType(this.config.resource),
        },
        fragment: {
          mimeType: '',
        },
      }, result);

      this.delegate.emit(PlaybackAdapterEvents.LOADED_METADATA, metadata);
    });
  }

  play(): void {
    this.adapter.play()
      .then(() => {
        this.pPaused = false;
      })
      .catch(error => {
        this.logger.warn('Exception caught in play() Promise: ' + error.message, LogLevel.WARN);
        this.emit(VideoSurfaceEvents.AUTOPLAY_BLOCKED, error);
      });
  };

  pause(): void {
    this.pPaused = true;
    this.adapter.pause();
  };

  suspend(): void {
    throw new Error('Method not implemented.');
  };

  resume(): void {
    throw new Error('Method not implemented.');
  };

  seek(position: number): void {
    this.pSeeking = true;
    this.adapter.seek(position).then(() => this.pSeeking = false);
  };

  resize(): void {
    this.adapter.resize();
  };

  clearCue(): void {
    this.adapter.clearText?.();
  }
}
