import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { DaiProxyApi } from '../deprecated/ad/dai-api/DaiProxyApi';
import { DaiAdService } from '../deprecated/ad/DaiAdService';
import { DaiPresentationMediator } from '../deprecated/ad/DaiPresentationMediator';
import { ImaAdServiceInterface } from '../deprecated/ad/iface';
import { ImaAdService } from '../deprecated/ad/ImaAdService';
import { ImaPresentationMediator } from '../deprecated/ad/ImaPresentationMediator';
import { AdapterRole } from '../enum/AdapterRole';
import { AdContext } from '../enum/AdContext';
import { Autoplay } from '../enum/Autoplay';
import { ErrorCode } from '../enum/ErrorCode';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlayerDom } from '../enum/PlayerDom';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { NotificationInterface, PlayerDomProxyInterface, PresentationMediatorInterface, ServiceInterface, SystemServiceInterface } from '../iface';
import { AdAdapterDelegateInterface } from '../iface/AdAdapterDelegateInterface';
import { AdAdapterInterface } from '../iface/AdAdapterInterface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceAdInterface } from '../iface/ResourceAdInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { AdapterProxy } from '../model/AdapterProxy';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { AdPresentationMediator } from '../view/AdPresentationMediator';
import { ContentPresentationMediator } from '../view/ContentPresentationMediator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class PrepPlaybackCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const sysSvc = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        const resource = notification.body as ResourceConfigurationInterface;

        if (!sysSvc.global || !sysSvc.global.document) {
            this.sendNotification(NotificationName.VIDEO_START_ERROR, { error: AppResources.messages.ENVIRONMENT_NOT_SUPPORTED, fatal: true });

            return;
        }

        const presoState = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
        const useAutoplay = this.isAutoplayRequested(playerOpts.autoplay);

        this.facade.registerProxy(new ContentPlaybackStateProxy(ProxyName.ContentPlaybackStateProxy));

        if (sysSvc.isWebMaf || sysSvc.isTizenSmartTv || sysSvc.isXboxOne) {
            this.processAutoplaySupport({
                supportsMutedAutoplay: true,
                supportsUnmutedAutoplay: true
            }, presoState, playerOpts);

            this.createPresentation(sysSvc, resource, presoState, playerOpts);
        }
        else if (!presoState.skipAutoplayCheck && useAutoplay) {
            this.checkAutoplay(playerOpts).then((result: AutoplayInfoInterface) => {
                this.processAutoplaySupport(result, presoState, playerOpts);
                this.createPresentation(sysSvc, resource, presoState, playerOpts);
            });
        }
        else {
            this.createPresentation(sysSvc, resource, presoState, playerOpts);
        }
    }

    private async createPresentation(sysSvc: SystemServiceInterface, resource: ResourceConfigurationInterface, presentationState: PresentationStateInterface, playerOpts: PlayerOptionsInterface) {
        const presoMed = await this.getPresoMediator(sysSvc, resource, presentationState, playerOpts);

        if (!presoMed) {
            return;
        }

        this.facade.registerMediator(presoMed);

        this.sendNotification(NotificationName.START_PRESENTATION);
    }

    private async getPresoMediator(sysSvc: SystemServiceInterface, resource: ResourceConfigurationInterface, presentationState: PresentationStateInterface, playerOpts: PlayerOptionsInterface): Promise<PresentationMediatorInterface> {
        const domProxy = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        const video = this.getVideo(sysSvc, domProxy, playerOpts);
        const adCfg = <ResourceAdInterface>resource.ad;
        const adContext: string | null = (adCfg && adCfg.context) || null;
        const n = MediatorName.PRESENTATION_MEDIATOR;

        let ret: PresentationMediatorInterface = null,
            ok: boolean;

        if (video === null) {
            this.sendNotification(NotificationName.RESOURCE_ERROR, {
                code: ErrorCode.UNEXPECTED_CONDITION,
                message: AppResources.messages.VIDEO_PLAYBACK_UNAVAILABLE,
                fatal: true,
            });

            return ret;
        }

        if (adContext && adContext !== AdContext.NONE || presentationState.isAutoplay) {
            // VTG-2214: Only required for iOS; attribute ignored elsewhere
            domProxy?.setVideoAttribute('playsinline', '');
        }

        switch (adContext) {
            case AdContext.IMA:
            case AdContext.IMA_AD_ONLY:
                ok = this.createImaAdService(resource);
                if (!ok) {
                    this.sendNotification(NotificationName.RESOURCE_ERROR, {
                        code: ErrorCode.IMA_SDK_MISSING,
                        message: AppResources.messages.IMA_SDK_MISSING,
                        fatal: true,
                    });
                }
                else {
                    ret = new ImaPresentationMediator(n, video);
                }
                break;

            case AdContext.DAI_SDK:
            case AdContext.DAI_API:
                const useApi = adContext === AdContext.DAI_API;
                ok = this.createDaiAdService(domProxy, resource, useApi, sysSvc);
                ok && (ret = new DaiPresentationMediator(n, video));
                !ok && this.sendNotification(NotificationName.RESOURCE_ERROR, {
                    code: ErrorCode.DAI_SDK_MISSING,
                    message: AppResources.messages.DAI_SDK_UNAVAILABLE,
                    fatal: true,
                });
                break;

            case AdContext.DAI_SSB:
                if (adCfg.dai.ssbStreamUrl) {
                    // for now- until we decide whether mediaUrl will act as fallback stream 
                    resource.location.mediaUrl = adCfg.dai.ssbStreamUrl;
                }
                ret = new ContentPresentationMediator(n, video);
                break;

            default:
                if (adCfg.enabled === true) {
                    try {
                        const ad = new AdPresentationMediator(n, video);
                        const delegate = ad.getDelegate();
                        const adapterProxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
                        const adapter = await adapterProxy.createAdapter<AdAdapterInterface, AdAdapterDelegateInterface>(AdapterRole.AD, resource, () => delegate);
                        ad.adAdapter = adapter;

                        const resourceOverride = await adapter.getResource?.();

                        if (resourceOverride) {
                            resource = resourceOverride;
                        }
                        ret = ad;
                    }
                    catch (error) {
                        this.sendNotification(NotificationName.RESOURCE_ERROR, {
                            code: ErrorCode.AD_ADAPTER_ERROR,
                            message: error.message,
                            data: error,
                            fatal: true,
                        });
                    }
                }
                else {
                    ret = new ContentPresentationMediator(n, video);
                }
                break;
        }

        this.createResourceProxy(resource);

        return ret;
    }

    private createResourceProxy(resource: ResourceConfigurationInterface): void {
        const resourceProxy = new ResourceProxy(ProxyName.ResourceProxy, resource);
        this.facade.registerProxy(resourceProxy);
    }

    ////////////////
    // Ad services
    private createDaiAdService(
        domProxy: PlayerDomProxyInterface,
        resource: ResourceConfigurationInterface,
        useApi: boolean,
        sysSvc: SystemServiceInterface
    ): boolean {

        if (this.facade.retrieveService(ServiceName.DaiAd)) {
            return true;
        }

        // issue/83: enable OM
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
        if (!useApi && playerOpts.enableOm === true) {
            /** @ts-ignore */
            sysSvc.global?.google?.ima?.dai?.api?.DaiSdkSettings?.setFeatureFlags({ 'enableOmidBeta': true });
        }

        const svc = new DaiAdService(ServiceName.DaiAd, (useApi ? DaiProxyApi : null));
        this.facade.registerService(svc);

        if (!svc.sdk) {
            svc.destroy();
            this.facade.removeService(ServiceName.DaiAd);
            return false;
        }

        const trkSets = resource.ad.viewabilityTracking;
        domProxy && svc.setContainer(domProxy.getElement(PlayerDom.AD_CONTAINER));

        const videoIface = useApi ? sysSvc.createSimpleVideoInterface() : domProxy.getVideo();
        svc.setVideoInterface(videoIface);

        svc.setTrackingData({
            pageType: trkSets.pageType,
            viewGuid: trkSets.viewGuid,
            partner: trkSets.partner
        });

        return true;
    }

    getVideoInterface(sysSvc: SystemServiceInterface): any {
        return sysSvc.createSimpleVideoInterface();
    }

    getVideo(sysSvc: SystemServiceInterface, domProxy: PlayerDomProxyInterface, playerOpts: PlayerOptionsInterface): any {
        if (sysSvc.isWebMaf) {
            return sysSvc.webMafPlayer;
        }
        else if (domProxy) {
            if (playerOpts.overrides?.reuseVideoElement === false) {
                domProxy.recreateVideo();
            }
            return domProxy.getVideo();
        }

        return null;
    }

    createImaAdService(resource: ResourceConfigurationInterface): boolean {
        if (this.facade.retrieveService(ServiceName.ImaAd)) {
            return true;
        }

        const svc: ImaAdServiceInterface = new ImaAdService(ServiceName.ImaAd);
        this.facade.registerService(<ServiceInterface>svc);

        const sdk = svc.sdk;

        if (sdk !== null) {
            const domProxy = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
            const adC = domProxy.getElement(PlayerDom.AD_CONTAINER);
            const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);

            if (playerOpts.enableOm === true) {
                // Issue #83; enable Open measurement
                (sdk as any).settings?.setFeatureFlags({ 'enableOmidBeta': true });
            }

            svc.setContainer(adC);
            svc.adsLoaderOptions = {
                enableOm: playerOpts.enableOm === true,
                adContainer: adC,
                adClickEl: domProxy.getElement(PlayerDom.AD_CLICK_EL),
                video: domProxy.getVideo(),
                sdkSettings: svc.sdkSettings,
                adService: svc,
            };

            const trkSets = resource.ad.viewabilityTracking;
            svc.setTrackingData({
                pageType: trkSets.pageType,
                viewGuid: trkSets.viewGuid,
                partner: trkSets.partner
            });
            svc.createAdsLoader();

            return true;
        }
        else {
            this.facade.removeService(ServiceName.ImaAd);

            return false;
        }
    }

    ////////////
    // autoplay
    private processAutoplaySupport(
        result: AutoplayInfoInterface,
        presoMdl: PresentationStateInterface,
        playerOpts: PlayerOptionsInterface
    ): void {

        const apOpt = playerOpts.autoplay;
        const mutedOk = result.supportsMutedAutoplay;
        const unmutedOk = result.supportsUnmutedAutoplay;

        const wantsMuted = apOpt === Autoplay.ATTEMPT_MUTED || apOpt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED;
        const wantsUnmuted = apOpt === Autoplay.ATTEMPT_UNMUTED;
        const conflict = (wantsMuted && !mutedOk) || (wantsUnmuted && !unmutedOk);
        if (!presoMdl.isAutoplay && conflict) {
            this.sendNotification(NotificationName.AUTOPLAY_BLOCKED, new Error('Autoplay capability conflict.'), NotificationType.INTERNAL);
        }

        presoMdl.autoplaySupport = {
            supportsMutedAutoplay: mutedOk,
            supportsUnmutedAutoplay: unmutedOk
        };

        presoMdl.isMuteAtPlayStart = (
            apOpt === Autoplay.ATTEMPT_MUTED && mutedOk ||
            (apOpt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && !unmutedOk && mutedOk)
        );

        presoMdl.isAutoplay = this.shouldAutoplay(apOpt, result);
    }

    private isAutoplayRequested(opt: Autoplay): boolean {
        if (Util.isEmpty(opt) || opt === Autoplay.NONE) {
            return false;
        }

        return true;
    }

    private shouldAutoplay(opt: Autoplay, info: AutoplayInfoInterface): boolean {
        if (Util.isEmpty(opt) || opt === Autoplay.NONE) {
            return false;
        }

        const mutedOk = info.supportsMutedAutoplay;
        const unmutedOk = info.supportsUnmutedAutoplay;

        const muteOnly = opt === Autoplay.ATTEMPT_MUTED && mutedOk;
        const soundOnly = opt === Autoplay.ATTEMPT_UNMUTED && unmutedOk;
        const fallback = !soundOnly && (opt === Autoplay.ATTEMPT_UNMUTED_THEN_MUTED && mutedOk);

        return muteOnly || soundOnly || fallback;
    }

    private checkAutoplay(playerOpts: PlayerOptionsInterface): Promise<AutoplayInfoInterface> {
        const ads = <AutoplayCapabilitiesService>this.getService(ServiceName.AutoplayCapabilities);

        return ads.detectCapabilities(playerOpts.overrides?.blankVideoUrl);
    }
}
