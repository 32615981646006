import { ModelName } from '../../enum/ModelName';
import { ProxyName } from '../../enum/ProxyName';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { AdItemInterface } from '../../iface/AdItemInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { ModelCollectionProxy } from '../../model/ModelCollectionProxy';
import { Proxy } from '../../mvc/Proxy';
import { AdDataProxyInterface } from './iface';


// Note: get accessors return a copy of the raw data - it can be read, 
// but modifications to the object/values are not recorded to the model.
export class AdDataProxy extends Proxy implements AdDataProxyInterface {

    private breakModel!: AdBreakInfoInterface;
    private adItemModel!: AdItemInterface;

    constructor(name: string, data?: any) {
        super(name, data);
    }

    override onRegister() {
        const mcp = <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy);

        this.breakModel = <AdBreakInfoInterface>mcp.getModel(ModelName.AdBreakInfo);
        this.adItemModel = <AdItemInterface>mcp.getModel(ModelName.AdItem);
    }

    override onRemove(): void {
        this.breakModel = null;
        this.adItemModel = null;

        super.onRemove();
    }

    resetAll() {
        (<ModelInterface>(<unknown>this.breakModel)).reset();
        this.resetAd();
    }

    resetAd() {
        (<ModelInterface>(<unknown>this.adItemModel)).reset();
    }

    set breakInfo(bi: AdBreakInfoInterface) {
        //@ts-ignore
        const m = this.breakModel.model;
        for (let q in m) {
            if (Object.prototype.hasOwnProperty.call(bi, q)) {
                //@ts-ignore
                m[q] = bi[q];
            }
        }
    }
    get breakInfo(): AdBreakInfoInterface {
        const m = <ModelInterface>(<unknown>this.breakModel);
        return <AdBreakInfoInterface>(m.data);
    }

    set adInfo(ai: AdItemInterface) {
        //@ts-ignore
        const m = this.adItemModel.model;
        for (let q in m) {
            if (Object.prototype.hasOwnProperty.call(ai, q)) {
                //@ts-ignore
                m[q] = ai[q];
            }
        }
    }
    get adInfo(): AdItemInterface {
        const m = <ModelInterface>(<unknown>this.adItemModel);
        return <AdItemInterface>(m.data);
    }
}
