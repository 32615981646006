import { StrAnyDict } from '../../iface/StrAnyDict';
import { Validator } from "../vo/Validator";


export const ad_item_config: StrAnyDict = {

    adDuration: {
        type: 'number',
        defaultValue: -1,
        setterTransformer: (val: number): number => {
            return Validator.validNumber(val, -1);
        }
    },

    adTitle: {
        type: 'string',
        defaultValue: null
    },

    adId: {
        type: 'string',
        defaultValue: null
    },

    adPosition: {
        type: 'number',
        defaultValue: -1,
        setterTransformer: (val: number): number => {
            return Validator.validNumber(val, -1);
        }
    },

    adAssetUrl: {
        type: 'string',
        defaultValue: null
    },

    isVpaid: {
        type: 'boolean',
        defaultValue: false,
        setterTransformer: (val: boolean): boolean => {
            return typeof val !== 'boolean' ? false : val;
        }
    },

    wrapperAdId: {
        type: 'string',
        defaultValue: null
    },

    creativeId: {
        type: 'string',
        defaultValue: null
    },

    wrapperCreativeId: {
        type: 'string',
        defaultValue: null
    },

    vastMediaWidth: {
        type: 'number',
        defaultValue: null
    },

    vastMediaHeight: {
        type: 'number',
        defaultValue: null
    },

    vastMediaBitrate: {
        type: 'number',
        defaultValue: null
    },
};
