import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationInterface, PresentationMediatorInterface } from '../iface';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class StartPresentationCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        this.sendNotification(NotificationName.RESOURCE_START);
        pm.start();
    }
}
