import { Util } from "../core/Util";
import { Network } from '../enum/Network';
import { EventHandler } from "../iface/EventHandler";
import { ScriptLoaderComplete } from '../iface/ScriptLoaderComplete';
import { ScriptLoaderOptions } from "../iface/ScriptLoaderOptions";
import { StrAnyDict } from "../iface/StrAnyDict";
import { StrStrDict } from "../iface/StrStrDict";
import { RecoveryEnabledRequest } from './RecoveryEnabledRequest';

export type ScriptLoadOptions = Partial<ScriptLoaderOptions>;

export class ScriptLoader extends RecoveryEnabledRequest {

    static event: StrStrDict = {
        COMPLETE: "complete"
    };

    private timeoutHandles: StrAnyDict | null;
    private map: StrAnyDict;

    static load(options: ScriptLoadOptions): Promise<ScriptLoaderComplete> {
        return new Promise((resolve, reject) => {
            options.onComplete = ({ data }: StrAnyDict) => (data.error || data.timedOut) ? reject(data) : resolve(data);
            new ScriptLoader(<ScriptLoaderOptions>options);
        });
    }

    constructor(options: ScriptLoaderOptions) {
        const opts = <ScriptLoaderOptions>Util.assign({ timeout: Network.TIMEOUT, }, options || {});

        if (!opts.timeout) {
            opts.timeout = Network.TIMEOUT;
        }

        super(opts);

        this.timeoutHandles = {};
        this.map = {};

        let hasListener = this.hasListenerFor(ScriptLoader.event.COMPLETE);

        if (this.opts.url && hasListener) {
            this.loadScript(this.opts.url);
        }
        else if (this.opts.urls && hasListener) {
            this.loadScripts(this.opts.urls);
        }
        else {
            throw new Error(`Script load error: Missing url or listener. url: ${this.opts.url || this.opts.urls}, hasListener: ${hasListener}`);
        }
    }

    override destroy() {
        if (!this.timeoutHandles) {
            return;
        }

        for (let q in this.timeoutHandles) {
            clearTimeout(this.timeoutHandles[q]);
        }
        this.timeoutHandles = null;
        super.destroy();
    }

    loadScript(scriptUrl: string, completeListener?: EventHandler): void {
        const scr = document.createElement('script'),
            sec = window.location.protocol === 'https:',
            url = sec ? scriptUrl.replace('http:', 'https:') : scriptUrl;

        this.map[url] = {
            hLoad: () => this.hLoadOrError(url),
            hError: () => this.hLoadOrError(url, true, false),
            scriptEl: scr
        };

        const mapObj = this.map[url];

        completeListener && this.on(ScriptLoader.event.COMPLETE, completeListener);

        scr.async = true;
        scr.addEventListener('load', mapObj.hLoad);
        scr.addEventListener('error', mapObj.hError);

        this.timeoutHandles[url] = setTimeout(
            () => this.hLoadOrError(url, true, true),
            this.opts.timeout
        );

        document.head.appendChild(scr);
        scr.src = url;
    }

    loadScripts(urls: string[], completeListener?: EventHandler) {
        completeListener && this.on(ScriptLoader.event.COMPLETE, completeListener);

        for (let i = 0, n = urls.length; i < n; i++) {
            this.loadScript(urls[i]);
        }
    }

    private hLoadOrError(url: string, error?: boolean, timedOut?: boolean): void {

        clearTimeout(this.timeoutHandles[url]);
        delete this.timeoutHandles[url];

        let mapObj: StrAnyDict = this.map[url];
        mapObj.scriptEl.removeEventListener('load', mapObj.hLoad);
        mapObj.scriptEl.removeEventListener('error', mapObj.hError);

        for (let q in mapObj) { delete mapObj[q]; }
        delete this.map[url];

        // VTG-1763: Add retries on error. Note that if we're loading multiple scripts (via array to loadScripts()),  
        // each script that fails will increment the retry count, which is 
        // limited to options.retryAttempts in total among the files being requested.
        if (error && this.shouldRetry()) {
            this.incrementAttempts();
            setTimeout(() => { this.loadScript(url); }, this.retryInterval);
        }
        else {
            this.emit(ScriptLoader.event.COMPLETE, {
                url: url,
                error: error,
                timedOut: timedOut === true
            });
        }
    }
}
