import { AsyncDataRequest } from '../dataservice/AsyncDataRequest';
import { RequestOptionsInterface } from "../iface/RequestOptionsInterface";
import { QueryString } from './QueryString';

/**
 * Helper function for making simple HTTP requests.
 * 
 * @param options 
 */
export function request(options: RequestOptionsInterface): Promise<any> {
	if (options.query) {
		options.url = QueryString.append(options.url, options.query);
	}
	return AsyncDataRequest.load(options).then(xhr => xhr.response);
}
