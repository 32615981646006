import { Deprecated } from '../../deprecated/Deprecated';
import { KeyboardScope } from '../../enum/KeyboardScope';
import { LogLevel } from '../../enum/LogLevel';
import { MediatorName } from '../../enum/MediatorName';
import { ProxyName } from '../../enum/ProxyName';
import { ServiceName } from '../../enum/ServiceName';
import { FacadeInterface, NotificationInterface, SystemServiceInterface } from '../../iface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { PlayerDomProxy } from '../../model/PlayerDomProxy';
import { AccessibilityKeyCommandMediator } from '../../view/AccessibilityKeyCommandMediator';
import { FullscreenMediator } from '../../view/FullscreenMediator';
import { UserKeyCommandMediator } from '../../view/UserKeyCommandMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepViewCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const f: FacadeInterface = this.facade,
            sysSvc = <SystemServiceInterface>f.retrieveService(ServiceName.System),
            playerOpts: PlayerOptionsInterface = notification.body.playerOptions;

        if (!sysSvc.global || sysSvc.isWebMaf) {
            this.log(LogLevel.INFO, 'Skipping view creation');
            return;
        }

        // ui
        const cvui = playerOpts?.uiConfig?.cvui || sysSvc.global.cvui;
        if (!playerOpts.useNativeControls && cvui) {
            Deprecated.createUi(this.facade, cvui, sysSvc, playerOpts);
        }
        else if (!playerOpts.useNativeControls) {
            this.log(LogLevel.INFO, 'PrepView: No UI specified.');
            const pdp = f.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxy;
            pdp.removePanelContainer();
        }

        // fullscreen
        if (!sysSvc.isTv) {
            f.registerMediator(new FullscreenMediator(MediatorName.FULLSCREEN));
        }

        const kScope = this.getScopeForKeys(playerOpts.overrides && playerOpts.overrides.keyboardScope);

        if (kScope !== KeyboardScope.NONE) {
            // accessibility key command mediator
            const akm = new AccessibilityKeyCommandMediator(MediatorName.ACC_KEY_CMD_MEDIATOR);
            f.registerMediator(akm);

            // user key commands
            const ukm = new UserKeyCommandMediator(MediatorName.KEY_COMMAND);
            f.registerMediator(ukm);

            // set key handling scope
            const c = this.getPlayerContainer();
            akm.scope = c;
            ukm.scope = c;
        }

        this.log(LogLevel.DEBUG, 'View prepared');
    }

    getScopeForKeys(scope: string): KeyboardScope {
        switch (scope) {
            case KeyboardScope.PLAYER:
            case KeyboardScope.DOCUMENT:
            case KeyboardScope.NONE:
                return scope;
        }

        return KeyboardScope.PLAYER;
    }

    getPlayerContainer(): HTMLElement {
        const domProxy = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);

        return domProxy.getMain();
    }
}
