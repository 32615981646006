import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { NotificationInterface, ValidationInterface } from '../iface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { ResourceValidator } from './command-helpers/ResourceValidator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';
import { ResourceConfiguration } from '../app/ResourceConfiguration';

export class PrepResourceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        switch (notification.name) {
            case NotificationName.PREP_RESOURCE_COLLECTION:
                this.prepResourceCollection(notification);
                break;

            case NotificationName.REGISTER_RESOURCE:
                this.registerResource(notification);
                break;
        }
    }

    private prepResourceCollection(notification: NotificationInterface): void {
        if (!notification.body.resource) {
            this.invalidResource(AppResources.messages.RESOURCE_MISSING);

            return;
        }

        const resource: ResourceConfigurationInterface = new ResourceConfiguration(notification.body.resource);

        this.validateResource(resource);
    }

    private validateResource(resource: ResourceConfigurationInterface): void {
        const resourceValidation: ValidationInterface = ResourceValidator.validate(resource);

        if (!resourceValidation.error) {
            this.facade.sendNotification(
                this.cmsDataRetrievalRequired(resource) ? NotificationName.RETRIEVE_RESOURCE : NotificationName.TRANSFORM_RESOURCE,
                { resource },
                NotificationType.INTERNAL
            );
        }
        else {
            this.invalidResource(resourceValidation.msg);
        }
    }

    private registerResource(notification: NotificationInterface): void {
        const resource: ResourceConfigurationInterface = notification.body.resource;
        this.facade.sendNotification(NotificationName.PLAY_RESOURCE, resource, NotificationType.INTERNAL);
    }

    private cmsDataRetrievalRequired(r: ResourceConfigurationInterface): boolean {
        return !Util.isEmpty(r.location.cms) &&
            (!Util.isEmpty(r.location.tokenMap) || !Util.isEmpty(r.location.cmsUri));
    }

    private invalidResource(msg: string) {
        this.log(LogLevel.ERROR, AppResources.messages.INVALID_RESOURCE);
        this.facade.sendNotification(NotificationName.RESOURCE_ERROR, {
            message: `${AppResources.messages.INVALID_RESOURCE}: ${msg}`,
            code: ErrorCode.INVALID_RESOURCE_FORMAT,
            fatal: true,
        }, NotificationType.INTERNAL);
    }

}
