import { LogAwareSimpleCommand } from "./LogAwareSimpleCommand";
import { NotificationInterface } from '../iface';
import { NotificationName } from '../enum/NotificationName';
import { MediatorName } from '../enum/MediatorName';
import { PluginMediator } from '../view/PluginMediator';
import { DiagnosticAction } from '../enum/DiagnosticAction';
import { ProxyName } from '../enum/ProxyName';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { AppMediator } from '../view/AppMediator';


export class UserKeyboardCommand extends LogAwareSimpleCommand {

    private copyNoticeStyle: string = 'position: absolute; z-Index: 900000000; top: 0; right: 0; width: 180px; ' +
        'background: #fc0; color: #000; font-family: Arial, sans-serif; text-align: center; height: 64px; line-height: 64px; font-size: 32px';

    execute(notification: NotificationInterface) {
        const action = notification.body && notification.body.action;

        switch (action) {
            case DiagnosticAction.DIAGNOSTIC_PLUGIN:
                const pim = <PluginMediator>this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR);

                pim && pim.toggleDiagnosticPlugin();
                break;

            case DiagnosticAction.CONFIG_TO_CLIPBOARD:
            case DiagnosticAction.CONFIG_TO_TEST_PAGE:
                const toClip = action === DiagnosticAction.CONFIG_TO_CLIPBOARD;
                const d = this.getSerializedData();

                if (toClip) {
                    this.copyToClipboard(d);
                    this.displayCopyNotice();
                }
                else {
                    // NOT YET IMPL'D: send to test page
                    // const w: Window = window.open('TEST_PAGE_URL', '_blank');
                    // w.addEventListener('load', () => {
                    //     w.postMessage(d, `${DOMAIN}`); // domain of test page
                    // });
                }
                break;

            default:
                this.sendNotification(NotificationName.APP_EVENT, {
                    notificationName: notification.name,
                    data: notification.body
                });
                break;
        }
    }


    displayCopyNotice(): void {
        const pdp = <PlayerDomProxy>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        const m = pdp.getMain();
        const d = document.createElement('div');

        d.setAttribute('style', this.copyNoticeStyle);
        d.innerHTML = 'Copied!';

        m.appendChild(d);
        setTimeout(() => m.removeChild(d), 2500);
    }

    copyToClipboard(str: string): void {
        const t: HTMLTextAreaElement = document.createElement('textarea');

        t.setAttribute('style', 'position: absolute; top: -120px;');
        document.body.appendChild(t);
        t.value = str;
        t.select();

        document.execCommand("copy");

        document.body.removeChild(t);
    }

    getSerializedData(): string {
        const am = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION);

        return am.getConfigAsJson();
    }
}