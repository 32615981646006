import { AdItemInterface } from '../../iface/AdItemInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class AdItem implements AdItemInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'AdItem'; }

    get data(): AdItemInterface { return <AdItemInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set adDuration(value: number) { this.model.adDuration = value; }
    get adDuration(): number { return this.model.adDuration; }

    set adTitle(value: string) { this.model.adTitle = value; }
    get adTitle(): string { return this.model.adTitle; }

    set adId(value: string) { this.model.adId = value; }
    get adId(): string { return this.model.adId; }

    set adPosition(value: number) { this.model.adPosition = value; }
    get adPosition(): number { return this.model.adPosition; }

    set adAssetUrl(value: string) { this.model.adAssetUrl = value; }
    get adAssetUrl(): string { return this.model.adAssetUrl; }

    set isVpaid(value: boolean) { this.model.isVpaid = value; }
    get isVpaid(): boolean { return this.model.isVpaid; }

    set wrapperAdId(value: string) { this.model.wrapperAdId = value; }
    get wrapperAdId(): string { return this.model.wrapperAdId; }

    set creativeId(value: string) { this.model.creativeId = value; }
    get creativeId(): string { return this.model.creativeId; }

    set wrapperCreativeId(value: string) { this.model.wrapperCreativeId = value; }
    get wrapperCreativeId(): string { return this.model.wrapperCreativeId; }

    set vastMediaWidth(value: number) { this.model.vastMediaWidth = value; }
    get vastMediaWidth(): number { return this.model.vastMediaWidth; }

    set vastMediaHeight(value: number) { this.model.vastMediaHeight = value; }
    get vastMediaHeight(): number { return this.model.vastMediaHeight; }

    set vastMediaBitrate(value: number) { this.model.vastMediaBitrate = value; }
    get vastMediaBitrate(): number { return this.model.vastMediaBitrate; }
}
