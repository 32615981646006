import { MediatorName } from '../enum/MediatorName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationInterface, PresentationMediatorInterface } from '../iface';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


// playlist notifications are announced to the external world as events.
export class PlaylistCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        switch (notification.name) {

            case NotificationName.PLAYLIST_ADVANCED:
                this.sendAppEventNotification(notification.name, notification.body);
                break;

            case NotificationName.PLAYLIST_COMPLETE:
            case NotificationName.PLAYLIST_CLEARED:
            case NotificationName.PLAYLIST_CURRENT_RESOURCE_CLEARED:
                // 'cleared' notifications carry the interrupted resource (if any) in body
                this.close()
                    .then(() => {
                        this.sendAppEventNotification(notification.name, notification.body);
                    });
                break;
        }
    }

    private sendAppEventNotification(n: string, data: any = null) {
        this.sendNotification(NotificationName.APP_EVENT, {
            notificationName: n,
            data: data
        });
    }

    private close(): Promise<void> {
        const presoM = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        return (presoM) ? presoM.close() : Promise.resolve();
    }
}
