import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { RangeInterface } from '../../iface/RangeInterface';
import { ResourcePlaybackAbrInterface } from '../../iface/ResourcePlaybackAbrInterface';
import { TextTrackInterface } from '../../iface/TextTrackInterface';
import { TextTracksInterface } from '../../iface/TextTracksInterface';
import { VideoSurfaceInterface } from '../../iface/VideoSurfaceInterface';
import { HlsjsRobustness } from '../enum/HlsjsRobustness';
import { Playback } from '../enum/Playback';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { TextTrackType } from '../enum/TextTrackType';
import { audioTracksUpdatedData, audioTrackSwitchedData, Config, errorData, fragLoadedData, Hls, Level, levelLoadedData, levelSwitchedData, levelSwitchingData, levelUpdatedData, manifestParsedData } from '../interface/HlsjsInterface';
import { Html5VideoSurface } from '../surface/Html5VideoSurface';
import { SmpteToVttCueConverter } from '../util/SmpteToVttCueConverter';
import { BaseHtml5Adapter } from './BaseHtml5Adapter';


export class HlsjsAdapter extends BaseHtml5Adapter {

    protected override pType = PlaybackAdapterType.HLSJS;

    private Hls: Hls = (<any>window).Hls; // static version of Hls and all static properties. 
    private player!: Hls; // hls instantiated instance. 
    private pFramerate: number = Number.NaN;
    private pFragmentType: string = '';
    private textTrackInfo: TextTracksInterface = {
        track: null,
        tracks: [],
    };
    private hlsjsEventMap = [
        {
            type: this.Hls.Events.MANIFEST_PARSED,
            callback: (type: string, data: manifestParsedData) => this.onManifestParsed(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_LOADED,
            callback: (type: string, data: any) => this.onLevelLoaded(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_UPDATED,
            callback: (type: string, data: levelUpdatedData) => this.onLevelUpdated(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_SWITCHING,
            callback: (type: string, data: levelSwitchingData) => this.onLevelSwitching(type, data)
        },
        {
            type: this.Hls.Events.LEVEL_SWITCHED,
            callback: (type: string, data: levelSwitchedData) => this.onLevelSwitched(type, data)
        },
        {
            type: this.Hls.Events.FRAG_LOADED,
            callback: (type: string, data: any) => this.onFragmentLoaded(type, data)
        },
        {
            type: this.Hls.Events.FRAG_PARSING_DATA,
            callback: (type: string, data: any) => this.onFragmentParsingData(type, data)
        },
        {
            type: this.Hls.Events.AUDIO_TRACKS_UPDATED,
            callback: (type: string, data: audioTracksUpdatedData) => this.onAudioTrackUpdated(type, data)
        },
        {
            type: this.Hls.Events.AUDIO_TRACK_SWITCHED,
            callback: (type: string, data: audioTrackSwitchedData) => this.onAudioTrackSwitched(type, data)
        },
        {
            type: this.Hls.Events.ERROR,
            callback: (type: string, data: errorData) => this.onError(type, data)
        },
        {
            type: this.Hls.Events.SUBTITLE_TRACKS_UPDATED,
            callback: (type: string, data: any) => this.onSubtitleTracksUpdated(type, data),
        },
        {
            type: this.Hls.Events.SUBTITLE_TRACK_SWITCH,
            callback: (type: string, data: any) => this.onSubtitleTrackSwitch(type, data),
        },
    ];

    constructor(config: PlaybackAdapterConfigInterface) {
        super(config);
        this.logger.log(LogLevel.INFO, 'HlsjsAdapter created');
    }

    override createVideoSurface(): VideoSurfaceInterface {
        return new Html5VideoSurface(this.config, false);
    }

    override set textTrackMode(mode: TextTrackMode) {
        this.enabledSubtitles(mode);
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode });
    }

    override set textTrack(value: TextTrackInterface) {
        if (!value) {
            return;
        }
        this.player.subtitleTrack = parseInt(value.id);
    }

    ////////////////////
    //Public Methods
    ////////////////////

    override initialize(): void {

        super.initialize();

        let hlsConfig = {} as Config;

        hlsConfig.debug = this.enableLogger;
        hlsConfig.capLevelToPlayerSize = this.playback.abr.capQualityToScreenSize;
        //hlsConfig.capLevelOnFPSDrop = todo?
        hlsConfig.autoStartLoad = false;
        hlsConfig.liveSyncDurationCount = this.playback.liveEdgeSyncFragmentCount;

        hlsConfig.manifestLoadingMaxRetry = HlsjsRobustness.MANIFEST_RETRY_ATTEMPTS;
        hlsConfig.manifestLoadingRetryDelay = HlsjsRobustness.MANIFEST_RETRY_DELAY;

        hlsConfig.levelLoadingRetryDelay = HlsjsRobustness.LEVEL_RETRY_DELAY;
        // hlsConfig.levelLoadingTimeOut = HlsjsRobustness.LEVEL_RETRY_TIMEOUT;
        hlsConfig.fragLoadingRetryDelay = HlsjsRobustness.FRAGMENT_RETRY_DELAY;
        // hlsConfig.fragLoadingTimeOut = HlsjsRobustness.FRAGMENT_RETRY_TIMEOUT;

        hlsConfig.enableCEA708Captions = true;

        // performance settings
        const settings = this.config.performanceSettings;
        if (settings.forwardBufferLength != null) {
            hlsConfig.maxBufferLength = settings.forwardBufferLength;
        }
        if (settings.backBufferLength != null) {
            hlsConfig.liveBackBufferLength = settings.backBufferLength;
        }
        if (settings.topQualityForwardBufferLength != null) {
            hlsConfig.maxMaxBufferLength = settings.topQualityForwardBufferLength;
        }

        hlsConfig = this.mergeStreamingConfigs(hlsConfig, this.config.resource.overrides);

        const xhrSetup = hlsConfig.xhrSetup;
        hlsConfig.xhrSetup = !xhrSetup ? this.onRequest.bind(this) : (xhr: XMLHttpRequest, url: string) => {
            xhrSetup(xhr, url);
            this.onRequest(xhr, url);
        };

        this.logger.log(LogLevel.INFO, `Hlsjs version: ${this.Hls.version}`);
        this.player = new this.Hls(hlsConfig);

        // This tells hlsjs to use "showing" or "hidden" when selecting a text track.
        this.player.subtitleDisplay = this.config.textTrackSettings.native;
        this.addEvents(this.player, this.hlsjsEventMap);
    }

    protected override loadMediaUrl(): Promise<void> {
        const loading = Util
            .eventsToPromise(
                [{ target: this.player, events: [this.Hls.Events.MANIFEST_LOADED] }],
                [{ target: this, events: [PlaybackAdapterEvents.ERROR] }],
            );

        this.player.loadSource(this.mediaUrl);

        return loading
            // Wait for manifest to finish loading before attaching the media
            .then((manifest) => {
                this.initNativeTextTracks(manifest);

                const attaching = Util
                    .eventsToPromise(
                        [{ target: this.player, events: [this.Hls.Events.MEDIA_ATTACHED] }],
                        [{ target: this, events: [PlaybackAdapterEvents.ERROR] }],
                    );
                this.player.attachMedia(this.videoSurface.video);
                return attaching;
            })
            // Wait for media to finish attaching before starting playback
            .then(() => {
                const startTime = this.playback.startTime;
                this.player.startLoad(!isNaN(startTime) ? startTime : -1);

                this.setBitrateRestrictionAtStartup();
                this.emit(PlaybackAdapterEvents.MANIFEST_LOADED);
            })
            .then(() => super.loadMediaUrl());
    }

    override destroy(): Promise<void> {
        this.removeEvents(this.player, this.hlsjsEventMap);

        this.destroyNativeTextTracks();

        const destroy = Util
            .eventsToPromise(
                [{ target: this.player, events: [this.Hls.Events.MEDIA_DETACHING] }],
                [{ target: this, events: [PlaybackAdapterEvents.ERROR] }],
            );
        this.player.destroy();
        this.player = null;
        this.Hls = null;

        Util.forEach(this.config.video.textTracks, (textTrack) => {
            textTrack.removeEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);
        });

        return destroy.then(() => super.destroy());
    }

    override suspend(): void {
        this.player.stopLoad();
    }

    override resume(): void {
        this.player.startLoad();
    }
    ////////////////////
    //Accessors
    ////////////////////
    override set audioTrack(track: AudioTrackInterface) {
        this.player.audioTrack = track.index;
    }

    set currentIndex(index: number) {
        this.player.loadLevel = index;
    }
    get currentIndex(): number {
        return this.player.loadLevel;
    }

    set autoQualitySwitching(value: boolean) {
        this.player.loadLevel = value ? -1 : this.player.nextLoadLevel;
    }
    get autoQualitySwitching(): boolean {
        return this.player.loadLevel === -1;
    }

    set minBitrate(value: number) {
        this.player.config.minAutoBitrate = value;
    }
    get minBitrate(): number {
        return this.player.config.minAutoBitrate;
    }

    set maxBitrate(value: number) {
        const reset = isNaN(value);
        this.player.capLevelToPlayerSize = reset ? this.playback.abr.capQualityToScreenSize : false;
        this.player.autoLevelCapping = reset ? -1 : Util.getIndexForBitrate(this.player.levels, value, false);
    }
    get maxBitrate(): number {
        return this.player.levels[this.player.autoLevelCapping].bitrate;
    }

    get manifestQualities(): QualityInterface[] {
        return this.player.levels.map((item: Level, index: number): QualityInterface => ({
            index,
            bitrate: item.bitrate,
            width: item.width,
            height: item.height,
            codec: (item.attrs) ? item.attrs.CODECS : undefined
        }));
    }

    override get seekable(): RangeInterface {
        const duration = this.videoSurface.video.duration;
        const dvr = (this.lowLevelDvrDetails) ? this.lowLevelDvrDetails.totalduration : 0;
        return { start: duration - dvr, end: duration };
    }

    override get segmentDuration(): number {
        return (this.lowLevelDvrDetails) ? this.lowLevelDvrDetails.averagetargetduration : super['segmentDuration'];
    }

    override get framerate(): number {
        return this.pFramerate;
    }

    override get fragmentType(): string {
        return this.pFragmentType;
    }

    ////////////////////
    //Event Handlers
    ////////////////////

    private onManifestParsed(type: string, data: manifestParsedData): void {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onLevelLoaded(type: string, data: levelLoadedData): void { //set type to any, hls definition of level is missing but is present at RT.
        this.lowLevelDvrDetails = data.details;
        this.pIsLiveStream = this.lowLevelDvrDetails.live;
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: data.level });
    }

    private onLevelUpdated(type: string, data: levelUpdatedData): void {
        //@ts-ignore
        this.checkAbrConstraints(this.player.maxAutoLevel);
    }

    private onLevelSwitching(type: string, data: levelSwitchingData): void {
        //TODO check to see if we still need to switch started type event.  none using in old player.
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_SWITCHING, { index: data.level });
    }

    private onLevelSwitched(type: string, data: levelSwitchedData): void {
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: data.level });
    }

    private onFragmentLoaded(type: string, data: fragLoadedData): void { //stats missing from ts def for Hls.fragloadData
        this.networkErrorRetryCount = 0;
        this.mediaErrorRetryCount = 0;
        if (!this.pFragmentType) {
            this.pFragmentType = Util.getMimeType(data.frag.url);
        }

        //@ts-ignore
        this.checkAbrConstraints(this.player.maxAutoLevel);

        const stats = (data.stats ? data.stats : data.frag.stats);
        const bw = (stats.loaded * 8000) / Number(window.performance.now() - stats.trequest);
        this.emit(PlaybackAdapterEvents.FRAGMENT_LOADED, { bandwidth: bw });
    }

    private onFragmentParsingData(type: any, data: any): void { //missing params from ts def for Hls.fragParsingData
        this.pFramerate = data.nb / (data.endPTS - data.startPTS);
        this.emit(PlaybackAdapterEvents.FRAGMENT_PARSED, { rate: this.pFramerate });
    }

    private onAudioTrackUpdated(type: string, data: audioTracksUpdatedData) {
        this.normalizedAudioTracks = this.normalizeAudioTracks(this.player.audioTracks, {
            codec: 'audioCodec',
            label: 'name'
        });

        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, {
            tracks: this.normalizedAudioTracks,
            track: this.normalizedAudioTracks[this.player.audioTrack],
        });
    }

    private onAudioTrackSwitched(type: string, data: audioTrackSwitchedData) {
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[parseInt(data.id)] });
    }

    private onSubtitleTrackSwitch(type: string, data: any) {
        const textTrack = this.textTrackInfo.tracks.find((track: any) => track.id == data.id);
        if (data.id === -1) {
            return;
        }

        if (!textTrack || this.textTrackInfo.track === textTrack) {
            return;
        }

        this.selectSubtitles(textTrack);
    }

    private onSubtitleTracksUpdated(type: string, data: any) {
        this.setTextTracks(data.subtitleTracks.map((track: any, index: number) => ({
            id: track.id,
            language: track.lang,
            kind: track.type.toLowerCase(),
            label: track.name,
            default: track.default,
        })));
    };

    private onCueChange = (event: Event): void => {
        const track = event.target as TextTrack;
        const activeCues = Util.dedupeCues(track);

        if (!this.config.textTrackSettings.native) {
            this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues });
        }
    };

    private onRequest(xhr: XMLHttpRequest, url: string): void {
        if (!xhr.withCredentials) {
            xhr.withCredentials = (url.indexOf('akamaihd') > -1 && url.indexOf('csmil') > -1);
        }

        const aes = this.config.resource.location.drm.aes;
        if (!Util.isEmpty(aes) && url.includes(aes.provider)) {
            const h = aes.header;
            for (let key in h) {
                const v = h[key];
                xhr.setRequestHeader(key, v);
            }
        }

        if (this.multiCdnHeaderPresent) {
            xhr.addEventListener('readystatechange', (e: any) => {
                if (url.indexOf('.ts') !== -1 && xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED) {

                    if (this.multiCdnHeaderPresent) {
                        var headers = xhr.getAllResponseHeaders();
                        this.multiCdnHeaderPresent = headers.indexOf(Playback.MULTI_CDN) !== -1;

                        if (this.multiCdnHeaderPresent) {
                            this.emit(PlaybackAdapterEvents.MULTI_CDN, { cdn: xhr.getResponseHeader(Playback.MULTI_CDN.toUpperCase()) });
                        }
                    }
                }
            });
        }
    }

    private onError(type: string, data: errorData): void {

        let code = ErrorCode.UNSPECIFIED_HLSJS_ERROR;

        switch (data.type) {
            case this.Hls.ErrorTypes.NETWORK_ERROR:
                data.fatal && this.handleNetworkErrors(data);
                break;

            case this.Hls.ErrorTypes.MEDIA_ERROR:
                data.fatal && this.handleMediaErrors(data);
                break;

            case this.Hls.ErrorTypes.MUX_ERROR:
                code = ErrorCode.HLSJS_MUX_ERROR;
            //no break is intended.             
            default:
                //TODO add message in AppResource for general error prefix to details
                const msg = this.getErrorMessage(`${data.details}`, data.fatal);
                this.log(LogLevel.ERROR, msg, data);
                this.throwError(code, msg, data, data.fatal);
        }
    }

    ////////////////////
    // Private Methods
    ////////////////////

    private handleNetworkErrors(data: errorData): void {
        const max = HlsjsRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS,
            ErrorDetails = this.Hls.ErrorDetails;

        switch (data.details) {

            case ErrorDetails.LEVEL_LOAD_ERROR:
            case ErrorDetails.FRAG_LOAD_ERROR:
                if (this.networkErrorRetryCount < max) {

                    this.player.startLoad();
                    this.networkErrorRetryCount++;

                    //retry logging                    
                    this.log(LogLevel.ERROR, this.getErrorMessage(
                        AppResources.messages.RETRY_PLAYBACK_NETWORK_ERROR, false,
                        `${this.networkErrorRetryCount} / ${max}`
                    ));
                    break;
                }
                this.throwError(ErrorCode.HLSJS_NETWORK_ERROR,
                    AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR, data);
                break;

            case ErrorDetails.MANIFEST_PARSING_ERROR:
                this.throwError(ErrorCode.HLSJS_PARSE_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${data.details}`, data);
                break;

            default:
                this.throwError(ErrorCode.HLSJS_NETWORK_ERROR, `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${data.details}`, data, data.fatal);
        }
    }

    private handleMediaErrors(data: errorData): void {

        const //max = HlsjsRobustness.FATAL_ERROR_RECOVERY_ATTEMPTS,
            ErrorDetails = this.Hls.ErrorDetails;

        switch (data.details) {
            case ErrorDetails.MANIFEST_INCOMPATIBLE_CODECS_ERROR:
                this.throwError(ErrorCode.HLSJS_SRC_NOT_SUPPORTED,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${data.details}`, data);
                break;

            default:

                // if (this.mediaErrorRetryCount < max) {
                //     if (this.mediaErrorRetryCount === 1) {
                //         this.player.swapAudioCodec();
                //     }
                //     this.player.recoverMediaError();
                //     this.mediaErrorRetryCount++;

                //     //retry logging                    
                //     this.log(LogLevel.ERROR, this.getErrorMessage(
                //         AppResources.messages.RETRY_PLAYBACK_MEDIA_ERROR, false,
                //         `${this.mediaErrorRetryCount} / ${max}`
                //     ));

                //     break;
                // }

                this.throwError(ErrorCode.HLSJS_MEDIA_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${data.details}`, data);
        }
    }

    private setBitrateRestrictionAtStartup(): void {
        const abr: ResourcePlaybackAbrInterface = this.playback.abr;

        if (!isNaN(abr.minBitrate)) {
            this.minBitrate = abr.minBitrate - 1; // HLS.js does not look at >= so we need to set the min just one below the actual bitrate. To ease developer confusion, handle inline.
        }

        if (!isNaN(abr.maxBitrate)) {
            this.maxBitrate = abr.maxBitrate;
        }

        if (!isNaN(abr.startBitrate)) {
            const levels: Level[] = this.player.levels;
            const index = Util.getIndexForBitrate(levels, abr.startBitrate, false);
            if (Util.inRange(index, 0, levels.length - 1)) {
                this.player.config.startLevel = index;
            }
            else {
                //log warning here no error event needed.  
            }
        }
    }

    private setTextTracks(tracks: TextTrackInterface[]) {
        this.textTrackInfo.tracks = tracks;

        Util.forEach(tracks, track => this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track));

        Util.forEach(this.config.video.textTracks, (textTrack) => {
            if (!Util.isTextTrack(textTrack.kind)) {
                return;
            }

            textTrack.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);
        });

        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);

        const defaultTrack = Util.find(tracks, track => track.default);
        if (!defaultTrack) {
            this.selectSubtitles(Util.findDefaultTrack(tracks, this.config.textTrackSettings.language));
        }
    }

    private selectSubtitles(track: TextTrackInterface) {
        this.textTrackInfo.track = track;
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, track);
        this.enabledSubtitles(this.config.textTrackSettings.mode);
    }

    private enabledSubtitles(mode: TextTrackMode) {
        if (mode !== TextTrackMode.DISABLED) {
            this.textTrack = this.textTrackInfo.track;
        }
        else {
            this.player.subtitleTrack = -1;
        }
    }

    private initNativeTextTracks(manifest: any) {
        const hasManifestTracks = manifest.subtitles?.length;
        const { textTrackUrl } = this.config.resource.location;

        if (hasManifestTracks) {
            if (textTrackUrl) {
                this.logger.warn(`Detected subtitles in the manifest. Ignoring resource's textTrackUrl`);
            }
            return;
        }

        const textTracks = this.config.video.textTracks;
        const tracks: TextTrackInterface[] = [];
        const map = new Map<any, any>();
        const getEnabled = () => Util.find(textTracks, track => Util.isTextTrack(track.kind) && track.mode !== TextTrackMode.DISABLED);
        let enabled = getEnabled();

        const onChange = (event: any) => {
            const change = getEnabled();
            if (change === enabled || !change) {
                return;
            }

            enabled = change;
            this.textTrackInfo.track = map.get(enabled);
        };

        const textTracksAvailable = Util.debounce(() => {
            this.setTextTracks(tracks);
            textTracks.addEventListener(TextTrackEvent.CHANGE, onChange);
        }, 100);

        const onAddTrack = (event: any) => {
            const { track } = event;

            if (!Util.isTextTrack(track.kind)) {
                return;
            }

            const normalized = {
                id: tracks.length.toString(),
                language: track.language,
                kind: track.kind,
                label: track.label,
                default: false,
            };

            map.set(normalized, track);
            map.set(track, normalized);

            tracks.push(normalized);

            textTracksAvailable();
        };

        textTracks.addEventListener(TextTrackEvent.ADD_TRACK, onAddTrack);

        // Override the enable function to operate on the text tracks directly
        this.enabledSubtitles = (mode: TextTrackMode) => {
            const track = map.get(this.textTrackInfo.track);
            if (!track) {
                return;
            }
            track.mode = mode;
        };

        // Override the destroy function to clean up the CEA708 specific listeners
        this.destroyNativeTextTracks = () => {
            this.config.video.textTracks.removeEventListener(TextTrackEvent.ADD_TRACK, onAddTrack);
            this.config.video.textTracks.removeEventListener(TextTrackEvent.CHANGE, onChange);
        };

        // Load side car text track
        if (textTrackUrl) {
            this.createSidecarTextTrack(textTrackUrl);
        }
    }

    private destroyNativeTextTracks() {
        // no-op
    }

    private async createSidecarTextTrack(url: string) {
        const isVtt = url.indexOf('.vtt') >= 0;
        const video = this.config.video;
        const label = 'English';
        const language = 'en';

        if (isVtt) {
            const track = document.createElement('track');
            track.kind = TextTrackType.CAPTIONS;
            track.label = label;
            track.srclang = language;
            track.id = 'sidecar-vtt';
            track.src = url;
            video.appendChild(track);
            track.track.mode = this.config.textTrackSettings.mode;
        }
        else {
            try {
                const converter = new SmpteToVttCueConverter(url, this.config.system);
                const cues = await converter.convert();
                const track = video.addTextTrack(TextTrackType.CAPTIONS, label, language);
                this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation successful');

                Util.forEach(cues, (item) => {
                    try {
                        track.addCue(item);
                    }
                    catch (error) {
                        this.logger.log(LogLevel.INFO, error);
                    }
                });
            }
            catch (error) {
                this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation error', error.message);
            }
        }
    }
}
