export enum ProxyName {
    AdDataProxy = 'AdDataProxy',
    ContentPlaybackStateProxy = 'ContentPlaybackStateProxy',
    PlayerDomProxy = 'PlayerDomProxy',
    Playlist = "PlaylistProxy",
    ModelCollectionProxy = 'ModelCollectionProxy',
    ResourceProxy = 'ResourceProxy',
    TrackingConfigProxy = 'TrackingConfigProxy',
    PerformanceProxy = 'PerformanceProxy',
    TextTrackProxy = 'TextTrackProxy',
    LocalizationProxy = 'LocalizationProxy',
    ActivityProxy = 'ActivityProxy',
    AdapterProxy = 'AdapterProxy',
    HookProxy = 'HookProxy',
}
