import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { NotificationInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { ResourceValidator } from './command-helpers/ResourceValidator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class TransformResourceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        const r: ResourceConfigurationInterface = notification.body.resource;
        const data = notification.body.data || null;
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
        const dtx: any = playerOpts.dataTransformers?.[r.location.cms]?.objectRef;

        Promise.resolve()
            .then(() => {
                return (dtx) ? this.transform(r, data, dtx) : r;
            })
            .then((resource) => {
                this.sendNotification(NotificationName.REGISTER_RESOURCE, { resource }, NotificationType.INTERNAL);
            })
            .catch((error) => {
                const code = this.getCodeForError(error);
                const message = error.message || AppResources.messages.CMS_RESOURCE_UNAVAILABLE;
                const fatal = true;
                this.sendNotification(NotificationName.RESOURCE_ERROR, { code, message, fatal }, NotificationType.INTERNAL);
            });
    }

    transform(resource: ResourceConfigurationInterface, data: any, dtx: any): Promise<ResourceConfigurationInterface> {
        const tx = Util.isFunction(dtx.transform) ? dtx : new dtx();
        const r = tx.transform?.(data, resource);
        return Promise.resolve(r)
            .then((r: any) => {
                // check for CMS errors
                if (r?.error) {
                    throw r.data;
                }

                // ensure resource is valid
                const resourceValidation = ResourceValidator.validate(r);
                if (resourceValidation.error) {
                    const error = new Error(resourceValidation.msg) as any;
                    error.code = ErrorCode.INVALID_RESOURCE_FORMAT;
                    throw error;
                }

                return r as ResourceConfigurationInterface;
            });
    }

    getCodeForError(obj: StrAnyDict): ErrorCode {
        if (obj.cms === 'mpx') {
            return this.getCodeForMpxError(obj);
        }
        else if (obj.code) {
            return obj.code;
        }
        else {
            return ErrorCode.RESOURCE_UNAVAILABLE;
        }
    }

    getCodeForMpxError(obj: StrAnyDict): ErrorCode {
        const exc = obj ? obj.exception : null;

        switch (exc) {
            case 'DomainBlocked':
                return ErrorCode.DOMAIN_BLOCKED;

            case 'GeoLocationBlocked':
                return ErrorCode.GEO_LOCATION_BLOCKED;

            case 'IpBlocked':
                return ErrorCode.IP_BLOCKED;

            case 'SatelliteProviderBlocked':
                return ErrorCode.SATELLITE_PROVIDER_BLOCKED;

            case 'Expired':
                return ErrorCode.EXPIRED;

            default:
                return ErrorCode.RESOURCE_UNAVAILABLE;
        }
    }
}
