
export enum MediaCapabilitiesMimeType {
    MP4_VIDEO = 'video/mp4',
    MP4_AUDIO = 'audio/mp4',
    HLS = 'application/vnd.apple.mpegurl',
    HLS_ALT = 'application/x-mpegURL',
    DASH = 'application/dash+xml',
    WEBM_VIDEO = 'video/webm',
    WEBM_AUDIO = 'audio/webm',
    TS_VIDEO = 'video/mp2t',
}
