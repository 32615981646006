import { buildInfo } from '../../core/BuildInfo';
import { Util } from '../../core/Util';
import { AdContext } from '../../enum/AdContext';
import { ImaVpaidMode } from '../../enum/ImaVpaidMode';
import { ModelName } from '../../enum/ModelName';
import { ServiceName } from '../../enum/ServiceName';
import { SystemServiceInterface } from '../../iface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { GamAdService } from './GamAdService';
import { ImaAdErrorInfoInterface, ImaAdServiceInterface, ImaAdsLoaderInterface, ImaAdsLoaderOptions, ImaAdsRenderingSettingsInterface, ImaSdkSettingsInterface } from './iface';
import { ima } from './ima';
import { ImaAdsLoader } from './ImaAdsLoader';

/**
 * @deprecated
 */
export class ImaAdService extends GamAdService implements ImaAdServiceInterface {

    private pAdsLoader: ImaAdsLoaderInterface;
    private pAdsLoaderOptions: ImaAdsLoaderOptions;

    constructor(name: string) {
        super(name, AdContext.IMA);
    }

    override destroy() {
        this.pAdsLoader && this.pAdsLoader.destroy();
        this.pAdsLoader = null;
        this.pAdsLoaderOptions = null;

        super.destroy();
    }

    get sdk(): ima.Sdk | null {
        const sysSvc = <SystemServiceInterface>this.getService(ServiceName.System),
            global = sysSvc.global;

        return (global && global.google && global.google.ima) || null;
    }

    get sdkSettings(): ImaSdkSettingsInterface {
        return {
            vpaidMode: ImaVpaidMode.INSECURE,
            disableCustomPlaybackForIOS10Plus: false,
            playerType: buildInfo.playerName,
            playerVersion: buildInfo.playerVersion
        };
    }

    get defaultRenderingSettings(): ImaAdsRenderingSettingsInterface {
        return {
            bitrate: 900,
            enablePreloading: false,
            loadVideoTimeout: -1,     // ms, default 8000 ms
            playAdsAfterTime: undefined,
            restoreCustomPlaybackStateOnAdBreakComplete: false
        };
    }

    set adsLoaderOptions(options: ImaAdsLoaderOptions) {
        this.pAdsLoaderOptions = options;
    }

    get adsLoader(): ImaAdsLoaderInterface {
        return this.pAdsLoader;
    }

    getSdkVersion() {
        return this.adsLoader?.getSdkVersion();
    }

    createAdsLoader(): void {
        if (!this.pAdsLoader) {
            this.pAdsLoaderOptions.enableOm = (<PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions))?.enableOm === true;
            this.pAdsLoader = new ImaAdsLoader(this.pAdsLoaderOptions);
        }
    }

    initializeAdsLoader(): boolean {
        this.createViewabilityTracker();
        return this.pAdsLoader.initialize();
    }

    // Error
    parseAdError(e: ima.AdErrorEvent): ImaAdErrorInfoInterface {
        const err = e.getError(),
            reqCtx = e.getUserRequestContext(),
            inner = err.getInnerError();

        return {
            type: this.getAdErrorType(err),
            errorCode: err.getErrorCode(),
            vastErrorCode: err.getVastErrorCode(),
            message: err.getMessage(),
            innerErrorMessage: Util.isString(inner) ? inner : null,
            sdkVersion: this.sdk.VERSION,
            requestContext: reqCtx || null
        };
    }

    //////////
    // PRIVATE
    private getAdErrorType(err: ima.AdError): string {
        const t = err.getType();
        return t == this.sdk.AdError.Type.AD_LOAD ? 'adLoad' : 'adPlay';
    }
}
