import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { AccessibilityAction } from '../enum/AccessibilityAction';
import { MediatorName } from '../enum/MediatorName';
import { NonPrintingKey } from '../enum/NonPrintingKey';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { StreamType } from '../enum/StreamType';
import { KeyCommandDef } from '../iface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { Notification } from '../mvc/Notification';
import { KeyCode } from '../util/KeyCode';
import { AbstractKeyCommandMediator } from './AbstractKeyCommandMediator';
import { AppMediator } from './AppMediator';



export class AccessibilityKeyCommandMediator extends AbstractKeyCommandMediator {

    /**
     * @deprecated
     */
    private uim: UiMediatorInterface = null;
    private vpi: VideoPlayerInterface = null;
    private seekMoving: boolean = false;
    private seekEnabled: boolean = false;

    private VOLUME_INCREMENT: number = 0.05;
    private SEEK_FACTOR_DISCRETE: number = 0.05;
    private SEEK_INCREMENT_CONTINUOUS: number = 0.001;

    private tZero: number = 0; // used for seek acceleration
    private timeout: number;

    constructor(name: string) {
        super(name);
    }

    override onRemove(): void {
        clearTimeout(this.timeout);
        this.vpi = null;
        this.uim = null;
        super.onRemove();
    }

    protected isControl(element: Element): boolean {
        // TODO: We need a more comprehensive way to do this, or a public
        //       API that the UI can call to disable/enable hotkeys
        return element.nodeName == 'BUTTON' || element.nodeName == 'INPUT';
    }

    protected hKeyUp(e: KeyboardEvent): void {
        if (this.disabled || this.isControl(document.activeElement)) {
            return;
        }

        if (!this.seekMoving) {
            this.processKeyEvent(e);
        }
        else {
            this.disengageSeek();
        }
    }

    protected hKeyDown(e: KeyboardEvent): void {
        const k = e.key,
            kc = e.keyCode,
            up = k === NonPrintingKey.ARROW_UP || kc === KeyCode.UP_ARROW,
            down = k === NonPrintingKey.ARROW_DOWN || kc === KeyCode.DOWN_ARROW,
            space = k === NonPrintingKey.SPACE || kc === KeyCode.SPACE,
            back = k === NonPrintingKey.ARROW_LEFT || kc === KeyCode.LEFT_ARROW,
            fwd = k === NonPrintingKey.ARROW_RIGHT || kc === KeyCode.RIGHT_ARROW,
            rep = e.repeat;

        // block default scrolling behavior when player has focus
        if ((fwd || back || up || down || space) && !this.isControl(document.activeElement)) {
            e.preventDefault();
            return;
        }

        if (!this.shouldSeekContinuous()) {
            return;
        }

        if (!back && !fwd) { return; }

        if (!this.seekMoving && rep) {
            this.tZero = Date.now();
            this.engageSeek();
        }

        else if (this.seekMoving && rep) {
            const d = (Date.now() - this.tZero) * .001,
                n = d >= 5 ? 3 : (d >= 3 ? 2 : 1), // after 3 seconds, move by 2x, after 5 sec, use 3x
                mul = back ? - 1 : 1;

            this.moveSeekBy(mul * this.SEEK_INCREMENT_CONTINUOUS * n);
        }

        this.stopEvent(e);
    }

    private engageSeek() {
        this.uim && this.uim.engageSeek();
        this.seekMoving = true;
    }

    private disengageSeek() {
        this.uim && this.uim.disengageSeek();
        this.seekMoving = false;
        this.tZero = null;

        this.timeout = setTimeout(() => {
            this.uim && this.uim.forceControlsVisible(false);
        }, 500);
    }

    private moveSeekBy(inc: number): void {
        if (this.uim) {
            this.uim.moveSeekBy(inc);
        }
    }

    private shouldSeekContinuous(): boolean {
        const viewOk = this.uim && this.uim.uiIsStandardView();

        return viewOk && !this.disabled && this.seekEnabled;
    }

    private get player(): VideoPlayerInterface {
        if (!this.vpi) {
            const appMed = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION);
            this.vpi = appMed.getAppApi();
        }

        return this.vpi;
    }

    private stopEvent(e: Event): void {
        e.stopImmediatePropagation();
        e.preventDefault();
    }

    private processKeyEvent(e: KeyboardEvent): void {
        if (
            e.ctrlKey
            || e.shiftKey
            || e.metaKey
            || e.key === NonPrintingKey.META
            || e.key === NonPrintingKey.SHIFT
            || e.key === NonPrintingKey.CONTROL

        ) {
            return null;
        }

        const cmdDef = this.getCommand(e, false);
        if (!cmdDef) {
            return;
        }

        this.doAction(cmdDef);
        this.stopEvent(e);
    }

    private doAction(cmdDef: KeyCommandDef): void {
        const a = cmdDef.action;
        let cv, v, mul;

        switch (a) {

            case AccessibilityAction.INCREASE_VOLUME:
            case AccessibilityAction.DECREASE_VOLUME:
                mul = a === AccessibilityAction.DECREASE_VOLUME ? - 1 : 1;
                cv = this.player.volume;
                v = Util.clampValue(cv + (this.VOLUME_INCREMENT * mul), 0, 1);

                if (this.player.muted && v > 0) {
                    this.player.muted = false;
                }

                this.player.volume = v;
                this.uim && this.uim.setAccVolumeLevelIndicator(v);
                this.notify({
                    action: a,
                    value: this.player.volume
                });
                break;

            case AccessibilityAction.SEEK_BACK:
            case AccessibilityAction.SEEK_FORWARD:
                if (!this.seekEnabled) { return; }

                const d = this.player.contentDuration;
                mul = a === AccessibilityAction.SEEK_BACK ? - 1 : 1;
                cv = this.player.contentTime;

                if (!d || isNaN(d) || (mul === 1 && this.player.streamType === StreamType.DVR && this.player.isPlayingLive)) {
                    return;
                }

                v = Util.clampValue(cv + (this.SEEK_FACTOR_DISCRETE * mul * d), 0, d);

                this.setSeekNotice(v, mul);
                this.notify({
                    action: a,
                    position: v,
                    duration: d
                });
                this.player.seek(v);

                break;

            case AccessibilityAction.ENTER_FULLSCREEN:
                this.player.enterFullscreen();
                break;

            case AccessibilityAction.TOGGLE_PLAY:
                if (this.player.streamType !== StreamType.LIVE) {
                    this.player.togglePlayPause();
                }
                break;

            case AccessibilityAction.TOGGLE_MUTE:
                this.player.muted = !this.player.muted;
                this.notify({
                    action: a,
                    muted: this.player.muted
                });
                break;

            case AccessibilityAction.TOGGLE_TT:
                this.player.textTrackEnabled = !this.player.textTrackEnabled;
                break;

            case AccessibilityAction.PLAYLIST_NEXT:
            case AccessibilityAction.PLAYLIST_PREV:
                mul = a == AccessibilityAction.PLAYLIST_PREV ? -1 : 1;
                if (this.player.playlist.length > 1) {
                    mul < 0 ? this.player.playlist.prev() : this.player.playlist.next();
                }
                break;

            case AccessibilityAction.STOP:
                if (this.player.streamType === StreamType.LIVE) {
                    this.player.stop();
                }
                else {
                    this.player.pause();
                }
                break;
        }
    }

    private setSeekNotice(v: number, dir: number) {
        if (!this.uim) { return; }

        const t = Util.secToHms(v);
        const d = Util.secToHms(this.player.contentDuration);
        const msg = dir > 0 ? `&gt;&nbsp;&nbsp;${t}&nbsp;/&nbsp;${d}` : `${t}&nbsp;/&nbsp;${d}&nbsp;&nbsp;&lt;`;

        this.uim.setSeekNotice(msg, dir);
    }

    private notify(data: any) {
        this.sendNotification(NotificationName.HOTKEY_ACTION, data, NotificationType.INTERNAL);
    }

    override handleNotification(notification: Notification) {
        const n = notification.name,
            nn = NotificationName;

        switch (n) {
            case nn.AD_BREAK_START:
                this.seekEnabled = false;
                this.enable();


                break;
            case nn.CONTENT_COMPLETE:
                this.seekEnabled = false;
                this.disable();
                break;

            case nn.CONTENT_START:
            case nn.CONTENT_SEGMENT_START:
                this.enable();
                this.seekEnabled = this.player.streamType !== StreamType.LIVE;
                break;
        }
    }

    override listNotificationInterests(): NotificationName[] {
        const n = NotificationName;
        return [
            n.AD_BREAK_START,
            n.CONTENT_START,
            n.CONTENT_SEGMENT_START,
            n.CONTENT_COMPLETE,
        ];
    }

    override onRegister(): void {
        super.onRegister();

        const acd = AppResources.accessibilityKeyCmdDefs;
        acd.forEach(c => this.registerCommandDef(c));

        this.uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI) || null;
    }
}
