import { NotificationName } from '../enum/NotificationName';
import { PlayerEvent } from '../enum/PlayerEvent';

export const event_map: Record<string, PlayerEvent> = {

    [NotificationName.READY]: PlayerEvent.READY,

    [NotificationName.POSTER_CLICK]: PlayerEvent.POSTER_CLICK,

    [NotificationName.VIDEO_START_ERROR]: PlayerEvent.VIDEO_START_ERROR,
    [NotificationName.VIDEO_PLAYBACK_ERROR]: PlayerEvent.VIDEO_PLAYBACK_ERROR,
    [NotificationName.RESOURCE_ERROR]: PlayerEvent.RESOURCE_ERROR,

    [NotificationName.PLAYLIST_ADVANCED]: PlayerEvent.PLAYLIST_ADVANCED,
    [NotificationName.PLAYLIST_COMPLETE]: PlayerEvent.PLAYLIST_COMPLETE,
    [NotificationName.PLAYLIST_CHANGE]: PlayerEvent.PLAYLIST_CHANGE,
    [NotificationName.PLAYLIST_CLEARED]: PlayerEvent.PLAYLIST_CLEARED,
    [NotificationName.PLAYLIST_CURRENT_RESOURCE_CLEARED]: PlayerEvent.PLAYLIST_CURRENT_RESOURCE_CLEARED,

    [NotificationName.RESOURCE_START]: PlayerEvent.RESOURCE_START,
    [NotificationName.RESOURCE_END]: PlayerEvent.RESOURCE_END,
    [NotificationName.RESOURCE_INTERRUPTED]: PlayerEvent.RESOURCE_INTERRUPTED,
    [NotificationName.PLAYLIST_OUT_OF_RANGE]: PlayerEvent.PLAYLIST_OUT_OF_RANGE,

    [NotificationName.PLAYBACK_SUSPENDED]: PlayerEvent.PLAYBACK_SUSPENDED,
    [NotificationName.PLAYBACK_RESUMED]: PlayerEvent.PLAYBACK_RESUMED,
    [NotificationName.PLAYBACK_STATE_CHANGE]: PlayerEvent.PLAYBACK_STATE_CHANGE,

    //content
    [NotificationName.AUDIO_TRACK_INFO_CHANGE]: PlayerEvent.AUDIO_TRACK_INFO_CHANGE,
    [NotificationName.AUDIO_TRACK_CHANGE]: PlayerEvent.AUDIO_TRACK_CHANGE,
    [NotificationName.QUALITY_CHANGE]: PlayerEvent.QUALITY_CHANGE,
    [NotificationName.QUALITY_INFO_CHANGE]: PlayerEvent.QUALITY_INFO_CHANGE,
    [NotificationName.CDN_CHANGE]: PlayerEvent.CDN_CHANGE,
    [NotificationName.CONTENT_START]: PlayerEvent.CONTENT_START,
    [NotificationName.CONTENT_DURATION_AVAILABLE]: PlayerEvent.CONTENT_DURATION_AVAILABLE,
    [NotificationName.CONTENT_SEGMENT_START]: PlayerEvent.CONTENT_SEGMENT_START,
    [NotificationName.CONTENT_TIME_UPDATE]: PlayerEvent.CONTENT_PROGRESS,
    [NotificationName.CONTENT_BUFFERING]: PlayerEvent.RESOURCE_BUFFERING,
    [NotificationName.CONTENT_PLAYING]: PlayerEvent.CONTENT_PLAYING,
    [NotificationName.CONTENT_PAUSED]: PlayerEvent.CONTENT_PAUSED,
    [NotificationName.CONTENT_SEEKING]: PlayerEvent.SEEK_START,
    [NotificationName.CONTENT_SEEKED]: PlayerEvent.SEEK_COMPLETE,
    [NotificationName.CONTENT_IS_LIVE_CHANGED]: PlayerEvent.CONTENT_IS_PLAYING_LIVE,
    [NotificationName.STREAM_TYPE_CHANGE]: PlayerEvent.STREAM_TYPE_CHANGE,
    [NotificationName.STREAM_METADATA]: PlayerEvent.STREAM_METADATA,
    [NotificationName.STREAM_ID_AVAILABLE]: PlayerEvent.STREAM_ID_AVAILABLE,
    [NotificationName.SEEK_REDIRECT_START]: PlayerEvent.SEEK_REDIRECT_START,
    [NotificationName.SEEK_REDIRECT_COMPLETE]: PlayerEvent.SEEK_REDIRECT_COMPLETE,
    [NotificationName.CONTENT_SEGMENT_END]: PlayerEvent.CONTENT_SEGMENT_END,
    [NotificationName.CONTENT_COMPLETE]: PlayerEvent.CONTENT_COMPLETE,
    [NotificationName.DRM_KEYSYSTEM_CREATED]: PlayerEvent.DRM_KEYSYSTEM_CREATED,
    [NotificationName.METADATA_CUEPOINT]: PlayerEvent.METADATA_CUEPOINT,
    [NotificationName.TEXT_CUEPOINT]: PlayerEvent.TEXT_CUEPOINT,
    [NotificationName.TEXT_TRACK_DISPLAY_MODE_CHANGE]: PlayerEvent.TEXT_TRACK_DISPLAY_MODE_CHANGE,
    [NotificationName.TEXT_TRACK_CHANGE]: PlayerEvent.TEXT_TRACK_CHANGE,
    [NotificationName.TEXT_TRACK_INFO_CHANGE]: PlayerEvent.TEXT_TRACK_INFO_CHANGE,
    [NotificationName.TEXT_TRACK_AVAILABLE]: PlayerEvent.TEXT_TRACK_AVAILABLE,
    [NotificationName.VIDEO_LOAD_COMPLETE]: PlayerEvent.VIDEO_LOAD_COMPLETE,
    [NotificationName.THUMBNAIL_TRACK_AVAILABLE]: PlayerEvent.THUMBNAIL_TRACK_AVAILABLE,

    [NotificationName.AD_SEGMENT_START]: PlayerEvent.AD_SEGMENT_START,
    [NotificationName.AD_SEGMENT_END]: PlayerEvent.AD_SEGMENT_END,
    [NotificationName.AD_CUEPOINTS_AVAILABLE]: PlayerEvent.AD_CUEPOINTS_AVAILABLE,
    [NotificationName.AD_BREAK_START]: PlayerEvent.AD_BREAK_START,
    [NotificationName.AD_BREAK_METADATA]: PlayerEvent.AD_BREAK_METADATA,
    [NotificationName.AD_START]: PlayerEvent.AD_START,
    [NotificationName.AD_PAUSED]: PlayerEvent.AD_PAUSED,
    [NotificationName.AD_PLAYING]: PlayerEvent.AD_PLAYING,
    [NotificationName.AD_TIME_UPDATE]: PlayerEvent.AD_PROGRESS,
    [NotificationName.AD_FIRST_QUARTILE]: PlayerEvent.AD_FIRST_QUARTILE,
    [NotificationName.AD_MIDPOINT]: PlayerEvent.AD_MIDPOINT,
    [NotificationName.AD_THIRD_QUARTILE]: PlayerEvent.AD_THIRD_QUARTILE,
    [NotificationName.AD_COMPLETE]: PlayerEvent.AD_COMPLETE,
    [NotificationName.AD_BREAK_COMPLETE]: PlayerEvent.AD_BREAK_COMPLETE,
    [NotificationName.AD_ERROR]: PlayerEvent.AD_ERROR,
    [NotificationName.AD_STALLED]: PlayerEvent.AD_STALLED,
    [NotificationName.AD_BREAK_DISCARDED]: PlayerEvent.AD_BREAK_DISCARDED,
    [NotificationName.AD_BUFFERING]: PlayerEvent.RESOURCE_BUFFERING,
    [NotificationName.AD_CLICK]: PlayerEvent.AD_CLICK,
    [NotificationName.AD_SKIPPED]: PlayerEvent.AD_SKIPPED,

    [NotificationName.USER_PAUSE]: PlayerEvent.USER_PAUSE,
    [NotificationName.BEFORE_CONTROLS_VISIBLE]: PlayerEvent.BEFORE_CONTROLS_VISIBLE,
    [NotificationName.BEFORE_CONTROLS_HIDDEN]: PlayerEvent.BEFORE_CONTROLS_HIDDEN,
    [NotificationName.CONTROLS_VISIBLE]: PlayerEvent.CONTROLS_VISIBLE,
    [NotificationName.CONTROLS_HIDDEN]: PlayerEvent.CONTROLS_HIDDEN,
    [NotificationName.USER_SETTINGS_REQUEST]: PlayerEvent.USER_SETTINGS,

    [NotificationName.LIVE_PRESENTATION_STOPPED]: PlayerEvent.LIVE_PRESENTATION_STOPPED,
    [NotificationName.PRESENTATION_SIZE_CHANGE]: PlayerEvent.PRESENTATION_SIZE_CHANGE,
    [NotificationName.FULLSCREEN_ENTER]: PlayerEvent.FULLSCREEN_ENTER,
    [NotificationName.FULLSCREEN_EXIT]: PlayerEvent.FULLSCREEN_EXIT,
    [NotificationName.MOUSE_ENTER_PRESENTATION]: PlayerEvent.MOUSE_ENTER_PRESENTATION,
    [NotificationName.MOUSE_LEAVE_PRESENTATION]: PlayerEvent.MOUSE_LEAVE_PRESENTATION,

    [NotificationName.LANGUAGE_CHANGE]: PlayerEvent.LANGUAGE_CHANGE,

    [NotificationName.USER_KEYBOARD_COMMAND]: PlayerEvent.USER_KEYBOARD_COMMAND,
    [NotificationName.HOTKEY_ACTION]: PlayerEvent.HOTKEY_ACTION,

    [NotificationName.AUTOPLAY_BLOCKED]: PlayerEvent.AUTOPLAY_BLOCKED,

    [NotificationName.VOLUME_CHANGE]: PlayerEvent.VOLUME_CHANGE,
    [NotificationName.MUTE]: PlayerEvent.MUTE_CHANGE,
    [NotificationName.UNMUTE]: PlayerEvent.MUTE_CHANGE,
};
