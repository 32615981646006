/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {MvcMessages} from "./MvcMessages";
import {Observer} from "./Observer";
import {
    MediatorInterface,
    MediatorMap,
    NotificationInterface,
    ObserverInterface,
    ObserverMap,
    ViewInterface,
    ViewMap
} from "../iface";
import { Logger } from "../core/Logger";


export class View implements ViewInterface {

    protected static viewMap: ViewMap = {};

    private mediatorMap: MediatorMap;
    private observerMap: ObserverMap;
    private readonly multiCoreKey: string;


    constructor(key: string) {
        if(View.viewMap[key] != null) {
            Logger.error(MvcMessages.ERROR_KEY_EXISTS.replace('$ACTOR_NAME$', 'View'));
            return;
        }

        this.multiCoreKey = key;
        this.mediatorMap = {};
        this.observerMap = {};

        View.viewMap[this.multiCoreKey] = this;

        this.initializeView();
    }

    static getInstance(key: string): ViewInterface {
        if (!key) return null;

        if (!this.viewMap[key]) {
            this.viewMap[key] = new View(key);
        }

        return this.viewMap[key];
    }

    static removeView(key: string): void {
        this.viewMap[key] && this.viewMap[key].destroy();
        delete this.viewMap[key];
    }

    destroy(): void {
        for (let q in this.mediatorMap) {
            this.removeMediator(this.mediatorMap[q].name);
        }

        this.mediatorMap = null;
        this.observerMap = null;
    }

    registerObserver(notificationName: string, observer: ObserverInterface): void {
        if(this.observerMap[notificationName] != null) {
            this.observerMap[notificationName].push(observer);
        }
        else {
            this.observerMap[notificationName] = [observer];
        }
    }

    notifyObservers(notification: NotificationInterface): void {
        let i, n;

        if (this.observerMap[notification.name] != null) {
            let observers_ref = this.observerMap[notification.name],
                observers = [], observer;

            for(i = 0, n = observers_ref.length; i < n; i++) {
                observer = observers_ref[i];
                observers.push(observer);
            }

            for(i = 0, n = observers.length; i < n; i++) {
                observer = observers[i];
                observer.notifyObserver(notification);
            }
        }
    }

    removeObserver(notificationName: string, notifyContext: any) {
        if (!this.observerMap) {
            return;
        }
        let observers = this.observerMap[notificationName],
            i = observers.length;

        while(i--) {
            if(observers[i].compareNotifyContext(notifyContext) === true) {
                observers.splice(i, 1);
                break;
            }
        }

        if(observers.length == 0) {
            delete this.observerMap[notificationName];
        }
    }
    
    registerMediator(mediator: MediatorInterface): void {
        let name = mediator.name,
            interests, i, n, obs;

        if(this.mediatorMap[name]) return;

        mediator.initializeNotifier(this.multiCoreKey);
        this.mediatorMap[name] = mediator;
        interests = mediator.listNotificationInterests();

        if(interests.length > 0) {
            obs = new Observer(mediator.handleNotification, mediator);

            for (i = 0, n = interests.length; i < n; i++) {
                this.registerObserver(interests[i], obs);
            }
        }

        mediator.onRegister();
    }

    retrieveMediator(mediatorName: string): MediatorInterface  {
        return this.mediatorMap[mediatorName];
    }

    removeMediator(mediatorName: string): void {
        let mediator = this.mediatorMap[mediatorName],
            interests, i;

        if(mediator) {
            interests = mediator.listNotificationInterests();
            i = interests.length;

            // remove observers
            while(i--) {
                this.removeObserver(interests[i], mediator);
            }

            delete this.mediatorMap[mediatorName];
            mediator.onRemove();
        }
    }

    hasMediator(mediatorName: string): boolean {
        return this.mediatorMap[mediatorName] != null;
    }

    initializeView(): void {
        /* implementation optional*/
    }
}

