/**
 * see [Plugins](../../guides/Plugins.md) for details.
 */
export enum PluginPriority {
    /**
     * Low priority plugins load after content starts
     */
    LOW = 'low',

    /**
     * High priority plugins load before content starts
     */
    HIGH = 'high',
}
