import { ErrorCode } from '../../..';
import { Utilities } from '../../../core/Utilities';
import { LogLevel } from '../../../enum/LogLevel';
import { QualityMode } from '../../../enum/QualityMode';
import { AudioTrackInterface } from '../../../iface/AudioTrackInterface';
import { AudioTracksInterface } from '../../../iface/AudioTracksInterface';
import { DestroyInterface } from '../../../iface/DestroyInterface';
import { EventInterface } from '../../../iface/EventInterface';
import { LiveStreamInfoInterface } from '../../../iface/LiveStreamInfoInterface';
import { LoggerInterface } from '../../../iface/LoggerInterface';
import { PlaybackAdapterContextInterface } from '../../../iface/PlaybackAdapterContextInterface';
import { PlaybackAdapterDelegateInterface } from '../../../iface/PlaybackAdapterDelegateInterface';
import { PlaybackAdapterInterface } from '../../../iface/PlaybackAdapterInterface';
import { PlaybackMetricsInterface } from '../../../iface/PlaybackMetricsInterface';
import { QualityInfoInterface } from '../../../iface/QualityInfoInterface';
import { QualityInterface } from '../../../iface/QualityInterface';
import { RangeInterface } from '../../../iface/RangeInterface';
import { StreamMetadataInterface } from '../../../iface/StreamMetadataInterface';
import { TextTrackInterface } from '../../../iface/TextTrackInterface';
import { TextTracksInterface } from '../../../iface/TextTracksInterface';
import { VideoSurfaceInterface } from '../../../iface/VideoSurfaceInterface';
import { TextTrackSurfaceEvents } from '../../../playback/enum/TextTrackSurfaceEvents';
import { TextTrackType } from '../../../playback/enum/TextTrackType';
import { VideoSurfaceEvents } from '../../../playback/enum/VideoSurfaceEvents';
import { Html5VideoSurface } from '../../../playback/surface/Html5VideoSurface';
import { MetadataSurface } from '../../../playback/surface/MetadataSurface';

export class PlaybackAdapterBase implements PlaybackAdapterInterface {
  protected context: PlaybackAdapterContextInterface;
  protected options: any;
  protected logger: LoggerInterface;
  protected delegate: PlaybackAdapterDelegateInterface;
  protected videoSurface: VideoSurfaceInterface;
  protected multiCdnHeaderPresent: boolean = true;
  protected liveStreamInfo: LiveStreamInfoInterface = {
    isPlayingLive: false,
    liveEdgeOffset: NaN,
    dvrWindowSize: NaN,
    safeSeekingDuration: NaN,
    safeSeekingTime: NaN,
    relativeTime: NaN,
    relativeDuration: NaN,
    absoluteStart: NaN,
    absoluteTime: NaN,
    absoluteDuration: NaN,
  };

  protected lastKnownTimes: any = {
    absolute: NaN,
    relative: NaN,
    end: NaN,
  };

  protected textTrackInfo: TextTracksInterface = {
    track: null,
    tracks: [],
  };

  protected qualityInfo: QualityInfoInterface = {
    quality: null,
    qualities: [],
    mode: QualityMode.AUTO,
  };

  protected audioTrackInfo: AudioTracksInterface = {
    track: null,
    tracks: [],
  };

  constructor(context: PlaybackAdapterContextInterface, options: any = {}, useTextSurface: boolean = true) {
    this.context = context;
    this.options = options;
    this.delegate = this.context.getDelegate();
    this.logger = context.logger;
    this.videoSurface = this.createVideoSurface(useTextSurface);
    this.onSurfaceEvent = this.onSurfaceEvent.bind(this);
    this.videoSurface.addEvents();
  }

  createVideoSurface(useTextSurface: boolean = true) {
    return new Html5VideoSurface(this.context, useTextSurface);
  }

  createMetadataSurface(): DestroyInterface {
    return new MetadataSurface(this.context, this.delegate.metadataCuepoint);
  }

  enableSurfaceEvents(enabled: boolean) {
    const action = (enabled) ? 'on' : 'off';
    Utilities.values(VideoSurfaceEvents).forEach(event => this.videoSurface[action](event, this.onSurfaceEvent));
    Utilities.values(TextTrackSurfaceEvents).forEach(event => this.videoSurface[action](event, this.onSurfaceEvent));
  }

  protected onSurfaceEvent(event: EventInterface): void {
    switch (event.type) {
      case VideoSurfaceEvents.TIME_UPDATE:
        this.delegate.timeUpdate();
        break;

      case VideoSurfaceEvents.PLAYING:
        this.delegate.playing();
        break;

      case VideoSurfaceEvents.PAUSE:
        this.delegate.paused();
        break;

      case VideoSurfaceEvents.SEEKING:
        this.delegate.seeking();
        break;

      case VideoSurfaceEvents.SEEKED:
        this.delegate.seeked();
        break;

      case VideoSurfaceEvents.ENDED:
        this.delegate.ended();
        break;

      case VideoSurfaceEvents.DURATION_CHANGE:
        this.delegate.durationChange();
        break;

      case VideoSurfaceEvents.VOLUME_CHANGE:
        this.delegate.volumeChange(event.data.target.volume, event.data.target.muted);
        break;

      case TextTrackSurfaceEvents.TEXT_CUEPOINT:
        this.delegate.textCuepoints(event.data.activeCues);
        break;

      case TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE:
        this.delegate.textTrackDisplayModeChange(event.data.mode);
        break;

      case TextTrackSurfaceEvents.TEXT_TRACK_ADDED:
        this.textTrackInfo.tracks.push(event.data as TextTrackInterface);
        break;

      case TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE:
        this.delegate.textTrackInfoChange(this.textTrackInfo);
        break;

      case TextTrackSurfaceEvents.TEXT_TRACK_CHANGE:
        const track = event.data as TextTrackInterface;
        this.textTrackInfo.track = track;
        this.delegate.textTrackChange(track);
        break;
    }
  }

  protected getSegmentDuration(): number {
    return 6;
  }

  protected getSeekable(): RangeInterface {
    const result = { start: 0, end: 0 };
    const video = this.videoSurface.video;
    const range = video.seekable;
    const index = range.length - 1;
    if (index >= 0) {
      result.start = range.start(index);
      result.end = range.end(index);
    }

    return result;
  }

  protected getErrorMessage(msg: string, isFatal: boolean, retry: string = 'n/a'): string {
    return `${msg} fatal: ${isFatal} retry: ${retry}`;
  }

  protected throwError(code: ErrorCode, message: string, data: any, fatal: boolean = true) {
    this.logger.log(LogLevel.ERROR, message);
    this.delegate.error({ code, message, data, fatal });
  }

  getIsLiveStream() {
    return this.getDuration() == Infinity;
  }

  setAutoQualitySwitching(auto: boolean): void {
    throw new Error('setAutoQualitySwitching Method not implemented.');
  }

  setQuality(quality: QualityInterface): void {
    throw new Error('setQuality Method not implemented.');
  }

  setMinBitrate(value: number): void {
    // no-op
  }

  setMaxBitrate(value: number): void {
    // no-op
  }

  setTextTrack(track: TextTrackInterface): void {
    this.videoSurface.textTrack = track as TextTrack;
  }

  setTextTrackMode(mode: TextTrackMode): void {
    this.videoSurface.textTrackMode = mode;
  }

  setAudioTrack(track: AudioTrackInterface): void {
    throw new Error('setAudioTrack Method not implemented.');
  }

  getType(): string {
    throw new Error('getType Method not implemented.');
  }

  getCurrentTime(): number {
    return this.videoSurface.time;
  }

  getDuration(): number {
    return this.videoSurface.duration;
  }

  getBufferLength(): number {
    return this.videoSurface.bufferLength;
  }

  getLiveStreamInfo(): LiveStreamInfoInterface {
    const details: LiveStreamInfoInterface = this.liveStreamInfo;
    const video = this.videoSurface.video;
    const { start, end } = this.getSeekable();
    const time = video.currentTime;
    const duration = end - start;
    const segmentDuration = this.getSegmentDuration();
    const count = this.context.resource.playback.liveEdgeSyncFragmentCount;
    const safeSeekDuration = this.context.resource.overrides?.safeSeekDuration || segmentDuration;

    details.relativeTime = Math.max(time - start, 0);
    details.relativeDuration = duration;
    details.absoluteDuration = Date.now();
    details.dvrWindowSize = Math.floor(duration);
    details.liveEdgeOffset = (segmentDuration * count) + segmentDuration;
    details.safeSeekingTime = Math.ceil(start + safeSeekDuration);
    details.safeSeekingDuration = Math.floor(end - safeSeekDuration);
    details.isPlayingLive = Math.ceil(time + details.liveEdgeOffset) >= details.safeSeekingDuration;

    if (end != this.lastKnownTimes.end) {
      this.lastKnownTimes.end = end;
      details.absoluteStart = details.absoluteDuration - (duration * 1000);
      this.lastKnownTimes.absolute = Math.round(details.absoluteStart + (details.relativeTime * 1000));
      this.lastKnownTimes.relative = time;
    }

    const delta = Math.round((time - this.lastKnownTimes.relative) * 1000);
    details.absoluteTime = this.lastKnownTimes.absolute + delta;

    return details;
  }

  async destroy(): Promise<void> {
    this.videoSurface.destroy();
  }

  async load(): Promise<Partial<StreamMetadataInterface> | void> {
    this.enableSurfaceEvents(true);
  }

  async play(): Promise<void> {
    await this.videoSurface.video.play();
  }

  pause(): void {
    this.videoSurface.pause();
  }

  suspend(): void {
    // no-op
  }

  resume(): void {
    // no-op
  }

  async seek(position: number): Promise<void> {
    await this.videoSurface.seek(position);
  }

  resize(): void {
    // no-op
  }

  clearText(): void {
    this.videoSurface.clearCue();
  }

  getMetrics(): PlaybackMetricsInterface {
    return {
      droppedVideoFrames: NaN,
      framerate: NaN,
      bandwidth: NaN,
    };
  }

  protected async createSidecarTextTrack(url: string) {
    const isVtt = url.indexOf('.vtt') >= 0;
    const video = this.context.video;
    const label = 'English';
    const language = 'en';

    if (isVtt) {
      const track = document.createElement('track');
      track.kind = TextTrackType.CAPTIONS;
      track.label = label;
      track.srclang = language;
      track.id = 'sidecar-vtt';
      track.src = url;
      video.appendChild(track);
      track.track.mode = this.context.textTrackSettings.mode;
    }
    else {
      try {
        const track = video.addTextTrack(TextTrackType.CAPTIONS, label, language);
        Utilities.smpteToVtt(url, track, this.context.system);

        this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation successful');
      }
      catch (error) {
        this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation error', error.message);
      }
    }
  }
}
