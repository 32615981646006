import { Network } from '../enum/Network';
import { XhrResponseType } from '../enum/XhrResponseType';
import { AsyncDataRequestOptionsInterface } from '../iface/AsyncDataRequestOptionsInterface';
import { StrAnyDict } from '../iface/StrAnyDict';

export class AsyncDataRequestOptions {

    protected constructor() { }

    static create(options: AsyncDataRequestOptionsInterface): AsyncDataRequestOptionsInterface {

        return {
            url: options.url || '',
            method: options.method || 'get',
            timeout: options.timeout || Network.TIMEOUT,
            headers: options.headers || {},
            data: this.getBody(options.data, options.encodeList, options.sendJson),
            withCredentials: options.withCredentials === true,
            responseType: options.responseType || XhrResponseType.JSON,
            onComplete: options.onComplete || null,
            errorRecovery: options.errorRecovery || null
        };
    }

    /**
     * @param objOrStr - can be a plain object or string
     * @param enc - if supplying an object, an array of property names whose values should be encoded
     * @param asJson - set TRUE if the body should be sent as a JSON string
     */
    private static getBody(objOrStr: StrAnyDict | string, enc: string[], asJson: boolean = false): string {
        if (!objOrStr) return null;
        if (typeof objOrStr === 'string') return objOrStr;
        if (!Object.keys(objOrStr).length) return null;

        const useEnc = Array.isArray(enc) && enc.length;
        !useEnc && (enc = []);

        for (let q in objOrStr) {
            objOrStr[q] = this.getValue(q, objOrStr[q], enc);
        }

        // return JSON
        if (asJson) {
            return JSON.stringify(objOrStr);
        }

        // return string (form-urlencoded)
        let str = '', v;

        for (let q in objOrStr) {
            v = objOrStr[q];
            v !== null && v != undefined && v != '' && (str += `${q}=${v}&`);
        }
        str = str.substr(0, str.length - 1);

        return str;
    }

    private static getValue(name: string, val: string, enc: string[]): string {
        return enc.indexOf(name) >= 0 ? encodeURIComponent(val) : val;
    }

}
