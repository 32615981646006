import { Autoplay } from '../../enum/Autoplay';
import { LogLevel } from '../../enum/LogLevel';
import { NativePlugin } from '../../enum/NativePlugin';
import { PerformanceMode } from '../../enum/PerformanceMode';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { DataTransformersConfigInterface } from '../../iface/DataTransformersConfigInterface';
import { DomReference } from '../../iface/DomReference';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { LocalizationData } from '../../iface/LocalizationData';
import { ModelInterface } from '../../iface/ModelInterface';
import { OverridesInterface } from '../../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { PluginConfigInterface } from '../../iface/PluginConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class PlayerOptions implements PlayerOptionsInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'PlayerOptions'; }

    get data(): PlayerOptionsInterface { return <PlayerOptionsInterface>(this.model.data); }

    reset(): void { this.model.reset(); }

    set container(value: DomReference) { this.model.container = value; }
    get container(): DomReference { return this.model.container; }

    set dataTransformers(value: DataTransformersConfigInterface) { this.model.dataTransformers = value; }
    get dataTransformers(): DataTransformersConfigInterface { return this.model.dataTransformers; }

    set plugins(value: PluginConfigInterface[]) { this.model.plugins = value; }
    get plugins(): PluginConfigInterface[] { return this.model.plugins; }

    set nativePlugins(value: NativePlugin[]) { this.model.nativePlugins = value; }
    get nativePlugins(): NativePlugin[] { return this.model.nativePlugins; }

    set autoplay(value: Autoplay) { this.model.autoplay = value; }
    get autoplay(): Autoplay { return this.model.autoplay; }

    set offsetTextOnControlsVisible(value: boolean) { this.model.offsetTextOnControlsVisible = value; }
    get offsetTextOnControlsVisible(): boolean { return this.model.offsetTextOnControlsVisible; }

    set id(value: string) { this.model.id = value; }
    get id(): string { return this.model.id; }

    set isEmbedded(value: boolean) { this.model.isEmbedded = value; }
    get isEmbedded(): boolean { return this.model.isEmbedded; }

    set networkErrorRecovery(value: ErrorRecoveryInterface) { this.model.networkErrorRecovery = value; }
    get networkErrorRecovery(): ErrorRecoveryInterface { return this.model.networkErrorRecovery; }

    set networkTimeout(value: number) { this.model.networkTimeout = value; }
    get networkTimeout(): number { return this.model.networkTimeout; }

    set performanceMode(value: PerformanceMode) { this.model.performanceMode = value; }
    get performanceMode(): PerformanceMode { return this.model.performanceMode; }

    set renderTextTrackNatively(value: boolean) { this.model.renderTextTrackNatively = value; }
    get renderTextTrackNatively(): boolean { return this.model.renderTextTrackNatively; }

    set useNativeFullscreen(value: boolean) { this.model.useNativeFullscreen = value; }
    get useNativeFullscreen(): boolean { return this.model.useNativeFullscreen; }

    set playsInline(value: boolean) { this.model.playsInline = value; }
    get playsInline(): boolean { return this.model.playsInline; }

    set uiConfig(value: StrAnyDict) { this.model.uiConfig = value; }
    get uiConfig(): StrAnyDict { return this.model.uiConfig; }

    set useNativeControls(value: boolean) { this.model.useNativeControls = value; }
    get useNativeControls(): boolean { return this.model.useNativeControls; }

    set overrides(value: OverridesInterface) { this.model.overrides = value; }
    get overrides(): OverridesInterface { return this.model.overrides; }

    set logLevel(value: LogLevel) { this.model.logLevel = value; }
    get logLevel(): LogLevel { return this.model.logLevel; }

    set language(value: string) { this.model.language = value; }
    get language(): string { return this.model.language; }

    set localization(value: LocalizationData) { this.model.localization = value; }
    get localization(): LocalizationData { return this.model.localization; }

    set idleDelay(value: number) { this.model.idleDelay = value; }
    get idleDelay(): number { return this.model.idleDelay; }

    set enableOm(value: boolean) { this.model.enableOm = value; }
    get enableOm(): boolean { return this.model.enableOm; }
}
