
import { AdTechnology } from '../../enum/AdTechnology';
import { AdBreakType } from '../../enum/AdBreakType';
import { AdBreakScheduleItemInterface } from '../../iface/AdBreakScheduleItemInterface';
import { AdBreakCollectionInterface } from './AdBreakCollectionInterface';
import { dai } from './dai';
import { AdBreakCollection2Interface } from "./iface";

declare type MidRollProcessor = (mid: AdBreakScheduleItemInterface) => any;

export class AdBreakSchedule {

    private breaks: AdBreakCollectionInterface;
    private breaksById: AdBreakCollection2Interface;

    constructor(cueTimes: number[] | null, cuePoints?: dai.CuePoint[], contentDur?: number) {
        this.breaks = {
            pre: null,
            mid: [],
            post: null
        };
        this.breaksById = {};

        if (cueTimes) this.createBreaksFromTimes(cueTimes);
        else if (cuePoints) this.createBreaksFromCuepoints(cuePoints, contentDur);
    }

    destroy(): void {
        this.breaks = null;
        this.breaksById = null;
        this.breaks = null;
    }

    get adBreaks(): AdBreakCollectionInterface {
        return this.breaks;
    }

    hasMidRolls(): boolean {
        return !!this.breaks.mid.length;
    }

    hasPostRoll(): boolean {
        return !!this.breaks.post;
    }

    getBreakById(podId: string): AdBreakScheduleItemInterface | null {
        return this.breaksById[podId] || null;
    }

    // dai-specific
    updateBreaks(cues: dai.CuePoint[]) {
        const played = [];
        let brk: AdBreakScheduleItemInterface;

        let i = cues.length;
        while (i--) {
            cues[i].played && played.push(cues[i].start);
        }

        for (let q in this.breaksById) {
            brk = this.breaksById[q];

            if (!brk.hasPlayed && played.indexOf(brk.streamStartTime) >= 0) {
                brk.hasPlayed = true;
            }
        }
    }

    /**
     * Returns true for supplied time (in seconds) if there
     * is an upcoming ad break within 'proximity' seconds.
     */
    isBreakPending(time: number, proximity: number): boolean {
        return !!this.processMid((mid: AdBreakScheduleItemInterface) => {
            if (mid.startTime > time && ((mid.startTime - time) <= proximity)) {
                return !mid.hasPlayed ? true : -1;
            }

            return null;
        });
    }

    /**
     * Returns ad break for supplied time (in seconds) if there
     * is immediately preceding, un-played ad break.
     */
    getBreakForContentSeekTime(time: number): AdBreakScheduleItemInterface {
        return this.processMid((mid: AdBreakScheduleItemInterface) => {
            if (mid.startTime <= time) {
                return !mid.hasPlayed ? mid : -1;
            }

            return null;
        });
    }

    hasPlayed(podId: string): boolean {
        return this.breaksById[podId] && this.breaksById[podId].hasPlayed === true;
    }

    markPlayed(podId: string): void {
        this.breaksById[podId] && (this.breaksById[podId].hasPlayed = true);
    }

    /**
     * fn must return a truthy value or -1 to break; null to continue loop
     */
    private processMid(fn: MidRollProcessor): AdBreakScheduleItemInterface | null {
        if (!this.hasMidRolls()) {
            return null;
        }

        const mid = this.breaks.mid;
        let i: number = mid.length,
            r: any;

        while (i--) {
            r = fn(mid[i]);

            if (r == -1) {
                return null;
            }
            else if (r) {
                return r;
            }
        }

        return null;
    }

    // DAI Cue points; value of  0 indicates a pre-roll.
    // These bracket the mid-roll times (which are in "content time").

    // dai case
    private createBreaksFromCuepoints(cues: dai.CuePoint[], contentDur: number): void {
        let midIdx = 1, t: number,
            e: number, id: string, d: number, sst: number,
            brk: AdBreakScheduleItemInterface;

        cues.sort(function (a, b) {
            return a.start - b.start;
        });

        let brkTimeTotal = 0;

        for (let i = 0, n = cues.length; i < n; i++) {
            t = cues[i].start;
            e = cues[i].end;
            d = e - t;
            sst = t;

            if ((t - brkTimeTotal) >= contentDur) {
                t = -1;
            }
            brkTimeTotal += d;

            id = this.makeBrkId(t, t > 0 ? midIdx++ : null);
            brk = this.makeBreak(t, id, sst, AdTechnology.SSAI);
            brk.endTime = e,
                brk.duration = d,
                this.addBreak(t, brk, id);
        }
    }

    private createBreaksFromTimes(cues: number[]): void {
        let midIdx = 1, t: number,
            id: string;

        cues.sort(function (a, b) {
            return a - b;
        });

        for (let i = 0, n = cues.length; i < n; i++) {
            t = cues[i];
            id = this.makeBrkId(t, t > 0 ? midIdx++ : null);
            this.addBreak(t, this.makeBreak(t, id, null, AdTechnology.CSAI), id);
        }
    }

    private addBreak(t: number, brk: AdBreakScheduleItemInterface, id: string): void {
        const prefix = id.split('_')[0];

        t <= 0 ? (this.breaks[prefix] = brk) : this.breaks.mid.push(brk);
        this.breaksById[id] = brk;
    }

    private makeBreak(t: number, id: string, sst: number = null, adTech: AdTechnology): AdBreakScheduleItemInterface {
        return {
            adTechnology: adTech,
            startTime: t,
            hasPlayed: false,
            streamStartTime: sst !== null ? sst : null,
            breakId: id,
            type: t === 0 ? AdBreakType.PRE : t === -1 ? AdBreakType.POST : AdBreakType.MID
        };
    }

    private makeBrkId(t: number, idx?: number): string {
        const idPrefix = t === 0 ? "pre" : (t === -1 ? "post" : "mid");

        return idPrefix + (t > 0 ? `_${idx}` : '_0');
    }
}
