export enum VideoSurfaceEvents {
    ABORT = 'abort',
    CAN_PLAY = 'canplay',
    CAN_PLAY_THROUGH = 'canplaythrough',
    DURATION_CHANGE = 'durationchange',
    ENDED = 'ended',
    ERROR = 'error',
    LOADED_METADATA = 'loadedmetadata',
    LOAD_START = 'loadstart',
    PAUSE = 'pause',
    PLAY = 'play',
    PLAYING = 'playing',
    PROGRESS = 'progress',
    RATE_CHANGE = 'ratechange',
    SEEKED = 'seeked',
    SEEKING = 'seeking',
    STALLED = 'stalled',
    TIME_UPDATE = 'timeupdate',
    WEBKIT_NEED_KEY = 'webkitneedkey',
    VOLUME_CHANGE = 'volumechange',
    AUTOPLAY_BLOCKED = 'autoplayblocked',
}
