export enum PlaybackAdapterType {
    HLSJS = 'hlsjs',
    DASHJS = 'dashjs',
    SHAKA = 'shaka',
    HTML5 = 'html5',
    PLAY_STATION = 'playStation',
    UNKNOWN = 'unknown',

    /**
     * @deprecated 
     */
    TWITCH_LOW_LATENCY = 'twitchLowLatency',
}
