import { StrAnyDict } from "../iface/StrAnyDict";
import { StrStrDict } from "../iface/StrStrDict";
import { Util } from "./Util";

export class QueryString {

	static encode(obj: any): string {
		if (Util.isEmpty(obj)) {
			return '';
		}
		const str: string[] = Util.entries(obj).map(([k, v]) => k + "=" + encodeURIComponent(v));
		return str.join("&");
	}

	static decode(uri: string): StrAnyDict {
		const results = {} as StrAnyDict;

		if (uri == null) {
			return results;
		}

		uri
			.replace(/^[^?]*\?/, '')
			//@ts-ignore
			.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0: string, $1: string, $2: string, $3: string) => {
				let value;

				try {
					value = Util.isUndefined($3) ? $3 : decodeURIComponent($3);
				}
				catch (error) {
					value = $3;
				}

				results[decodeURIComponent($1)] = value;
			});

		return results;
	}

	static parseValue(value: string): any {
		if (Util.isUndefined(value)) {
			return true;
		}

		let temp: any = value.toLowerCase();
		if (temp === "true" || temp === "false") {
			return temp === "true";
		}

		if (value === "undefined") {
			return undefined;
		}

		if (temp === "null") {
			return null;
		}

		temp = parseFloat(value);
		if (!isNaN(temp)) {
			return temp;
		}

		return value;
	}

	static parse(uri: string): StrAnyDict {
		const params = QueryString.decode(uri);
		for (const k in params) {
			params[k] = QueryString.parseValue(params[k]);
		}
		return params;
	}

	static append(url: string, params: StrStrDict) {
		const prefix = Util.includes(url, '?') ? '&' : '?';
		return url + prefix + QueryString.encode(params);
	}
}
