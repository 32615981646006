/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import { FacadeInterface, NotifierInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { Facade } from './Facade';


/**
 * @hideconstructor
 */
export class Notifier implements NotifierInterface {

    protected multiCoreKey: string;

    constructor() {
        this.multiCoreKey = null;
    }

    /**
     * @ignore
     */
    destroy() {
        // no impl
    }

    /**
    * @ignore
    */
    onRemove() {
        this.multiCoreKey = null;
    }

    /**
    * @ignore
    */
    get facade(): FacadeInterface {
        return Facade.getInstance(this.multiCoreKey);
    }

    /**
    * @ignore
    */
    sendNotification(notificationName: string, body?: StrAnyDict, type?: string): void {
        let f = this.facade;
        f && f.sendNotification(notificationName, body, type);
    }

    /**
    * @ignore
    */
    initializeNotifier(key: string): void {
        this.multiCoreKey = key;
    }
}
