import { NotificationName } from '../enum/NotificationName';
import { PresentationMediatorInterface } from '../iface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { CommonPresentationMediator } from './CommonPresentationMediator';

/**
 * ContentPresentationMediator is used for content-only playback
 */
export class ContentPresentationMediator extends CommonPresentationMediator implements PresentationMediatorInterface {

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
    }

    // start the presentation
    override start(): void {
        const pm: PresentationStateInterface = this.presoModel;

        this.uiMediator && this.uiMediator.setMuteState(pm.isMuted);

        if (this.isClickToPlay) {
            this.setForClickToPlay();
        }
        else {
            this.notify(NotificationName.VIDEO_LOAD_START);
        }
    }

    protected override respondToVideoPlaying(): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        let sendStart = false;

        cps.started = true;

        if (!this.presoModel.started) {
            this.presoModel.started = true;
            this.started = true;

            if (this.uiMediator) {
                this.uiMediator.displayPoster(false);
                this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted && this.uiMediator.showUnmutePrompt();
                this.notify(NotificationName.ENABLE_UI);
                this.uiMediator.setFullScreenAccessRestricted(false);
                this.uiMediator.enableFullscreen();
                this.setTransportType();
            }

            sendStart = true;
        }

        super.respondToVideoPlaying();
        this.setPlayingState();

        sendStart && this.notify(NotificationName.CONTENT_START);
        this.notify(NotificationName.CONTENT_PLAYING);
    }

    protected override respondToVideoTimeUpdate(streamTime: number) {
        if (this.contentComplete) {
            return;
        }
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        if (this.presoModel.started) {
            this.presoModel.streamTime = streamTime;
            cps.time = streamTime;

            super.respondToVideoTimeUpdate(streamTime);
        }
    }

    protected override respondToVideoPaused(): void {
        super.respondToVideoPaused();
        this.setPausedState();

        this.notify(NotificationName.CONTENT_PAUSED);
    }

    protected override respondToIsPlayingLiveChange(isLive: boolean): void {
        this.uiMediator && this.uiMediator.setIsPlayingLive(isLive);
        super.respondToIsPlayingLiveChange(isLive);
    }

    protected override respondToVideoEnd(): void {
        this.contentComplete = true;
        this.notify(NotificationName.CONTENT_COMPLETE);
        super.respondToVideoEnd();
    }

    protected override respondToDurationChange(duration: number): void {
        this.presoModel.streamDuration = duration;
        this.contentPlaybackStateProxy.model.duration = duration;

        if (!this.contentDurationReleased) {
            this.releaseContentDuration(duration);
        }
    }

    override onRegister() {
        super.onRegister();
        this.domProxy && this.domProxy.showAdContainer(false);

        !this.isClickToPlay && this.prepareForPlayback();
    }
}
