import { HookType } from '../enum/HookType';
import { CoreError } from './CoreError';

export class HookError extends CoreError {
  override readonly name = "HookError";

  constructor(public type: HookType, message: string, cause?: Error) {
    super(`(${type}): ${message}`, cause);
  }
}
