import { QueryString } from '../core/QueryString';
import { cvui } from '../deprecated/cvui/cvui';
import { Deprecated } from '../deprecated/Deprecated';
import { PlayerDom } from '../enum/PlayerDom';
import { PlayerDomProxyInterface, PluginServicesOptions, SystemServiceInterface } from '../iface';
import { BuildInfoInterface } from '../iface/BuildInfoInterface';
import { LocalizationInterface } from '../iface/LocalizationInterface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { PlayerDomInterface } from '../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PluginServicesInterface } from '../iface/PluginServicesInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import * as avia from '../index';

export class PluginServices implements PluginServicesInterface {

    xhr = Deprecated.xhr;

    event = {
        dispatchPluginEvent: (data: StrAnyDict) => {
            this.dispatch(data);
        }
    };

    util = {
        queryStrFromObj(obj: StrAnyDict, encode?: string[]): string {
            return QueryString.encode(obj);
        }
    };

    /**
     * @deprecated
     */
    private vc: cvui.ViewControllerInterface = null;
    private playerApi: VideoPlayerInterface = null;
    private sys: SystemServiceInterface = null;
    private bi: BuildInfoInterface = null;
    private domProxy: PlayerDomProxyInterface = null;
    private playerOpts: PlayerOptionsInterface = null;
    private loc: LocalizationInterface = null;
    private dispatch: (data: StrAnyDict) => void;
    private log: LoggerInterface = null;

    constructor(options: PluginServicesOptions) {
        this.playerApi = options.player;
        this.vc = options.viewController;
        this.sys = options.system;
        this.bi = options.buildInfo;
        this.domProxy = options.domProxy;
        this.playerOpts = options.playerOptions;
        this.loc = options.localization;
        this.dispatch = options.dispatch;
        this.log = options.logger;
    }

    get player(): VideoPlayerInterface {
        return this.playerApi;
    }

    get logger(): LoggerInterface {
        return this.log;
    }

    /**
     * @deprecated
     */
    get viewController(): cvui.ViewControllerInterface {
        return this.vc;
    }

    get system(): SystemServiceInterface {
        return this.sys;
    }

    get appNamespace(): any {
        return this.sys.appNamespace;
    }

    get avia() {
        return this.sys.appNamespace as typeof avia;
    }

    get playerOptions() {
        return this.playerOpts;
    }

    get buildInfo(): BuildInfoInterface {
        return this.bi;
    }

    get playerDomProxy(): PlayerDomProxyInterface {
        Deprecated.warn('The PluginService.playerDomProxy method is deprecated. Use PluginService.dom instead. Access to this property will be completely removed April 28th, 2022');
        return this.domProxy;
    }

    get localization(): LocalizationInterface {
        return this.loc;
    }

    get dom(): PlayerDomInterface {
        const vid = () => {
            return this.domProxy?.getVideo();
        };
        return {
            main: this.domProxy?.getMain(),
            get video() { return vid(); },
            videoContainer: this.domProxy?.getElement(PlayerDom.VIDEO_CONTAINER),
            ccContainer: this.domProxy?.getElement(PlayerDom.CC_CONTAINER),
            adContainer: this.domProxy?.getElement(PlayerDom.AD_CONTAINER),
            adClickEl: this.domProxy?.getElement(PlayerDom.AD_CLICK_EL),
        };
    }
}
