//es-list-ignore
export enum NotificationName {
    //////////////////////////
    // Application
    //////////////////////////
    DEFAULT = 'default',
    BOOT_APP = 'bootApp',
    PREP_TRACKING = 'prepTracking',
    PREP_SERVICE = 'prepService',
    TIMER_TIC = 'timerTic',
    APP_EVENT = 'appEvent',
    APP_ERROR_EVENT = 'appErrorEvent',
    READY = 'ready',
    METRIC = 'metric',

    //////////////////////////
    // ERRORS
    /////////////////////////
    RESOURCE_ERROR = 'resourceError',
    VIDEO_START_ERROR = 'videoStartError',
    VIDEO_PLAYBACK_ERROR = 'videoPlaybackError',
    //////////////////////////
    // Resource Preparation & Playlist
    //////////////////////////
    PREP_RESOURCE_COLLECTION = 'prepResourceCollection',
    RETRIEVE_RESOURCE = 'retrieveResource',
    TRANSFORM_RESOURCE = 'transformResourceData',
    REGISTER_RESOURCE = 'registerResource',
    PLAY_RESOURCE = 'playResource',
    PREP_PLUGINS = 'prepPlugins',
    LOAD_PLUGINS = 'loadPlugins',
    REMOVE_PLUGIN = 'removePlugin',
    START_PRESENTATION = 'startPresentation',

    RESOURCE_START = 'resourceStart',
    RESOURCE_END = 'resourceEnd',
    RESOURCE_PLAYING = "resourcePlaying",
    RESOURCE_PAUSED = "resourcePaused",
    RESOURCE_COMPLETE = "resourceComplete",

    ADVANCE_PLAYLIST = 'advancePlaylist',
    PLAYLIST_CHANGE = 'playlistChanged',
    PLAYLIST_ADVANCED = 'playlistAdvanced',
    PLAYLIST_COMPLETE = 'playlistComplete',
    PLAYLIST_CLEARED = 'playlistCleared',
    PLAYLIST_CURRENT_RESOURCE_CLEARED = 'playlistCurrentResourceCleared',
    PLAYLIST_OUT_OF_RANGE = 'playlistOutOfRange',

    RESOURCE_INTERRUPTED = 'resourceInterrupted',
    CONTENT_COMPLETE = 'contentComplete',

    LIVE_PRESENTATION_STOPPED = 'livePresentationStopped',
    KILL_RESOURCE_PRESENTATION = 'killResourcePresentation',

    POSTER_CLICK = 'posterClick',

    SUSPEND_PLAYBACK = 'suspendPlayback',
    RESUME_PLAYBACK = 'resumePlayback',
    PLAYBACK_SUSPENDED = 'playbackSuspended',
    PLAYBACK_RESUMED = 'playbackResumed',
    //////////////////////////
    // Presentation
    //////////////////////////
    FULLSCREEN_ENTER = 'fullscreenEnter',
    FULLSCREEN_EXIT = 'fullscreenExit',
    PRESENTATION_SIZE_CHANGE = 'presentationSizeChange',
    MOUSE_ENTER_PRESENTATION = 'mouseEnterPresentation',
    MOUSE_LEAVE_PRESENTATION = 'mouseLeavePresentation',
    USER_PAUSE = 'userPause',
    METADATA_CUEPOINT = 'metadataCuepoint',
    TEXT_CUEPOINT = 'textCuepoint',
    TEXT_TRACK_CHANGE = 'textTrackChange',
    TEXT_TRACK_DISPLAY_MODE_CHANGE = 'textTrackDisplayModeChange',
    TEXT_TRACK_AVAILABLE = 'textTrackAvailable',
    TEXT_TRACK_INFO_CHANGE = 'textTrackInfoChange',
    AUDIO_TRACK_CHANGE = 'audioTrackSwitched',
    AUDIO_TRACK_INFO_CHANGE = 'audioTrackInfoChange',
    QUALITY_INFO_CHANGE = 'qualityInfoChange',
    THUMBNAIL_TRACK_AVAILABLE = 'thumbnailTrackAvailable',
    PLAYBACK_STATE_CHANGE = 'playbackStateChange',

    //////////////////////////
    // Control Playback API/UI
    //////////////////////////    
    AUTO_QUALITY_SWITCHING = 'autoQualitySwitching',
    MIN_BITRATE = 'minBitrate',
    MAX_BITRATE = 'maxBitrate',
    PLAY_ON_USER_GESTURE = 'playOnUSerGesture',
    PLAY = 'play',
    PAUSE = 'pause',
    STOP = 'stop',
    SEEK = 'seek',
    SEEK_FORWARD = 'seekForward',
    SEEK_BACK = 'seekBack',
    SEEK_TO_LIVE = 'seekToLive',
    SWITCH_BITRATE = 'switchBitrate',
    SWITCH_QUALITY_CATEGORY = 'switchQualityCategory',
    SWITCH_AUDIO_TRACK = 'switchAudioTrack',
    SWITCH_TEXT_TRACK = 'switchTextTrack',
    SWITCH_TEXT_MODE = 'switchTextMode',
    //////////////////////////
    // Content Playback
    //////////////////////////
    CONTENT_START = 'contentStart',
    CONTENT_DURATION_AVAILABLE = 'contentDurationAvailable',
    CONTENT_SEGMENT_START = 'contentSegmentStart',
    CONTENT_SEEKING = 'contentSeeking',
    CONTENT_SEEKED = 'contentSeeked',
    SEEK_REDIRECT_START = 'seekRedirectStart',
    SEEK_REDIRECT_COMPLETE = 'seekRedirectComplete',
    CONTENT_BUFFERING = 'contentBuffering',
    CONTENT_PLAYING = 'contentPlaying',
    CONTENT_PAUSED = 'contentPaused',
    QUALITY_CHANGE = 'contentQualityChange',
    CDN_CHANGE = 'contentCdnChange',
    DRM_KEYSYSTEM_CREATED = 'drmKeySystemCreated',
    CONTENT_SEGMENT_END = 'contentSegmentEnd',
    CONTENT_TIME_UPDATE = "contentTimeupdate",
    CONTENT_IS_LIVE_CHANGED = 'contentIsLiveChanged',
    STREAM_TYPE_CHANGE = 'streamTypeChange',
    STREAM_METADATA = 'streamMetadata',
    STREAM_ID_AVAILABLE = 'streamIdAvailable',
    VIDEO_PROGRESS = "contentVideoProgress",
    VIDEO_LOAD_START = 'videoLoadStart',
    VIDEO_LOAD_COMPLETE = 'videoLoadComplete',
    VIDEO_LOADED_METADATA = 'videoLoadedMetadata',
    AUTOPLAY_BLOCKED = 'autoplayBlocked',
    /////////////////////////
    // AD Events
    /////////////////////////
    AD_SEGMENT_START = 'adsegmentstart',
    AD_SEGMENT_END = 'adsegmentend',
    AD_BUFFERING = 'adBuffering',
    AD_CUEPOINTS_AVAILABLE = 'adCuepointsAvailable',
    AD_BREAK_START = 'adBreakStart',
    AD_BREAK_METADATA = 'adBreakMetadata',
    AD_START = 'adStart',
    AD_TIME_UPDATE = 'adProgress',
    AD_FIRST_QUARTILE = 'adFirstQuartile',
    AD_MIDPOINT = 'adMidpoint',
    AD_THIRD_QUARTILE = 'adThirdQuartile',
    AD_COMPLETE = 'adComplete',
    AD_BREAK_COMPLETE = 'adBreakEnd',
    AD_PAUSED = 'adPaused',
    AD_PLAYING = 'adPlaying',
    AD_ERROR = 'adError',
    AD_STALLED = 'adStalled',
    AD_BREAK_DISCARDED = 'adBreakDiscarded',
    AD_CLICK = 'adClick',
    AD_SKIPPED = 'adSkipped',
    /////////////////////////
    // View and UI
    /////////////////////////
    ENABLE_UI = 'enableUi',
    DISABLE_UI = 'disableUi',
    FULLSCREEN_AVAILABLE = 'fullscreenAvailable',
    FULLSCREEN_CHANGE = 'fullscreenChange',
    ENTER_FULLSCREEN_REQUEST = 'enterFullscreenRequest',
    EXIT_FULLSCREEN_REQUEST = 'exitFullscreenRequest',
    BEFORE_CONTROLS_VISIBLE = 'beforeControlsVisible',
    CONTROLS_VISIBLE = 'controlsVisible',
    BEFORE_CONTROLS_HIDDEN = 'beforeControlsHidden',
    CONTROLS_HIDDEN = 'controlsHidden',
    MUTE = 'mute',
    UNMUTE = 'unmute',
    VOLUME_CHANGE = 'volumeChange',
    USER_KEY_COMMAND = 'userKeyCommand', // this maps to a command only
    USER_KEYBOARD_COMMAND = 'userKeyboardCommand', // this maps to an external event
    HOTKEY_ACTION = 'hotkeyAction',

    TT_OFFSET_REQUEST = 'ttOffsetRequest',
    USER_SETTINGS_REQUEST = 'userSettingsRequest',

    CHANGE_LANGUAGE = 'changeLanguage',
    LANGUAGE_CHANGE = 'languageChange',
}
