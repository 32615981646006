import { AbstractMacroCommand } from '../../mvc/AbstractMacroCommand';
import { CreateDomCommand } from "./CreateDomCommand";
import { PrepModelCommand } from "./PrepModelCommand";
import { PrepPluginsCommand } from './PrepPluginsCommand';
import { PrepTimerCommand } from './PrepTimerCommand';
import { PrepViewCommand } from "./PrepViewCommand";

export class BootCommand extends AbstractMacroCommand {

    constructor() {
        super();
    }

    initializeMacroCommand(): void {
        this.addSubCommand(PrepModelCommand);
        this.addSubCommand(CreateDomCommand);
        this.addSubCommand(PrepTimerCommand);
        this.addSubCommand(PrepViewCommand);
        this.addSubCommand(PrepPluginsCommand);
    }
}
