import { StrAnyDict } from '../../iface/StrAnyDict';


export const player_dom_config: StrAnyDict = {

    main: {
        type: 'object',
        defaultValue: null,
    },

    video: {
        type: 'object',
        defaultValue: null,
    },

    videoContainer: {
        type: 'object',
        defaultValue: null,
    },

    ccContainer: {
        type: 'object',
        defaultValue: null,
    },

    adContainer: {
        type: 'object',
        defaultValue: null,
    },

    panelContainer: {
        type: 'object',
        defaultValue: null,
    },

    adClickEl: {
        type: 'object',
        defaultValue: null,
    }

};
