import { AppResources } from '../app/AppResources';
import { event_map } from '../app/event_map';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { Deprecated } from '../deprecated/Deprecated';
import { ModelSnapshotInterface } from '../deprecated/ModelSnapshotInterface';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NativePlugin } from '../enum/NativePlugin';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlayerEvent } from '../enum/PlayerEvent';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import {
    ApplicationInterface,
    MediatorInterface,
    NotificationInterface,
    PlayerDomProxyInterface,
    PresentationMediatorInterface,
    SystemServiceInterface
} from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { DimensionsInterface } from '../iface/DimensionsInterface';
import { EventInterface } from '../iface/EventInterface';
import { PluginInterface } from '../iface/PluginInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { PlaylistProxy } from '../model/PlaylistProxy';
import type { ResourceProxy } from '../model/ResourceProxy';
import { PlayerOptions } from '../model/vo/PlayerOptions';
import { Utils } from '../util/Utils';
import { AdPresentationMediator } from './AdPresentationMediator';
import { FullscreenMediator } from './FullscreenMediator';
import { LogAwareMediator } from "./LogAwareMediator";
import { PluginMediator } from './PluginMediator';


export class AppMediator extends LogAwareMediator implements MediatorInterface {

    private app: ApplicationInterface;

    constructor(name: string, app: ApplicationInterface) {
        super(name);

        this.app = app;
    }

    setFullscreenElement(el: HTMLElement): void {
        const fsm = <FullscreenMediator>this.facade.retrieveMediator(MediatorName.FULLSCREEN);
        fsm.fullscreenElement = el;
    }

    getFullscreenElement(): HTMLElement {
        const fsm = <FullscreenMediator>this.facade.retrieveMediator(MediatorName.FULLSCREEN);

        return fsm.fullscreenElement;
    }

    override onRemove() {
        this.app = null;
        super.onRemove();
    }

    getAppApi(): VideoPlayerInterface {
        return this.app.getApi();
    }

    loadNativePlugin(name: NativePlugin, url?: string): Promise<PluginInterface> {
        const cfg = AppResources.nativePluginConfig[name];
        if (!cfg) {
            return null;
        }

        if (url) {
            cfg.url = url;
        }
        const pim = <PluginMediator>this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR);

        return pim?.loadPlugin(cfg);
    }

    /**
     * @deprecated
     */
    getSnapshot(): ModelSnapshotInterface {
        return (<ModelCollectionProxy>this.getProxy(ProxyName.ModelCollectionProxy)).collection.getSnapshot();
    }

    getConfigAsJson(spacing?: number): string {
        const m = <ModelCollectionProxy>this.getProxy(ProxyName.ModelCollectionProxy);
        const o = m.getModel<PlayerOptions>(ModelName.PlayerOptions).data;
        const p = <PlaylistProxy>this.getProxy(ProxyName.Playlist);
        const s = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        return Utils.serializeConfigSnapshot(o, p, s, spacing);
    }

    validateSeek(position: number, duration: number): number | null {
        if (isNaN(position)) {
            this.log(LogLevel.WARN, `Invalid seek() time [${position}] supplied`);

            return null;
        }

        const pm = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        const pbp = <ContentPlaybackStateProxy>this.getProxy(ProxyName.ContentPlaybackStateProxy);
        const linear = pbp && pbp.model.streamType === StreamType.LIVE;

        if (!pm || !pbp || pm.isCurrentVideoAd || linear) {
            this.log(LogLevel.WARN, 'seek() may not be called in the current context');

            return null;
        }

        return Util.clampValue(position, 0, duration);
    }

    isPlaybackSuspended(): boolean {
        const pm = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return pm.suspended;
    }

    /**
     * @deprecated
     */
    getAutoplayCapabilities(): Promise<AutoplayInfoInterface> {
        return Deprecated.getAutoplayCapabilities(this);
    }

    getContainerRect(): ClientRect | null {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy);

        return domProxy ? domProxy.getPresentationRect() : null;
    }

    attachResource(resource: Partial<ResourceConfigurationInterface>, onStopComplete?: Function): Promise<ResourceConfigurationInterface> {
        if (!onStopComplete) {
            const pl = this.getProxy(ProxyName.Playlist);
            (pl as PlaylistProxy)?.softClear();
        }

        return this.stopPresentation()
            .then(() => {
                onStopComplete?.();
                return this.app.sendAsyncNotification({
                    name: NotificationName.PREP_RESOURCE_COLLECTION,
                    body: { resource: resource, start: true },
                }, [PlayerEvent.RESOURCE_START], [PlayerEvent.RESOURCE_ERROR]);
            })
            .then(() => this.getCurrentResource());
    }

    getCurrentResource(): ResourceConfigurationInterface | null {
        const p = this.getProxy(ProxyName.ResourceProxy);

        return (p as ResourceProxy)?.resource || null;
    }

    killCurrentResource(): Promise<void> {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        this.log(LogLevel.INFO, 'Killing current resource');

        return Promise.resolve()
            .then(() => pm?.close())
            .catch((e: Error) => {
                Logger.error(e);
            });
    }

    stopPresentation(): Promise<any> {
        const pbp = <ContentPlaybackStateProxy>this.getProxy(ProxyName.ContentPlaybackStateProxy);

        if (!pbp?.model) {
            return Promise.resolve();
        }

        const note = { name: NotificationName.STOP, type: NotificationType.EXTERNAL };
        return this.app.sendAsyncNotification(note, [PlayerEvent.RESOURCE_INTERRUPTED, PlayerEvent.RESOURCE_END]);
    }

    skipAd() {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        (pm as AdPresentationMediator).skipAd();
    }

    getPlugin(name: string): any {
        const pim = <PluginMediator>this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR);

        return pim ? pim.getPlugin(name) : null;
    }

    dispatchPluginEvent(data: StrAnyDict) {
        this.app.sendEvent(PlayerEvent.PLUGIN_EVENT, data);
    }

    prepForPlayerRemoval(): void {
        const uim = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI);
        if (!uim) { return; }
        uim.killUi();
        this.log(LogLevel.INFO, 'UI layer destroyed');
    }

    getMuteState(): boolean {
        const presoModel = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return presoModel ? presoModel.isMuted : null;
    }

    getVolume(): number {
        const presoModel = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        return presoModel ? presoModel.volume : NaN;
    }

    getDimensions(): DimensionsInterface {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy);
        return domProxy ? domProxy.getDimensions() : null;
    }

    getAdBreakTimes(): AdCuePointInterface[] {
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);
        return pm ? pm.getAdBreakTimes() : null;
    }

    grabFrame(): HTMLImageElement | null {
        const domProxy = <PlayerDomProxyInterface>this.getProxy(ProxyName.PlayerDomProxy),
            vidEl = domProxy && domProxy.getVideo();

        if (!domProxy || !vidEl) { return null; }

        const cvs = document.createElement('canvas');
        let ctx, dUrl, img;

        ctx = cvs.getContext('2d');
        const w = cvs.width = vidEl.videoWidth;
        const h = cvs.height = vidEl.videoHeight;
        ctx.drawImage(<HTMLVideoElement>vidEl, 0, 0, w, h);
        dUrl = cvs.toDataURL();

        img = document.createElement('img');
        img.setAttribute('src', dUrl);

        return img;
    }

    override listNotificationInterests(): string[] {
        return [
            NotificationName.APP_EVENT
        ];
    }

    sendErrorEvent(event: EventInterface): void {
        this.app.sendErrorEvent(event);
    }

    handleNotification(notification: NotificationInterface): void {
        const b = notification.body;
        const pe = event_map[b.notificationName] || b.type;

        switch (notification.name) {
            case NotificationName.APP_EVENT:
                pe && this.app.sendEvent(pe, b.data);
                break;
        }
    }

    override onRegister(): void {
        super.onRegister();
    }
}
