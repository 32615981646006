import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { MediaError } from '../../enum/MediaError';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { ResourceLocationDrmInterface } from '../../iface/ResourceLocationDrmInterface';
import { DrmType } from '../../util/enum/DrmType';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';
import { FairPlay } from '../util/FairPlay';
import { BaseHtml5Adapter } from './BaseHtml5Adapter';


export class Html5Adapter extends BaseHtml5Adapter {

    protected override pType = PlaybackAdapterType.HTML5;

    private fairplay: FairPlay;
    private audioTrackIdx: number = -1;
    private fairPlayEventHandler: EventHandler = (e: EventInterface) => this.onFairplayEvent(e);

    constructor(config: PlaybackAdapterConfigInterface) {
        super(config);
        this.logger.log(LogLevel.INFO, 'Html5Adapter created');
    }

    ////////////////////
    //Public Methods
    ////////////////////
    override initialize(): void {
        super.initialize();
        if (this.hasFairplayDrm(this.config.resource.location.drm)) {
            this.fairplay = new FairPlay(this.videoSurface, this.config, this.logger);
            this.fairplay.on(PlaybackAdapterEvents.ERROR, this.fairPlayEventHandler);
            this.fairplay.initialize();
            this.emit(PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED, { keysystem: DrmType.FAIRPLAY_1_0 });
        }
    }

    override destroy(): Promise<void> {
        // Setting src to an empty string can cause a MEDIA_ERR_SRC_NOT_SUPPORTED error
        // Use removeAttribute instead, https://github.com/w3c/media-source/issues/53
        this.videoSurface.video.removeAttribute('src');
        // For all browsers to cleaning unload the src we need to call load() here
        // https://www.w3.org/TR/html5/embedded-content-0.html#best-practices-for-authors-using-media-elements
        this.videoSurface.video.load();

        if (this.fairplay) {
            this.fairplay.off(PlaybackAdapterEvents.ERROR, this.fairPlayEventHandler);
            this.fairplay.destroy();
        }

        return super.destroy();
    }

    ////////////////////
    //Accessors
    ////////////////////

    set currentIndex(index: number) {
        this.logger.log(LogLevel.INFO, AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
    }
    // get currentIndex(): number {        
    // }

    set autoQualitySwitching(value: boolean) {
        this.logger.log(LogLevel.INFO, AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
    }
    // get autoBitrateSwitching(): boolean {

    // }

    set minBitrate(value: number) {
        this.logger.log(LogLevel.INFO, AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
    }
    // get minBitrate(): number {

    // }

    set maxBitrate(value: number) {
        this.logger.log(LogLevel.INFO, AppResources.messages.MANUAL_ABR_SWITCHING_UNAVAILABLE);
    }
    // get maxBitrate(): number {    
    // }

    get manifestQualities(): QualityInterface[] {
        return [];
    }

    ////////////////////
    // Protected 
    ////////////////////
    protected override onVideoSurfaceEvent(e: EventInterface): void {
        switch (e.type) {
            case VideoSurfaceEvents.DURATION_CHANGE:
                this.pIsLiveStream = this.videoSurface.duration == Infinity;
                break;
        }

        super.onVideoSurfaceEvent(e);
        this.updateAudioTracks(e);
    }

    protected override loadMediaUrl(): Promise<void> {
        this.videoSurface.src = this.mediaUrl;
        return super.loadMediaUrl()
            .then((): Promise<any> | void => {
                const startTime = this.playback.startTime;
                if (startTime > 0) {
                    return this.eventsToPromise(VideoSurfaceEvents.CAN_PLAY_THROUGH)
                        .then(() => this.seek(startTime));
                }
            });
    }

    ////////////////////
    // Private
    ////////////////////
    private updateAudioTracks(e: EventInterface): void {
        const audioTracksInitialized = this.audioTrackIdx !== -1,
            tracks = this.videoSurface && this.videoSurface.video && this.videoSurface.video.audioTracks;

        if (!tracks) {
            return;
        }

        const enabledAudioTrackIdx = this.getEnabledAudioTrackId(tracks);
        switch (e.type) {
            case VideoSurfaceEvents.CAN_PLAY:
                if (!audioTracksInitialized) {
                    this.audioTrackIdx = enabledAudioTrackIdx;
                    this.normalizedAudioTracks = this.normalizeAudioTracks(Util.toArray(tracks), {
                        type: 'kind',
                        lang: 'language'
                    });
                    this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, {
                        tracks: this.normalizedAudioTracks,
                        track: this.normalizedAudioTracks[this.audioTrackIdx]
                    });
                }
                break;
            case VideoSurfaceEvents.PROGRESS:
                if (audioTracksInitialized) {
                    if (this.audioTrackIdx !== enabledAudioTrackIdx) {
                        this.audioTrackIdx = enabledAudioTrackIdx;
                        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_CHANGE, { track: this.normalizedAudioTracks[this.audioTrackIdx] });
                    }
                }
                break;
        }
    }

    private onFairplayEvent(e: EventInterface) {
        this.emit(e.type, e.data);
    }

    private getEnabledAudioTrackId(tracks: any): number {
        let i = tracks.length;
        while (i--) {
            if (tracks[i].enabled) {
                return i;
            }
        }
        return i;
    }

    private hasFairplayDrm(drmParams: ResourceLocationDrmInterface): boolean {
        return drmParams !== null ? !Util.isEmpty(drmParams.fairplay) : false;
    }

    protected override handleVideoSurfaceError(e: EventInterface) {
        //TODO  stall event if video.networkState is 2 we can attempt a hard retry of the source content with a follow up seek to the last known time?                
        super.handleVideoSurfaceError(e);
        const error = this.videoSurface.video.error;
        const message = Object.keys(MediaError)[error?.code - 1] || AppResources.messages.UNSPECIFIED_ERROR;

        if (error) {
            switch (error.code) {
                case MediaError.MEDIA_ERR_NETWORK:
                    this.throwError(ErrorCode.HTML5_NETWORK_ERROR, message, error);
                    break;
                case MediaError.MEDIA_ERR_DECODE:
                    this.throwError(ErrorCode.HTML5_MEDIA_ERROR, message, error);
                    break;
                case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    this.throwError(ErrorCode.HTML5_SRC_NOT_SUPPORTED, message, error);
                    break;
                default:
                    this.throwError(ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR, message, error);
            }
        }
    }
}
