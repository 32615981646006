import { NotificationName } from '../enum/NotificationName';
import { PlayerEvent } from '../enum/PlayerEvent';
import { NotificationInterface } from '../iface';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class MetricCommand extends LogAwareSimpleCommand {

  execute(notification: NotificationInterface): void {
    const { body } = notification;

    // TODO: Record Metric to MetricProxy

    this.sendNotification(NotificationName.APP_EVENT, {
      type: PlayerEvent.METRIC,
      data: body,
    });
  }
}
