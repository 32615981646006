import { Emitter } from '../core/Emitter';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { ApplicationInterface, ApplicationOptionsInterface, FacadeInterface, NotificationInterface, ServiceCollection } from '../iface';
import { EventInterface } from '../iface/EventInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { Facade } from '../mvc/Facade';
import { LogService } from '../service/LogService';
import { AppMediator } from '../view/AppMediator';

/**
 * @hideconstructor
 */
export abstract class AbstractApplication extends Emitter implements ApplicationInterface {

    private pFacade: FacadeInterface;
    private readonly pAppId: string;
    private mdlCollProxy: ModelCollectionProxy;


    protected constructor(options: ApplicationOptionsInterface) {
        super(options || {});

        this.pAppId = options.id || Util.uid8();
        this.pFacade = Facade.createFacade(
            this.pAppId,
            options.commandMap || null,
        );
    }

    /**
    * @ignore
    */
    override destroy() {
        this.mdlCollProxy = null;
        this.pFacade = null;

        super.destroy();

        Facade.removeCore(this.pAppId);
    }

    /**
    * @ignore
    */
    get appId(): string {
        return this.pAppId;
    }

    abstract sendEvent(name: string, data: StrAnyDict): void;
    abstract sendErrorEvent(event: EventInterface): void;
    abstract getApi(): VideoPlayerInterface;
    abstract sendAsyncNotification(notification: NotificationInterface, event: string[], errorEvent?: string[]): Promise<any>;


    protected sendNotification(name: string, body?: StrAnyDict, type?: string) {
        this.pFacade.sendNotification(name, body, type);
    }

    protected createLoggingService(logLvl: LogLevel): void {
        const logger = new Logger({
            logLevel: logLvl,
            id: this.appId,
            onLogEvent: (e: EventInterface) => this.sendEvent(e.type, e.data)
        });
        this.pFacade.registerService(new LogService(ServiceName.Logging, logger));
    }

    protected registerGlobalServices(gServices: ServiceCollection): void {
        const f = this.pFacade;

        for (let s in gServices) {
            f.registerService(gServices[s]);
        }
    }

    protected get facade(): FacadeInterface {
        return this.pFacade;
    }

    protected get modelCollectionProxy(): ModelCollectionProxy {
        if (!this.mdlCollProxy) {
            this.mdlCollProxy = <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy);
        }
        return this.mdlCollProxy;
    }

    protected get appMediator(): AppMediator {
        return <AppMediator>this.pFacade.retrieveMediator(MediatorName.APPLICATION);
    }

    // The concrete Application instance is passed to an AppMediator, which will
    // interrogate the app instance (i.e, 'this') for its Notification interests, and will make
    // those interests its own. Upon receiving a relevant Notification, it will
    // invoke handleNotification() on the app instance, for subsequent event dispatch.
    protected createApplicationMediator(name: string): void {
        const am = new AppMediator(name, this);
        this.pFacade.registerMediator(am);
    }
}
