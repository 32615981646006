import { EmitterInterface } from "../iface/EmitterInterface";
import { EventHandler } from "../iface/EventHandler";
import { EventInterface } from "../iface/EventInterface";
import { StrAnyDict } from "../iface/StrAnyDict";
import { BaseClass } from './BaseClass';
import { CoreEvent } from './CoreEvent';
import { LinkedList } from './LinkedList';
import { Util } from "./Util";


export class Emitter extends BaseClass implements EmitterInterface {

    protected opts: StrAnyDict;
    protected target: EmitterInterface;
    protected eventMap: Record<string, LinkedList<EventHandler>>;

    /**
     * An event emitter, with methods to add and remove event listeners.
     * Base class for any class using event-based communication.
     */
    constructor(options?: StrAnyDict, target?: EmitterInterface) {
        super();
        this.target = target || this;
        this.opts = options || {};
        this.eventMap = {};

        let o = this.opts, e;

        for (let q in o) {
            if (Util.isFunction(o[q]) && /^on[A-Z]/.test(q)) {
                e = q.charAt(2).toLowerCase() + q.substring(3);
                this.on(e, o[q]);
                delete this.opts[q];
            }
        }
    }

    destroy(): void {
        let q;

        if (this.opts) {
            for (q in this.opts) {
                delete this.opts[q];
            }
            this.opts = null;
        }

        this.offAll(null);

        if (this.eventMap) {
            for (q in this.eventMap) {
                delete this.eventMap[q];
            }
            this.eventMap = null;
        }
    }

    /**
     * Adds an event listener
     */
    on(name: string, func: EventHandler): void {
        if (!this.eventMap || !name || !Util.isFunction(func)) {
            return;
        }

        const handlers = this.eventMap[name] || (this.eventMap[name] = new LinkedList<EventHandler>());

        if (handlers.has(func)) {
            return;
        }

        handlers.add(func);
    }

    /**
     * Adds a one time event listener
     */
    once(name: string, func: EventHandler): void {
        if (!this.eventMap || !name || !Util.isFunction(func)) {
            return;
        }

        const handler = (event: EventInterface) => {
            this.off(name, handler);
            func(event);
        };

        this.on(name, handler);
    }

    /**
     * Removes the supplied EventHandler, or, if omitted, will remove all
     * handlers for the supplied (required) event name
     */
    off(name: string, func?: EventHandler): void {
        !func && this.offAll(name);
        func && this.remove(name, func);
    }

    /**
     * Removes all event handlers for the supplied (required) event name
     */
    offAll(name?: string): void {
        if (!this.eventMap) {
            return;
        }
        let map = this.eventMap, q;

        if (name) {
            this.remove(name, null);

            return;
        }

        for (q in map) {
            this.remove(q, null);
        }
    }

    hasListenerFor(name: string): boolean {
        return !!(this.eventMap && this.eventMap[name] && !this.eventMap[name].empty);
    }

    emit(name: string, data: StrAnyDict = null): void {
        let e = new CoreEvent(name, this, data);
        this.dispatchEvt(e);
    }

    dispatchEvt(e: EventInterface) {
        if (!this.eventMap || !this.eventMap[e.type]) {
            return;
        }
        this.eventMap[e.type].forEach(h => h(e));
    }

    private remove(name: string, func: EventHandler = null): void {
        if (!this.eventMap || !this.eventMap[name]) {
            return;
        }

        if (!func) {
            this.eventMap[name].clear();
        }
        else {
            this.eventMap[name].delete(func);
        }
    }
}
