import { ServiceName } from '../enum/ServiceName';
import { LogServiceInterface, ServiceInterface } from '../iface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { Facade } from '../mvc/Facade';
import { ProxyName } from '../enum/ProxyName';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { ModelName } from '../enum/ModelName';


export class ServiceBase implements ServiceInterface {

    private readonly pName: string;
    private facadeKey: string;

    constructor(name: string) {
        this.pName = name;
    }

    get name(): string {
        return this.pName;
    }

    destroy(): void {
        // null op, provide in subclass if desired
    }

    onRegister(key: string): void {
        this.facadeKey = key;
    }

    protected getLogger(): LoggerInterface {
        const logSvc = <LogServiceInterface>this.getService(ServiceName.Logging);
        return logSvc.logger;
    }

    protected getService(name: string): ServiceInterface {
        return Facade.getInstance(this.facadeKey).retrieveService(name);
    }

    protected getModel<T>(name: ModelName): T {
        return <T>(this.getModelCollection().getModel(name));
    }

    protected getModelCollection(): ModelCollectionProxy {
        return <ModelCollectionProxy>Facade.getInstance(this.facadeKey)?.retrieveProxy(ProxyName.ModelCollectionProxy);
    }
}
