import { NotificationName } from '../enum/NotificationName';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface, PlayerDomProxyInterface } from '../iface';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class ReadyCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const { name, body } = notification;

        // accessibility
        const dom = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
        if (dom) {
            const main = dom.getMain();
            main.setAttribute('role', 'region');

            const tabindex = main.getAttribute('tabindex') || "0";
            main.setAttribute('tabindex', tabindex);
        }

        this.sendNotification(NotificationName.APP_EVENT, { notificationName: name, data: body });
    }
}
