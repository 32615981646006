import screenfull from 'screenfull';
import { Util } from '../core/Util';
import { Browser } from '../enum/Browser';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { Os } from '../enum/Os';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface, PlayerDomProxyInterface } from '../iface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { LogAwareMediator } from './LogAwareMediator';


// screenfull publishes a type declaration, but for some 
// reason the only property on it is 'isEnabled'.
interface ScreenfullInterface {
    isEnabled: boolean;
    isFullscreen: boolean;
    raw: StrAnyDict;
    exit(): void;
    on(e: string, h: VoidFunction): void;
    off(e: string, h: VoidFunction): void;
    request(l: HTMLElement): void;
    toggle(): void;
    onchange(e: string, h: VoidFunction): void;
    onerror(e: string, h: VoidFunction): void;
}

interface IosFsPropsInterface extends StrAnyDict {
    ENTER_EVENT: string;
    EXIT_EVENT: string;
    ENTER_METHOD: string;
    EXIT_METHOD: string;
    SUPPORTED: string;
}

export class FullscreenMediator extends LogAwareMediator {

    private isFullscreen: boolean = false;
    private fsElement: HTMLElement = null;
    private enabled: boolean = false;
    private pIsAndroidFirefox: boolean | null = null;
    private isIos: boolean = false;
    private fsMgr: ScreenfullInterface = null;
    private changeHandler: (e?: Event) => void;
    private useCustomFullscreen = true;

    private iosPropNames: IosFsPropsInterface = {
        ENTER_EVENT: 'webkitbeginfullscreen',
        EXIT_EVENT: 'webkitendfullscreen',
        ENTER_METHOD: 'webkitEnterFullscreen',
        EXIT_METHOD: 'webkitExitFullscreen',
        SUPPORTED: 'webkitSupportsFullscreen',
    };

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
        this.fsMgr = <ScreenfullInterface>screenfull;
        this.changeHandler = (e?: Event) => this.hChange(e);
    }

    set fullscreenElement(el: HTMLElement) {
        if (!(el instanceof HTMLElement)) {
            return;
        }
        // VTG-2222: support alt fs element for non-mobile
        if (!this.systemService.isIos && !this.systemService.isAndroid) {
            this.fsElement = el;
        }
    }

    get fullscreenElement(): HTMLElement {
        return this.fsElement;
    }

    override onRemove(): void {
        if (this.isIos && this.fsElement) {
            this.fsElement.removeEventListener(this.iosPropNames.ENTER_EVENT, this.changeHandler);
            this.fsElement.removeEventListener(this.iosPropNames.EXIT_EVENT, this.changeHandler);
        }

        // Service #185: 'off' method undefined on iPhone (cause ?)
        if (this.fsMgr && Util.isFunction(this.fsMgr.off)) {
            this.fsMgr.off('change', this.changeHandler);
        }

        this.fsElement = null;
        this.fsMgr = null;

        super.onRemove();
    }

    enter(): void {
        if (!this.enabled) { return; }

        if (this.useCustomFullscreen) {
            this.isFullscreen = true;
            this.sendFullscreenNotification();

            return;
        }

        this.isIos ? this.enterFsIos() : this.fsMgr.request(this.fsElement);
    }

    exit(): void {
        if (!this.enabled) { return; }

        if (this.useCustomFullscreen) {
            this.isFullscreen = false;
            this.sendFullscreenNotification();

            return;
        }

        this.isIos ? this.exitFsIos() : this.fsMgr.exit();
    }

    isFullScreenPermitted(): boolean {
        return this.enabled;
    }

    isFullScreen(): boolean {
        return this.isFullscreen;
    }

    ///////////////////////////////////
    // PRIVATE / MVC

    private get isAndroidFirefox(): boolean {
        if (this.pIsAndroidFirefox === null) {
            const sysSvc = this.systemService;
            this.pIsAndroidFirefox = sysSvc.os === Os.ANDROID && sysSvc.browser === Browser.FIREFOX;
        }
        return this.pIsAndroidFirefox;
    }

    handleNotification(notification: NotificationInterface): void {
        const n = notification.name;

        if (n === NotificationName.VIDEO_LOADED_METADATA) {
            this.handleMetadataLoaded();
            return;
        }

        if (!this.enabled) { return; }

        const enter = n === NotificationName.ENTER_FULLSCREEN_REQUEST;
        enter ? this.enter() : this.exit();
    }

    override listNotificationInterests(): string[] {
        return [
            NotificationName.VIDEO_LOADED_METADATA,
            NotificationName.ENTER_FULLSCREEN_REQUEST,
            NotificationName.EXIT_FULLSCREEN_REQUEST
        ];
    }

    private handleMetadataLoaded(): void {
        this.enabled = this.enabled || this.webkitIosEnabled();
        this.enabled && this.sendNotification(NotificationName.FULLSCREEN_AVAILABLE, null);
    }

    private hChange(e?: Event): void {
        this.isFullscreen = this.isIos ? this.checkWebkitIsoState(e) : (<any>screenfull).isFullscreen;

        if (this.isAndroidFirefox) {
            // controls must be explicitly set for this case
            this.isFullscreen && this.fsElement.setAttribute('controls', '');
            !this.isFullscreen && this.fsElement.removeAttribute('controls');
        }

        this.sendFullscreenNotification();
    }

    private sendFullscreenNotification() {
        this.sendNotification(
            NotificationName.FULLSCREEN_CHANGE,
            { isFullscreen: this.isFullscreen },
            NotificationType.INTERNAL
        );
    }

    private enterFsIos(): void {
        (<any>this.fsElement)[this.iosPropNames.ENTER_METHOD]();
    }

    private exitFsIos(): void {
        (<any>this.fsElement)[this.iosPropNames.EXIT_METHOD]();
    }

    private webkitIosEnabled(): boolean {
        return this.systemService.isIos && (<any>this.fsElement)[this.iosPropNames.SUPPORTED];
    }

    private checkWebkitIsoState(e: Event): boolean {
        return e.type === this.iosPropNames.ENTER_EVENT;
    }

    override onRegister(): void {
        this.useCustomFullscreen = !(this.getModel(ModelName.PlayerOptions) as PlayerOptionsInterface).useNativeFullscreen;

        if (this.useCustomFullscreen) {
            this.enabled = true;
            return;
        }

        const sys = this.systemService,
            dp = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);

        // On iOS and Android devices, use native fullscreen; i.e,
        // the video element itself will be the fullscreen element.

        // Android has Fullscreen API support, so we can use 
        // 'screenfull' to manage fullscreen state.

        // iOS has mixed support for Fullscreen API, but it's easiest
        // to just manage it separately, as if it doesn't, and leave
        // 'screenfull' out of any iOS FS management.

        // Note that this approach forces all mobile devices - even those that do
        // have FS API support (Android phones and tablets, iPad) - to use native 
        // controls in fullscreen mode, and this is intended (as of may 2020, r 0.10.0).
        if (sys.isIos || sys.isAndroid) {
            this.isIos = sys.isIos;
            this.fsElement = <HTMLElement>(<unknown>dp.getVideo());
        }
        else {
            // otherwise, the main container is used as the fullscreen element
            this.fsElement = dp.getMain();
        }

        if (!this.isIos && this.fsElement && this.fsMgr.isEnabled) {
            this.enabled = true;
            this.fsMgr.on('change', this.changeHandler);
        }
        else if (this.fsElement && this.isIos) {
            this.fsElement.addEventListener(this.iosPropNames.ENTER_EVENT, this.changeHandler);
            this.fsElement.addEventListener(this.iosPropNames.EXIT_EVENT, this.changeHandler);
        }
    }
}
