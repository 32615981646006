import { EmitterInterface } from "../iface/EmitterInterface";
import { EventInterface } from "../iface/EventInterface";
import { StrAnyDict } from "../iface/StrAnyDict";

export class CoreEvent implements EventInterface {

    timeStamp: number;
    data: StrAnyDict;
    target: EmitterInterface;
    type: string;

    /**
     * Custom event class
     */
    constructor(type: string, target: EmitterInterface, data: StrAnyDict) {
        this.timeStamp = Date.now();
        this.data = data;
        this.target = target;
        this.type = type;
    }
}
