export class Transformer {

    protected constructor() { }

    static clampValue(val: any, min: number, max: number): number | null {
        if (typeof val !== "number") {
            return null;
        }
        return Math.max(min, Math.min(val, max));
    }

    static lowerCase(val: any): any {
        return typeof val === "string" ? val.toLowerCase() : val;
    }

    static strToNum(val: any): number | any {
        if (typeof val === 'number') {
            return val;
        }
        if (typeof val === 'string') {
            let pf = parseFloat(val);
            return isNaN(pf) ? val : pf;
        }

        return val;
    }
}
