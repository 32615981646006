import { AppResources } from '../app/AppResources';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { CmsDataRetrieverOptions, NotificationInterface } from '../iface';
import { DataTransformerConfigInterface } from '../iface/DataTransformerConfigInterface';
import { DataTransformersConfigInterface } from '../iface/DataTransformersConfigInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { CmsDataRetriever } from './command-helpers/CmsDataRetriever';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class RetrieveResourceCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        const r: ResourceConfigurationInterface = notification.body.resource,
            loc = r && r.location,
            cms: string = (loc && loc.cms) || null,
            cfg: DataTransformerConfigInterface = this.getConfig(cms),
            valid = cfg && ((cfg.uriTemplate && loc.tokenMap) || loc.cmsUri);

        if (valid) {
            const pOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
            const opts: CmsDataRetrieverOptions = {
                uriTemplate: cfg.uriTemplate,
                tokenMap: loc && loc.tokenMap || null,
                url: loc && loc.cmsUri || null,
                responseType: cfg.responseType,
                errorRecovery: pOpts.networkErrorRecovery,
                timeout: cfg.timeout || pOpts.networkTimeout,
                callback: (doc: XMLDocument, err: StrAnyDict | null) => {
                    if (err) {
                        this.log(LogLevel.ERROR, err.status, err.message);
                        this.sendNotification(NotificationName.RESOURCE_ERROR, {
                            code: ErrorCode.SELECTOR_CALL_ERROR,
                            message: err.message,
                            fatal: true,
                        }, NotificationType.INTERNAL);

                        return;
                    }

                    this.sendNotification(NotificationName.TRANSFORM_RESOURCE, { resource: r, data: doc }, NotificationType.INTERNAL);
                }
            };

            new CmsDataRetriever(opts);
        }
        else {
            this.log(LogLevel.ERROR, `RetrieveResourceCommand: ${AppResources.messages.UNEXPECTED_CONDITION}; CMS config error.`);
        }
    }

    getConfig(cms: string): DataTransformerConfigInterface | null {
        const playerOptions = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions),
            txDefs: DataTransformersConfigInterface = playerOptions.dataTransformers;

        if (cms && txDefs && txDefs[cms] && txDefs[cms].objectRef && txDefs[cms].responseType) {
            return txDefs[cms];
        }

        return null;
    }
}
