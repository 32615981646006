import { Util } from '../core/Util';
import { AdapterRole } from '../enum/AdapterRole';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { ProxyName } from '../enum/ProxyName';
import { AdapterConfigInterface } from '../iface/AdapterConfigInterface';
import { AdapterContextInterface } from '../iface/AdapterContextInterface';
import { AdapterDelegateFactory } from '../iface/AdapterDelegateFactory';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { Proxy } from '../mvc/Proxy';
import { PluginMediator } from '../view/PluginMediator';
import { ModelCollectionProxy } from './ModelCollectionProxy';
import { PerformanceProxy } from './PerformanceProxy';
import { TextTrackProxy } from './TextTrackProxy';


export class AdapterProxy extends Proxy {

  private adapterMap: Record<string, AdapterConfigInterface<any, any>> = {};

  constructor(data?: any) {
    super(ProxyName.AdapterProxy, data);
  }

  override onRemove() {
    this.adapterMap = null;
    super.onRemove();
  }

  registerAdapter<A, D>(adapterConfig: AdapterConfigInterface<A, D>): void {
    const { id } = adapterConfig;
    this.adapterMap[id] = adapterConfig;
  }

  retrieveAdapter<A, D>(id: string): AdapterConfigInterface<A, D> {
    return this.adapterMap[id];
  }

  removeAdapter(id: string): void {
    delete this.adapterMap[id];
  }

  getAdaptersByRole<A, D>(role: string): AdapterConfigInterface<A, D>[] {
    return Util.values<AdapterConfigInterface<A, D>>(this.adapterMap).filter(config => Util.includes(config.role, role));
  }

  private createContext<D>(resource: ResourceConfigurationInterface, getDelegate: AdapterDelegateFactory<D>): AdapterContextInterface<D> {
    const services = (this.facade.retrieveMediator(MediatorName.PLUGIN_MEDIATOR) as PluginMediator).createPluginServices();
    const { dom, system } = services;
    const modelCollection = this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) as ModelCollectionProxy;
    const presentationState = modelCollection.getModel(ModelName.PresentationState) as PresentationStateInterface;
    const textTrackProxy = this.facade.retrieveProxy(ProxyName.TextTrackProxy) as TextTrackProxy;
    const performanceProxy = this.facade.retrieveProxy(ProxyName.PerformanceProxy) as PerformanceProxy;

    return Object.assign(services, {
      resource,
      video: (system.isWebMaf) ? system.webMafPlayer : dom.video,
      container: dom.main,
      autoplay: presentationState.isAutoplay,
      muted: presentationState.isMuteAtPlayStart,
      performanceSettings: performanceProxy,
      textTrackSettings: textTrackProxy,
      getDelegate,
    });
  }

  async createAdapter<A, D>(role: AdapterRole, resource: ResourceConfigurationInterface, delegateFactory: AdapterDelegateFactory<D>): Promise<A> {
    const context = this.createContext<D>(resource, delegateFactory);
    const adapters = this.getAdaptersByRole<A, D>(role);
    const { id, options = {} } = context.resource[role]?.adapter || {};
    const list = (id) ? adapters.filter(adapter => adapter.id === id) : adapters;

    for (const config of list) {
      const adapter = await config.factory(context, Util.merge({}, config.options, options));
      if (adapter) {
        return adapter;
      }
    }

    throw new Error(`Could not find adapter for role: ${role}`);
  }
}
