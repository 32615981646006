import { AppResources } from '../app/AppResources';
import { buildInfo } from '../core/BuildInfo';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { NativePlugin } from '../enum/NativePlugin';
import { QualityCategory } from '../enum/QualityCategory';
import { SystemServiceInterface } from '../iface';
import { ConfigInterface } from '../iface/ConfigInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { PlaylistProxy } from '../model/PlaylistProxy';
import { SystemService } from '../service/SystemService';
import { QualityMap } from './QualityMap';


export class Utils {

    static getMinAndMaxValuesForCategory(list: QualityInterface[], category: string): Record<string, number> {
        let minIndex = Infinity;
        let maxIndex = 0;
        let minBitrate = Infinity;
        let maxBitrate = 0;

        list.forEach(q => {
            if (q.category.indexOf(category) >= 0) {
                maxIndex = Math.max(maxIndex, q.index);
                minIndex = Math.min(minIndex, q.index);
                maxBitrate = Math.max(maxBitrate, q.bitrate);
                minBitrate = Math.min(minBitrate, q.bitrate);
            }
        });

        return { maxIndex, minIndex, minBitrate, maxBitrate };
    }

    static getQualityCategoryForVideoHeight(ht: number): QualityCategory {
        const m = QualityMap;

        if (ht >= m[QualityCategory.UHD_8K]) return QualityCategory.UHD_8K;

        if (ht >= m[QualityCategory.UHD_4K]) return QualityCategory.UHD_4K;

        if (ht >= m[QualityCategory.UHD]) return QualityCategory.UHD;

        if (ht >= m[QualityCategory.HD]) return QualityCategory.HD;

        return QualityCategory.SD;
    }

    static classFromQualifiedName(qName: string): any | null {
        if (!Util.isString(qName)) {
            return qName;
        }

        const g = SystemService.getInstance().global;

        let a = qName.split('.'),
            o = <StrAnyDict>g,
            len = a.length,
            i, n;

        if (a[0] === 'window') {
            a = a.slice(1);
            len = a.length;
        }

        for (i = 0; i < len; i++) {
            n = a[i];
            if (o[n] === undefined) {
                Logger.error(`Util.classFromQualifiedName: '${qName}' is undefined.`);
                return null;
            }
            o = o[n];
        }

        return o;
    }

    static serializeConfigSnapshot(playerOptions: PlayerOptionsInterface, playlist: PlaylistProxy, system: SystemServiceInterface, spacing: number = 0) {
        const r = playlist.currentResource ? JSON.parse(JSON.stringify(playlist.currentResource)) : {};

        // remove props with null and NaN values
        const rmNullAndNan = (p: any): any => {
            const t = typeof p;
            const del = '\0';

            if (p && t == 'object') {
                for (const q in p) {
                    const v = rmNullAndNan(p[q]);
                    if (v === del) delete p[q];
                    else p[q] = v;
                }
                return p;
            }
            else {
                return p === null || (t === 'number' && isNaN(p)) ? del : p;
            }
        };
        rmNullAndNan(r);

        // mod player options
        const poStr = JSON.stringify(playerOptions, (key, value) => (value instanceof HTMLElement) ? undefined : value);
        const po = JSON.parse(poStr);

        delete po.dataTransformers;
        po.container = null;

        const out: ConfigInterface = {
            buildInfo,
            playerOptions: po,
            resource: r,
            system: system.info,
        };

        return JSON.stringify(out, null, spacing);
    }

    static getNativePluginConfig(plugin: NativePlugin, playerOptions: PlayerOptionsInterface) {
        const base = AppResources.nativePluginConfig[plugin];

        if (!base) {
            return null;
        }

        const config = Object.assign({}, base);
        const url = playerOptions.overrides?.dependencies?.[plugin] || config.url;
        config.url = url;
        return config;
    }
}
