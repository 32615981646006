import { Util } from '../../core/Util';
import { LogLevel } from '../../enum/LogLevel';
import { ModelName } from '../../enum/ModelName';
import { ProxyName } from '../../enum/ProxyName';
import { ServiceName } from '../../enum/ServiceName';
import { NotificationInterface, SystemServiceInterface } from '../../iface';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { AdapterProxy } from '../../model/AdapterProxy';
import { LocalizationProxy } from '../../model/LocalizationProxy';
import { ModelCollectionProxy } from '../../model/ModelCollectionProxy';
import { PerformanceProxy } from '../../model/PerformanceProxy';
import { PlayerDomProxy } from '../../model/PlayerDomProxy';
import { PlaylistProxy } from '../../model/PlaylistProxy';
import { TextTrackProxy } from '../../model/TextTrackProxy';
import { ModelCollection } from '../../model/vo/ModelCollection';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepModelCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System),
            mdlColl = new ModelCollection(),
            mdlCollProxy = new ModelCollectionProxy(ProxyName.ModelCollectionProxy, mdlColl),
            playerOptions = <PlayerOptionsInterface>notification.body.playerOptions;

        // Model collection Proxy
        this.facade.registerProxy(mdlCollProxy);
        this.setPlayerOptions(mdlCollProxy, playerOptions);

        // Performance Proxy
        this.facade.registerProxy(new PerformanceProxy(ProxyName.PerformanceProxy));

        // Localization Proxy
        const loc = new LocalizationProxy(ProxyName.LocalizationProxy, playerOptions.language);
        this.facade.registerProxy(loc);

        // Text Track Proxy
        this.facade.registerProxy(new TextTrackProxy(ProxyName.TextTrackProxy, { native: playerOptions.renderTextTrackNatively, language: loc.language }));

        // DOM Proxy
        !sys.isWebMaf && this.createDomProxy(mdlCollProxy);

        // Adapter Proxy
        this.facade.registerProxy(new AdapterProxy());

        // Playlist Proxy
        this.facade.registerProxy(new PlaylistProxy(ProxyName.Playlist));

        this.log(LogLevel.DEBUG, 'Model prepared');
    }

    private setPlayerOptions(proxy: ModelCollectionProxy, opts: PlayerOptionsInterface): void {
        const pOptsMdl = <PlayerOptionsInterface>proxy.getModel(ModelName.PlayerOptions);

        for (let q in opts) {
            // @ts-ignore
            !Util.isUndefined(pOptsMdl[q]) && (pOptsMdl[q] = opts[q]);
        }
    }

    private createDomProxy(proxy: ModelCollectionProxy): void {
        const mdl = <PlayerDomInterface>proxy.getModel(ModelName.PlayerDom),
            domCollProxy = new PlayerDomProxy(ProxyName.PlayerDomProxy, mdl);

        this.facade.registerProxy(domCollProxy);
    }
}
