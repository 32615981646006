/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import { CommandInterface, NotificationInterface } from "../iface";
import { Notifier } from "./Notifier";

export abstract class AbstractMacroCommand extends Notifier implements CommandInterface {

    private subCommands: any[] = null;

    protected constructor() {
        super();
        this.subCommands = [];
        this.initializeMacroCommand();
    }

    /**
     * Subclasses override this method to initialize the sub-commands list;
     * sub-commands may be AbstractMacroCommands or AbstractSimpleCommands
     * @example
     *     initializeMacroCommand: function () {
     *         this.addSubCommand(SomeCommand);
     *         this.addSubCommand(OtherCommand);
     *         this.addSubCommand(YetAnotherCommand);
     *     };
     */
    abstract initializeMacroCommand(): void

    addSubCommand(commandClassRef: any): void {
        this.subCommands.push(commandClassRef);
    }

    execute(notification: NotificationInterface): void {
        let cmdClass, cmd;

        while (this.subCommands.length > 0) {
            cmdClass = this.subCommands.shift();
            cmd = new (<any>cmdClass)();
            cmd.initializeNotifier(this.multiCoreKey);
            cmd.execute(notification);
        }
    }
}






