import { Util } from '../core/Util';
import { ServiceName } from '../enum/ServiceName';
import { KeyCommandDef, NotificationInterface, SystemServiceInterface } from '../iface';
import { LogAwareMediator } from './LogAwareMediator';


export abstract class AbstractKeyCommandMediator extends LogAwareMediator {

    protected defs: KeyCommandDef[] = [];

    private pScope: HTMLElement | Document = null;
    private keyUpHandler: EventListenerOrEventListenerObject = null;
    private keyDownHandler: EventListenerOrEventListenerObject = null;
    private pDisabled: boolean = false;
    private suppressed: boolean = false;
    private scopeActive: boolean | null = null;

    constructor(name: string) {
        super(name);
    }

    override onRemove() {
        this.activateScope(false);
        this.pScope = null;
        const mt = function (e: KeyboardEvent) { };
        this.keyUpHandler = mt;
        this.keyDownHandler = mt;
    }

    get disabled(): boolean {
        return this.suppressed || this.pDisabled;
    }

    set scope(scp: HTMLElement | Document) {
        if (this.suppressed) {
            return;
        }

        if (this.pScope && this.scopeActive) {
            this.activateScope(false);
        }

        this.pScope = scp;

        const active = !!this.scopeActive || this.scopeActive === null;

        active && this.activateScope(true);
    }

    registerCommandDef(cmdDef: KeyCommandDef) {
        if (this.suppressed) { return; }

        this.defs.push(cmdDef);
    }

    disable() {
        if (this.suppressed) { return; }
        this.scopeActive && this.activateScope(false);
        this.pDisabled = true;
    }

    enable() {
        if (this.suppressed) { return; }
        !this.scopeActive && this.activateScope(true);
        this.pDisabled = false;
    }

    protected abstract hKeyUp(e: KeyboardEvent): void;

    protected abstract hKeyDown(e: KeyboardEvent): void;

    validatePrimaryKey(def: KeyCommandDef, e: KeyboardEvent): boolean {
        const k = e.key,
            kl = Util.isString(k) && k.toLowerCase(),
            kc = e.keyCode;

        if (!k && !kc) { return false; }

        const hasKey = def.primaryKey === k || def.primaryKey === kl || def.keyCode === kc;

        return hasKey;
    }

    getCommand(e: KeyboardEvent, checkModifiers: boolean = true): KeyCommandDef | null {
        let i = this.defs.length,
            def = null,
            mods, d;

        while (i--) {
            d = this.defs[i];
            if (this.validatePrimaryKey(d, e)) {
                mods = !checkModifiers || this.hasRequiredModifiers(d, e);
                if (mods) {
                    def = d;
                    break;
                }
            }
        }

        return def;
    }

    /**
     * Checks if required modifier keys are pressed.
     *
     * Using bitwise XOR; any mismatch will yield a '1'.
     * e.g. 1 ^ 1 = 0;  1 ^ 0 = 1; 0 ^ 0 = 0
     * 
     * Note that JS allows booleans in XOR expressions, but TS doesn't,
     * so bool values are converted to 1 / 0
     */
    hasRequiredModifiers(def: KeyCommandDef, event: KeyboardEvent): boolean {
        const c1 = !!((def.requireCtrl ? 1 : 0) ^ (event.ctrlKey ? 1 : 0)),
            c2 = !!((def.requireShift ? 1 : 0) ^ (event.shiftKey ? 1 : 0)),
            c3 = !!((def.requireMeta ? 1 : 0) ^ (event.metaKey ? 1 : 0));

        // each of the above must be FALSE to pass test
        return !c1 && !c2 && !c3;
    }

    activateScope(flag: boolean): void {
        if (!this.pScope || this.suppressed) {
            return;
        }

        const m = flag ? 'addEventListener' : 'removeEventListener';
        this.pScope[m]("keyup", this.keyUpHandler);
        this.pScope[m]("keydown", this.keyDownHandler);

        this.scopeActive = flag;
    }

    handleNotification(notification: NotificationInterface): void {
        // no impl
    }

    override onRegister(): void {
        super.onRegister();

        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        if (!sys.document || sys.isTv || sys.isWebMaf) {
            this.suppressed = true;
            return;
        }

        this.keyDownHandler = (e: KeyboardEvent) => this.hKeyDown(e);
        this.keyUpHandler = (e: KeyboardEvent) => this.hKeyUp(e);
    }
}
