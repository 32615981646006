import { Emitter } from '../core/Emitter';
import { StrAnyDict } from '../iface/StrAnyDict';

export class RecoveryEnabledRequest extends Emitter {

    private retryCount: number = 0;
    private limit: number = 0;
    private retryIntervals: number[] | null = null;

    constructor(options: StrAnyDict) {
        super(options);

        const er = this.opts.errorRecovery;

        if (er && !isNaN(er.retryAttempts) && er.retryAttempts > 0) {
            this.limit = er.retryAttempts; 
        }

        if (er && er.retryIntervals && Array.isArray(er.retryIntervals)) {
            this.retryIntervals = er.retryIntervals; 
        }
    }

    protected shouldRetry(): boolean {
        return this.limit && !this.atLimit;
    }

    protected incrementAttempts(): void {
        this.retryCount++;
    }

    protected get retryLimit(): number {
        return this.limit;
    }

    protected get atLimit(): boolean {
        return this.retryCount == this.limit;
    }

    protected get retryInterval(): number {
        return (this.retryIntervals && this.retryIntervals[this.retryCount - 1]) || this.retryCount * 300;
    }
}