import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { EventInterface } from '../iface/EventInterface';
import { LiveStreamInfoInterface } from '../iface/LiveStreamInfoInterface';
import { PlaybackAdapterConfigInterface } from '../iface/PlaybackAdapterConfigInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { BasePlaybackAdapter } from '../playback/adapter/BasePlaybackAdapter';
import { PlaybackAdapterEvents } from '../playback/enum/PlaybackAdapterEvents';
import { PlaybackAdapterType } from '../playback/enum/PlaybackAdapterType';
import { VideoSurfaceEvents } from '../playback/enum/VideoSurfaceEvents';
import { MediaPlayer, Quality } from './TwitchInterface';
import { TwitchOverrides } from './TwitchOverrides';


export class TwitchLowLatencyAdapter extends BasePlaybackAdapter {

    private MAX_BITRATE_CLEAR_VALUE: number = 100000000;
    protected override pType = PlaybackAdapterType.TWITCH_LOW_LATENCY;

    private twitch: MediaPlayer = (<any>window).IVSPlayer;
    private player!: MediaPlayer;

    private autoMaxBitrate: number = Number.POSITIVE_INFINITY;

    private twitchEventMap = [
        {
            type: this.twitch.PlayerState.READY,
            callback: (e: any) => this.onReady(e)
        },
        {
            type: this.twitch.PlayerEventType.QUALITY_CHANGED,
            callback: (e: any) => this.onQualityChange(e)
        },
        {
            type: this.twitch.PlayerEventType.ERROR,
            callback: (e: any) => this.onError(e)
        },
        {
            type: this.twitch.PlayerEventType.AUDIO_BLOCKED,
            callback: (e: any) => this.onError(e)
        },
        {
            type: this.twitch.PlayerEventType.PLAYBACK_BLOCKED,
            callback: (e: any) => this.onError(e)
        },
        // {
        //     type: this.twitch.PlayerEventType.REBUFFERING,
        //     callback: (e: any) => this.onRebuffering(e)
        // },
        // {
        //     type: this.twitch.PlayerEventType.METADATA,
        //     callback: (e: any) => this.onMetadata(e)
        // }
    ];

    constructor(config: PlaybackAdapterConfigInterface) {
        super(config);
        this.logger.log(LogLevel.INFO, 'TwitchLowLatencyAdapter created');
    }

    ////////////////////
    //Public Methods
    ////////////////////
    override initialize(): void {
        super.initialize();

        this.player = this.twitch.create();
        this.player.attachHTMLVideoElement(this.videoSurface.video);
        this.player.setAutoplay(false);

        const logLevel = this.enableLogger ? 'debug' : 'error';
        this.player.setLogLevel(logLevel);

        const o: TwitchOverrides = this.config.resource.overrides?.twitch || {};
        const rebufferToLive = Util.isBoolean(o.rebufferToLive) ? o.rebufferToLive : true;
        this.player.setRebufferToLive(rebufferToLive);

        this.addEvents(this.player, this.twitchEventMap);

        !isNaN(this.playback.abr.maxBitrate) && (this.maxBitrate = this.playback.abr.maxBitrate);
        !isNaN(this.playback.abr.minBitrate) && (this.minBitrate = this.playback.abr.minBitrate);//just to get logging that it is not avail.

        this.logger.log(LogLevel.INFO, 'Twitch Version', this.player.getVersion());
    }

    override seek(position: number): void {
        return; //no reason to allow seeking with twitch ull.
        //this.player.seekTo(position);
    }

    override destroy(): Promise<void> {
        this.removeEvents(this.player, this.twitchEventMap);
        //const x = (<Worker>this.player.worker).terminate() // they are looking into making sure the worker is terminated properly. I raised issue. 
        this.player.delete();
        this.player = null;
        this.twitch = null;
        return super.destroy();
    }

    override play(): void {
        this.player.play();
    }

    ////////////////////
    //Accessors
    ////////////////////
    override get duration(): number {
        return this.player.getDuration();
    }

    override get time(): number {
        return this.player.getPosition();
    }

    set autoQualitySwitching(value: boolean) {
        this.player.setAutoSwitchQuality(value);
    }
    get autoQualitySwitching(): boolean {
        return this.player.getAutoSwitchQuality();
    }

    set currentIndex(index: number) {
        const q = this.player.getQualities();
        this.player.setQuality(q[index]);
    }
    get currentIndex(): number {
        const q: Quality = this.player.getQuality();
        return Util.getIndexForBitrate(this.manifestQualities, q.bitrate, false);
    }

    set minBitrate(value: number) {
        this.logger.log(LogLevel.INFO, AppResources.messages.ABR_MIN_BITRATE_RESTRICTION_UNAVAILABLE);
    }

    get minBitrate(): number {
        return this.manifestQualities[0].bitrate;
    }

    set maxBitrate(value: number) {
        this.autoMaxBitrate = !isNaN(value) ? value : this.MAX_BITRATE_CLEAR_VALUE;
        this.player.setAutoMaxBitrate(this.autoMaxBitrate);
    }

    get maxBitrate(): number {
        const index = !isNaN(this.autoMaxBitrate) ?
            Util.getIndexForBitrate(this.manifestQualities, this.autoMaxBitrate, false) :
            this.manifestQualities.length - 1;

        return this.manifestQualities[index].bitrate;
    }

    get manifestQualities(): QualityInterface[] {
        const qualities = this.player.getQualities();
        return <QualityInterface[]>qualities.sort((a: Quality, b: Quality) => a.bitrate - b.bitrate).map(this.normalizeQuality);
    }

    get liveStreamInfo(): LiveStreamInfoInterface {
        const details: LiveStreamInfoInterface = this.liveStreamInfoVO;
        if (this.pIsLiveStream) {
            details.isPlayingLive = true;
            details.relativeDuration = this.duration;
            details.relativeTime = this.time;
            details.liveEdgeOffset = this.player.getLiveLatency();
        }
        return details;
    }

    ////////////////////
    // Protected 
    ////////////////////
    protected override onVideoSurfaceEvent(e: EventInterface): void {
        switch (e.type) {
            case VideoSurfaceEvents.DURATION_CHANGE:
                this.pIsLiveStream = this.duration === Infinity;
                break;
        }
        super.onVideoSurfaceEvent(e);
    }

    protected override loadMediaUrl(): Promise<void> {
        return Promise.resolve().then(() => {
            this.player.load(this.mediaUrl);
        });
    }

    ////////////////////
    // Events
    ////////////////////
    private onReady(e: any): void {
        this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, { profile: this.manifestQualities });
    }

    private onQualityChange(e: any): void {
        const index = Util.getIndexForBitrate(this.manifestQualities, e.bitrate, false);
        this.checkAbrConstraints(Util.getIndexForBitrate(this.manifestQualities, this.maxBitrate, false));
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: index });

    }
    // private onMetadata(e: any): void {
    //     //text tracks have cues as well 
    //     console.log('onMetadata', e);
    // }

    // private onRebuffering(e: any): void {
    //     console.log('onRebuffering', e);
    // }

    private onError(e: any = {}): void {
        switch (e.type) {
            case this.twitch.ErrorType.NETWORK_IO:
            case this.twitch.ErrorType.NETWORK:
            case this.twitch.ErrorType.NOT_AVAILABLE:
                this.throwError(ErrorCode.TWITCH_NETWORK_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_NETWORK_ERROR} : ${e.message}`, e);
                break;

            case this.twitch.ErrorType.INVALID_DATA:
                this.throwError(ErrorCode.TWITCH_PARSE_ERROR,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.message}`, e);
                break;

            case this.twitch.ErrorType.NOT_SUPPORTED:
                this.throwError(ErrorCode.TWITCH_SRC_NOT_SUPPORTED,
                    `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.message}`, e);
                break;

            default:
                switch (e.source) {
                    case this.twitch.ErrorSource.DECODER:
                    case this.twitch.ErrorSource.RENDERER:
                    case this.twitch.ErrorSource.SEGMENT:
                        this.throwError(ErrorCode.TWITCH_MEDIA_ERROR,
                            `${AppResources.messages.FATAL_PLAYBACK_MEDIA_ERROR} : ${e.message}`, e);
                        break;

                    default:
                        this.throwError(ErrorCode.UNSPECIFIED_TWITCH_ERROR, e.message, e, false);
                }
        }
    }
    ////////////////////
    // Private
    ////////////////////    
}
