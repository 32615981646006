export class Validator {

    protected constructor() { }

    static checkType(val: any, type: string | string[], allowNull?: boolean): boolean {
        if (typeof type == "string") {
            type = [type];
        }
        return allowNull && val === null ? true : type.some(t => typeof val === t);
    }

    static notEmpty(obj: any): boolean {
        return !(
            obj === null || obj === undefined ||
            (Array.isArray(obj) && obj.length === 0) ||
            ("object" === typeof obj && obj.constructor === Object && Object.keys(obj).length === 0) ||
            "string" === typeof obj && obj === ''
        );
    }

    static isArray(obj: any): boolean {
        return Array.isArray(obj);
    }

    static validNumber(val: any, defVal: number) {
        return typeof val !== 'number' ? defVal : val;
    }

    static isTypedArray(obj: any, type: string): boolean {
        if (this.isArray(obj) && this.notEmpty(obj)) {
            let i = obj.length;

            while (i--) {
                if (typeof obj[i] !== type) {
                    return false
                }
            }

            return true;
        }

        return false;
    }

    static isType(value: any, type: any): boolean {
        return value instanceof type;
    }

    static isPositiveNumber(value: any): boolean {
        return typeof value === 'number' && value >= 0;
    }
}
