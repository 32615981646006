import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { AudioTrackInterface } from '../../iface/AudioTrackInterface';
import { AudioTracksInterface } from '../../iface/AudioTracksInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { VideoSurfaceInterface } from '../../iface/VideoSurfaceInterface';
import { Timer } from '../../util/Timer';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { PlayStation } from '../enum/PlayStation';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';


export class WebMafVideoSurface extends Emitter implements VideoSurfaceInterface {

    private timer: Timer;
    private onTicHandler: EventHandler = (e: EventInterface) => this.onTic(e);

    private pTime: number = NaN;
    private pDuration: number = NaN;
    private pVideo: any;
    private pMetrics: any;
    private pState: string;
    private pStatePreSeek: string; //artificial state for buffering when seeking.Trap the current state at seeking , and set it back to that state at seeked.     
    private abrProfileCount: number = 0;
    private pMasterBitrateProfile: StrAnyDict[] = [];
    private logger: LoggerInterface;
    private audioTrackInfo: AudioTracksInterface;
    private audioTrackUpdated: boolean = false;
    private pStartTime = NaN;
    private global: any;
    private config: PlaybackAdapterConfigInterface;
    private debugMode: boolean = false;
    private fatalErrorInProgress: boolean = false;
    private cea608708Detected: boolean = false;
    private cea608708Enabled: boolean = false;



    ///state
    private seeking: boolean = false;

    constructor(config: PlaybackAdapterConfigInterface) {
        super();

        this.logger = config.logger;

        this.timer = new Timer(500);
        this.timer.on(Timer.TIC_EVENT, this.onTicHandler);
        this.config = config;
        this.logger.log(LogLevel.WARN, `${this.config}`); //TODO remove once id3 owner tag filtering is implemented. 
        this.global = config.system.global;
        //WebMaf Bindings
        this.pVideo = this.global.WM_videoPlayer;

        this.global.accessfunction = (json: any) => this.accessfunction(json);

        this.pMetrics = this.global.videometrics;
        this.pMetrics.onBitrateChange = (value: number) => this.onBitrateChange(value);

        // this.callExternalWebMafApi(
        //     '{"command":"setAdaptiveStreamingParameters",' +
        //     ' "bandwidthHistoryMaxEntries":10, ' +
        //     ' "bandwidthHistoryMinEntries": 1, ' +
        //     ' "bandwidthUtilisationPercentage": 90, ' +
        //     ' "segmentsBetweenSwitchUp": 2}'
        // );

        this.debugMode = config.playerOptions.overrides?.enableLowLevelStreamingLogs ? true : false;
        //@ts-ignore
        if (this.logger) {
            this.pVideo.TTY = (tty: any) => {
                this.logger.log(LogLevel.DEBUG, tty);
            };
            this.pVideo.TTYLevel = this.debugMode ? 3 : 1;
        }
    }

    override destroy() {
        this.callExternalWebMafApi('{"command":"stop"}');
        this.timer.off(Timer.TIC_EVENT, this.onTicHandler);
        super.destroy();
    }

    // NOTE: This function only exists to fullfil the interface requirements. This entire class will soon be removed.
    load(starTime?: number) {
        return Promise.resolve();
    }

    play(): Promise<void> {
        this.callExternalWebMafApi('{"command":"play"}');
        return Promise.resolve();
    }

    pause(): void {
        this.callExternalWebMafApi('{"command":"pause"}');
    }

    seek(position: number): Promise<void> {
        if (!this.seeking) {
            this.seeking = true;
            this.pStatePreSeek = this.pState;
            const pos = Math.floor(position); //webmaf will fail to seek with float.
            this.callExternalWebMafApi('{"command":"setPlayTime","playTime":' + pos + '}');
            this.logger.log(LogLevel.INFO, `seeking to ${pos}`);
            this.emit(VideoSurfaceEvents.SEEKING, { position: pos });
            this.pState = PlayStation.BUFFERING; // since we seeked, webmaf is delayed on buffering and does not change state if seek is too quick. 
        }
        return Util.eventsToPromise([{ events: [VideoSurfaceEvents.SEEKED], target: this }], [{ events: [VideoSurfaceEvents.ERROR], target: this }]);
    }

    addEvents(): void {
        //TODO fill out with constructor code so we can add events at controlled time.  
    }

    clearCue(): void {
        // noop
    }

    set startTime(value: number) {
        this.logger.log(LogLevel.INFO, `startTime set on webmaf ${value}`);
        this.pStartTime = value;
    }

    set volume(value: number) {//Ensemble does not control volume on webmaf, just lets TV. But you could.  
        this.callExternalWebMafApi('{"command":"setVolume", "volume":' + value + '}');
    }
    get volume(): number {
        return 1;
    }

    set muted(value: boolean) {//Ensemble does not control volume on webmaf, just lets TV. But you could.  
        this.callExternalWebMafApi('{"command": "setVolume", "volume": 0.0}');
    }
    get muted(): boolean {
        return false;
    }

    set src(value: string) {
        this.callExternalWebMafApi(value);
    }

    get video(): any {
        return this.pVideo;
    }

    get framerate(): number {
        return this.metrics.renderedFramerate;
    }

    get metrics(): StrAnyDict {
        return this.pMetrics;
    }

    get time(): number {
        return this.pTime;
    }

    get duration(): number {
        return this.pDuration;
    }

    get bufferLength(): number {
        return this.pVideo.bufferedRange;
    }

    get buffering(): boolean {
        return this.pState === PlayStation.BUFFERING;
    }

    get paused(): boolean {
        return this.pState === PlayStation.PAUSED;
    }

    get state(): string {
        return this.pState;
    }

    get masterBitrateProfile(): StrAnyDict[] {
        return this.pMasterBitrateProfile;
    }

    get bitrate(): number {
        return this.pMetrics.currentBitrate;
    }

    set startingBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `startingBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setVideoStartingBandwidth","bandwidth":' + value + '}');
    }

    set minBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `minBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setFixVideoRepresentations", "minBitrate":' + value + '}');
    }

    set maxBitrate(value: number) {
        this.logger.log(LogLevel.INFO, `maxBitrate set on webmaf ${value}`);
        this.callExternalWebMafApi('{"command":"setFixVideoRepresentations", "maxBitrate":' + value + '}');
    }

    set audioTrack(track: AudioTrackInterface) {
        const id = this.audioTrackInfo.tracks[track.index].id;
        this.audioTrackUpdated = true;
        this.callExternalWebMafApi('{"command":"setAudioTrack", "audioTrack":' + JSON.stringify(id) + '}');
    }

    set textTrackMode(mode: TextTrackMode) {
        const enable = mode !== TextTrackMode.DISABLED;
        if (this.cea608708Detected) {
            this.cea608708Enabled = enable;
            this.callExternalWebMafApi('{"command":"setClosedCaptions","enable": ' + enable + '}');
        }
        else {
            const id = enable ? 'en' : '';
            this.callExternalWebMafApi('{"command":"setSubtitleTrack","subtitleTrack":' + JSON.stringify(id) + ', "disablePreviousTrack":false }');
        }
        this.logger.log(LogLevel.INFO, `Text Track is enabled: ${enable}`);
    }

    set textTrack(track: TextTrack) {
        this.logger.log(LogLevel.WARN, 'Changing the text track language is not supported on PlayStation 4 at this time');
        //To support multi lang tracks use this and rework textTrackMode
        // this.callExternalWebMafApi('{"command":"setSubtitleTrack","subtitleTrack": ' + JSON.stringify(track.language) + '}');
        // this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, track);
    }

    set textTrackSrc(url: string) {
        this.logger.log(LogLevel.INFO, `Sidecar text is not supported on playstation ${url}`);
    }

    get textTracks(): TextTrack[] {
        return [];
    }

    private callExternalWebMafApi(command: string) {
        this.global.external.user(command);
    }

    private onTic(e: EventInterface) {

        this.callExternalWebMafApi('{"command":"getPlaybackTime"}');
        if (this.audioTrackUpdated) { // may be able to call this right after setting but since codec fails will not set. 
            this.audioTrackUpdated = false;
        }

        this.pMetrics.poll();
    }

    private respondToPlaybackTime(data: any) {
        /**
         * webmaf spits out bogus time value when user 
         * seeking quickly and we do not want to update time on that
         * or any value > duration.  Live we set to Number.POS_INFINITY... 
         */
        if (this.pTime !== data.elapsedTime && data.elapsedTime <= this.pDuration) { //SOMETIMES start at first segment length on live streams as time marches on

            this.pTime = data.elapsedTime;

            if (!this.seeking) {
                this.emit(VideoSurfaceEvents.TIME_UPDATE, { value: this.pTime });
            }

            if (this.seeking) {
                this.seeking = false;
                this.pState = this.pStatePreSeek;
                this.emit(VideoSurfaceEvents.SEEKED);
                //TODO -If paused we need to do one timeupdate event here to up post seek complete. calling '{"command":"getPlaybackTime"} does not seem to fire if paused. 
            }
        }
    }

    private onBitrateChange(value: number) {
        const videoBitrate = value;
        const index = Util.getIndexForBitrate(this.pMasterBitrateProfile, videoBitrate, false);
        this.logger.log(LogLevel.INFO, `bitrate change ${videoBitrate}`);
        this.emit(PlaybackAdapterEvents.ABR_QUALITY_LOADED, { index: index });
        //TODO can we event quality info changed with webmaf??
    }

    /**
     * Not supported by webmaf for any format besides smooth streaming. 
     */
    private parseAudioInfo(info: any): AudioTracksInterface {

        const rawTracks: [] = info.audioTracks.split(',');
        const channels: [] = info.audioNumChannels.split(',');
        const returnObj: AudioTracksInterface = { track: null, tracks: [] };

        for (let i = 0, len = rawTracks.length; i < len; i++) {
            const track: AudioTrackInterface = {
                index: i,
                id: rawTracks[i],
                type: channels[i],
                lang: rawTracks[i],
                label: rawTracks[i],
                codec: ''
            };

            returnObj.tracks.push(track);

            if (rawTracks[i] === info.currentAudioTrack) {
                returnObj.track = track;
            }
        }
        return returnObj;
    }

    /**
     * WebMaf Access Point.
     */
    private accessfunction(json: any) {

        if (this.debugMode &&
            json.indexOf('getPlaybackTime') === -1 &&
            json.indexOf('playerSubtitle') === -1 &&
            json.indexOf('playerMessage') === -1) {
            this.logger.log(LogLevel.INFO, 'Access Function Avia:', json);
        }

        const data = JSON.parse(json);

        switch (data.command) {
            case PlayStation.GET_AUDIO_TRACKS:
                this.respondToAudioTrackInfo(data);
                break;

            case PlayStation.GET_VIDEO_REP_COUNT:
                this.respondToVideoRepCount(data);
                break;

            case PlayStation.GET_VIDEO_REP_INFO:
                this.respondToVideoRepInfo(data);
                break;

            case PlayStation.GET_SUBTITLE_TRACKS:
                this.respondToGetSubtitleTracks(data);
                break;

            case PlayStation.GET_PLAYBACK_TIME:
                this.respondToPlaybackTime(data);
                break;

            case PlayStation.NETWORK_STATUS_CHANGE:
                break;

            case PlayStation.CONTENT_AVAILABLE:
                this.respondToContentAvailable(data);
                break;

            case PlayStation.PLAYER_SUBTITLE:
                this.respondToPlayerSubtitle(data);
                break;

            case PlayStation.GET_SEEK_WINDOW:
                break;

            case PlayStation.PLAYER_TIMED_EVENT:
                this.respondToTimedMetadataEvent(data);
                break;

            case PlayStation.PLAYER_ERROR:
            case PlayStation.PLAYER_STREAMING_ERROR:
                if (!this.fatalErrorInProgress) {
                    this.fatalErrorInProgress = true;
                    this.respondToError(data);
                }
                break;

            case PlayStation.PLAYER_STATUS_CHANGE:
                this.respondToPlayerStatusChange(data);
                break;
        }
    }

    /**
    * Player Status
    */
    private respondToPlayerStatusChange(data: any) {
        switch (data.playerState) {
            case PlayStation.BUFFERING:
                //we only set state on buffering and take no other action.  Case not needed. 
                break;
            case PlayStation.READY:
                this.respondToPlayerStatusReady(data);
                break;

            case PlayStation.PAUSED:
                this.emit(VideoSurfaceEvents.PAUSE);
                break;

            case PlayStation.PLAYING:
                this.emit(VideoSurfaceEvents.PLAYING);
                break;

            case PlayStation.DISPLAYING_VIDEO:
                this.timer.start();
                break;

            case PlayStation.END_OF_STREAM:
                this.respondToPlayerStatusEOS();
                break;
            default:
                break;
        }
        this.pState = data.playerState;
    }

    private respondToError(data: any) {
        const startError: boolean = this.pDuration === 0;
        const code = startError ? ErrorCode.WEBMAF_START_ERROR : ErrorCode.WEBMAF_NETWORK_ERROR;
        const fatal = data.status === 'fail';

        let message = null;
        if (data.error_info) { //generic video error.         
            message = data.error_info;
        } else if (data.error && data.status_code) {
            message = data.error + ' : ' + data.status_code;
        }

        this.emit(VideoSurfaceEvents.ERROR, { code, message, data, fatal });
    }

    private respondToGetSubtitleTracks(data: any) {
        const arr = data.subtitleTracks.split(',');
        arr.forEach((item: any) => {
            if (item === 'und') { //metadata track, not working in latest webmaf
                this.logger.log(LogLevel.INFO, "timed metadata detected.", data);
                this.callExternalWebMafApi('{"command":"setTimedMetadata","enable":true}');
                this.callExternalWebMafApi('{"command":"setSubtitleTrack","subtitleTrack":"und", "disablePreviousTrack":false}');
                this.callExternalWebMafApi('{"command":"setClosedCaptions","enable":true}'); //turned on here to start detection of embedded captions track. 

            }
            else if (item === 'en') {
                //Temp solution when we take on multi lang tracks for text and audio on ps4,  will need some refactor.
                const track: any = {
                    language: item,
                    kind: 'subtitle',
                    label: item
                };
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, track);//use this to set the current track in info.
            }
        });
    }

    private respondToTimedMetadataEvent(data: any) {
        //NOTE - duplicate events come in here as well as to respondToPlayerSubtitle so ignore text and webvtt types unless text type is google_
        // Since ps4 does not tell you if you have a valid lang in und metadata track for embedded captions we are forced to do our own detection scheme.
        if (data.datatype === 'cea-708') {
            if (!this.cea608708Detected) {
                this.cea608708Detected = true;
                //We need to look at deferred enablement turn off explicitly and dispatch we have found captions to app user.
                this.callExternalWebMafApi('{"command":"setClosedCaptions","enable":false}'); // this takes time so if captions come in between detection and turning off they will go out. 
                this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
            }
            else if (this.cea608708Enabled) {
                this.respondToPlayerSubtitle(data); //forward cea-708 data to the subs handler. in this case not sent to both like others are. DOH?
            }
        }
        else if (data.datatype === 'data') {
            //Note this is how they come in and why 6.  Tired trim().  need more solid way to get the ? chars out and trim whitespace etc.  
            //"ID3    PRIV  {  www.nielsen.com/GIacGeL6qt1pDJEY8aGMbw==/f1bYkmvY8AaRr_I-uNLUxQ==/F08C4UUc65OhEq8MxxxZ06Tg2PhDjvquPumVp2bpAm46-5iZjSQBZHDeRiD5IjyBFexrZskzvqgLjgcglZHCRnACer0jfflfiV4X7sfv4T3BIAAqYA017yEk_AeP0JT03WPSX100zdCIGeIlgZnkYj6UvWAkVbpgJFXEO1A=/49977/39178/00 A"            
            const arr = this.config.resource.playback.id3OwnerIds;
            const decodeBase64 = atob(data.data);
            const info = decodeBase64.split('PRIV')[1].slice(6, -1);//Nielsen tested only. 
            Util.forEach(arr, (id) => {
                if (info.indexOf(id) !== -1) {
                    this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, { id: 'PRIV', info, data: data.data });
                }
            });
        }
        else if (data.datatype === 'text' && this.detectDaiImpression(data.data)) { //DAI impression case.            
            this.emit(TextTrackSurfaceEvents.METADATA_CUEPOINT, { id: 'google_dai', info: '', data: data.data, startTime: (data.timestamp / 1000) });
        }
    }

    private detectDaiImpression(str: string): boolean {
        return /google_\d+/.test(str);
    }

    private respondToPlayerSubtitle(data: any) {

        if (this.detectDaiImpression(data.text)) {
            return; //block google impression duplicated event here.
        }

        let cueList: StrAnyDict = [];

        //CEA Embedded 608    
        if (data.datatype === 'cea-708') {
            cueList = data.details.captions;
        }
        else {
            cueList.push({ text: data.text });
        }

        if (cueList.length > 0) {
            this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues: cueList });
        }
    }

    private respondToContentAvailable(data: any) {
        if (this.pDuration !== data.totalLength) {
            this.pDuration = data.totalLength === 0 ? Number.POSITIVE_INFINITY : data.totalLength; //live linear or vod.  DVR will need to grab seek window info and use duration from that.  
            this.emit(VideoSurfaceEvents.DURATION_CHANGE, { value: this.pDuration });
        }
    }

    private respondToVideoRepInfo(data: any) {
        this.pMasterBitrateProfile.push(data);
        if (this.pMasterBitrateProfile.length === this.abrProfileCount) {
            this.emit(PlaybackAdapterEvents.MANIFEST_PARSED, this.pMasterBitrateProfile);
        }
    }

    private respondToVideoRepCount(data: any) {
        this.abrProfileCount = data.count;
        for (let i = 0; i < this.abrProfileCount; i++) {
            this.callExternalWebMafApi('{"command":"getRepresentationInfo", "representation":' + i + '}');
        }
    }

    private respondToAudioTrackInfo(data: any) {
        this.audioTrackInfo = this.parseAudioInfo(data);
        this.emit(PlaybackAdapterEvents.AUDIO_TRACK_UPDATED, this.audioTrackInfo);
    }

    private respondToPlayerStatusEOS() {
        this.timer.stop();
        this.emit(VideoSurfaceEvents.ENDED);
    }

    private respondToPlayerStatusReady(data: any) {
        this.emit(VideoSurfaceEvents.LOADED_METADATA);
        this.callExternalWebMafApi('{"command":"getRepresentationsCount"}');
        this.callExternalWebMafApi('{"command":"getSubtitleTracks"}');
        this.callExternalWebMafApi('{"command":"getSeekWindow"}');

        //this.callExternalWebMafApi('{"command":"getAudioTracks"}');// disabled for now, not supported for HLS or Dash. 

        //set deferred playback options here.
        !isNaN(this.pStartTime) && this.seek(this.pStartTime);
    }
}
