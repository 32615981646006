import { MediatorName } from '../enum/MediatorName';
import { ProxyName } from '../enum/ProxyName';
import { NotificationInterface } from '../iface';
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';

export class KillResourcePresentationCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface): void {
        // remove preso mediator
        this.facade.removeMediator(MediatorName.PRESENTATION_MEDIATOR);

        // remove the resource Proxy
        this.facade.removeProxy(ProxyName.ResourceProxy);

        // remove the content state Proxy
        this.facade.removeProxy(ProxyName.ContentPlaybackStateProxy);

        // reset models (see ModelCollectionProxy)
        const p = <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy);
        p.reset();
    }
}
