import { QualityCategory } from '../enum/QualityCategory';

export const QualityMap = {
    [QualityCategory.UHD_8K]: 4320,
    [QualityCategory.UHD_4K]: 2160,
    [QualityCategory.UHD]: 1440,
    [QualityCategory.HD]: 720,
    [QualityCategory.SD]: 0,
}

