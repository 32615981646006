import { AdTechnology } from '../../enum/AdTechnology';
import { AdBreakType } from '../../enum/AdBreakType';
import { AdContext } from '../../enum/AdContext';
import { ServiceName } from '../../enum/ServiceName';
import { SystemServiceInterface } from '../../iface';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { ResourceAdViewabilityTrackingInterface } from '../../iface/ResourceAdViewabilityTrackingInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { StrStrDict } from '../../iface/StrStrDict';
import { ServiceBase } from '../../service/ServiceBase';
import { dai } from './dai';
import { AdViewabilityTrackingEventInterface, ImaAdIdInfoInterface, ImaAdInfoInterface } from './iface';
import { ima } from './ima';
import { MoatTracker } from './MoatTracker';


export class GamAdService extends ServiceBase {

    protected adContainer: HTMLElement;
    protected adContext: AdContext;
    private moatTracker: MoatTracker;
    private trackingData: ResourceAdViewabilityTrackingInterface;


    constructor(name: string, adContext: AdContext) {
        super(name);
        this.adContext = adContext;
    }

    override destroy(): void {
        this.moatTracker && this.moatTracker.destroy();
        this.moatTracker = null;
        this.adContainer = null;
        this.trackingData = null;
        super.destroy();
    }

    getGamPlatformString(): string {
        const sys = <SystemServiceInterface>this.getService(ServiceName.System);
        // TODO - are there/will there be other valid platform values?
        return sys.isDesktop ? 'desktop' : 'mobile_web';
    }

    setTrackingData(obj: ResourceAdViewabilityTrackingInterface /* = partner, pagetype, viewguid */): void {
        this.trackingData = obj;
    }

    getAdContext(): AdContext {
        return this.adContext;
    }

    setContainer(el: HTMLElement): void {
        this.adContainer = el;
    }

    getAdBreakTypeFromPodIndex(idx: number): AdBreakType {
        return idx === 0 ? AdBreakType.PRE : idx === -1 ? AdBreakType.POST : AdBreakType.MID;
    }

    // Ad Break (pod)
    assembleAdBreakInfo(ad: ima.Ad | dai.Ad, adTech: AdTechnology): AdBreakInfoInterface {
        const pi: ima.AdPodInfo = ad.getAdPodInfo(),
            idx = pi.getPodIndex();

        return {
            adBreakDuration: pi.getMaxDuration(),
            adBreakPosition: idx,
            adTotal: pi.getTotalAds(),
            adBreakType: this.getAdBreakTypeFromPodIndex(idx),
            adServerName: this.adContext,
            adTechnology: adTech
        };
    }

    assembleAdInfo(ad: ima.Ad | dai.Ad, adData?: StrAnyDict, isAdStart: boolean = false): ImaAdInfoInterface {
        const podInfo = ad.getAdPodInfo();
        const adPos = (podInfo && podInfo.getAdPosition()) || -1;
        const idInfo: ImaAdIdInfoInterface = this.getIdInfo(ad);
        const isIma = this.adContext === AdContext.IMA;

        return {
            adObject: isAdStart ? ad : null,
            adServerName: this.adContext,
            adDuration: ad.getDuration(),
            adTitle: ad.getTitle(),
            adId: idInfo.adId,
            adPosition: adPos,
            adAssetUrl: isIma ? (<ima.Ad>ad).getMediaUrl() : (adData.mediaUrl || null),
            isVpaid: isIma ? this.getIsVpaid(<ima.Ad>ad) : null,
            wrapperAdId: idInfo.wrapperAdId,
            creativeId: idInfo.creativeId,
            wrapperCreativeId: idInfo.wrapperCreativeId,
            vastMediaHeight: isIma ? (<ima.Ad>ad).getVastMediaHeight() : null,
            vastMediaWidth: isIma ? (<ima.Ad>ad).getVastMediaWidth() : null,
            vastMediaBitrate: isIma ? (<ima.Ad>ad).getVastMediaBitrate() : null
        };
    }

    // Moat tracking
    trackAd(adInfo: ImaAdInfoInterface): void {
        const td = this.trackingData;

        this.moatTracker.track({
            viewGuid: td.viewGuid,
            pageType: td.pageType,
            adInfo: adInfo,
            adVideoContainer: this.adContainer,
            context: this.adContext,
            partner: td.partner
        });
    }

    trackAdEvent(obj: AdViewabilityTrackingEventInterface): void {
        this.moatTracker.trackEvent(obj);
    }

    untrackAd(): void {
        this.moatTracker.untrack();
    }

    protected createViewabilityTracker(): void {
        this.moatTracker = new MoatTracker();
    }

    ////////////
    // PRIVATE
    ////////////
    private getIdInfo(ad: ima.Ad | dai.Ad): ImaAdIdInfoInterface {
        const w = this.getWrapperId(ad),
            c = this.getCreativeId(ad);

        return {
            adId: w.adId,
            wrapperAdId: w.wrapperAdId,
            creativeId: c.creativeId,
            wrapperCreativeId: c.wrapperCreativeId,
            adServerName: AdContext.IMA,
            adDuration: ad.getDuration()
        };
    }

    private getWrapperId(ad: ima.Ad | dai.Ad): StrStrDict {
        const ids = ad.getWrapperAdIds();
        const len = Array.isArray(ids) && ids.length;
        const defId = ad.getAdId() || '';
        const adId = !len ? defId : ids[0];

        let wrapperAdId = !len ? defId : ids[ids.length - 1];
        if (!wrapperAdId) wrapperAdId = null;

        return {
            adId,
            wrapperAdId
        };
    }

    private getCreativeId(ad: ima.Ad | dai.Ad): StrStrDict {
        const ids = ad.getWrapperCreativeIds();
        const len = Array.isArray(ids) && ids.length;
        const defId = ad.getCreativeId() || null;
        const creativeId = !len ? defId : ids[0];

        let wrapperCreativeId = !len ? defId : ids[ids.length - 1];
        if (!wrapperCreativeId) wrapperCreativeId = null;

        return {
            creativeId,
            wrapperCreativeId
        };
    }

    private getIsVpaid(ad: ima.Ad): boolean {
        return ad.getContentType() === 'application/javascript';
    }
}
