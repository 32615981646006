import {CapabilityConfigInterface} from "../../iface";

export const media_decode_configs: Array<CapabilityConfigInterface> = [
    {
        type: 'avc',
        config: {
            type: 'media-source', // or 'file'
            audio: {
                contentType: 'audio/mp4; codecs=mp4a.40.2',
                channels: '2', // audio channels used by the track
                bitrate: 132266, // number of bits used to encode a second of audio
                samplerate: 48000 // number of samples of audio carried per second
            },
            video: {
                contentType: 'video/mp4; codecs="avc1.4D401E"',
                width: 1920,
                height: 1080,
                bitrate: 2646242, // number of bits used to encode a second of video
                framerate: '25' // number of frames used in one second
            }
        }
    },
    {
        type: 'hevc',
        config: {
            type: 'media-source', // or 'file'
            audio: {
                contentType: 'audio/mp4; codecs=mp4a.40.2',
                channels: '2', // audio channels used by the track
                bitrate: 132266, // number of bits used to encode a second of audio
                samplerate: 48000 // number of samples of audio carried per second
            },
            video: {
                contentType: 'video/mp4; codecs="hev1.1.2.L93.B0"',
                width: 1920,
                height: 1080,
                bitrate: 2646242, // number of bits used to encode a second of video
                framerate: '25' // number of frames used in one second
            }
        }
    },
    {
        type: 'webm',
        config: {
            type: 'media-source', // or 'file'
            audio: {
                contentType: 'audio/webm; codecs=opus',
                channels: '2', // audio channels used by the track
                bitrate: 132266, // number of bits used to encode a second of audio
                samplerate: 48000 // number of samples of audio carried per second
            },
            video: {
                contentType: 'video/webm; codecs="vp09.00.10.08"',
                width: 1920,
                height: 1080,
                bitrate: 2646242, // number of bits used to encode a second of video
                framerate: '25' // number of frames used in one second
            }
        }
    }
];
