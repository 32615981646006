import { CoreEvent } from '../core/CoreEvent';
import { Util } from '../core/Util';
import { ErrorCode } from '../enum/ErrorCode';
import { ErrorEventInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';


export class PlayerErrorEvent extends CoreEvent implements ErrorEventInterface {

    private pCode: ErrorCode;
    private pMessage: string;
    private pFatal: boolean;

    constructor(type: string, code: ErrorCode, msg: string, data: StrAnyDict, fatal: boolean = false) {
        super(type, null, Util.assign({}, data));

        this.pCode = code;
        this.pMessage = msg;
        this.pFatal = fatal;
    }

    get code(): ErrorCode {
        return this.pCode;
    }

    get message(): string {
        return this.pMessage;
    }

    get fatal(): boolean {
        return this.pFatal;
    }
}
