import { Emitter } from '../core/Emitter';


export class Timer extends Emitter {

    static TICK: string = 'tic';
    static TIC_EVENT: string = Timer.TICK;

    private pInterval: number;
    private timer: any = NaN;
    private pCount: number = 0;

    constructor(pInterval: number = 250) {
        super({});
        this.pInterval = pInterval;
    }

    override destroy() {
        this.stop();
        super.destroy();
    }

    start() {
        if (this.running) {
            return;
        }

        this.timer = setInterval(() => {
            this.pCount++;
            this.emit(Timer.TIC_EVENT, { count: this.pCount });
        }, this.pInterval);
    }

    stop() {
        clearInterval(this.timer);
        this.timer = NaN;
        this.pCount = 0;
    }

    get running(): boolean {
        return !isNaN(this.timer);
    }

    get interval(): number {
        return this.pInterval;
    }

    get count(): number {
        return this.pCount;
    }
}
