import { Utilities } from '../../core/Utilities';
import { ResourceAdSsaiInterface } from '../../iface/ResourceAdSsaiInterface';
import { dai } from './dai';
import { AdRequestVersionInfoInterface } from './iface';
import { Util } from '../../core/Util';


const FILTER_OUT_PREMIUM_AUDIO = 'codec:ac-3,codec:ec-3';
const RAND_TOKEN = '[RANDOM]';
const SZ = 'sz';
const SZ_DEFAULT = '640x480';
const SZ_MUTED = '640x483';
const SZ_PARAM = `${SZ}=${SZ_DEFAULT}`;
const SZ_PARAM_MUTED = `${SZ}=${SZ_MUTED}`;
const REFERER_KEY_SSAI = 'imafw__fw_h_referer';
const HOST_KEY_SSAI = 'imafw_host';


// FW / GAM SDK

export function processAdCallUrlForFw(url: string, { muted }: any, skipHostAndReferer: boolean, versionInfo: AdRequestVersionInfoInterface) {

    if (muted) {
        url = url.replace(SZ_PARAM, SZ_PARAM_MUTED);
    }

    return Util.template(url, {
        get RANDOM() { return rand16(); },
        get HOST() { return getHost(); },
        get REFERER() { return skipHostAndReferer ? '' :  getReferer(); },
        get PLAYERINFO() { return `${versionInfo.playername_version}`},
        get SDK() {return `${versionInfo.ima_sdkv}`} 
    }, '\\[', '\\]');
}

export function assembleStreamRequest(ssai: ResourceAdSsaiInterface, { muted }: any, skipHostAndReferer: boolean = false, versionInfo: AdRequestVersionInfoInterface): dai.StreamRequest {
    const params = ssai.adParameters;
    let hasHost = false;
    let hasRef = false;

    if (ssai.filterOutPremiumAudioCodecs) {
        params['dai-excl'] = FILTER_OUT_PREMIUM_AUDIO;
    }
    for (const q in params) {
        if (q == SZ && muted && params[q] == SZ_DEFAULT) {
            params[q] = SZ_MUTED;
        }

        if (params[q] == RAND_TOKEN) {
            params[q] = rand16();
        }

        if (q == HOST_KEY_SSAI) hasHost = true;
        if (q == REFERER_KEY_SSAI) hasRef = true;
    }

    if (!skipHostAndReferer) {
        if (!hasHost) {
            params[HOST_KEY_SSAI] = getHost();
        }
        if (!hasRef) {
            params[REFERER_KEY_SSAI] = getReferer();
        }
    }

    for (const key in versionInfo) {
        if (key.indexOf('imafw_') >= 0) {
            params[key] = versionInfo[key];
        }
    }

    const obj: Partial<dai.StreamRequest> = {
        apiKey: ssai.apiKey,
        authToken: ssai.authToken || null,
        assetKey: ssai.assetKey || null,
        adTagParameters: params,
        videoId: ssai.videoId || null,
        contentSourceId: ssai.contentSourceId || null
    };

    if (Utilities.isString(ssai.format)) {
        obj.format = ssai.format;
    }

    return obj as dai.StreamRequest;
}

export function rand16(): string {
    let n = String(Math.round(1000000000000000 + Math.random() * 9000000000000000));

    if (n.length < 16) { while (n.length < 16) n += '0'; }
    if (n.length > 16) { n = n.substr(0, 17); }

    return n;
}

export function getHost(): string {
    const hUrl = new URL(window.location.href);
    return hUrl.host;
}

export function getReferer(): string {
    return document?.referrer;
}
