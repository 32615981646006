import { AppResources } from '../../app/AppResources';
import { Emitter } from '../../core/Emitter';
import { QueryString } from '../../core/QueryString';
import { request } from '../../core/Request';
import { Util } from '../../core/Util';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { XhrResponseType } from '../../enum/XhrResponseType';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { ResourceLocationDrmInterface } from '../../iface/ResourceLocationDrmInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { VideoSurfaceInterface } from '../../iface/VideoSurfaceInterface';
import { SystemService } from '../../service/SystemService';
import { DrmType } from '../../util/enum/DrmType';
import { DrmVendor } from '../enum/DrmVendor';
import { PlaybackAdapterEvents } from '../enum/PlaybackAdapterEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';


export class FairPlay extends Emitter {

    private WEBKIT_KEY_MESSAGE = 'webkitkeymessage';
    private WEBKIT_KEY_ADDED = 'webkitkeyadded';
    private WEBKIT_KEY_ERROR = 'webkitkeyerror';

    private videoSurface: VideoSurfaceInterface;
    private drmInfo: ResourceLocationDrmInterface;
    private logger: LoggerInterface;
    private drmVendor: string;
    private appCertData: Uint8Array;
    private initData: Uint16Array;
    private appCertLoaded: boolean = false;
    private webKitNeedKeyCalled: boolean = false;
    private webkitNeedKeyHandler: EventHandler = (e: EventInterface) => this.onWebkitNeedKey(e);

    constructor(videoSurface: VideoSurfaceInterface,
        config: PlaybackAdapterConfigInterface,
        logger: LoggerInterface) {

        super();
        this.drmInfo = config.resource.location.drm;
        this.videoSurface = videoSurface;
        this.videoSurface.on(VideoSurfaceEvents.WEBKIT_NEED_KEY, this.webkitNeedKeyHandler);
        this.logger = logger;
    }

    ////////////////////
    //Public Methods
    ////////////////////
    initialize(): void {
        request({
            url: this.drmInfo.fairplay.appCertUrl,
            responseType: XhrResponseType.ARRAY_BUFFER
        })
            .then((response: any) => this.onAppCertLoaded(response));
    }

    override destroy(): void {
        this.videoSurface.off(VideoSurfaceEvents.WEBKIT_NEED_KEY, this.webkitNeedKeyHandler);
    }

    ////////////////////
    //Events Methods
    ////////////////////
    /*
    * Async, dependent on webkitneedkey's init data to continue.
    */
    private onAppCertLoaded(response: any): void {
        this.appCertData = new Uint8Array(response);
        this.appCertLoaded = true;
        if (this.webKitNeedKeyCalled) {
            this.initializeFairplayCDM();
        }
    }

    /*
    * Async dependent on app certdata to continue.
    */
    private onWebkitNeedKey(event: EventInterface) {
        this.initData = event.data.initData;
        this.webKitNeedKeyCalled = true;
        if (this.appCertLoaded) {
            this.initializeFairplayCDM();
        }
    }

    private onLicenseRequestReady(url: string, event: Event) {

        this.drmVendor = this.getDrmVendor(url);

        let xhr = new XMLHttpRequest();
        (<any>xhr).session = event.target;

        xhr.addEventListener('load', this.onLicenseLoaded.bind(this), false);
        xhr.open('POST', url, true);
        xhr.responseType = this.drmVendor === DrmVendor.IRDETO || this.drmVendor === DrmVendor.UNKNOWN ?
            XhrResponseType.ARRAY_BUFFER :
            XhrResponseType.TEXT;

        const spcMessage = encodeURIComponent(Util.base64EncodeUint8Array((<any>event).message));
        const params = 'spc=' + spcMessage + '&' + (<any>xhr).session.contentId;

        xhr = this.setRequestHeaders(xhr);
        xhr.send(params);
    }

    private setRequestHeaders(xhr: XMLHttpRequest): XMLHttpRequest {
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        if (!Util.isEmpty(this.drmInfo.fairplay.header)) {
            const h: StrAnyDict = this.drmInfo.fairplay.header;
            for (let key in h) {
                const v = h[key];
                xhr.setRequestHeader(key, v);
            }
        }
        return xhr;
    }

    private onLicenseLoaded(event: Event) {

        const xhr: XMLHttpRequest = <XMLHttpRequest>event.target;
        const session = (<any>xhr).session;

        if (!Util.inRange(xhr.status, 200, 400)) {
            this.throwFatalError(ErrorCode.FAIRPLAY_LIC_ERROR, AppResources.messages.FAIRPLAY_LICENSE_ERROR);
        }
        const key = xhr.responseType !== 'arraybuffer' ?
            Util.base64DecodeUint8Array(xhr.responseText) :
            new Uint8Array(xhr.response);

        session.update(key);
    }

    private onWebkitKeyAdded(event: Event) {
        this.logger.log(LogLevel.INFO, AppResources.messages.WEBKIT_KEY_ADDED_SUCCESS);
    }

    private onWebkitError(event: Error) {
        this.throwFatalError(ErrorCode.FAIRPLAY_LIC_ERROR, `${AppResources.messages.FAIRPLAY_LICENSE_ERROR}`);
    }

    ////////////////////
    //Private Methods
    ////////////////////

    private initializeFairplayCDM(): void {

        const licenseServerUrl: string = this.getLicenseServerUrl(this.initData);
        const contentId: string = this.getId(licenseServerUrl, this.initData);
        const video = (<any>this.videoSurface.video);
        const global = SystemService.getInstance().global;
        const errMsg = AppResources.messages;

        if (!contentId) {
            this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_NO_CONTENT_ID);
            return;
        }

        if (!video.webkitKeys) {
            const keySystem = DrmType.FAIRPLAY_1_0;
            if (!global.WebKitMediaKeys.isTypeSupported(keySystem, 'video/mp4')) {
                this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_WEBKIT_ERROR);
                return;
            }
            try {
                video.webkitSetMediaKeys(new global.WebKitMediaKeys(keySystem));
            } catch (e) {
                this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, e.message);
                return;
            }
        }

        const appCertInitData = this.concatInitDataIdAndCertificate(this.initData, contentId, this.appCertData);
        const keySession = video.webkitKeys.createSession('video/mp4', appCertInitData);

        if (!keySession) {
            this.throwFatalError(ErrorCode.FAIRPLAY_APP_CERT_ERROR, errMsg.FAIRPLAY_WEBKIT_ERROR);
            return;
        }

        //TODO update this remove bind and use fat arrow to scope? Port from old code need to understand the flow a bit before change.  
        keySession.contentId = contentId;
        keySession.addEventListener(this.WEBKIT_KEY_MESSAGE, this.onLicenseRequestReady.bind(this, licenseServerUrl), false);
        keySession.addEventListener(this.WEBKIT_KEY_ADDED, this.onWebkitKeyAdded.bind(this), false);
        keySession.addEventListener(this.WEBKIT_KEY_ERROR, this.onWebkitError.bind(this), false);
    }

    private concatInitDataIdAndCertificate(initData: Uint16Array, id: Uint16Array | string, cert: Uint8Array): Uint8Array {

        if (typeof id === 'string') {
            id = Util.stringToArray(id);
        }

        // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
        let offset = 0;
        const buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
        const dataView = new DataView(buffer);

        const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
        initDataArray.set(initData);
        offset += initData.byteLength;

        dataView.setUint32(offset, id.byteLength, true);
        offset += 4;

        const idArray = new Uint16Array(buffer, offset, id.length);
        idArray.set(id);
        offset += idArray.byteLength;

        dataView.setUint32(offset, cert.byteLength, true);
        offset += 4;

        const certArray = new Uint8Array(buffer, offset, cert.byteLength);
        certArray.set(cert);

        return new Uint8Array(buffer, 0, buffer.byteLength);
    }

    private getLicenseServerUrl(initData: Uint16Array): string {
        let url = null;

        if (!Util.isEmpty(this.drmInfo.fairplay.licenseUrl)) {
            url = this.drmInfo.fairplay.licenseUrl;
        }
        else {
            let initDataString = String.fromCharCode.apply(null, new Uint16Array(initData.buffer));
            var splitString = initDataString.split('://');
            url = 'https://' + splitString[1];
        }

        if (this.drmInfo.sessionId) {
            url = QueryString.append(url, { 'SessionId': this.drmInfo.sessionId });
        }
        if (this.drmInfo.ticket) {
            url = QueryString.append(url, { 'Ticket': this.drmInfo.ticket });
        }

        return url;
    }

    private extractContentId(initData: Uint16Array) {
        return Util.arrayToString(initData).split('skd://')[1];
    }

    private getId(licenseServerUrl: string, initData: Uint16Array): string | null {
        const obj: StrAnyDict = QueryString.decode(licenseServerUrl);
        console.log(obj);
        return obj.ContentId || obj.contentId || obj.assetId || this.extractContentId(initData);
    }

    /**
     * license server url needed to parse vendor
     */
    private getDrmVendor(url: string): string {
        let v = DrmVendor.UNKNOWN;
        if (url.indexOf(DrmVendor.IRDETO) !== -1) {
            v = DrmVendor.IRDETO;
        } else if (url.indexOf(DrmVendor.DRM_TODAY) !== -1) {
            v = DrmVendor.DRM_TODAY;
        }
        return v;
    }

    private throwFatalError(code: string, message: string) {

        this.logger.log(LogLevel.ERROR, code, message);

        this.emit(PlaybackAdapterEvents.ERROR, {
            code,
            message,
            fatal: true
        });
    }
}
