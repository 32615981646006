import { AdBreakType } from '../../enum/AdBreakType';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class AdBreakInfo implements AdBreakInfoInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'AdBreakInfo'; }

    get data(): AdBreakInfoInterface { return <AdBreakInfoInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set adBreakDuration(value: number) { this.model.adBreakDuration = value; }
    get adBreakDuration(): number { return this.model.adBreakDuration; }

    set adBreakPosition(value: number) { this.model.adBreakPosition = value; }
    get adBreakPosition(): number { return this.model.adBreakPosition; }

    set adBreakType(value: AdBreakType) { this.model.adBreakType = value; }
    get adBreakType(): AdBreakType { return this.model.adBreakType; }

    set adServerName(value: string) { this.model.adServerName = value; }
    get adServerName(): string { return this.model.adServerName; }

    set adTotal(value: number) { this.model.adTotal = value; }
    get adTotal(): number { return this.model.adTotal; }
}
