import { EventTargetInterface } from '../iface/EventTargetInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';

export function waitUntil(func: () => boolean, interval = 100): Promise<void> {
  return new Promise((resolve, reject) => {
    const i = setInterval(() => {
      if (func()) {
        clearInterval(i);
        resolve();
      }
    }, interval);
  });
}

export function waitForEvent<T = any>(target: EventTargetInterface, success: string, fail: string | number = 'error', timeout = NaN): Promise<T> {
  if (typeof fail === 'number') {
    timeout = fail;
  }

  return new Promise((resolve, reject) => {
    let timeoutId: number;
    const undo: Function[] = [];
    const cleanUp = () => undo.forEach(u => u());
    const apply = (action: Function, event: string) => {
      const on = target.on ? 'on' : 'addEventListener';
      const off = target.off ? 'off' : 'removeEventListener';
      const complete = (e: T, d?: T) => {
        clearTimeout(timeoutId);
        cleanUp();
        action(d || e);
      };

      target[on](event, complete);
      undo.push(() => target[off](event, complete));
    };

    apply(resolve, success);

    if (typeof fail === 'string') {
      apply(reject, fail);
    }

    if (timeout > -1) {
      timeoutId = setTimeout(() => {
        cleanUp();
        reject(new Error("timeout"));
      }, timeout);
    }
  });
}

export function waitForTime(target: HTMLVideoElement | VideoPlayerInterface, time: number, prop?: string) {
  if (!prop) {
    prop = target instanceof HTMLVideoElement ? 'currentTime' : 'contentTime';
  }

  // @ts-ignore
  return waitUntil(() => target[prop] >= time);
}
