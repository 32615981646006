import { LocalizationId } from "../enum/LocalizationId";
import { NotificationName } from "../enum/NotificationName";
import { ProxyName } from "../enum/ProxyName";
import { NotificationInterface, PlayerDomProxyInterface } from "../iface";
import { LocalizationProxy } from "../model/LocalizationProxy";
import { LogAwareSimpleCommand } from "./LogAwareSimpleCommand";


export class LocalizationCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const { name, body } = notification;

        switch (name) {

            case NotificationName.CHANGE_LANGUAGE:
                const localization = this.facade.retrieveProxy(ProxyName.LocalizationProxy) as LocalizationProxy;
                const lang = body.value;

                localization.changeLanguage(lang).then(() => {
                    const dom = this.facade.retrieveProxy(ProxyName.PlayerDomProxy) as PlayerDomProxyInterface;
                    if (dom) {
                        const main = dom.getMain();
                        main.setAttribute('aria-label', localization.localize(LocalizationId.MEDIA_PLAYER));
                        main.setAttribute('lang', localization.language);
                    }
                    this.sendNotification(NotificationName.LANGUAGE_CHANGE, body);
                });
                break;

            case NotificationName.LANGUAGE_CHANGE:
                this.sendNotification(NotificationName.APP_EVENT, {
                    notificationName: name,
                    data: body
                });
                break;
        }
    }
}
