/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {MvcMessages} from "./MvcMessages";
import {MvcModelInterface, ModelMap, ProxyInterface, ProxyMap} from "../iface";
import { Logger } from "../core/Logger";

export class Model implements MvcModelInterface {

    protected static modelMap: ModelMap = {};

    private proxyMap: ProxyMap = null;
    private readonly multiCoreKey: string;

    constructor(key: string) {
        if(Model.modelMap[key]) {
            Logger.error(MvcMessages.ERROR_KEY_EXISTS.replace('$ACTOR_NAME$', 'Model'));
            return;
        }

        this.multiCoreKey = key;
        Model.modelMap[key]= this;

        this.proxyMap = {};
        this.initializeModel();
    }

    static getInstance (key: string): MvcModelInterface {
        if (!key) return null;

        if(this.modelMap[key] == null) {
            this.modelMap[key] = new Model(key);
        }

        return this.modelMap[key];
    }

    static removeModel(key: string): void {
        this.modelMap[key] && this.modelMap[key].destroy();
        delete this.modelMap[key];
    }

    destroy(): void {
        for (let q in this.proxyMap) {
            this.removeProxy(q);
        }
        this.proxyMap = null;
    }

    initializeModel(): void {
        /* implementation optional */
    }

    registerProxy(proxy: ProxyInterface): void  {
        this.proxyMap[proxy.name] = proxy;
        proxy.initializeNotifier(this.multiCoreKey);
        proxy.onRegister();
    }

    retrieveProxy(proxyName: string): ProxyInterface {
        return this.proxyMap[proxyName] || null;
    }

    hasProxy(proxyName: string): boolean {
        return this.proxyMap[proxyName] != null;
    }

    removeProxy(proxyName: string): void {
        let proxy = this.proxyMap[proxyName];

        if(proxy) {
            proxy.onRemove();
            this.proxyMap[proxyName] = null;
        }
    }

}
