import { LogLevel } from '../../enum/LogLevel';
import { MediatorName } from '../../enum/MediatorName';
import { FacadeInterface, NotificationInterface } from '../../iface';
import { PluginMediator } from '../../view/PluginMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepPluginsCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const f: FacadeInterface = this.facade;
        f.registerMediator(new PluginMediator(MediatorName.PLUGIN_MEDIATOR));

        this.log(LogLevel.DEBUG, 'Plugins Prepared');
    }
}
