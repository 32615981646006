import { GamStaticParametersInterface } from './iface';

export const gam_static_params: GamStaticParametersInterface = {
    cmsid: 2289,
    output: 'xml_vmap1',
    unviewed_position_start: 1,
    correlator: '1',
    gdfp_req: 1,
    env: 'vp'
};
