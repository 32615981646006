import { Util } from '../core/Util';
import { VideoFormat } from '../enum/VideoFormat';
import { DeepPartial } from '../iface/DeepPartial';
import { ResourceAdInterface } from '../iface/ResourceAdInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { ResourceLocationInterface } from '../iface/ResourceLocationInterface';
import { ResourceOverridesInterface } from '../iface/ResourceOverridesInterface';
import { ResourcePlaybackInterface } from '../iface/ResourcePlaybackInterface';


export class ResourceConfiguration implements ResourceConfigurationInterface {

    constructor(config: DeepPartial<ResourceConfigurationInterface> = {}) {
        Util.merge(this, config);
    }

    ad: ResourceAdInterface = {
        enabled: false,
        adapter: null,
        context: null,
        showPrerollOnNonZeroStart: false,
        suppressAdUi: false,
        viewabilityTracking: {
            partner: null,
            viewGuid: null,
            pageType: null,
        },
        adTagParameters: {
            description_url: null,
            sz: '640x480',
            url: null,
            iu: null,
            ad_rule: null,
            ciu_szs: null,
            pp: null,
            cmsid: '2289',
            vid: null,
            hl: null,
            msid: null,
            an: null,
            nofb: null,
            ppid: null,
            tfcd: null,
            npa: null,
            cust_params: null
        },
        csai: {
            adCallUrl: null,
            loadVideoTimeoutMs: 4000,
            enableAdPreloading: false,
            enableStalledAdCheck: true,
            stalledAdThreshold: 5,
            preloadContentAtEndOfPreRoll: false,
            ignoreAnomalousConditionOnSeek: false,
            fw: null,
        },
        ssai: {
            apiKey: null,
            authToken: null,
            adParameters: null,
            contentSourceId: null,
            videoId: null,
            assetKey: null,
            useApi: false,
            format: VideoFormat.HLS,
            isLive: false,
            filterOutPremiumAudioCodecs: false,
        },
        ima: {
            adCallUrl: null,
            loadVideoTimeoutMs: 4000,
            enableAdPreloading: false,
            enableStalledAdCheck: true,
            stalledAdThreshold: 5,
            preloadContentAtEndOfPreRoll: false,
            ignoreAnomalousConditionOnSeek: false,
        },
        dai: {
            contentSourceId: null,
            daiVideoId: null,
            assetKey: null,
            apiKey: null,
            ssbStreamUrl: null,
            isLive: false,
            format: null,
            streamActivityMonitorId: null
        }
    };

    location: ResourceLocationInterface = {
        cms: null,
        tokenMap: null,
        cmsUri: null,
        mediaUrl: null,
        textTrackUrl: null,
        thumbnailTrackUrl: null,
        posterImageUrl: null,
        drm: {
            enabled: false,
            sessionId: null,
            ticket: null,
            playready: {
                url: null,
                header: null
            },
            widevine: {
                url: null,
                header: null
            },
            fairplay: {
                appCertUrl: null,
                licenseUrl: null,
                header: null
            },
            aes: {
                header: null,
                provider: null
            }
        }
    };

    playback: ResourcePlaybackInterface = {
        id3OwnerIds: null,
        startTime: NaN,
        preloadContent: false,
        enableAdvancedCodecs: false,
        liveEdgeSyncFragmentCount: 3,
        abr: {

            maxBitrate: NaN,
            minBitrate: NaN,
            startBitrate: NaN,
            capQualityToScreenSize: true
        }
    };

    metadata: Record<string, any> = {};
    tracking: Record<string, any> = {};
    overrides: ResourceOverridesInterface = {};
}
