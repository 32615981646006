
export enum PerformanceMode {

    /** Auto select the best mode for the detected environment */
    AUTO = 'auto',

    /** Allows the most forward and backwards buffer*/
    DESKTOP = 'desktop',
    
    /** Slightly reduces the forward and backwards buffer compared to desktop*/
    MOBILE = 'mobile',

    /** Allows the least amount of forward and backwards buffer, most restrictive setting.*/
    TV = 'tv'
}
