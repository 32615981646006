export enum TextTrackKind {
	/** 
	 * The track defines translation of dialogue and sound effects (suitable for deaf users)
	 */
	CAPTIONS = 'captions',

	/**
	 * The track defines subtitles, used to display subtitles in a video
	 */
	SUBTITLES = 'subtitles',

	/**
	 * The track defines a textual description of the video content (suitable for blind users)
	 */
	DESCRIPTIONS = 'descriptions',

	/**
	 * The track defines chapter titles (suitable for navigating the media resource)
	 */
	CHAPTERS = 'chapters',

	/**
	 * The track defines content used by scripts. Not visible for the user
	 */
	METADATA = 'metadata',
}
