class LinkedNode<T> {
	constructor(public value: T, public next?: LinkedNode<T>) { }
}

export class LinkedList<T> {
	head: LinkedNode<T> = null;
	tail: LinkedNode<T> = null;

	get empty(): boolean {
		return this.head == null;
	}

	forEach(func: (item: T) => void) {
		let node: LinkedNode<T> = this.head;
		while (node) {
			func(node.value);
			node = node.next;
		}
	}

	add(value: T) {
		const node = new LinkedNode<T>(value);

		if (this.head == null) {
			this.head = node;
		}
		else if (this.tail == null) {
			this.head.next = this.tail = node;
		}
		else {
			this.tail.next = this.tail = node;
		}
	}

	has(value: T): boolean {
		let node = this.head;
		while (node) {
			if (node.value == value) {
				return true;
			}
			node = node.next;
		}
		return false;
	}

	delete(value: T): boolean {
		let previous = null;
		let node = this.head;
		while (node) {
			if (node.value == value) {
				if (previous) {
					previous.next = node.next;
				}

				if (node == this.head) {
					this.head = node.next;
				}
				else if (node == this.tail) {
					this.tail = previous;
				}
				return true;
			}
			previous = node;
			node = node.next;
		}
		return false;
	}

	clear(): void {
		let node = this.head;
		let next;
		while (node) {
			next = node.next;
			node.value = node.next = null;
			node = next;
		}
		this.head = this.tail = null;
	}
}
