import { PlaybackState } from '../../enum/PlaybackState';
import { StreamType } from '../../enum/StreamType';
import { AudioTracksInterface } from '../../iface/AudioTracksInterface';
import { ContentPlaybackStateInterface } from '../../iface/ContentPlaybackStateInterface';
import { DataConfigInterface } from '../../iface/DataConfigInterface';
import { LiveStreamInfoInterface } from '../../iface/LiveStreamInfoInterface';
import { ModelInterface } from '../../iface/ModelInterface';
import { QualityInfoInterface } from '../../iface/QualityInfoInterface';
import { QualityInterface } from '../../iface/QualityInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { TextTracksInterface } from '../../iface/TextTracksInterface';
import { ConfigurablePropertyContainer } from './ConfigurablePropertyContainer';


export class ContentPlaybackState implements ContentPlaybackStateInterface, ModelInterface {

    private model: StrAnyDict;

    constructor(config: DataConfigInterface) {
        this.model = new ConfigurablePropertyContainer(config);
    }

    static get modelName(): string { return 'ContentPlaybackState'; }

    get data(): ContentPlaybackStateInterface { return <ContentPlaybackStateInterface>(this.model.data); }

    reset(): void { this.model.reset(); }


    set manifestQualities(value: QualityInterface[]) { this.model.manifestQualities = value; }
    get manifestQualities(): QualityInterface[] { return this.model.manifestQualities; }

    set qualityInfo(value: QualityInfoInterface) { this.model.qualityInfo = value; }
    get qualityInfo(): QualityInfoInterface { return this.model.qualityInfo; }

    set audioTracks(value: AudioTracksInterface) { this.model.audioTracks = value; }
    get audioTracks(): AudioTracksInterface { return this.model.audioTracks; }

    set textTracks(value: TextTracksInterface) { this.model.textTracks = value; }
    get textTracks(): TextTracksInterface { return this.model.textTracks; }

    set streamType(value: StreamType) { this.model.streamType = value; }
    get streamType(): StreamType { return this.model.streamType; }

    set liveStreamInfo(value: LiveStreamInfoInterface) { this.model.liveStreamInfo = value; }
    get liveStreamInfo(): LiveStreamInfoInterface { return this.model.liveStreamInfo; }

    set bufferLength(value: number) { this.model.bufferLength = value; }
    get bufferLength(): number { return this.model.bufferLength; }

    set cdn(value: string) { this.model.cdn = value; }
    get cdn(): string { return this.model.cdn; }

    set drmType(value: string) { this.model.drmType = value; }
    get drmType(): string { return this.model.drmType; }

    set contentSegment(value: number) { this.model.contentSegment = value; }
    get contentSegment(): number { return this.model.contentSegment; }

    set maxBandwidth(value: number) { this.model.maxBandwidth = value; }
    get maxBandwidth(): number { return this.model.maxBandwidth; }

    set time(value: number) { this.model.time = value; }
    get time(): number { return this.model.time; }

    set state(value: PlaybackState) { this.model.state = value; }
    get state(): PlaybackState { return this.model.state; }

    set duration(value: number) { this.model.duration = value; }
    get duration(): number { return this.model.duration; }

    set bitrate(value: number) { this.model.bitrate = value; }
    get bitrate(): number { return this.model.bitrate; }

    set started(value: boolean) { this.model.started = value; }
    get started(): boolean { return this.model.started; }

    set averageDroppedFps(value: number) { this.model.averageDroppedFps = value; }
    get averageDroppedFps(): number { return this.model.averageDroppedFps; }

    set framerate(value: number) { this.model.framerate = value; }
    get framerate(): number { return this.model.framerate; }
}
