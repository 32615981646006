import { Util } from '../core/Util';
import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { Device } from '../enum/Device';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { NotificationInterface, PlayerDomProxyInterface, PresentationMediatorInterface, SystemServiceInterface } from '../iface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { PlaylistProxy } from '../model/PlaylistProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { AppMediator } from '../view/AppMediator';
import { LogAwareSimpleCommand } from './LogAwareSimpleCommand';


export class PlaybackCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const presoMediator = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR),
            cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy?.model;

        if (!cps) {
            this.log("play() can not be invoked in this context.");
            return;
        }

        let pos, presoMdl;

        switch (notification.name) {

            case NotificationName.PLAY_ON_USER_GESTURE:
                if (!presoMediator) {
                    this.facade.sendNotification(NotificationName.POSTER_CLICK, null, NotificationType.INTERNAL);
                }
                else {
                    this.invokePlayOnUserGesture(presoMediator);

                    const pl = <PlaylistProxy>this.facade.retrieveProxy(ProxyName.Playlist);

                    this.facade.sendNotification(
                        NotificationName.POSTER_CLICK,
                        { resource: pl.currentResource },
                        NotificationType.INTERNAL
                    );
                }

                break;

            case NotificationName.PLAY:
                const hasPreso = !!presoMediator,
                    started = hasPreso && (<PresentationStateInterface>this.getModel(ModelName.PresentationState)).started;

                if (hasPreso && notification.type === NotificationType.EXTERNAL && !started) {
                    this.invokePlayOnUserGesture(presoMediator);
                }
                else if (started) {
                    presoMediator && presoMediator.play();
                }
                break;

            case NotificationName.PAUSE:
                presoMediator && presoMediator.pause();
                presoMdl = <PresentationStateInterface>this.getModel(ModelName.PresentationState);

                if (!presoMdl.isCurrentVideoAd && notification.type === NotificationType.UI) {
                    this.facade.sendNotification(NotificationName.USER_PAUSE, null, NotificationType.INTERNAL);
                }
                break;

            case NotificationName.STOP:
                const appMed = <AppMediator>this.facade.retrieveMediator(MediatorName.APPLICATION),
                    r = (<PlaylistInterface>(<unknown>this.facade.retrieveProxy(ProxyName.Playlist))).currentResource,
                    isVod = this.isVod();

                appMed.killCurrentResource()
                    .then(() => {
                        if (isVod) {
                            return;
                        }

                        this.sendNotification(NotificationName.APP_EVENT, {
                            notificationName: NotificationName.LIVE_PRESENTATION_STOPPED,
                            data: r
                        });
                    });
                break;

            case NotificationName.SEEK:
                presoMediator.seek(notification.body.value);
                break;

            case NotificationName.SEEK_FORWARD:
                pos = cps.time + notification.body.value;
                presoMediator.seek(Math.min(pos, cps.duration));
                break;

            case NotificationName.SEEK_BACK:
                pos = cps.time - notification.body.value;
                presoMediator.seek(Math.max(pos, 0));
                break;

            case NotificationName.SEEK_TO_LIVE:
                if (!this.isVod() && cps.liveStreamInfo) {
                    pos = cps.liveStreamInfo.relativeDuration;
                    presoMediator.seek(Math.min(pos, cps.duration));
                }
                break;

            case NotificationName.SWITCH_AUDIO_TRACK:
                presoMediator.adapter.audioTrack = notification.body.value;
                break;

            case NotificationName.SWITCH_TEXT_TRACK:
                const track = <TextTrackInterface>notification.body.value;
                if (track) {
                    (<TextTrackProxy>this.facade.retrieveProxy(ProxyName.TextTrackProxy)).language = track.language;
                    const textTrack = this.contentPlaybackStateProxy.getTextTrackById(track.id);
                    presoMediator.adapter.textTrack = textTrack;
                }
                break;

            case NotificationName.SWITCH_TEXT_MODE:
                const enabled = notification.body.value;
                const textTrackProxy = (<TextTrackProxy>this.facade.retrieveProxy(ProxyName.TextTrackProxy));
                textTrackProxy.enabled = enabled;
                if (presoMediator?.adapter) {
                    presoMediator.adapter.textTrackMode = textTrackProxy.mode;
                }
                break;

            case NotificationName.MUTE:
                presoMediator.mute(true);

                this.sendNotification(NotificationName.APP_EVENT, {
                    notificationName: NotificationName.MUTE,
                    data: true
                });
                break;

            case NotificationName.UNMUTE:
                presoMediator.mute(false);

                this.sendNotification(NotificationName.APP_EVENT, {
                    notificationName: NotificationName.UNMUTE,
                    data: false
                });
                break;

            case NotificationName.VOLUME_CHANGE:
                const { value, muted } = notification.body;
                !isNaN(value) && presoMediator.setVolume(value);

                // keep the muted state in sync with the video element
                presoMdl = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
                if (!Util.isUndefined(muted) && presoMdl.isMuted != muted) {
                    this.sendNotification(muted ? NotificationName.MUTE : NotificationName.UNMUTE);
                }

                if (notification.type === NotificationType.EXTERNAL) {
                    const uiMed = <UiMediatorInterface>this.facade.retrieveMediator(MediatorName.UI);
                    uiMed && uiMed.setVolume(value);
                }

                this.sendNotification(NotificationName.APP_EVENT, {
                    notificationName: NotificationName.VOLUME_CHANGE,
                    data: value
                });
                break;
        }
    }

    get contentPlaybackStateProxy(): ContentPlaybackStateProxy {
        return this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy) as ContentPlaybackStateProxy;
    }

    isVod(): boolean {
        const streamType = this.contentPlaybackStateProxy.model.streamType;
        return (streamType != StreamType.LIVE && streamType != StreamType.DVR);
    }

    shouldSkipPriming(): boolean {
        const domProxy = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
        const overrides = playerOpts.overrides;

        return !domProxy || sys.device == Device.LG_SMART_TV || sys.device == Device.PLAYSTATION_5 || overrides?.skipPriming === true;
    }

    invokePlayOnUserGesture(presoMediator: PresentationMediatorInterface): void {
        const domProxy = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);
        const playerOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
        const overrides = playerOpts.overrides;

        presoMediator.beforePlayOnUserGesture();

        if (!this.shouldSkipPriming()) {
            const p: Promise<any> = domProxy.primeVideo(overrides?.blankVideoUrl);
            p.then(() => {
                if (sys.device == Device.IPHONE) {
                    const ev = 'timeupdate';
                    const v = domProxy.getVideo(),
                        h = (e: Event) => {
                            v.removeEventListener(ev, h);
                            setTimeout(() => presoMediator.playOnUserGesture(), 200);
                        };
                    v.addEventListener(ev, h);
                }
                else {
                    presoMediator.playOnUserGesture();
                }
            }).catch((e) => {
                this.log(LogLevel.ERROR, `ERROR - PlaybackCommand: Priming FAILED.  ${e.message}`);
                this.facade.sendNotification(NotificationName.AUTOPLAY_BLOCKED, e, NotificationType.INTERNAL);
            });
        }
        else {
            presoMediator && presoMediator.playOnUserGesture();
        }
    }
}
