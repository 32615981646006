import { LogServiceInterface } from '../iface';
import { LoggerInterface } from '../iface/LoggerInterface';
import { ServiceBase } from './ServiceBase';


export class LogService extends ServiceBase implements LogServiceInterface {

    private pLogger: LoggerInterface;

    constructor(name: string, logger: LoggerInterface) {
        super(name);
        this.pLogger = logger;
    }

    override destroy(): void {
        this.pLogger.destroy();
        this.pLogger = null;
    }

    get logger(): LoggerInterface {
        return this.pLogger;
    }
}
