import { buildInfo } from '../core/BuildInfo';
import { Logger } from '../core/Logger';
import { AsyncDataRequest } from '../dataservice/AsyncDataRequest';
import { MediatorName } from '../enum/MediatorName';
import { PlayerDom } from '../enum/PlayerDom';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { FacadeInterface, SystemServiceInterface } from '../iface';
import { AsyncDataRequestOptionsInterface } from '../iface/AsyncDataRequestOptionsInterface';
import { AutoplayInfoInterface } from '../iface/AutoplayInfoInterface';
import { PlaybackAdapterConfigInterface } from '../iface/PlaybackAdapterConfigInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { PlayerDomProxy } from '../model/PlayerDomProxy';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { AppMediator } from '../view/AppMediator';
import { cvui } from './cvui/cvui';
import { UiEnumCollectionInterface } from './cvui/UiEnumCollectionInterface';
import { UiMediator } from './cvui/UiMediator';
import { TwitchLowLatencyAdapter } from './TwitchLowLatencyAdapter';


/**
 * This is a collection of deprecated functionality
 * 
 * @deprecated
 */
export class Deprecated {

    static warn(msg: string) {
        Logger.deprecated(msg);
    }

    /**
     * @deprecated
     */
    static checkEventName(name: string) {
        if (/[A-Z]+/.test(name)) {
            Deprecated.warn('Event name is being forced to lowercase. Please use the avia.PlayerEvent enum instead of a string literal. This check will be removed April 1st, 2022');
            name = name.toLowerCase();
        }
        return name;
    }

    /**
     * @deprecated
     */
    static createUi(facade: FacadeInterface, ui: cvui.UiObjectInterface, sysSvc: SystemServiceInterface, playerOpts: PlayerOptionsInterface) {
        Deprecated.warn('CVUI is deprecated. Please use the avia-js-ui instead. Access to this feature will be completely removed April 1st, 2022');

        const domProxy = <PlayerDomProxy>facade.retrieveProxy(ProxyName.PlayerDomProxy);
        let uic: StrAnyDict = playerOpts.uiConfig;

        // vtg-1732 - exclude CC
        if (sysSvc.isIos || sysSvc.isAndroid) {
            if (!uic) {
                uic = {};
            }
            if (!uic.exclude || !Array.isArray(uic.exclude)) {
                uic.exclude = [];
            }
            uic.exclude.indexOf(ui.ControlName.CC_TOGGLE) == -1 && uic.exclude.push(ui.ControlName.CC_TOGGLE);
        }

        const viewController: cvui.ViewControllerInterface = ui.createUi({
            controlContainer: domProxy.getMain(),
            panelContainer: domProxy.getElement(PlayerDom.PANEL_CONTAINER),
            zOrigin: domProxy.getSafeZIndex(),
            systemInfo: sysSvc.info,
            configuration: uic || {},
            omitVolume: !sysSvc.isDesktop
        });

        const enums: UiEnumCollectionInterface = {
            ControlName: ui.ControlName,
            ControlEvent: ui.ControlEvent,
            PlayState: ui.PlayState,
            DecoratorName: ui.DecoratorName,
            VideoType: ui.VideoType,
        };

        const m = new UiMediator(MediatorName.UI, viewController);

        facade.registerMediator(m);
        m.initialize(enums);
    }

    /**
     * @deprecated
     */
    static getModelSnapShot(appMediator: AppMediator) {
        Deprecated.warn('getModelSnapShot method is deprecated. Access to this property will be completely removed April 1st, 2022');

        const snapshot = appMediator.getSnapshot();
        snapshot.BuildInfo = buildInfo;
        return snapshot;
    }

    /**
     * @deprecated
     */
    static get xhr() {
        return {
            performXhr(options: AsyncDataRequestOptionsInterface) {
                Deprecated.warn('performXhr method is deprecated. Please use Util.request, or fetch directly. Access to this property will be completely removed April 28th, 2022');
                const temp = options.onComplete;
                options.onComplete = (e) => {
                    temp(e);
                    e.target.destroy();
                };
                new AsyncDataRequest(options);
            }
        };
    }

    /**
     * @deprecated
     */
    static setResource(appMediator: AppMediator, resource: Partial<ResourceConfigurationInterface>) {
        Deprecated.warn('The setter for the resource property is deprecated. Please use player.attachResource. Access to this property will be completely removed July 1st, 2022');
        appMediator.attachResource(resource);
    }


    /**
     * @deprecated
     */
    static createTwitch(config: PlaybackAdapterConfigInterface) {
        Deprecated.warn('The twitch low latency adapter is deprecated. Please use the Twitch Adapter Plugin. This feature will be completely removed January 1st, 2022');
        return new TwitchLowLatencyAdapter(config);
    }

    /**
     * @deprecated
     */
    static getAutoplayCapabilities(appMediator: AppMediator): Promise<AutoplayInfoInterface> {
        Deprecated.warn('The getAutoplayCapabilities function is deprecated. Please use avia.getAutoplayCapabilities. This feature will be completely removed January 1st, 2022');

        const apc = <AutoplayCapabilitiesService>appMediator.getService(ServiceName.AutoplayCapabilities);

        return apc.detectCapabilities();
    }
}
