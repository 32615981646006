
/**
 * For details on using ad settings, see [Ad Play: IMA & DAI](../../guides/ad_play)
 */
export enum AdContext {
    /**
     * Specifies CSAI using IMA
     */
    IMA = 'ima',

    /**
     * Specifies a resource that plays a client-side ad using IMA,
     * with no content. This is typically done as means to show a 
     * pre-roll ad before a DAI live stream.
     */
    IMA_AD_ONLY = 'ima_ad_only',

    /**
     * Specifies SSAI using DAI SDK
     */
    DAI_SDK = 'dai_sdk',

    /**
     * Specifies SSAI using a custom SDK 
     */
    DAI_API = 'dai_api',

    /**
     * Specifies SSAI, non-SDK mediated (ad play not monitored or tracked client-side)
     */
    DAI_SSB = 'dai_ssb',

    /**
     * Specifies playback without ads
     */
    NONE = ''
}
