import { Util } from '../core/Util';
import { ResourceAdInterface } from '../iface/ResourceAdInterface';
import { ResourceAdViewabilityTrackingInterface } from '../iface/ResourceAdViewabilityTrackingInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { ResourceLocationInterface } from '../iface/ResourceLocationInterface';
import { ResourcePlaybackInterface } from '../iface/ResourcePlaybackInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { Proxy } from '../mvc/Proxy';


export class ResourceProxy extends Proxy {

    private pResource!: ResourceConfigurationInterface;

    constructor(name: string, data?: any) {
        super(name, data);
    }

    //TODO: can we annotate to be removed during compilation? Or use Object.assign?
    mock(r: ResourceConfigurationInterface): void {
        this.pResource = r;
    }

    override onRemove() {
        this.pResource = null;
        super.onRemove();
    }

    override onRegister(): void {
        super.onRegister();
        this.pResource = this.data;
    }

    get resource(): ResourceConfigurationInterface {
        return this.pResource;
    }

    get location(): ResourceLocationInterface {
        return this.pResource.location;
    }

    get playback(): ResourcePlaybackInterface {
        return this.pResource.playback;
    }

    get metadata(): StrAnyDict {
        return this.pResource.metadata;
    }

    get ad(): ResourceAdInterface {
        return this.pResource.ad;
    }

    get adTrackingSettings(): ResourceAdViewabilityTrackingInterface {
        return this.pResource.ad.viewabilityTracking;
    }

    get hasValidMediaUrl(): boolean {
        return !Util.isEmpty(this.location.mediaUrl);
    }
}
