export enum LocalizedLanguage {
	EN = "English",
	ES = "Español",
	FR = "Français",
	PT = "Português",
	DE = "Deutsche",
	PL = "Polski",
	JA = "日本人",
	KO = "한국어",
	ZH = "简体中文",
	IT = "Italiano",
	RU = "Русский"
}
