import { PlaybackAdapterDelegate } from '../app/adapter/playback/PlaybackAdapterDelegate';
import { PlaybackAdapterWrapper } from '../app/adapter/playback/PlaybackAdapterWrapper';
import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { AdapterRole } from '../enum/AdapterRole';
import { Browser } from '../enum/Browser';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { NotificationType } from '../enum/NotificationType';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { QualityMode } from '../enum/QualityMode';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { TextTrackMode } from '../enum/TextTrackMode';
import { MediaCapabilitiesServiceInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { EventHandler } from '../iface/EventHandler';
import { EventInterface } from '../iface/EventInterface';
import { PlaybackAdapterConfigInterface } from '../iface/PlaybackAdapterConfigInterface';
import { PlaybackAdapterCoreInterface } from '../iface/PlaybackAdapterCoreInterface';
import { PlaybackAdapterDelegateInterface } from '../iface/PlaybackAdapterDelegateInterface';
import { PlaybackAdapterInterface } from '../iface/PlaybackAdapterInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { PlaylistInterface } from '../iface/PlaylistInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { QualityInfoInterface } from '../iface/QualityInfoInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { StrStrDict } from '../iface/StrStrDict';
import { TextTrackInterface } from '../iface/TextTrackInterface';
import { AdapterProxy } from '../model/AdapterProxy';
import { ContentPlaybackStateProxy } from '../model/ContentPlaybackStateProxy';
import { PerformanceProxy } from '../model/PerformanceProxy';
import { ResourceProxy } from '../model/ResourceProxy';
import { TextTrackProxy } from '../model/TextTrackProxy';
import { PlaybackAdapterFactory } from '../playback/adapter/PlaybackAdapterFactory';
import { PlaybackAdapterEvents } from '../playback/enum/PlaybackAdapterEvents';
import { TextTrackSurfaceEvents } from '../playback/enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../playback/enum/VideoSurfaceEvents';
import { ThumbnailTrackSurface } from '../playback/surface/ThumbnailTrackSurface';
import { AppMediator } from './AppMediator';
import { LogAwareMediator } from './LogAwareMediator';
import { TimerMediator } from './TimerMediator';


type ErrorNotification = { name: NotificationName, error: any; };

/**
 * AbstractPresentationMediator interfaces with an appropriate (stream type-dependent)
 * playback adapter, and also captures events from the video element
 * (supplied as 'viewControl' to constructor). Key events invoke a 'respondTo<Context>'
 * method, each of which is abstract and must be implemented in concrete presentation sub-classes.
 *
 * In addition, this base object will listen for timer tic notifications to
 * trigger checks on buffering and size change of the presentation.
 */
export abstract class AbstractPresentationMediator extends LogAwareMediator {

    protected started: boolean = false;
    protected preloadContent: boolean = true;
    protected isPlayingLive: boolean;
    protected streamType: StreamType;
    protected minDvrDuration = 1800;
    protected contentPlaybackStateProxy: ContentPlaybackStateProxy;
    protected performanceProxy: PerformanceProxy;
    protected playerOptions: PlayerOptionsInterface;
    protected resourceProxy: ResourceProxy;
    protected presoModel: PresentationStateInterface;
    protected closing: Promise<void>;
    protected initializing: boolean;
    protected adapterTask = Promise.resolve();
    protected seeking: boolean = false;
    protected onLoadError = ({ name, error }: ErrorNotification) => this.sendErrorNotification(name, error);
    protected previousPlaybackState: { state: PlaybackState | null, overrides: Record<string, boolean>; } = {
        state: null,
        overrides: {},
    };

    protected contentIsBuffering: boolean = false;
    protected adapterEventHandler: EventHandler = (e: EventInterface) => this.hPlaybackAdaptorEvents(e);
    protected videoEventHandler: EventHandler = (e: EventInterface) => this.hVideoAdapterEvents(e);
    protected textTrackEventHandler: EventHandler = (e: EventInterface) => this.hTextTrackEvents(e);

    private pAdapter: PlaybackAdapterCoreInterface;
    private endFreezeTimeoutHandle: any = null;
    private loadComplete: boolean = false;
    private offsetTtOnControlVis: boolean = false;
    private isMonitoringCueEvents: boolean = false;
    private bufferingSampleRate: number;

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
        // debounce to limit the number of info changes events fired.
        this.respondToTextTrackInfoChange = Util.debounce(this.respondToTextTrackInfoChange.bind(this), 10);
    }

    get adapter(): PlaybackAdapterCoreInterface {
        return this.pAdapter;
    }

    override onRemove(): void {
        clearTimeout(this.endFreezeTimeoutHandle);
        this.removeEvents(PlaybackAdapterEvents, this.adapterEventHandler);
        this.removeEvents(VideoSurfaceEvents, this.videoEventHandler);
        this.removeEvents(TextTrackSurfaceEvents, this.textTrackEventHandler);

        this.pAdapter = null;
        this.contentPlaybackStateProxy = null;
        this.resourceProxy = null;
        this.presoModel = null;
        this.playerOptions = null;

        super.onRemove();
    }

    setVolume(value: number): void {
        // TODO - WEBMAF - volume via adapter
        this.presoModel.volume = value;
    }

    load(): Promise<void> {
        return this.loadVideo()
            .then(() => {
                // NOTE: Attach listeners for the for the error events now that the adapter has 
                //       successfully loaded the resource. (VTG-2393)
                const filter = (value: string) => value == PlaybackAdapterEvents.ERROR || value == VideoSurfaceEvents.ERROR;
                this.addEvents(PlaybackAdapterEvents, this.adapterEventHandler, filter);
                this.addEvents(VideoSurfaceEvents, this.videoEventHandler, filter);
            })
            .catch((e) => {
                const error = (e.type) ? e.data : e;
                throw { name: NotificationName.VIDEO_START_ERROR, error };
            });
    }

    play(): void {
        this.playVideo();
    }

    pause(): void {
        this.pauseVideo();
    }

    suspend(): void {
        this.pAdapter.suspend();
    }

    resume(): void {
        this.pAdapter.resume();
    }

    abstract playOnUserGesture(): void;
    abstract getAdBreakTimes(): AdCuePointInterface[];
    abstract close(): Promise<void>;

    protected abstract checkSize(): void;
    protected abstract respondToVideoPlaying(): void;
    protected abstract respondToVideoPaused(): void;
    protected abstract respondToVideoSeeking(): void;
    protected abstract respondToVideoSeeked(): void;
    protected abstract respondToVideoProgress(): void;
    protected abstract respondToVideoTimeUpdate(streamTime: number): void;
    protected abstract respondToQualityChange(quality?: QualityInterface): void;
    protected abstract respondToVideoEnd(): void;
    protected abstract respondToBufferingStatusCheck(count: number): void;
    protected abstract respondToDurationChange(dur: number): void;
    protected abstract respondToFullscreenChange(state: boolean): void;
    protected abstract respondToError(errorInfo: ErrorInfoInterface): void;
    protected abstract respondToId3Data(d: any): void;
    protected abstract respondToTextTrackModeChange(enabled: boolean): void;

    protected loadVideo(): Promise<void> {
        this.respondToWaiting('loading', true);
        return this.pAdapter.load().then(() => {
            this.respondToWaiting('loading', false);
        });
    }

    protected playVideo(): void {
        this.pAdapter && this.pAdapter.play();
    }

    protected pauseVideo(): void {
        this.pAdapter && this.pAdapter.pause();
    }

    protected muteVideo(flag: boolean): void {
        if (!flag && !this.presoModel.userHasUnmuted) {
            this.presoModel.userHasUnmuted = true;
        }
        this.presoModel.isMuted = flag;
    }

    protected seekVideo(position: number): void {
        this.pAdapter && this.pAdapter.seek(position);
    }

    protected checkVideoBuffering(count: number): void {
        const mod = Math.max(Math.floor(this.bufferingSampleRate / TimerMediator.INTERVAL), 1);
        if (isFinite(mod) && count % mod) {
            return;
        }

        const buffering = this.pAdapter?.buffering || false;

        if (this.contentIsBuffering !== buffering) {
            this.contentIsBuffering = buffering;
            this.notify(NotificationName.CONTENT_BUFFERING, { value: buffering });
            this.respondToWaiting('buffering', buffering);
        }
    }

    protected get video(): HTMLVideoElement {
        return this.viewControl as HTMLVideoElement;
    }

    protected notify(name: NotificationName, data?: any): void {
        this.sendNotification(name, data || null, NotificationType.INTERNAL);
    }

    protected respondToIsPlayingLiveChange(isLive: boolean): void {
        this.notify(NotificationName.CONTENT_IS_LIVE_CHANGED, { value: isLive });
    }

    protected respondToStreamTypeChange(streamType: StreamType): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        if (this.streamType != streamType) {
            this.streamType = streamType;
            cps.streamType = streamType;
            this.notify(NotificationName.STREAM_TYPE_CHANGE, { value: streamType });
        }
    }

    protected respondToWaiting(key: string, value: boolean) {
        this.previousPlaybackState.overrides[key] = value;

        const waiting = !this.previousPlaybackState.state || Util.includes(Util.values(this.previousPlaybackState.overrides), true);

        this.respondToPlaybackStateChange(waiting ? PlaybackState.WAITING : this.previousPlaybackState.state);
    }

    protected respondToPlaybackStateChange(playbackState: PlaybackState): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        const notWaiting = playbackState !== PlaybackState.WAITING;

        if (cps.state != playbackState) {
            if (playbackState !== PlaybackState.IDLE && notWaiting) {
                this.previousPlaybackState.state = playbackState;
            }

            if (this.seeking && notWaiting) {
                return;
            }

            cps.state = playbackState;
            this.notify(NotificationName.PLAYBACK_STATE_CHANGE, { value: playbackState });
        }
    }

    protected respondToTtOffsetRequest(controlsVisible: boolean): void {
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        if (sys.browser === Browser.FIREFOX) {
            this.monitorTtCues(controlsVisible);
        }
        else {
            const dp = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
            dp && dp.setControlVisibility(controlsVisible);
        }
    }

    // For Firefox only
    protected monitorTtCues(flag: boolean): void {
        const ctt = Util.find(this.video.textTracks, track => Util.isTextTrack(track.kind) && track.mode === TextTrackMode.SHOWING);
        const cues = ctt?.activeCues;

        this.isMonitoringCueEvents = flag;

        if (!cues) {
            return;
        }

        for (let i = 0, n = cues.length || 0; i < n; i++) {
            this.respondToTtCue(cues[i]);
        }
    }

    // invoked for Firefox only
    protected respondToTtCue(cue: TextTrackCue): void {
        (<any>cue).line = this.isMonitoringCueEvents ? 14 : 'auto';
    }

    //////////////////////////
    // private and mvc internal
    handleNotification(notification: NotificationInterface): void {
        switch (notification.name) {
            case NotificationName.TIMER_TIC:
                this.respondToBufferingStatusCheck(notification.body.count);
                this.checkSize();
                break;

            case NotificationName.FULLSCREEN_CHANGE:
                const data = notification.body;
                this.presoModel.isFullscreen = data.isFullscreen;
                this.respondToFullscreenChange(data.isFullscreen);

                break;

            case NotificationName.AUTO_QUALITY_SWITCHING:
                this.notify(NotificationName.QUALITY_INFO_CHANGE, this.contentPlaybackStateProxy.model.qualityInfo);
                break;

            case NotificationName.TT_OFFSET_REQUEST:
                if (this.offsetTtOnControlVis) {
                    this.respondToTtOffsetRequest(notification.body.controlsVisible);
                }
                break;
        }
    }

    override listNotificationInterests(): string[] {
        return [
            NotificationName.TIMER_TIC,
            NotificationName.FULLSCREEN_CHANGE,
            NotificationName.TT_OFFSET_REQUEST
        ];
    }

    override onRegister(): void {
        super.onRegister();

        this.contentPlaybackStateProxy = <ContentPlaybackStateProxy>this.facade.retrieveProxy(ProxyName.ContentPlaybackStateProxy);
        this.resourceProxy = <ResourceProxy>this.facade.retrieveProxy(ProxyName.ResourceProxy);
        this.performanceProxy = <PerformanceProxy>this.facade.retrieveProxy(ProxyName.PerformanceProxy);
        this.presoModel = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        this.playerOptions = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);

        const overrides = this.resourceProxy.resource.overrides;
        if (Util.isNumber(overrides?.minDvrDuration)) {
            this.minDvrDuration = overrides.minDvrDuration;
        }

        if (this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted) {
            this.muteVideo(true);
            this.presoModel.isMuted = true;
        }

        this.offsetTtOnControlVis = this.playerOptions.offsetTextOnControlsVisible;

        this.bufferingSampleRate = this.performanceProxy.bufferingSampleRate;
    }

    protected prepareForPlayback(forcePlay: boolean = false): void {
        if (this.initializing) {
            return;
        }

        const config: PlaybackAdapterConfigInterface = {
            system: this.systemService,
            capabilities: (this.facade.retrieveService(ServiceName.MediaCapabilities) as MediaCapabilitiesServiceInterface).capabilities,
            playerOptions: this.playerOptions, //TODO should we have a playerOpts proxy?
            resource: this.resourceProxy.resource,
            performanceSettings: this.performanceProxy,
            textTrackSettings: <TextTrackProxy>this.facade.retrieveProxy(ProxyName.TextTrackProxy),
            video: this.viewControl,
            logger: this.logger,
        };

        this.adapterTask = this.initializeAdapter(config, forcePlay)
            .catch(this.onLoadError);
    }

    protected sendErrorNotification(name: NotificationName, error: any) {
        this.logger.log(LogLevel.ERROR, error);
        this.notify(name, error);
    }

    protected async getAdapter(config: PlaybackAdapterConfigInterface): Promise<PlaybackAdapterCoreInterface> {
        const legacyAdapter = await PlaybackAdapterFactory.getAdapter(config);
        if (legacyAdapter) {
            return legacyAdapter;
        }

        const proxy = this.facade.retrieveProxy(ProxyName.AdapterProxy) as AdapterProxy;
        const delegate = new PlaybackAdapterDelegate();
        const adapter = await proxy.createAdapter<PlaybackAdapterInterface, PlaybackAdapterDelegateInterface>(AdapterRole.PLAYBACK, config.resource, () => delegate);
        return new PlaybackAdapterWrapper(config, adapter, delegate);
    }

    protected async initializeAdapter(config: PlaybackAdapterConfigInterface, forcePlay: boolean) {
        try {
            this.initializing = true;

            // bail gracefully if the mediator is in the process of shutting down
            if (this.closing) {
                return;
            }

            // create and configure adapter
            try {
                const adapter = await this.getAdapter(config);

                // check for shutdown after every async process
                if (this.closing) {
                    return;
                }

                this.pAdapter = adapter;

                // NOTE: To avoid duplicate error events being dispatched in the public API, do not
                //       attach listeners for the for the error events until after the adapter has 
                //       successfully loaded the resource. (VTG-2393)
                const filter = (value: string) => value != PlaybackAdapterEvents.ERROR && value != VideoSurfaceEvents.ERROR;
                this.addEvents(PlaybackAdapterEvents, this.adapterEventHandler, filter);
                this.addEvents(VideoSurfaceEvents, this.videoEventHandler, filter);
                this.addEvents(TextTrackSurfaceEvents, this.textTrackEventHandler);

                this.pAdapter.initialize();

                if (config.playerOptions.overrides?.exposePlaybackAdapter === true) {
                    const app = this.facade.retrieveMediator(MediatorName.APPLICATION) as AppMediator;
                    const api = app.getAppApi();

                    // @ts-ignore
                    api.adapter = (this.pAdapter as any).adapter || this.pAdapter;
                }
            }
            catch (error) {
                throw { name: NotificationName.RESOURCE_ERROR, error };
            }

            // play resource
            try {
                // load resource
                if (this.preloadContent) {
                    await this.load();
                }

                // check for shutdown after every async process
                if (this.closing) {
                    return;
                }

                if (!(this.presoModel.isAutoplay || forcePlay)) {
                    return;
                }

                this.play();
            }
            catch (error) {
                throw { name: NotificationName.VIDEO_PLAYBACK_ERROR, error };
            }
        }
        finally {
            this.initializing = false;
        }
    }

    private hPlaybackAdaptorEvents(e: EventInterface): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        switch (e.type) {
            case PlaybackAdapterEvents.ABR_QUALITY_LOADED:
                if (this.contentPlaybackStateProxy.isAbrSwitchingAvailable) {
                    const qualityInfo: QualityInfoInterface = this.contentPlaybackStateProxy.qualityInfo,
                        bitrate = this.contentPlaybackStateProxy.manifestQualities[e.data.index].bitrate,
                        index = Util.getIndexForBitrate(qualityInfo.qualities, bitrate, false),
                        quality = qualityInfo.qualities[index];

                    if (!qualityInfo.quality || qualityInfo.quality.bitrate !== quality.bitrate) {
                        this.contentPlaybackStateProxy.quality = quality;
                        cps.bitrate = quality.bitrate;
                        this.respondToQualityChange(quality);
                    }
                }
                break;

            case PlaybackAdapterEvents.ABR_CONSTRAINTS_CHANGE:
                if (!this.contentPlaybackStateProxy.isAbrSwitchingAvailable) {
                    this.logger.log(LogLevel.INFO, AppResources.messages.ABR_UNAVAILABLE);
                    this.contentPlaybackStateProxy.qualitySwitchingMode = QualityMode.UNAVAILABLE;
                }
                else if (cps.qualityInfo) {
                    this.contentPlaybackStateProxy.updateQualityProfile(e.data.minIndex, e.data.maxIndex, e.data.manifestQualities);
                    this.log(LogLevel.INFO, 'Quality Info: ', cps.qualityInfo);
                    this.notify(NotificationName.QUALITY_INFO_CHANGE, cps.qualityInfo);
                }
                break;

            case PlaybackAdapterEvents.AUDIO_TRACK_UPDATED:
                this.contentPlaybackStateProxy.updateAudioTracks(e.data.track, e.data.tracks);
                this.notify(NotificationName.AUDIO_TRACK_INFO_CHANGE, e.data);
                break;

            case PlaybackAdapterEvents.AUDIO_TRACK_CHANGE:
                this.contentPlaybackStateProxy.updateAudioTracks(e.data.track);
                this.notify(NotificationName.AUDIO_TRACK_CHANGE, e.data.track);
                break;

            case PlaybackAdapterEvents.FRAGMENT_LOADED:
                this.contentPlaybackStateProxy.maxBandwidth = e.data.bandwidth;
                break;

            case PlaybackAdapterEvents.FRAGMENT_PARSED:
                cps.framerate = e.data.rate;
                break;

            case PlaybackAdapterEvents.MANIFEST_PARSED:
                this.contentPlaybackStateProxy.processQualityProfile(e.data.profile);
                this.contentPlaybackStateProxy.manifestQualities = e.data.profile;
                break;

            case PlaybackAdapterEvents.LOADED_METADATA:
                this.notify(NotificationName.STREAM_METADATA, e.data);

                try {
                    const resource = (<PlaylistInterface>(<unknown>this.facade.retrieveProxy(ProxyName.Playlist))).currentResource;
                    const url = resource.location.thumbnailTrackUrl;
                    if (!this.contentPlaybackStateProxy.thumbnailTrack && url) {
                        ThumbnailTrackSurface.create(url)
                            .then(t => {
                                this.contentPlaybackStateProxy.thumbnailTrack = t;
                                this.notify(NotificationName.THUMBNAIL_TRACK_AVAILABLE, t);
                            })
                            .catch(e => this.log(LogLevel.WARN, "Could not load thumbnail track"));
                    }
                }
                catch (error) {
                    this.log(LogLevel.WARN, "Could not load thumbnail track");
                }
                break;

            case PlaybackAdapterEvents.ERROR:
                this.respondToError(e.data as ErrorInfoInterface);
                break;

            case PlaybackAdapterEvents.MULTI_CDN:
                const cdn = e.data.cdn;
                if (cdn !== cps.cdn) {
                    cps.cdn = cdn;
                    this.notify(NotificationName.CDN_CHANGE, e.data);
                }
                break;

            case PlaybackAdapterEvents.DRM_KEYSYSTEM_CREATED:
                cps.drmType = e.data.keysystem;
                this.notify(NotificationName.DRM_KEYSYSTEM_CREATED, e.data);
                break;
        }
    }

    private hVideoAdapterEvents(e: EventInterface): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        switch (e.type) {
            case VideoSurfaceEvents.PROGRESS:
                cps.bufferLength = this.pAdapter.bufferLength;
                cps.averageDroppedFps = this.pAdapter.droppedVideoFrames;
                cps.framerate = this.pAdapter.framerate;
                this.respondToVideoProgress();
                break;

            case VideoSurfaceEvents.CAN_PLAY_THROUGH:
                if (!this.loadComplete) {
                    this.loadComplete = true;
                    this.notify(NotificationName.VIDEO_LOAD_COMPLETE);
                }
                break;

            case VideoSurfaceEvents.LOADED_METADATA:
                this.updateLiveStreamInfo();
                this.notify(NotificationName.VIDEO_LOADED_METADATA);
                break;

            case VideoSurfaceEvents.TIME_UPDATE:
                clearTimeout(this.endFreezeTimeoutHandle);
                const t = this.pAdapter.time;
                const d = this.presoModel.streamDuration;

                if (this.pAdapter.isLiveStream) {
                    this.updateLiveStreamInfo();
                }
                else if (!isNaN(d) && (d - t <= 0.15)) {
                    this.protectAgainstEndFreeze(250);
                }
                this.respondToVideoTimeUpdate(t);
                break;

            case VideoSurfaceEvents.PLAYING:
                this.updateLiveStreamInfo();
                this.respondToVideoPlaying();
                break;

            case VideoSurfaceEvents.PAUSE:
                this.respondToVideoPaused();
                break;

            case VideoSurfaceEvents.SEEKING:
                this.seeking = true;
                this.respondToVideoSeeking();
                this.respondToWaiting('seeking', true);
                break;

            case VideoSurfaceEvents.SEEKED:
                this.seeking = false;
                this.respondToVideoSeeked();
                this.respondToWaiting('seeking', false);
                break;

            case VideoSurfaceEvents.ENDED:
                clearTimeout(this.endFreezeTimeoutHandle);
                this.respondToVideoEnd();
                break;

            case VideoSurfaceEvents.DURATION_CHANGE:
                // Protect against invalid durations such as NaN
                const duration = this.pAdapter.duration;
                if (duration > 0) {
                    this.respondToDurationChange(duration);
                }
                break;

            case VideoSurfaceEvents.VOLUME_CHANGE:
                const video = e.data.target || e.data;
                this.notify(NotificationName.VOLUME_CHANGE, { value: video.volume, muted: video.muted });
                break;

            case VideoSurfaceEvents.AUTOPLAY_BLOCKED:
                this.sendNotification(NotificationName.AUTOPLAY_BLOCKED, e.data, NotificationType.INTERNAL);
                break;
        }
    }

    private hTextTrackEvents(e: EventInterface): void {
        const textTrackProxy = <TextTrackProxy>this.facade.retrieveProxy(ProxyName.TextTrackProxy);

        switch (e.type) {
            case TextTrackSurfaceEvents.METADATA_CUEPOINT:
                this.respondToId3Data(e.data);
                break;

            case TextTrackSurfaceEvents.TEXT_CUEPOINT:
                if (this.isMonitoringCueEvents) {
                    this.respondToTtCue(<TextTrackCue>e.data);
                }
                this.notify(NotificationName.TEXT_CUEPOINT, e.data);
                break;

            case TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE:
                const enabled = e.data.mode !== TextTrackMode.DISABLED;
                textTrackProxy.mode = e.data.mode;
                this.notify(NotificationName.TEXT_TRACK_DISPLAY_MODE_CHANGE, { enabled });
                this.respondToTextTrackModeChange(enabled);
                break;

            case TextTrackSurfaceEvents.TEXT_TRACK_ADDED:
                this.contentPlaybackStateProxy.addTextTrack(<TextTrackInterface>e.data);
                this.respondToTextTrackInfoChange();
                break;

            case TextTrackSurfaceEvents.TEXT_TRACK_CHANGE:
                const track = <TextTrackInterface>e.data;
                this.contentPlaybackStateProxy.textTrack = track;
                this.notify(NotificationName.TEXT_TRACK_CHANGE, this.contentPlaybackStateProxy.textTrack);
                this.respondToTextTrackInfoChange();
                break;

            case TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE:
                this.notify(NotificationName.TEXT_TRACK_AVAILABLE);
                break;
        }
    }

    private respondToTextTrackInfoChange(): void {
        this.notify(NotificationName.TEXT_TRACK_INFO_CHANGE, this.contentPlaybackStateProxy.textTrackInfo);
    }

    // TODO: This should be replaced with a debounced function
    private protectAgainstEndFreeze(delay: number) {
        clearTimeout(this.endFreezeTimeoutHandle);
        this.endFreezeTimeoutHandle = setTimeout(() => {
            this.respondToVideoEnd();
        }, delay);
    }

    private addEvents(map: StrStrDict, fn: EventHandler, filter = (i: string) => true): void {
        if (!this.pAdapter) {
            return;
        }

        Util.values<string>(map)
            .filter(filter)
            .forEach(value => this.pAdapter.on(value, fn));
    }

    private removeEvents(map: StrStrDict, fn: EventHandler): void {
        if (!this.pAdapter) {
            return;
        }

        Util.values<string>(map)
            .forEach(value => this.pAdapter.off(value, fn));
    }

    private calculateStreamType(): StreamType {
        if (this.pAdapter.isLiveStream) {
            const liveStreamInfo = this.pAdapter.liveStreamInfo;
            const dvr = liveStreamInfo.dvrWindowSize;
            const isLiveLinearStream = dvr < this.minDvrDuration || isNaN(dvr);
            return (isLiveLinearStream) ? StreamType.LIVE : StreamType.DVR;
        }
        else {
            return StreamType.VOD;
        }
    }

    private updateLiveStreamInfo(): void {
        if (!this.pAdapter.isLiveStream) {
            this.respondToStreamTypeChange(StreamType.VOD);
            return;
        }

        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        const liveStreamInfo = this.pAdapter.liveStreamInfo;
        cps.liveStreamInfo = liveStreamInfo;

        const streamType = this.calculateStreamType();
        this.respondToStreamTypeChange(streamType);

        const isPlayingLive = (streamType == StreamType.LIVE) ? true : liveStreamInfo.isPlayingLive;
        if (this.isPlayingLive != isPlayingLive) {
            this.isPlayingLive = isPlayingLive;
            this.respondToIsPlayingLiveChange(isPlayingLive);
        }

        if (cps.streamType == StreamType.DVR) {
            this.respondToDurationChange(liveStreamInfo.relativeDuration);
        }
    }
}
