import { Util } from '../core/Util';
import { ServiceName } from '../enum/ServiceName';
import { CapabilityConfigInterface, MediaCapabilitiesResultInterface, MediaCapabilitiesServiceInterface, MediaDecodeCapabilitiesResultInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { media_decode_configs } from '../util/config/MediaDecodeConfigs';
import { MediaCapabilitiesCodec } from '../util/enum/MediaCapabilitiesCodec';
import { MediaCapabilitiesMimeType } from '../util/enum/MediaCapabilitiesMimeType';
import { System } from '../util/System';
import { ServiceBase } from './ServiceBase';


export class MediaCapabilitiesService extends ServiceBase implements MediaCapabilitiesServiceInterface {

    private static instance: MediaCapabilitiesService;

    static getInstance(): MediaCapabilitiesService {
        if (!this.instance) {
            this.instance = new MediaCapabilitiesService();
        }

        return this.instance;
    }

    private pCapabilities: MediaCapabilitiesResultInterface;
    private types: StrAnyDict = {
        video: {
            HLS: MediaCapabilitiesMimeType.HLS,
            AVC: MediaCapabilitiesMimeType.MP4_VIDEO + '; codecs=' + MediaCapabilitiesCodec.AVC,
            HEVC: MediaCapabilitiesMimeType.MP4_VIDEO + '; codecs=' + MediaCapabilitiesCodec.HEVC,
            WEBM_VP8: MediaCapabilitiesMimeType.WEBM_VIDEO + '; codecs=' + MediaCapabilitiesCodec.VP8,
            WEBM_VP9: MediaCapabilitiesMimeType.WEBM_VIDEO + '; codecs=' + MediaCapabilitiesCodec.VP9
        },
        audio: {
            AVC: MediaCapabilitiesMimeType.MP4_AUDIO + '; codecs=' + MediaCapabilitiesCodec.AVC,
            HEVC: MediaCapabilitiesMimeType.MP4_AUDIO + '; codecs=' + MediaCapabilitiesCodec.HEVC,
            WEBM_VP8: MediaCapabilitiesMimeType.WEBM_AUDIO + '; codecs=' + MediaCapabilitiesCodec.VP8,
            WEBM_VP9: MediaCapabilitiesMimeType.WEBM_AUDIO + '; codecs=' + MediaCapabilitiesCodec.VP9
        }
    };

    constructor() {
        super(ServiceName.MediaCapabilities);
    }

    get capabilities(): MediaCapabilitiesResultInterface {
        return this.pCapabilities;
    }

    detectCapabilities(): Promise<MediaCapabilitiesResultInterface> {
        return new Promise((resolve, reject) => {
            let mediaCapabilitiesResult: MediaCapabilitiesResultInterface = {
                mediaDecodeCapabilitiesResults: [],
                drmCapabilitiesResults: [],
                hasMediaSource: this.hasMediaSource,
                hasEncryptedMediaSource: this.hasEncryptedMediaSource,
                isWebKit: this.isWebKit,
                supportsVp8: this.supportsVp8,
                supportsMediaDecodeConfigurations: this.supportsMediaDecodeConfigurations, //advance
                supportsNativeHls: this.isTypeSupported(this.types.video.HLS), //native hls
                supportsRequestMediaKeySystemAccess: this.supportsRequestMediaKeySystemAccess, //drm
                supportsAvc: false,
                supportsHevc: false,
                supportsWebM: false,
                supportsVp9: false
            };

            this.pCapabilities = mediaCapabilitiesResult;

            //Fork logic path to test deeper MediaCapabilities if they exist
            if (this.supportsMediaDecodeConfigurations) {

                this.testMediaDecodeCapabilities(mediaCapabilitiesResult, (mcResult: MediaCapabilitiesResultInterface) => {

                    const mDcr: MediaDecodeCapabilitiesResultInterface[] = mcResult.mediaDecodeCapabilitiesResults;

                    mediaCapabilitiesResult.supportsAvc = mDcr[0].supported;
                    mediaCapabilitiesResult.supportsHevc = mDcr[1].supported;
                    mediaCapabilitiesResult.supportsWebM = mDcr[2].supported;
                    mediaCapabilitiesResult.supportsVp9 = mDcr[2].supported;

                    resolve(mediaCapabilitiesResult);
                });
            }
            else {
                mediaCapabilitiesResult.supportsAvc = this.isTypeSupported(this.types.video.AVC);
                mediaCapabilitiesResult.supportsHevc = this.isTypeSupported(this.types.video.HEVC);
                mediaCapabilitiesResult.supportsWebM = this.supportsWebM;
                mediaCapabilitiesResult.supportsVp9 = this.supportsVp9;

                resolve(mediaCapabilitiesResult);
            }
        });
    }

    private get hasMediaSource(): boolean {
        return !!(System.global && (System.global.MediaSource || this.isWebKit));
    }

    private get hasEncryptedMediaSource(): boolean {
        const g = System.global,
            eme = g && (g.MediaKeys || g.WebKitMediaKeys || g.MSMediaKeys);
        return !!eme;
    }

    private get isWebKit(): boolean {
        return !!(System.global && System.global.WebKitMediaSource);
    }

    private get supportsWebM(): boolean {
        return this.supportsVp8 || this.supportsVp9;
    }

    private get supportsVp8(): boolean {
        return this.isTypeSupported(this.types.video.WEBM_VP8);
    }

    private get supportsVp9(): boolean {
        return this.isTypeSupported(this.types.video.WEBM_VP9);
    }

    private get supportsRequestMediaKeySystemAccess(): boolean {
        return !!(System.global && System.global.MediaKeySystemAccess);
    }

    private get supportsMediaDecodeConfigurations(): boolean {
        return !!(<any>navigator).mediaCapabilities;
    }

    private isTypeSupported(codec: string): boolean {

        let supported: boolean = false;

        if (!System.global) {
            return false;
        }

        if (this.isWebKit) {
            supported = System.global.WebKitMediaSource.isTypeSupported(codec);
        }
        else if (this.hasMediaSource) {
            supported = System.global.MediaSource.isTypeSupported(codec);
        }

        if (!supported) {
            //final check for codec support. 
            const element = document.createElement('video');
            if (Util.isObject(element) && Util.isFunction(element.canPlayType)) {
                if (element.canPlayType(codec) === 'probably' || element.canPlayType(codec) === 'maybe') {
                    supported = true;
                }
            }
        }

        return supported;
    }

    //Currently only works with Chrome 63+
    private testMediaDecodeCapabilities(mcResult: MediaCapabilitiesResultInterface, callback: Function): void {

        if (!(<any>navigator)) {
            callback(mcResult);
        }

        let i: number = 0;

        const hResults = (result: MediaDecodeCapabilitiesResultInterface) => {

            mcResult.mediaDecodeCapabilitiesResults.push(result);

            if (mcResult.mediaDecodeCapabilitiesResults.length === media_decode_configs.length) {
                //end testing
                callback(mcResult);
            }
            else {
                /*eslint-disable-next-line*/
                runTest(media_decode_configs[i]);
                i++;
            }
        };

        const runTest = (config: CapabilityConfigInterface) => {

            let mdcResult: MediaDecodeCapabilitiesResultInterface = <MediaDecodeCapabilitiesResultInterface>{
                type: config.type
            };

            const { decodingInfo } = (<any>navigator).mediaCapabilities;

            if (decodingInfo) {
                decodingInfo(config.config).then((result: any) => {
                    mdcResult.supported = result.supported;
                    mdcResult.smooth = result.smooth;
                    mdcResult.powerEfficient = result.powerEfficient;

                    hResults(mdcResult);

                }).catch(() => {

                    hResults(mdcResult);
                });
            }
            else {
                // decodingInfo is not available
                hResults(mdcResult);
            }
        };

        runTest(media_decode_configs[i]);
        i++;
    }
}
