import { HookType } from '..';
import { delegateApi, delegateMethod } from '../app/ApiDecorators';
import { AppResources } from '../app/AppResources';
import { Util } from '../core/Util';
import { AdBreakType } from '../enum/AdBreakType';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { NotificationInterface, PresentationMediatorInterface, SystemServiceInterface } from '../iface';
import { AdAdapterDelegateInterface } from '../iface/AdAdapterDelegateInterface';
import { AdAdapterInterface } from '../iface/AdAdapterInterface';
import { AdBreakScheduleItemInterface } from '../iface/AdBreakScheduleItemInterface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { AdItemInterface } from '../iface/AdItemInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { HookProxy } from '../model/HookProxy';
import { CommonPresentationMediator } from './CommonPresentationMediator';


export class AdPresentationMediator extends CommonPresentationMediator implements PresentationMediatorInterface, AdAdapterDelegateInterface {

    private pAdAdapter: AdAdapterInterface = null;
    private adAdapterStarted: boolean = false;
    private contentSegmentStarted: boolean = false;
    private contentStartReleased: boolean = false;
    private contentCompleteReleased: boolean = false;
    private fatalContentErrorReceived: boolean = false;
    private presoComplete: boolean = false;
    private hasMidRolls: boolean = false;
    private pendingSeekTime: number = null;
    private currentAd: AdItemInterface = null;
    private currentBreak: AdBreakScheduleItemInterface = null;
    private breakSchedule: AdBreakScheduleItemInterface[];

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
        this.preloadContent = false;
    }

    set adAdapter(adapter: AdAdapterInterface) {
        this.pAdAdapter = adapter;
    }

    get adAdapter(): AdAdapterInterface {
        return this.pAdAdapter;
    }

    get adInProgress(): boolean {
        return this.presoModel.isCurrentVideoAd;
    }

    override onRemove(): void {
        this.pAdAdapter?.destroy?.();
        this.pAdAdapter = null;

        super.onRemove();
    }

    override closeAds(): void {
        this.domProxy?.showAdContainer(false);
        this.adAdapter?.destroy();
        this.pAdAdapter = null;
    }

    override beforePlayOnUserGesture() {
        this.adAdapter?.playClicked();
    }

    override start(): void {
        const contentStartTime = this.resourceProxy.playback.startTime;
        const showPreRoll = this.resourceProxy.ad.showPrerollOnNonZeroStart;
        const nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0;

        super.start();

        this.mute(this.presoModel.isMuted);

        if (nonZeroStart && showPreRoll) {
            this.pendingSeekTime = contentStartTime;
        }

        if (this.isClickToPlay) {
            this.setForClickToPlay();
        }
        else {
            const r = this.resourceProxy.resource;
            if (r.ad?.csai?.adCallUrl && !r.location?.mediaUrl) {
                this.play();
            }
            else {
                this.notify(NotificationName.VIDEO_LOAD_START);
                this.prepareForPlayback();
            }
        }
    }

    /** override */
    override getAdBreakTimes(): AdCuePointInterface[] {
        const aci: AdCuePointInterface[] = [];
        this.breakSchedule.forEach(b => {
            aci.push({
                start: b.startTime,
                streamTimeStart: b.streamStartTime,
                end: b.endTime,
                streamTimeEnd: b.streamStartTime + b.duration,
                played: b.hasPlayed,
                ...b
            });
        });

        return aci;
    }

    getDelegate(): AdAdapterDelegateInterface {
        const obj = delegateApi({}, this);

        return <AdAdapterDelegateInterface>obj;
    }

    override play(): void {
        if (!this.presoModel.started) {
            this.presoModel.started = true;
            this.adAdapterStarted = true;

            this.adAdapter.start();
        }
        else {
            if (this.adInProgress) {
                if (this.adAdapter?.resume) {
                    this.adAdapter.resume();
                }
                else {
                    this.playVideo();
                }

                return;
            }
            this.playVideo();
        }
    }

    override pause() {
        if (this.adInProgress) {
            if (this.adAdapter?.pause) {
                this.adAdapter.pause();
            }
            else {
                this.pauseVideo();
            }

            return;
        }
        this.pauseVideo();
    }

    // override
    override async mute(flag: boolean) {
        this.adAdapter?.setMuteState(flag);
        super.mute(flag);
    }

    override setVolume(value: number) {
        this.adAdapter?.setVolume(value);
        super.setVolume(value);
    }

    // override
    override seek(position: number): void {
        let seekTime = position;

        if (this.hasMidRolls && this.adAdapter) {
            const t = this.adAdapter.getPermittedSeekTime(position);
            if (t != position) {
                this.pendingSeekTime = this.adAdapter.streamTimeForContentTime?.(position) || t;
                seekTime = t;
                this.notify(NotificationName.SEEK_REDIRECT_START, {
                    requestedSeekTime: position,
                    actualSeekTime: t
                });
            }
        }

        this.seekVideo(seekTime);
    }

    skipAd() {
        this.adAdapter?.skipAd();
    }
    ///////////////////////////////////////////////////////////
    // Delegate interface
    ///////////////////////////////////////////////////////////

    @delegateMethod()
    pauseContent() {
        this.pauseVideo();
        this.domProxy?.showAdContainer(true);
    }

    @delegateMethod()
    resumeContent() {
        this.currentAd = null;
        this.currentBreak = null;
        this.domProxy?.showAdClickElement(false);
        this.domProxy?.showAdContainer(false);

        if (this.fatalContentErrorReceived) {
            this.notify(NotificationName.DISABLE_UI);
            return;
        }

        if (!this.hasContent || this.contentComplete) {
            super.respondToVideoEnd();
            return;
        }

        if (!this.contentSegmentStarted) {
            if (!this.contentStartReleased) {
                const contentStartTime = this.resourceProxy.playback.startTime,
                    nonZeroStart = !isNaN(contentStartTime) && contentStartTime > 0;

                if (nonZeroStart) {
                    const breaks = this.getAdBreakTimes();
                    const adjustedStart = this.adjustStartTimeForAdBreakProximity(contentStartTime, breaks);

                    this.resourceProxy.playback.startTime = adjustedStart;
                }
            }

            this.load()
                .then(() => {
                    this.resumeContentPlayComplete();
                })
                .catch(this.onLoadError);

            return;
        }

        this.resumeContentPlayComplete();
    }

    @delegateMethod()
    streamIdAvailable(id: string): void {
        this.notify(NotificationName.STREAM_ID_AVAILABLE, { streamId: id });
    }


    @delegateMethod()
    applyHook<T>(type: HookType, data: T): Promise<T> {
        return new Promise((resolve) => {
            const hp = <HookProxy>this.getProxy(ProxyName.HookProxy);
            hp.applyHook(type, data).then(d => resolve(d));
        });
    }

    @delegateMethod()
    adSegmentStarted() {
        this.contentSegmentStarted = false;
        this.presoModel.isCurrentVideoAd = true;
        this.notify(NotificationName.AD_SEGMENT_START);
    }

    @delegateMethod()
    adSegmentEnded() {
        this.contentSegmentStarted = false;
        if (this.presoModel) {
            this.presoModel.isCurrentVideoAd = false;
        }
        this.notify(NotificationName.AD_SEGMENT_END);
    }

    @delegateMethod()
    adBreaksAvailable(adBreaks: AdBreakScheduleItemInterface[]): void {
        this.hasMidRolls = adBreaks.some(b => b.type === AdBreakType.MID);
        this.breakSchedule = adBreaks;
        // TODO  account for API method getAdBreakTimes();
        const times = adBreaks.map(b => b.startTime);
        this.notify(NotificationName.AD_CUEPOINTS_AVAILABLE, { cuepoints: times });
    }

    @delegateMethod()
    seekToStreamTime(t: number) {
        this.seekVideo(t);
    }

    @delegateMethod()
    adBreakStart(): void {
        this.endContentSegment();
        this.updateSize();
        this.domProxy?.showAdContainer(true);
        this.presoModel.isCurrentVideoAd = true;

        this.setAdPlaying();
        this.notify(NotificationName.AD_BREAK_START);
    }

    @delegateMethod()
    adBreakMetadata(breakInfo: AdBreakScheduleItemInterface): void {
        this.currentBreak = breakInfo;
        this.notify(NotificationName.AD_BREAK_METADATA, { adBreakInfo: breakInfo });
    }

    @delegateMethod()
    adLoaded(): void {
        // no impl
    }

    @delegateMethod()
    adStart(adData: AdItemInterface): void {
        this.currentAd = adData;
        this.notify(NotificationName.AD_START, { adInfo: adData });
    }

    @delegateMethod()
    adProgress(currentTime: number, duration: number): void {
        this.notify(NotificationName.AD_TIME_UPDATE, {
            currentTime: currentTime,
            duration: duration,
            streamTime: this.presoModel.streamTime,
            streamDuration: this.presoModel.streamDuration
        });
        this.checkPreload(currentTime, duration);
    }

    @delegateMethod()
    adFirstQuartile(): void {
        this.notify(NotificationName.AD_FIRST_QUARTILE);
    }

    @delegateMethod()
    adMidpoint(): void {
        this.notify(NotificationName.AD_MIDPOINT);
    }

    @delegateMethod()
    adThirdQuartile(): void {
        this.notify(NotificationName.AD_THIRD_QUARTILE);
    }

    @delegateMethod()
    adComplete(): void {
        this.currentAd = null;
        this.notify(NotificationName.AD_COMPLETE);
    }

    @delegateMethod()
    adBreakComplete(): void {
        this.currentBreak = null;
        this.currentAd = null;
        this.domProxy?.showAdContainer(false);
        this.domProxy?.showAdClickElement(false);
        this.presoModel.isCurrentVideoAd = false;
        this.notify(NotificationName.AD_BREAK_COMPLETE);

        if (this.pendingSeekTime) {
            const t = this.pendingSeekTime;
            this.pendingSeekTime = null;
            this.contentPlaybackStateProxy.model.started && this.notify(NotificationName.SEEK_REDIRECT_COMPLETE);
            this.seekVideo(t);
        }
    }

    @delegateMethod()
    adClicked(): void {
        const cp = this.contentPlaybackStateProxy?.model?.state;
        if (cp === PlaybackState.PLAYING) {
            this.pause();
        }
    }

    @delegateMethod()
    adError(info: ErrorInfoInterface): void {
        this.currentAd = null;
        if (!info.message) {
            info.message = AppResources.messages.UNSPECIFIED_ERROR;
        }
        this.notify(NotificationName.AD_ERROR, info);
    }

    @delegateMethod()
    adPaused(): void {
        this.respondToPlaybackStateChange(PlaybackState.PAUSED);
        this.notify(NotificationName.AD_PAUSED);
    }

    @delegateMethod()
    adResumed(): void {
        this.notify(NotificationName.AD_BUFFERING, { value: false });
        this.notify(NotificationName.AD_PLAYING);
    }

    @delegateMethod()
    adStalled(): void {
        this.notify(NotificationName.AD_STALLED);
    }

    @delegateMethod()
    adBreakDiscarded(): void {
        this.notify(NotificationName.AD_BREAK_DISCARDED);
    }

    @delegateMethod()
    adSkipped(): void {
        this.notify(NotificationName.AD_SKIPPED);
    }

    @delegateMethod()
    allAdsComplete(): void {
        this.hasMidRolls = false;
    }

    @delegateMethod()
    adBuffering(state: boolean): void {
        // TODO
    }

    @delegateMethod()
    displayAdClickElement(): void {
        this.domProxy?.showAdClickElement(true);
    }

    ///////////////////////////////////////////////////////////
    // END Delegate interface
    ///////////////////////////////////////////////////////////


    private resumeContentPlayComplete() {
        if (this.pendingSeekTime) {
            this.notify(NotificationName.SEEK_REDIRECT_COMPLETE);
            this.seekVideo(this.pendingSeekTime);
            this.pendingSeekTime = null;
        }

        this.play();
    }

    ///////////////////////////////////////////////////////////
    // Respond to playback commands and adapter events
    // +  calls to adapter
    override handleNotification(notification: NotificationInterface): void {
        const n = notification.name;

        switch (n) {
            case NotificationName.FULLSCREEN_EXIT:
            case NotificationName.FULLSCREEN_ENTER:
                this.adAdapter.setFullscreenState(n == NotificationName.FULLSCREEN_ENTER);
                break;
        }

        super.handleNotification(notification);
    }

    protected updateSize() {
        const dimensions = this.domProxy?.getPresentationRect();
        if (dimensions) {
            this.adAdapter.updateSize(dimensions);
        }
    }

    protected override respondToSizeChange(): void {
        this.updateSize();
    }

    protected override respondToId3Data(d: any): void {
        if (d.info) {
            d.msg = d.info;
        }
        this.adAdapter.handleTimedMetadata(d);
        super.respondToId3Data(d);
    }

    protected override respondToVideoPaused(): void {
        super.respondToVideoPaused();

        if (this.adInProgress) {
            this.notify(NotificationName.AD_PAUSED);
        }
        else {
            this.respondToPlaybackStateChange(PlaybackState.PAUSED);
            this.setPausedState();
            this.notify(NotificationName.CONTENT_PAUSED);
        }
    }

    protected override respondToVideoPlaying(): void {
        super.respondToVideoPlaying();
        if (!this.adAdapterStarted) {
            this.adAdapterStarted = true;
            this.adAdapter.start();
        }

        const t = this.contentPlaybackStateProxy.model.time;

        if (t > 0.5) {
            if (this.adInProgress) {
                this.notify(NotificationName.AD_PLAYING);
            }
            else if (this.contentSegmentStarted) {
                this.respondToPlaybackStateChange(PlaybackState.PLAYING);
                this.setPlayingState();
                this.notify(NotificationName.CONTENT_PLAYING);
            }
        }
    }

    // Call plugin.setStreamTime()
    protected override respondToVideoTimeUpdate(streamTime: number): void {
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;

        this.presoModel.streamTime = streamTime;
        this.adAdapter?.setStreamTime(streamTime);

        cps.time = this.adAdapter?.contentTimeForStreamTime ? this.adAdapter?.contentTimeForStreamTime(streamTime) : streamTime;

        if (this.adInProgress) {
            return;
        }

        if (streamTime > 0.75 && !this.contentSegmentStarted) {
            cps.started = true;
            this.domProxy?.showAdClickElement(false);
            this.domProxy?.showAdContainer(false);
            this.setPlayingState();
            this.setTransportType();
            !this.contentStartReleased && this.signalContentStart();
            this.contentSegmentStarted = true;

            if (this.hasMidRolls || cps.streamType == StreamType.LIVE || cps.streamType == StreamType.DVR) {
                this.notify(NotificationName.CONTENT_SEGMENT_START);
            }
            else {
                this.notify(NotificationName.CONTENT_PLAYING);
            }
        }

        if (this.contentSegmentStarted) {
            super.respondToVideoTimeUpdate(streamTime);
        }

        if (cps.streamType !== StreamType.LIVE && (cps.time >= cps.duration) && !this.contentCompleteReleased) {
            this.signalContentComplete();
        }
    }

    protected endContentSegment() {
        if (this.contentSegmentStarted) {
            this.contentSegmentStarted = false;
            this.notify(NotificationName.CONTENT_SEGMENT_END);
        }
    }

    protected override respondToVideoEnd(): void {
        this.contentComplete = true;
        if (!this.contentCompleteReleased) {
            this.signalContentComplete();
        }
        this.presoComplete = true;
        super.respondToVideoEnd();
    }

    protected override respondToDurationChange(duration: number): void {
        if (duration && !isNaN(duration) && duration > 0) {

            this.adAdapter.setStreamDuration(duration);
            this.presoModel.streamDuration = duration;

            const contentDur = this.adAdapter.contentTimeForStreamTime?.(duration) || duration;

            this.contentPlaybackStateProxy.model.duration = contentDur;

            if (!this.contentDurationReleased) {
                this.releaseContentDuration(duration);
            }
        }
    }

    protected override respondToError(data: ErrorInfoInterface) {
        this.fatalContentErrorReceived = data.fatal;
        super.respondToError(data);
    }

    protected override isContentComplete() {
        return this.presoComplete;
    }

    ///////////
    // PRIVATE
    protected override startContentSegment(): void {
        this.contentSegmentStarted = true;
        this.contentPlaybackStateProxy.model.started = true;
        this.domProxy?.showAdContainer(false);
        this.setPlayingState();

        if (!this.contentStartReleased) {
            this.contentStartReleased = true;
            this.notify(NotificationName.CONTENT_START);
        }

        super.startContentSegment();

        this.notify(NotificationName.CONTENT_SEGMENT_START);
    }

    private checkPreload(currentTime: number, duration: number): void {
        const preload = this.resourceProxy.ad.csai.preloadContentAtEndOfPreRoll === true;
        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        if (!preload) {
            return;
        }

        const lastAd = this.currentAd?.adPosition === this.currentBreak?.adTotal;

        if (preload && lastAd && !sys.isIos) {
            const threshold = Util.clampValue(Math.ceil(duration / 4), 2, 10);
            const time = Math.round(currentTime);
            if (time >= threshold) {
                this.load().catch(this.onLoadError);
            }
        }
    }

    private signalContentStart(): void {
        this.contentStartReleased = true;
        this.notify(NotificationName.CONTENT_START);
    }

    private signalContentComplete(): void {
        this.contentCompleteReleased = true;
        if (this.contentSegmentStarted) {
            this.notify(NotificationName.CONTENT_SEGMENT_END);
        }
        this.notify(NotificationName.CONTENT_COMPLETE);
    }

    override listNotificationInterests(): string[] {
        return super.listNotificationInterests().concat([
            NotificationName.FULLSCREEN_ENTER,
            NotificationName.FULLSCREEN_EXIT,
            NotificationName.PRESENTATION_SIZE_CHANGE
        ]);
    }

    override onRegister() {
        super.onRegister();
        this.domProxy?.showAdContainer(false);
        this.domProxy?.showAdClickElement(false);
    }
}
