import { buildInfo } from '../../core/BuildInfo';
import { Util } from '../../core/Util';
import { LogLevel } from '../../enum/LogLevel';
import { ModelName } from '../../enum/ModelName';
import { NotificationName } from '../../enum/NotificationName';
import { PlaybackState } from '../../enum/PlaybackState';
import { ProxyName } from '../../enum/ProxyName';
import { ServiceName } from '../../enum/ServiceName';
import { StreamType } from '../../enum/StreamType';
import { PresentationMediatorInterface, SystemServiceInterface } from '../../iface';
import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { AdCuePointInterface } from '../../iface/AdCuePointInterface';
import { AdItemInterface } from '../../iface/AdItemInterface';
import { ContentPlaybackStateInterface } from '../../iface/ContentPlaybackStateInterface';
import { EventHandler } from '../../iface/EventHandler';
import { EventInterface } from '../../iface/EventInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';
import { ResourceProxy } from '../../model/ResourceProxy';
import { DashEmsg } from '../../playback/enum/DashEmsg';
import { CommonPresentationMediator } from '../../view/CommonPresentationMediator';
import { Deprecated } from '../Deprecated';
import { AdDataProxy } from './AdDataProxy';
import { assembleStreamRequest } from './ad_util';
import { dai } from './dai';
import { DaiStreamManager } from './DaiStreamManager';
import { DaiStreamRequestAssembler } from './DaiStreamRequestAssembler';
import { AdDataProxyInterface, AdPlaybackContextInterface, DaiAdServiceInterface } from './iface';


/**
 * DaiPresentationMediator is used for Google DAI streams (combined content/ad stream)
 * 
 * @deprecated
 */
export class DaiPresentationMediator extends CommonPresentationMediator implements PresentationMediatorInterface {

    private adDataProxy: AdDataProxyInterface;
    private streamManager!: DaiStreamManager;
    private streamEventHandler!: EventHandler;
    private contentSegmentStarted: boolean = false;
    private contentStartReleased: boolean = false;
    private pendingSeekTime: number = null;
    private contentCompleteReleased: boolean = false;
    private presoComplete: boolean = false;
    private rawAdSchedule: AdCuePointInterface[] = null;

    constructor(name: string, viewControl?: any) {
        super(name, viewControl);
        Deprecated.warn('The internal ad service is deprecated. Please use the @cbsinteractive/avia-js-gam plugin. Access to this property will be completely removed July 1st, 2022');
    }

    override onRemove(): void {
        this.listenToStreamManager(false);
        this.adService && this.adService.reset();
        this.streamManager?.destroy();
        this.streamManager = null;
        this.adDataProxy = null;

        super.onRemove();
    }

    override closeAds(): void {
        this.listenToStreamManager(false);
        this.domProxy?.showAdContainer(false);
        this.adService?.reset();
    }

    override start(): void {
        const pm = this.presoModel;

        if (this.uiMediator) {
            this.uiMediator.setMuteState(pm.isMuted);
            if (this.resourceProxy?.ad?.suppressAdUi === true) {
                this.uiMediator.adViewSuppressed = true;
            }
        }

        this.adService.setMuted(pm.isMuteAtPlayStart && !pm.userHasUnmuted);
        this.presoModel.usesSsai = true;
        this.initStreamManager();
    }

    override getAdBreakTimes(): AdCuePointInterface[] {
        return this.streamManager ? this.streamManager.getCuePointsInContentTime() : null;
    }

    // Note:  DaiStreamManager wraps a Google SDK StreamManager,
    // or its equivalent if using the DAI API
    initStreamManager(): void {
        const streamRequest = this.assembleStreamRequest(),
            pOpts = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions),
            isLive = !Util.isEmpty(streamRequest.assetKey);

        this.streamManager = new DaiStreamManager(this.adService, this.playerOptions.enableOm);
        this.streamEventHandler = (e: EventInterface) => { this.hStreamEvent(e); };
        this.listenToStreamManager(true);

        if (isLive) {
            this.streamManager.requestLiveStream(<dai.LiveStreamRequest>streamRequest, pOpts.networkErrorRecovery);
        }
        else {
            this.streamManager.requestVODStream(<dai.VODStreamRequest>streamRequest, pOpts.networkErrorRecovery);
        }
    }

    assembleStreamRequest(): dai.StreamRequest {
        const resource: ResourceConfigurationInterface = (<ResourceProxy>this.facade.retrieveProxy(ProxyName.ResourceProxy)).resource;
        const context: AdPlaybackContextInterface = {
            buildEnv: buildInfo.env,
            platform: this.adService.getGamPlatformString(),
            mutedPlayback: this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted
        };
        const useLegacyGam = !Util.isEmpty(resource.ad.dai?.daiVideoId || resource.ad.dai?.assetKey);
        const useFw = (resource.ad.ssai?.videoId || resource.ad.ssai?.assetKey) && !Util.isEmpty(resource.ad.ssai?.adParameters);

        if (useLegacyGam) {
            const assembler = new DaiStreamRequestAssembler();

            return assembler.assembleStreamRequest(
                resource,
                context,
                false,
                {
                    playername_version: this.getPlayerVersionInfo()
                }
            );
        }
        else if (useFw) {
            const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

            return assembleStreamRequest(
                resource.ad.ssai,
                {
                    muted: this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted,
                    autoplay: this.presoModel.isAutoplay
                },
                !(sys.isDesktop || sys.isMobile),
                {
                    imafw_playername_version: this.getPlayerVersionInfo()
                }
            );
        }

        return null;
    }

    override mute(flag: boolean): void {
        this.muteVideo(flag);
        this.uiMediator && this.uiMediator.setMuteState(flag);
        this.adService.setMuted(flag);
    }

    override seek(position: number): void {
        if (this.isAdPlaying()) { return; }

        const t: number = this.streamManager.getPermittedSeekTime(position),
            t2 = t === position ? this.streamManager.streamTimeForContentTime(t) : t;

        if (t !== position) {
            this.notify(NotificationName.SEEK_REDIRECT_START, {
                requestedSeekTime: position,
                actualSeekTime: t
            });
            this.pendingSeekTime = this.streamManager.streamTimeForContentTime(position);
        }

        this.seekVideo(t2);
    }

    protected override respondToId3Data(d: any): void {
        const msg = !Util.isEmpty(d.info) ? d.info : (!Util.isEmpty(d.data) ? d.data : null);

        if (msg && (d.id === 'google_dai' || d.id == DashEmsg.GOOGLE_DAI)) {
            this.log(LogLevel.INFO, `metadata received - id: ${d.id} msg ${msg}`);
            this.streamManager.onTimedMetadata({ TXXX: msg });
        }
        else {
            super.respondToId3Data(d);
        }
    }

    protected override respondToVideoPlaying(): void {
        if (!this.presoModel.started) {
            this.presoModel.started = true;

            if (this.uiMediator) {
                this.uiMediator && this.uiMediator.displayPoster(false);
                this.uiMediator && this.uiMediator.hideClickCatcher(true);
                this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted && this.uiMediator.showUnmutePrompt();
                this.setTransportType();
            }

            this.notify(NotificationName.ENABLE_UI);
        }

        super.respondToVideoPlaying();

        if (this.isAdPlaying()) {
            this.notify(NotificationName.AD_PLAYING);
        }
        else if (this.contentSegmentStarted) {
            this.respondToPlaybackStateChange(PlaybackState.PLAYING);
            this.setPlayingState();
            this.notify(NotificationName.CONTENT_PLAYING);
        }
    }

    protected override respondToVideoPaused(): void {
        super.respondToVideoPaused();

        if (this.isAdPlaying()) {
            this.notify(NotificationName.AD_PAUSED);
        }
        else {
            this.respondToPlaybackStateChange(PlaybackState.PAUSED);
            this.setPausedState();
            this.notify(NotificationName.CONTENT_PAUSED);
        }
    }

    protected override respondToVideoTimeUpdate(streamTime: number): void {
        if (this.contentComplete) {
            return;
        }
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        this.adService.setCurrentTime(streamTime);
        this.streamManager.ignoreAdEvents(false);

        this.presoModel.streamTime = streamTime;
        cps.time = this.streamManager.contentTimeForStreamTime(streamTime);

        // NOTE: 'ad period' denotes an ad break that has already played or is otherwise unlocked
        // the check will result in an AD_PERIOD_STARTED event (and in turn a seek to the next content segment)
        // Manual checking is necessary because The GAM SDK does not emit the AD_PERIOD_STARTED event
        const willSkipBreak = this.streamManager.checkForAdPeriod(streamTime);

        if (this.isAdPlaying() || willSkipBreak) {
            return;
        }

        // we may get a video time update before the stream pre-roll break event,
        // so wait a little bit (0.75 sec) to detect content start. If we're past 0.75
        // and there's no ad playing (see above), then we're in content.
        if (streamTime > 0.75 && !this.contentSegmentStarted) {
            this.contentSegmentStarted = true;
            this.contentPlaybackStateProxy.model.started = true;
            this.domProxy && this.domProxy.showAdContainer(false);
            this.setPlayingState();
            this.setTransportType();
            !this.contentStartReleased && this.signalContentStart();

            if (this.fullscreenRestrictedDuringAdPlay && this.uiMediator) {
                this.uiMediator.setFullScreenAccessRestricted(false);
                this.uiMediator.enableFullscreen();
            }

            this.notify(NotificationName.CONTENT_SEGMENT_START);
        }

        if (this.contentSegmentStarted) {
            super.respondToVideoTimeUpdate(streamTime);
        }

        if (cps.streamType !== StreamType.LIVE && (cps.time >= cps.duration) && !this.contentCompleteReleased) {
            this.signalContentComplete();
        }
    }

    protected override respondToVideoEnd(): void {
        this.contentComplete = true;
        if (!this.contentCompleteReleased) {
            this.signalContentComplete();
        }
        this.presoComplete = true;
        super.respondToVideoEnd();
    }

    protected override respondToDurationChange(duration: number): void {
        const cd = this.streamManager.contentTimeForStreamTime(duration);

        this.contentPlaybackStateProxy.model.duration = cd;
        this.presoModel.streamDuration = duration;
        this.adService.setDuration(duration);

        if (!this.contentDurationReleased && cd && !isNaN(cd) && cd > 0) {
            this.releaseContentDuration(cd);
        }
        this.streamManager.streamDuration = duration;
    }

    protected override isContentComplete() {
        return this.presoComplete;
    }

    ///////////
    // PRIVATE

    private signalContentStart(): void {
        this.contentStartReleased = true;
        this.notify(NotificationName.CONTENT_START);
    }

    private signalContentComplete(): void {
        this.contentCompleteReleased = true;
        this.notify(NotificationName.CONTENT_SEGMENT_END);
        this.notify(NotificationName.CONTENT_COMPLETE);
    }

    private get adService(): DaiAdServiceInterface {
        return <DaiAdServiceInterface>this.facade.retrieveService(ServiceName.DaiAd);
    }

    private handleMediaUrlAvailable(url: string): void {
        const suppliedContentStartTime = this.resourceProxy.playback.startTime,
            showPreRoll = this.resourceProxy.ad.showPrerollOnNonZeroStart,
            nonZeroStart = !isNaN(suppliedContentStartTime) && suppliedContentStartTime > 0;

        if (nonZeroStart) {
            const times: number[] = this.rawAdSchedule.map((o: AdCuePointInterface) => o.streamTimeStart),
                actualContentStartTime = this.adjustStartTimeForAdBreakProximity(suppliedContentStartTime, this.rawAdSchedule),
                streamStartTime = this.streamManager.streamTimeForContentTime(actualContentStartTime);

            this.checkForBreaksToUnlock(times);

            if (showPreRoll) {
                this.resourceProxy.playback.startTime = NaN;
                this.pendingSeekTime = streamStartTime;
            }
            else {
                const isDash = this.resourceProxy.ad.dai.format == 'dash';
                if (isDash) {
                    this.streamManager.ignoreAdEvents(true);
                }
                this.resourceProxy.playback.startTime = streamStartTime;
            }
        }

        this.resourceProxy.location.mediaUrl = url;

        this.adService.initViewabilityTracking();

        if (this.isClickToPlay) {
            this.setForClickToPlay();
        }
        else {
            this.notify(NotificationName.VIDEO_LOAD_START);
            this.prepareForPlayback();
        }
    }

    private checkForBreaksToUnlock(brkTimes: number[]) {
        if (!brkTimes || !brkTimes.length) { return; }

        const t = this.resourceProxy.playback.startTime,
            pre = this.resourceProxy.ad.showPrerollOnNonZeroStart,
            st = this.streamManager.streamTimeForContentTime(t),
            out = [];

        if (st > 0) {
            if (pre !== true) out.push(0);

            let i = brkTimes.length;
            while (i--) {
                if (i > 0 && st > brkTimes[i]) {
                    out.push(brkTimes[i]);
                    break;
                }
            }

            this.streamManager && this.streamManager.setUnlockedBreaks(out);
        }
    }

    private hStreamEvent(e: EventInterface): void {
        const dse = DaiStreamManager.event,
            t = e.type;

        //t != dse.AD_PROGRESS && console.log(`%cEvt: ${t}`, 'color: #808; font-size: 14px; font-weight: bold');

        switch (t) {
            case dse.RAW_AD_SCHEDULE_AVAILABLE:
                this.rawAdSchedule = e.data.schedule;
                break;

            case dse.STREAM_ID_AVAILABLE:
                this.presoModel.streamId = this.streamManager.streamId;
                this.notify(NotificationName.STREAM_ID_AVAILABLE, { streamId: this.streamManager.streamId });
                break;

            case dse.MEDIA_URL_AVAILABLE:
                this.handleMediaUrlAvailable(e.data.mediaUrl);
                break;

            case dse.AD_CUEPOINTS_AVAILABLE:
                this.notify(NotificationName.AD_CUEPOINTS_AVAILABLE, e.data);
                break;

            case dse.AD_PERIOD_STARTED:
                this.seekVideo(e.data.streamResumeTime);
                break;

            case dse.AD_BREAK_START:
                if (this.contentSegmentStarted) {
                    this.contentSegmentStarted = false;
                    this.notify(NotificationName.CONTENT_SEGMENT_END);
                }
                this.domProxy && this.domProxy.showAdContainer(true);
                this.presoModel.isCurrentVideoAd = true;

                this.uiMediator && this.uiMediator.hideClickCatcher(true);

                this.notify(NotificationName.AD_BREAK_START);
                break;

            case dse.AD_BREAK_METADATA:
                const bi = <AdBreakInfoInterface>e.data;
                this.adDataProxy.breakInfo = bi;
                this.notify(NotificationName.AD_BREAK_METADATA, { adBreakInfo: bi });
                break;

            case dse.AD_START:
                const adData = <AdItemInterface>e.data;
                this.adDataProxy.adInfo = adData;
                this.notify(NotificationName.AD_START, { adInfo: adData });
                if (this.contentPlaybackStateProxy.model.state === PlaybackState.PAUSED) {
                    this.notify(NotificationName.AD_PAUSED);
                }
                break;

            case dse.AD_PROGRESS:
                const cpsMdl = this.contentPlaybackStateProxy.model;
                if (cpsMdl.state === PlaybackState.PLAYING) {
                    const data: Record<string, number> = {
                        currentTime: e.data.currentTime,
                        duration: e.data.duration,
                        streamTime: this.presoModel.streamTime,
                        streamDuration: this.presoModel.streamDuration
                    };
                    if (cpsMdl.streamType === StreamType.LIVE) {
                        data.elapsedTimeMs = this.updateTimeSpent().elapsedTime;
                    }
                    this.notify(NotificationName.AD_TIME_UPDATE, data);
                }
                break;

            case dse.AD_FIRST_QUARTILE:
                this.notify(NotificationName.AD_FIRST_QUARTILE);
                break;

            case dse.AD_MIDPOINT:
                this.notify(NotificationName.AD_MIDPOINT);
                break;

            case dse.AD_THIRD_QUARTILE:
                this.notify(NotificationName.AD_THIRD_QUARTILE);
                break;

            case dse.AD_COMPLETE:
                this.notify(NotificationName.AD_COMPLETE);
                break;

            case dse.AD_BREAK_COMPLETE:
                this.domProxy && this.domProxy.showAdContainer(false);
                this.presoModel.isCurrentVideoAd = false;
                this.notify(NotificationName.AD_BREAK_COMPLETE);
                if (this.pendingSeekTime) {
                    this.contentPlaybackStateProxy.model.started && this.notify(NotificationName.SEEK_REDIRECT_COMPLETE);
                    this.seekVideo(this.pendingSeekTime);
                    this.pendingSeekTime = null;
                }
                break;

            case dse.AD_CLICK:
                this.pause();
                break;

            case dse.AD_ERROR:
                // An error from the StreamManager amounts to a fatal resource error
                e.data.fatal = true;
                this.notify(NotificationName.RESOURCE_ERROR, e.data);
                break;
        }
    }

    private listenToStreamManager(flag: boolean): void {
        if (!this.streamManager) { return; }
        const dse = DaiStreamManager.event,
            m = flag ? 'on' : 'off';

        for (let q in dse) {
            (<any>this.streamManager)[m](dse[q], this.streamEventHandler);
        }
    }

    override onRegister() {
        super.onRegister();
        this.domProxy && this.domProxy.showAdContainer(false);

        this.adDataProxy = new AdDataProxy(ProxyName.AdDataProxy, null);
        this.facade.registerProxy(this.adDataProxy);
    }
}
