import { dai } from '../dai';
import { StreamEvent } from './StreamEvent';
import { StreamManager } from './StreamManager';
import { VODStreamRequest } from './VODStreamRequest';

const se = <unknown>StreamEvent;
const vr = <unknown>VODStreamRequest;
const sm = <unknown>StreamManager;

/**
 * @deprecated
 */
export const DaiProxyApi: dai.Sdk = {
    StreamManager: <dai.StreamManagerConstructor>sm,
    StreamEvent: <dai.StreamEventConstructor>se,
    VODStreamRequest: <dai.VODStreamRequestConstructor>vr
};
