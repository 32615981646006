import { Util } from '../../../core/Util';
import { AdTagParametersInterface } from '../../../iface/AdTagParametersInterface';
import { dai } from '../dai';


export class VODStreamRequest {

    private req: dai.VODStreamRequest = {
        apiKey: null,
        streamActivityMonitorId: null,
        adTagParameters: null,
        assetKey: null,
        format: 'hls',
        contentSourceId: null,
        videoId: null
    };

    constructor(vodStrReq?: dai.VODStreamRequest) {
        vodStrReq && Util.assign(this.req, vodStrReq);
    }

    set adTagParameters(val: AdTagParametersInterface) {
        this.req.adTagParameters = val;
    }

    get adTagParameters(): AdTagParametersInterface {
        return this.req.adTagParameters;
    }

    set videoId(val: string) {
        this.req.videoId = val;
    }

    get videoId(): string {
        return this.req.videoId;
    }

    set contentSourceId(val: string) {
        this.req.contentSourceId = val;
    }

    get contentSourceId(): string {
        return this.req.contentSourceId;
    }

    set apiKey(val: string) {
        this.req.apiKey = val;
    }

    get apiKey(): string {
        return this.req.apiKey;
    }

    set authToken(val: string) {
        this.req.authToken = val;
    }

    get authToken(): string {
        return this.req.authToken;
    }

    set format(val: string) {
        this.req.format = val;
    }

    get format(): string {
        return this.req.format;
    }

    set assetKey(val: string) {
        this.req.assetKey = val;
    }

    get assetKey(): string {
        return this.req.assetKey;
    }

    set streamActivityMonitorId(val: string) {
        this.req.streamActivityMonitorId = val;
    }

    get streamActivityMonitorId(): string {
        return this.req.streamActivityMonitorId;
    }
}
