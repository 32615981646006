import { ModelSnapshotInterface } from '../deprecated/ModelSnapshotInterface';
import { ModelName } from '../enum/ModelName';
import { ProxyName } from '../enum/ProxyName';
import { PlayerDomProxyInterface } from '../iface';
import { ModelInterface } from '../iface/ModelInterface';
import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { Proxy } from '../mvc/Proxy';
import { ModelCollection } from './vo/ModelCollection';


export class ModelCollectionProxy extends Proxy {

    /*
        All models:

            AdBreakInfo             | Reset between resources
            AdItem                  | 
            ContentPlaybackState    |
            - - - - - - - - - - - - - - - - - - - - - - - - 
            PresentationState       | Partial reset
            ------------------------------------------------
            BuildInfo               | NO reset
            PlayerDom               |
            PlayerOptions           | 
    */

    private resettables: string[] = [
        ModelName.AdBreakInfo,
        ModelName.AdItem,
        ModelName.ContentPlaybackState
    ];

    constructor(name: string, data?: ModelCollection) {
        super(name, data);
    }

    override onRemove(): void {
        this.resettables = null;
        const pdp = <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
        if (pdp) {
            pdp.removeDom();
        }
        super.onRemove();
    }

    get collection(): ModelCollection {
        return <ModelCollection>(this.data);
    }

    /**
     * @deprecated
     */
    getSnapshot(): ModelSnapshotInterface {
        return this.collection.getSnapshot();
    }

    getModel<T>(name: string): T {
        return <T>((<ModelCollection>(this.data)).getModel(name));
    }

    reset(): void {
        const list = this.resettables;
        let i = list.length;

        while (i--) {
            (<ModelInterface>this.getModel(list[i])).reset();
        }

        // partial reset for presentation state
        const psi = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        psi.skipAutoplayCheck = !psi.isAutoplay || psi.autoplaySupport !== null;
        psi.isAutoplay = true; // coerce true for a reset (only should occur after a resource has played!)
        psi.isCurrentVideoAd = false;
        psi.started = false;
        psi.streamDuration = NaN;
        psi.streamTime = NaN;
    }
}
