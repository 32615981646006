import { AdBreakInfoInterface } from '../../iface/AdBreakInfoInterface';
import { AdItemInterface } from '../../iface/AdItemInterface';
import { ContentPlaybackStateInterface } from '../../iface/ContentPlaybackStateInterface';
import { ModelSnapshotInterface } from '../../iface/ModelSnapshotInterface';
import { PlayerDomInterface } from '../../iface/PlayerDomInterface';
import { PlayerOptionsInterface } from '../../iface/PlayerOptionsInterface';
import { PresentationStateInterface } from '../../iface/PresentationStateInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { config } from '../cfg';
import { AdBreakInfo } from './AdBreakInfo';
import { AdItem } from './AdItem';
import { ContentPlaybackState } from './ContentPlaybackState';
import { PlayerDom } from './PlayerDom';
import { PlayerOptions } from './PlayerOptions';
import { PresentationState } from './PresentationState';


export class ModelCollection {

    private mdlCollection: StrAnyDict;

    constructor() {
        this.mdlCollection = {};
        this.createModels();
    }

    destroy() {
        for (let q in this.mdlCollection) {
            delete this.mdlCollection[q];
        }
        this.mdlCollection = null;
    }

    getModel<T>(name: string): T {
        return <T>(this.mdlCollection[name]);
    }

    getSnapshot(): ModelSnapshotInterface {
        const out = <ModelSnapshotInterface>{};
        for (let q in this.mdlCollection) {
            if (q == 'PlayerDom' || q == 'PlayerOptions' || q == 'BuildInfo') continue;
            out[q] = this.mdlCollection[q].data;
        }

        return out;
    }

    get AdBreakInfo(): AdBreakInfoInterface { return this.mdlCollection.AdBreakInfo; }

    get AdItem(): AdItemInterface { return this.mdlCollection.AdItem; }

    get ContentPlaybackState(): ContentPlaybackStateInterface { return this.mdlCollection.ContentPlaybackState; }

    get PlayerDom(): PlayerDomInterface { return this.mdlCollection.PlayerDom; }

    get PlayerOptions(): PlayerOptionsInterface { return this.mdlCollection.PlayerOptions; }

    get PresentationState(): PresentationStateInterface { return this.mdlCollection.PresentationState; }


    private createModels(): void {
        let models = [
            AdBreakInfo,
            AdItem,
            ContentPlaybackState,
            PlayerDom,
            PlayerOptions,
            PresentationState,
        ] as any,
            i = models.length;

        while (i--) {
            let c = models[i],
                n = c.modelName,
                cfg = config[this.classToCfgName(n)];

            this.mdlCollection[n] = new c(cfg);
        }
    }

    private classToCfgName(str: string): string {
        return (str.replace(/(?:^|\.?)([A-Z])/g, function (x, y) {
            return '_' + y.toLowerCase();
        }).replace(/^_/, '')) + '_config';
    }
}
