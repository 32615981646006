/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {NotificationHandler, NotificationInterface, ObserverInterface} from "../iface";

export class Observer implements ObserverInterface {

    private notify: NotificationHandler;
    private context: any;

    constructor (notifyMethod: NotificationHandler, notifyContext: any) {
        this.setNotifyMethod(notifyMethod);
        this.setNotifyContext(notifyContext);
    }

    destroy(): void {
        this.context = null;
        this.notify = null;
    }

    setNotifyMethod(notifyMethod: NotificationHandler): void {
        this.notify = notifyMethod;
    }

    setNotifyContext(notifyContext: any): void {
        this.context = notifyContext;
    }

    getNotifyMethod(): NotificationHandler {
        return this.notify;
    }

    getNotifyContext(): any {
        return this.context;
    }

    notifyObserver(notification: NotificationInterface): void {
        this.getNotifyMethod().call(this.getNotifyContext(), notification);
    }

    /**
     * Compare an object to this instance's notification context.
     */
    compareNotifyContext(object: any): boolean {
        return object === this.context;
    }
}
