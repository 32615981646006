export enum MediatorName {
    APPLICATION = 'AppMediator',
    UI = 'UiMediator',
    FULLSCREEN = 'FullscreenMediator',
    TRACKING_MEDIATOR = 'TrackingMediator',
    TIMER_MEDIATOR = 'TimerMediator',
    PRESENTATION_MEDIATOR = 'PresentationMediator',
    PLUGIN_MEDIATOR = 'pluginMediator',
    KEY_COMMAND = 'keyCommandMediator',
    ACC_KEY_CMD_MEDIATOR = 'accessibilityKeyCommandMediator',
}
