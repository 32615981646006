import { DestroyInterface } from '../..';
import { Util } from '../../core/Util';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { WILDCARD } from '../../enum/WildCard';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { MetadataCuepointInterface } from '../../iface/MetadataCuepointInterface';
import { VideoSurfaceConfigInterface } from '../../iface/VideoSurfaceConfigInterface';
import { TextTrackType } from '../enum/TextTrackType';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';

export class MetadataSurface implements DestroyInterface {

    private static MAX_ID3_DEDUPE_LENGTH = 32;

    private logger: LoggerInterface;
    private video: HTMLVideoElement;
    private onMetadataCuepoint: (cue: MetadataCuepointInterface) => void;
    private metadataTracks: TextTrack[] = [];
    private id3Map: MetadataCuepointInterface[] = [];
    private daiId3 = /^google_/;
    private id3OwnerIds: string[];
    private maxId3DedupeLength = MetadataSurface.MAX_ID3_DEDUPE_LENGTH;

    constructor(config: VideoSurfaceConfigInterface, onMetadataCuepoint: (cue: MetadataCuepointInterface) => void) {
        this.onMetadataCuepoint = onMetadataCuepoint;
        this.video = config.video;

        this.id3OwnerIds = ['com.cbsi.live.sg'].concat(config.resource.playback.id3OwnerIds || []);

        const maxId3DedupeLength = config.playerOptions.overrides?.maxId3DedupeLength;
        if (maxId3DedupeLength != null) {
            this.maxId3DedupeLength = maxId3DedupeLength;
        }

        this.addEvents();

        this.logger = config.logger;
        this.logger.log(LogLevel.INFO, 'MetadataSurface created');
    }

    destroy() {
        this.onMetadataCuepoint = null;
        this.id3Map = null;
        this.removeEvents();
        Util.forEach(this.metadataTracks, (t) => this.cleanupTrack(t));
    }

    private addEvents(): void {
        this.video.textTracks.addEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
        this.video.addEventListener(VideoSurfaceEvents.SEEKING, this.onSeeking);
    }

    private removeEvents(): void {
        this.video.textTracks.removeEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
        this.video.removeEventListener(VideoSurfaceEvents.SEEKING, this.onSeeking);
        Util.forEach(this.metadataTracks, (t) => t.removeEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange));
    }

    private clearId3() {
        this.id3Map = [];
    }

    private cleanupTrack(track: TextTrack) {
        function cleanupCue(cue: TextTrackCue) {
            try {
                if (cue) {
                    track.removeCue(cue);
                }
            }
            catch (error) {
                // ignore errors and continue cleanup
            }
        }

        // Cues must be cleaned up in reverse order. Otherwise half of the cues will be left behind.
        Util.forEachReverse(track.cues, cleanupCue);
        Util.forEachReverse(track.activeCues, cleanupCue);

        //@ts-ignore
        track.expired = true;

        //@ts-ignore
        track.mode = TextTrackMode.DISABLED;
    }

    private onTextTrackAdded = (e: TrackEvent): void => {
        const track = e.track;

        if (!this.isValidTrack(track)) {
            return;
        }

        track.mode = TextTrackMode.HIDDEN;
        track.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);
    };

    private onCueChange = (e: Event): void => {
        const t = <TextTrack>e.target;
        const cues = t && t.activeCues;

        if (!cues || !cues.length) {
            return;
        }

        Util.forEach(cues, cue => this.processId3(cue));
    };

    private onSeeking = () => {
        this.clearId3();
    };

    private processId3(cue: any): void {
        if (Util.isEmpty(cue.value)) {
            return;
        }

        const { value, startTime, endTime } = cue;
        const { key, info = '', data } = value;
        const metadata = { key, info, data, startTime, endTime } as any;

        if (key === 'TXXX' && this.daiId3.test(data)) {
            if (Util.isEmpty(info)) {
                metadata.info = data;
            }
            metadata.id = 'google_dai';
        }
        else {
            metadata.id = Util.find(this.id3OwnerIds, id => id === WILDCARD || Util.includes(info, id));
        }

        if (!metadata.id) {
            return;
        }

        let text;

        try {
            text = (data instanceof ArrayBuffer) ? Util.bufferToString(data) : data;
        }
        catch (error) {
            text = '';
        }

        // TODO: Deprecate this conversion. Promote the use of the `text` property
        if (key === 'PRIV' && this.id3OwnerIds[0] === info) {
            metadata.data = text;
        }

        metadata.text = text;

        const duplicate = this.id3Map.some(d => d.info === metadata.info && d.startTime == metadata.startTime && d.text == metadata.text);

        if (duplicate) {
            return;
        }

        if (this.id3Map.length > this.maxId3DedupeLength) {
            this.id3Map.pop();
        }
        this.id3Map.unshift(metadata);

        this.onMetadataCuepoint(metadata);
    }

    private isMetadataTrack(type: string) {
        return type === TextTrackType.METADATA;
    }

    private isExpired(track: TextTrack): boolean {
        //@ts-ignore
        return track.expired;
    }

    private isValidTrack(track: TextTrack): boolean {
        if (!track) {
            return false;
        }

        if (!this.isMetadataTrack(track.kind)) {
            return false;
        }

        if (this.isExpired(track)) {
            return false;
        }

        return true;
    }
};
