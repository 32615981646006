import { ScriptLoader, ScriptLoadOptions } from '../dataservice/ScriptLoader';
import { Browser } from '../enum/Browser';
import { XhrResponseType } from '../enum/XhrResponseType';
import { SystemServiceInterface } from '../iface';
import { RequestOptionsInterface } from "../iface/RequestOptionsInterface";
import { ThumbnailCueInterface } from '../iface/ThumbnailCueInterface';
import { ThumbnailTrackSurface } from '../playback/surface/ThumbnailTrackSurface';
import { request } from './Request';
import { Util } from './Util';

// NOTE: This class is simply a wrapper for util classes/functions that needed to be separated due to circular reference issues.
export class Utilities extends Util {
    /**
     * Render a thumbnail cue into a given container.
     */
    static renderThumbnail(cue: ThumbnailCueInterface, container: HTMLElement): void {
        ThumbnailTrackSurface.renderThumbnail(cue, container);
    }

    /**
     * Helper function for making simple HTTP requests.
     */
    static request(options: RequestOptionsInterface): Promise<any> {
        return request(options);
    }

    /**
     * Helper function for loading external JS files.
     */
    static loadScript(options: ScriptLoadOptions): Promise<any> {
        return ScriptLoader.load(options);
    }

    /**
     * Helper function for converting a SMPTE file to a VTT cue array
     */
    static async smpteToVtt(url: string, textTrack: TextTrack, system: SystemServiceInterface) {
        const xml: XMLDocument = await Utilities.request({ url, responseType: XhrResponseType.DOCUMENT });
        const smpteCues = xml.querySelectorAll('tt body div p');

        if (!smpteCues.length) {
            throw new Error(`No cues found in ${url}`);
        }

        const align = system.browser === Browser.SAFARI ? 'middle' : 'center';
        const lineOffset = system.browser === Browser.SAFARI ? -1 : 0;
        const isVTTCueSupported = system.global.VTTCue !== undefined;
        const Cue: typeof VTTCue = isVTTCueSupported ? system.global.VTTCue : system.global.TextTrackCue;

        smpteCues.forEach((item, i, cues) => {
            if (Utilities.isEmpty(item.textContent)) {
                return;
            }

            // Convert old <span> tags. 
            const text = item.textContent
                .replace(/(.*)<span.*tts:fontStyle="italic">(.*)<\/span>(.*)/g, '$1<i>$2</i>$3')
                .replace(/(.*)<span.*tts:fontWeight="bold">(.*)<\/span>(.*)/g, '$1<b>$2</b>$3')
                .replace(/(.*)<span.*tts:textDecoration="under">(.*)<\/span>(.*)/g, '$1<u>$2</u>$3');

            // Count lines for positioning.
            const lc = Utilities.getNumLines(text);
            // @ts-ignore
            item._lineCountPrev = item._lineCountPrev || 0;
            // @ts-ignore
            item._lineCountNext = item._lineCountNext || 0;

            let n = i + 1;
            let nextItem = cues[n];

            const begin = item.getAttribute('begin');

            // Determine number of lines for each start time.
            while (nextItem?.getAttribute('begin') === begin) {
                // @ts-ignore
                nextItem._lineCountPrev = lc + item._lineCountNext;
                // @ts-ignore
                item._lineCountNext += Utilities.getNumLines(nextItem.textContent);
                nextItem = cues[++n];
            }

            const cue = new Cue(Utilities.hmsToSec(begin), Utilities.hmsToSec(item.getAttribute('end')), text);
            cue.align = item.getAttribute('tts:textAlign') as AlignSetting;

            // Can only add position data to VTTCue, Edge only supports TextTrackCue
            if (isVTTCueSupported) {
                // @ts-ignore
                const metadata = item.querySelector('metadata');
                // TODO: This should be normalized with _setPosition?? FROM OLD PLAYER TODO
                if (metadata) {
                    cue.snapToLines = true; // Ensures lines don't overlap.

                    const col = parseInt(metadata.getAttribute('cccol'));
                    const row = parseInt(metadata.getAttribute('ccrow'));

                    // NOTE: `cccol` has a max of 35.
                    const position = Math.round(col / 35 * 100);
                    cue.position = position;

                    // VTG-1399: Safari v12.1 does not accept 'middle' - must be 'center; but previous version need middle.
                    try {
                        // @ts-ignore - middle is not an AlignSetting, but old safari does not conform with center!! 
                        cue.align = position < 45 ? 'start' : position > 55 ? 'end' : align;
                    }
                    catch (error) {
                        cue.align = 'center';
                    }

                    cue.positionAlign = position < 45 ? 'line-left' : position > 55 ? 'line-right' : 'center';

                    // NOTE: `ccrow` has a max of 15. Subtract 2 lines to bring text
                    //       above control bar. Negative numbers indicate bottom
                    //       up, where -1 is absolute bottom.
                    cue.line = row - 17 + lineOffset;
                }
            }

            try {
                textTrack.addCue(cue);
            }
            catch (error) {
                // ignore errors
            }
        });
    }
}
