import { AppResources } from '../../app/AppResources';
import { ResourceConfiguration } from '../../app/ResourceConfiguration';
import { Util } from '../../core/Util';
import { AdContext } from '../../enum/AdContext';
import { ValidationInterface } from '../../iface';
import { ResourceAdInterface } from '../../iface/ResourceAdInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';


export class ResourceValidator {

    static validate(r: ResourceConfigurationInterface): ValidationInterface {
        if (Util.isEmpty(r)) {
            return { error: true, msg: AppResources.messages.RESOURCE_MISSING };
        }

        const hasRootKeys = this.hasRootKeys(r);
        if (hasRootKeys.error) return hasRootKeys;

        const canAccessMedia = this.canAccessMedia(r, r.ad.enabled);
        if (canAccessMedia.error) return canAccessMedia;

        return { error: false, msg: '' };
    }

    private static hasRootKeys(r: ResourceConfigurationInterface): ValidationInterface {
        const missing: string[] = [];
        const defRsc = new ResourceConfiguration();
        let msg = null;

        Object.keys(defRsc).forEach(k => {
            if (!(k in r)) {
                missing.push(k);
            }
        });
        if (missing.length) {
            msg = `One or more configuration properties not found: ${missing.join(', ')}`;
        }

        return {
            error: !!msg,
            msg: msg
        };
    }

    private static canAccessMedia(r: ResourceConfigurationInterface, usesAdAdapter: boolean): ValidationInterface {
        const hasCms = !Util.isEmpty(r.location.cms),
            hasIdOrUri = !Util.isEmpty(r.location.tokenMap) || !Util.isEmpty(r.location.cmsUri),
            hasMediaUrl = !Util.isEmpty(r.location.mediaUrl) || r.ad.context === AdContext.IMA_AD_ONLY,
            hasSsbMedia = (!r.ad.context && !usesAdAdapter && hasMediaUrl) || r.ad.context === AdContext.DAI_SSB && !Util.isEmpty(r.ad.dai.ssbStreamUrl),
            hasValidAdSettings = this.hasValidDaiSettings(r, usesAdAdapter),
            mediaNotConfigured = !(hasSsbMedia || hasMediaUrl) && !(hasCms && hasIdOrUri),
            msg = hasValidAdSettings.error ? hasValidAdSettings.msg : (mediaNotConfigured ? 'Media not specified' : '');

        return {
            error: mediaNotConfigured && hasValidAdSettings.error,
            msg: msg
        };
    }

    private static hasValidDaiSettings(r: ResourceConfigurationInterface, usesAdAdapter: boolean): ValidationInterface {
        const adSets: ResourceAdInterface = r.ad;
        const fwCfg = adSets?.ssai;
        const gamCfg = adSets?.dai;
        const context = adSets?.context;

        let error: boolean = false, msg = '';

        if (!adSets) {
            return { error, msg };
        }
    
        let cfg: any = null;
        let isFw = false;

        if (gamCfg?.assetKey || gamCfg?.contentSourceId || (gamCfg.ssbStreamUrl && context == AdContext.DAI_SSB)) {
            cfg = gamCfg;
        }
        else if (fwCfg?.assetKey || fwCfg?.contentSourceId) {
            cfg = fwCfg;
            isFw = true;
        }

        // GAM plugin (or other ad plugin) will validate ad config
        if (usesAdAdapter || !context) return { error, msg };

        if (context === AdContext.DAI_SDK || context === AdContext.DAI_API) {
            if (!cfg.isLive) {
                error = isFw && Util.isEmpty(cfg.videoId) || !isFw && Util.isEmpty(cfg.daiVideoId) ;
                msg = 'Missing video id';
            }
            else {
                error = Util.isEmpty(cfg.assetKey);
                msg = 'Missing assetKey';
            }
        }
        else if (context === AdContext.DAI_SSB) {
            error = (!isFw && Util.isEmpty(cfg.ssbStreamUrl) && Util.isEmpty(r.location?.mediaUrl)) 
                    ||
                    (isFw && Util.isEmpty(r.location?.mediaUrl));

            msg = 'Missing asset url for SSB';
        }

        return { error, msg: error ? msg : '' };
    }
    
}
