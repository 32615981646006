export enum AccessibilityAction {  
    ENTER_FULLSCREEN = 'enterFullScreen',
    INCREASE_VOLUME = 'increaseVolume',
    DECREASE_VOLUME = 'decreaseVolume',
    SEEK_BACK = 'seekBack',
    SEEK_FORWARD = 'seekForward',
    TOGGLE_PLAY = 'togglePlay',
    TOGGLE_TT = 'toggleTextTrack',
    TOGGLE_MUTE = 'toggleMute',
    PLAYLIST_NEXT = 'playListNext',
    PLAYLIST_PREV = 'playListPrev',
    STOP = 'stop',
}
