import { MetricInterface } from '../iface/MetricInterface';

export class Metric<T = any> implements MetricInterface {
  key: string;
  value: any;
  timeStamp: number;
  type: string;

  constructor(key: string, value: T, type?: string) {
    this.key = key;
    this.value = value;
    this.type = type;
    this.timeStamp = Date.now();
  }
}
