import { AppResources } from '../../app/AppResources';
import { Util } from '../../core/Util';
import { Utilities } from '../../core/Utilities';
import { Deprecated } from '../../deprecated/Deprecated';
import { Browser } from '../../enum/Browser';
import { ErrorCode } from '../../enum/ErrorCode';
import { LogLevel } from '../../enum/LogLevel';
import { Network } from '../../enum/Network';
import { Os } from '../../enum/Os';
import { SystemServiceInterface } from '../../iface';
import { ErrorRecoveryInterface } from '../../iface/ErrorRecoveryInterface';
import { OverridesInterface } from '../../iface/OverridesInterface';
import { PlaybackAdapterConfigInterface } from '../../iface/PlaybackAdapterConfigInterface';
import { PlaybackAdapterCoreInterface } from '../../iface/PlaybackAdapterCoreInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';
import { MediaCapabilitiesMimeType } from '../../util/enum/MediaCapabilitiesMimeType';
import { ExtLibEndpoint } from '../enum/ExtLibEndpoint';
import { PlaybackAdapterType } from '../enum/PlaybackAdapterType';
import { StreamingLibraryVersion } from '../enum/StreamingLibraryVersion';
import { HlsjsAdapter } from './HlsjsAdapter';
import { Html5Adapter } from './Html5Adapter';
import { PlayStationAdapter } from './PlayStationAdapter';
import { ShakaAdapter } from './ShakaAdapter';

export class PlaybackAdapterFactory {

    protected constructor() { }

    private static getAdapterOverride(config: PlaybackAdapterConfigInterface) {
        return config.resource.overrides?.adapter;
    }

    static getAdapterType(config: PlaybackAdapterConfigInterface): PlaybackAdapterType {
        // External Adapter Lookup
        const id = config.resource.playback.adapter?.id;
        if (id) {
            return null;
        }

        const rules = [
            { adapter: PlaybackAdapterType.TWITCH_LOW_LATENCY, condition: PlaybackAdapterFactory.isTwitchLowLatencyAdapter },
            { adapter: PlaybackAdapterType.PLAY_STATION, condition: PlaybackAdapterFactory.isPlayStationAdapter },
            { adapter: PlaybackAdapterType.HTML5, condition: PlaybackAdapterFactory.isHTML5Adapter },
            { adapter: PlaybackAdapterType.SHAKA, condition: PlaybackAdapterFactory.isDashAdapter },
            { adapter: PlaybackAdapterType.HLSJS, condition: PlaybackAdapterFactory.isHlsAdapter },
        ];

        // Override with valid string from PlaybackAdapterType Enum
        const override: PlaybackAdapterType = PlaybackAdapterFactory.getAdapterOverride(config);
        if (!Util.isEmpty(override)) {
            const rule = Util.find(rules, r => r.adapter === override);
            if (!rule || rule.condition(config)) {
                return override;
            }
        }

        const rule = Util.find(rules, r => r.condition(config));
        return rule?.adapter || PlaybackAdapterType.UNKNOWN;
    }

    private static getUrl(type: PlaybackAdapterType, config: PlaybackAdapterConfigInterface): string {
        const overrides: OverridesInterface = config.playerOptions.overrides;
        const resolveVersionTemplate = (url: string, version: string, context: any = {}): string => {
            return Util.template(url, { VERSION: version, ...context });
        };

        if (overrides?.dependencies?.[type]) {
            return overrides.dependencies[type];
        }

        switch (type) {
            case PlaybackAdapterType.HLSJS:
                return resolveVersionTemplate(ExtLibEndpoint.HLSJS_GZIP_CDN, StreamingLibraryVersion.HLSJS);

            case PlaybackAdapterType.SHAKA:
                const DEBUG = (overrides?.enableLowLevelStreamingLogs === true) ? '.debug' : '';
                return resolveVersionTemplate(ExtLibEndpoint.SHAKA_GZIP_CDN, StreamingLibraryVersion.SHAKA, { DEBUG });

            case PlaybackAdapterType.TWITCH_LOW_LATENCY:
                return resolveVersionTemplate(ExtLibEndpoint.TWITCH_GZIP_CDN, StreamingLibraryVersion.TWITCH);

            default:
                return null;
        }
    }

    private static loadLib(url: string, config: PlaybackAdapterConfigInterface, lib: () => any): Promise<any> {
        try {
            if (lib() != null) {
                return Promise.resolve();
            }
        }
        catch (error) { /* */ }

        return Utilities.loadScript({
            url: PlaybackAdapterFactory.checkSslForUrl(config.system, url),
            timeout: PlaybackAdapterFactory.getScriptLoaderTimeout(config),
            errorRecovery: PlaybackAdapterFactory.getErrorRecoveryFromConfig(config),
        });
    }

    static getAdapter(config: PlaybackAdapterConfigInterface): Promise<PlaybackAdapterCoreInterface> {
        const { logger } = config;
        const type = PlaybackAdapterFactory.getAdapterType(config);
        const loadError = (code: string, message: string = AppResources.messages.ADAPTER_LIB_UNAVAILABLE): any => ({ target: type, code, message, fatal: true });
        const url = PlaybackAdapterFactory.getUrl(type, config);

        switch (type) {
            case PlaybackAdapterType.HLSJS:
                return PlaybackAdapterFactory.loadLib(url, config, () => (<any>window).Hls)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.HLSJS} external library version: ${StreamingLibraryVersion.HLSJS}`);
                        return new HlsjsAdapter(config);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.HLS_SDK_MISSING);
                    });

            case PlaybackAdapterType.SHAKA:
                return PlaybackAdapterFactory.loadLib(url, config, () => (<any>window).shaka.Player)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.SHAKA} external library version: ${StreamingLibraryVersion.SHAKA}`);
                        return new ShakaAdapter(config);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.SHAKA_SDK_MISSING);
                    });

            case PlaybackAdapterType.TWITCH_LOW_LATENCY:
                return PlaybackAdapterFactory.loadLib(url, config, () => (<any>window).IVSPlayer)
                    .then(() => {
                        logger.log(LogLevel.INFO, `Loading ${PlaybackAdapterType.TWITCH_LOW_LATENCY} external library version: ${StreamingLibraryVersion.TWITCH}`);
                        return Deprecated.createTwitch(config);
                    })
                    .catch((error) => {
                        throw loadError(ErrorCode.TWITCH_SDK_MISSING);
                    });

            case PlaybackAdapterType.HTML5:
                return Promise.resolve(new Html5Adapter(config));

            case PlaybackAdapterType.PLAY_STATION:
                return Promise.resolve(new PlayStationAdapter(config));
        }

        return null;
    }

    private static getMimeType(resource: ResourceConfigurationInterface) {
        const override = resource.overrides?.mimeType || '';
        return override.split(';').shift() || Util.getMimeType(resource.location.mediaUrl);
    }

    private static isPlayStationAdapter(config: PlaybackAdapterConfigInterface): boolean {
        return config.system.isWebMaf;
    }

    private static isHTML5Adapter(config: PlaybackAdapterConfigInterface): boolean {

        const fairplayDetected = !Util.isEmpty(config.resource.location.drm?.fairplay?.appCertUrl);
        const isSafari = config.system.info.browser === Browser.SAFARI;
        const isIOS = config.system.os === Os.IOS;
        const isAndroid = config.system.os == Os.ANDROID;
        const osVersion = config.system.osVersionInfo;
        const type = PlaybackAdapterFactory.getMimeType(config.resource);
        const isM3u8 = type == MediaCapabilitiesMimeType.HLS || type == MediaCapabilitiesMimeType.HLS_ALT;

        const override: PlaybackAdapterType = PlaybackAdapterFactory.getAdapterOverride(config);
        if (!config.capabilities.hasMediaSource || override == PlaybackAdapterType.HTML5) {
            // NOTE: Do not convert this to optional chaining syntax. This check needs to explicitly know if the video tag exists.
            //       If the video property is `null`, `config.video?.canPlayType(type) != ''` will evaluate to `true` which is not
            //       the correct return value for that scenario.
            return config.video && config.video.canPlayType(type) != '';
        }

        if (!isM3u8 && type == MediaCapabilitiesMimeType.MP4_VIDEO) {
            return true;
        }

        //HLS with FairPlay on Safari
        if (isM3u8 && fairplayDetected && isSafari) {
            return true;
        }

        //IOS
        if (isM3u8 && isIOS) {
            if (fairplayDetected && isSafari) {
                return true;
            }
            else if (!fairplayDetected) {
                return true;
            }
        }

        //Android < 5
        if (isM3u8 && !fairplayDetected && isAndroid && osVersion.majorVersion < 5) {
            return true;
        }

        return false;
    }

    private static isDashAdapter(config: PlaybackAdapterConfigInterface): boolean {
        return config.capabilities.hasMediaSource && PlaybackAdapterFactory.getMimeType(config.resource) == MediaCapabilitiesMimeType.DASH;
    }

    private static isTwitchLowLatencyAdapter(config: PlaybackAdapterConfigInterface): boolean {
        //TODO Twitch Temp code until we decide what to do
        const type = PlaybackAdapterFactory.getAdapterOverride(config);
        return type === PlaybackAdapterType.TWITCH_LOW_LATENCY;
    }

    private static isHlsAdapter(config: PlaybackAdapterConfigInterface): boolean {
        const isSafari = config.system.info.browser === Browser.SAFARI;
        const isIOS = config.system.os === Os.IOS;
        const type = PlaybackAdapterFactory.getMimeType(config.resource);
        const isM3u8 = type == MediaCapabilitiesMimeType.HLS || type == MediaCapabilitiesMimeType.HLS_ALT;

        if (!config.capabilities.hasMediaSource) {
            return false;
        }

        // Unsupported playback scenario... 
        if (isM3u8 && isIOS && !isSafari) {
            return false;
        }

        return isM3u8;
    }

    private static checkSslForUrl(system: SystemServiceInterface, baseUrl: string): string {
        return system.isWebOs ? Util.makeUrl(true, baseUrl) : baseUrl;
    }

    private static getScriptLoaderTimeout(config: PlaybackAdapterConfigInterface): number {
        const { overrides, networkTimeout } = config.playerOptions;
        return overrides.streamingLibLoaderTimeout || networkTimeout || Network.TIMEOUT;
    }

    private static getErrorRecoveryFromConfig(config: PlaybackAdapterConfigInterface): ErrorRecoveryInterface {

        let errorRecovery: ErrorRecoveryInterface = null;

        const er = config.playerOptions.networkErrorRecovery;
        if (!Util.isEmpty(er)) {
            errorRecovery = {
                retryAttempts: er.retryAttempts,
                retryIntervals: er.retryIntervals
            };
        }

        return errorRecovery;
    }
}
