
// Non-printing keys; used mainly for Accessibility
export enum NonPrintingKey {
    SPACE = ' ',
    ENTER = 'Enter',
    TAB = 'Tab',
    CONTROL = 'Control',
    SHIFT = 'Shift',
    ESCAPE = 'Escape',
    META = 'Meta',
    ARROW_LEFT = 'ArrowLeft',
    ARROW_RIGHT = 'ArrowRight',
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
}
