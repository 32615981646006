/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import {MvcMessages} from "./MvcMessages";
import {View} from "./View";
import {Observer} from "./Observer";
import {
    CommandInterface,
    CommandMap,
    ControllerInterface,
    ControllerMap,
    NotificationInterface,
    ViewInterface
} from "../iface";
import { Logger } from "../core/Logger";

export class Controller implements ControllerInterface {

    private static controllerMap: ControllerMap = {};

    private readonly multiCoreKey: string;
    private commandMap: CommandMap;
    private view: ViewInterface;

    constructor(key: string) {
        if (Controller.controllerMap[key]) {
            Logger.error(MvcMessages.ERROR_KEY_EXISTS.replace('$ACTOR_NAME$', 'Controller'));
            return;
        }

        this.multiCoreKey = key;
        Controller.controllerMap[this.multiCoreKey] = this;

        this.commandMap = {};
        this.view = null;
        this.initializeController();
    }

    static getInstance(key: string): ControllerInterface  {
        if (!key) return null;

        if (!this.controllerMap[key]) {
            this.controllerMap[key] = new Controller(key);
        }

        return this.controllerMap[key];
    }

    static removeController(key: string): void {
        this.controllerMap[key] && this.controllerMap[key].destroy();
        delete this.controllerMap[key];
    }

    destroy(): void {
        for (let q in this.commandMap) {
            this.removeCommand(q);
        }

        this.commandMap = null;
        this.view = null;
    }

    initializeController(): void {
        this.view = View.getInstance(this.multiCoreKey);
    }

    executeCommand(notification: NotificationInterface): void {
        let commandClassRef = this.commandMap[notification.name];

        if (!commandClassRef) return;

        let commandInstance: CommandInterface = new (<any>commandClassRef)();

        commandInstance.initializeNotifier(this.multiCoreKey);
        commandInstance.execute(notification);
    }

    registerCommand(notificationName: string, commandClassRef: any) {
        if(!this.commandMap[notificationName]) {
            this.view.registerObserver(notificationName, new Observer(this.executeCommand, this));
        }

        this.commandMap[notificationName] = commandClassRef;
    }


    hasCommand(notificationName: string): boolean {
        return !!this.commandMap[notificationName];
    }

    removeCommand(notificationName: string): void {
        if(this.hasCommand(notificationName)) {
            this.view.removeObserver(notificationName, this);
            this.commandMap[notificationName]= null;
        }
    }
}
