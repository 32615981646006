import { ErrorCode } from '../enum/ErrorCode';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { CoreError } from './CoreError';

export class PlayerError extends CoreError implements ErrorInfoInterface {
  override readonly name = "PlayerError";

  data: any;

  constructor(public code: ErrorCode, message: string, cause?: Error, public fatal = true) {
    super(message, cause);
    this.data = cause;
  }
}
