
export enum Platform {
    /** */
    MAC = 'Macintosh',

    /** */
    WINDOWS = 'Windows',

    /** */
    LINUX = 'Linux',

    /** */
    UNKNOWN = 'Unknown'
}
