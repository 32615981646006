/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import { ModelName } from '../enum/ModelName';
import { ProxyName } from '../enum/ProxyName';
import { CommandInterface } from "../iface";
import { ModelCollectionProxy } from '../model/ModelCollectionProxy';
import { Notification } from './Notification';
import { Notifier } from './Notifier';
import { ServiceName } from '../enum/ServiceName';


export abstract class AbstractSimpleCommand extends Notifier implements CommandInterface {

    protected constructor() {
        super();
    }

    abstract execute(notification: Notification): void;

    protected getModel<T>(name: ModelName): T {
        return <T>(this.getModelCollection().getModel(name));
    }

    protected getProxy<T>(name: ProxyName): T {
        return this.facade ? <T><unknown>(this.facade.retrieveProxy(name)) : null;
    }

    protected getService<T>(name: ServiceName): T | null {
        return this.facade ? <T><unknown>(this.facade.retrieveService(name)) : null;
    }

    protected getModelCollection(): ModelCollectionProxy {
        return this.facade ? <ModelCollectionProxy>this.facade.retrieveProxy(ProxyName.ModelCollectionProxy) : null;
    }
}


