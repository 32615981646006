import { QueryString } from '../../core/QueryString';
import { Util } from '../../core/Util';
import { AdTagParametersInterface } from '../../iface/AdTagParametersInterface';
import { ResourceAdViewabilityTrackingInterface } from '../../iface/ResourceAdViewabilityTrackingInterface';
import { ResourceConfigurationInterface } from '../../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { gam_static_params } from './gam_static_params';
import { AdPlaybackContextInterface, GamStaticParametersInterface } from './iface';

/* 
adTagParameters: {
    description_url: string;
    sz: string;
    url: string;
    iu: string;
    ad_rule: string;
    ciu_szs: string;
    pp: string;
    cmsid: string;
    vid: string;
    cust_params: object,
    hl: string;
    msid: string;
    an: string;
    nofb: string;
    ppid: string;
    tfcd: string;
    npa:  string;
    
    output: string;
}
*/

export class AdParamAssembler {

    private static SZ_DEFAULT: string = '640x480';
    private static SZ_MUTED: string = '640x483';

    assembleStdParams(rc: ResourceConfigurationInterface, info: AdPlaybackContextInterface): AdTagParametersInterface {
        const constParams: GamStaticParametersInterface = gam_static_params,
            stdp: AdTagParametersInterface = rc.ad.adTagParameters,
            trkp: ResourceAdViewabilityTrackingInterface = rc.ad.viewabilityTracking,
            out = <AdTagParametersInterface>{};

        let q: string;

        // const params; use supplied if defined or defaults in gam_static_params
        for (q in constParams) {
            out[q] = stdp[q] || constParams[q];
        }

        // user-supplied standard adTagParams
        for (q in stdp) {
            if (q !== 'cust_params' && !Util.isEmpty(stdp[q])) {
                out[q] = stdp[q];
            }
        }

        // viewability tracking (Moat)
        for (q in trkp) {
            out[q] = trkp[q];
        }

        if (out.sz === AdParamAssembler.SZ_DEFAULT) {
            const szStr = this.getSzStringForPlayback(info.mutedPlayback);
            szStr && (out.sz = szStr);
        }

        return out;
    }

    getSzStringForPlayback(muted: boolean): string | null {
        return muted ? AdParamAssembler.SZ_MUTED : null;
    }

    substituteStringForMutedPlayback(str: string) {
        const szStr = this.getSzStringForPlayback(true);

        if (szStr) {
            const re = new RegExp(`sz=${AdParamAssembler.SZ_DEFAULT}`, 'g');

            return str.replace(re, `sz=${szStr}`);
        }

        return str;
    }

    queryStrFromObj(obj: StrAnyDict, encode?: string[]): string {
        return QueryString.encode(obj);
    }

    objFromQueryStr(str: string): StrAnyDict {
        return QueryString.decode(str);
    }
}
