import { ActiveState } from "../enum/ActiveState";
import { NotificationName } from "../enum/NotificationName";
import { PlayerEvent } from "../enum/PlayerEvent";
import { Proxy } from '../mvc/Proxy';

type ActivityData = { container: HTMLElement, document: Document, idleDelay: number; };

export class ActivityProxy extends Proxy {
  private document: Document;
  private container: HTMLElement;
  private timeout: number;
  private mouseDown: boolean = false;
  private mouseOver: boolean = false;
  private idleDelay: number = 3000;
  private idleEvents = [
    "mousemove",
    "mousedown",
    "keypress",
    "DOMMouseScroll",
    "mousewheel",
    "touchmove",
    "touchstart",
    "MSPointerMove",
    "MSPointerDown",
    "pointermove",
    "pointerdown"
  ];

  constructor(name: string, data?: ActivityData) {
    super(name, { activeState: ActiveState.INACTIVE });

    this.container = data.container;
    this.document = data.document;
    this.idleDelay = data.idleDelay;
  }

  override onRegister() {
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.reset = this.reset.bind(this);
    this.idle = this.idle.bind(this);

    this.container.addEventListener('mouseenter', this.onMouseEnter);
    this.container.addEventListener('mouseleave', this.onMouseLeave);
    this.container.addEventListener('mousedown', this.onMouseDown);
    this.document.addEventListener('mouseup', this.onMouseUp);
    this.document.addEventListener('keydown', this.onKeyDown);
  }

  override onRemove() {
    this.container.removeEventListener('mouseenter', this.onMouseEnter);
    this.container.removeEventListener('mouseleave', this.onMouseLeave);
    this.container.removeEventListener('mousedown', this.onMouseDown);
    this.document.removeEventListener('mouseup', this.onMouseUp);
    this.document.removeEventListener('keydown', this.onKeyDown);

    this.stop();

    this.container = null;
    this.document = null;

    super.onRemove();
  }

  private applyListeners(add = true) {
    const action = (add) ? 'add' : 'remove';
    // @ts-ignore
    this.idleEvents.forEach(event => this.container[`${action}EventListener`](event, this.reset, { passive: true }));
  }

  private start() {
    this.stop();
    this.applyListeners();
    this.timeout = setTimeout(this.idle, this.idleDelay);
  }

  private stop() {
    clearTimeout(this.timeout);
    this.applyListeners(false);
  }

  private idle() {
    this.activeState = (this.mouseOver) ? ActiveState.IDLE : ActiveState.INACTIVE;
  }

  private reset() {
    this.activeState = ActiveState.ACTIVE;
    this.start();
  }

  private onMouseEnter() {
    this.reset();
    this.mouseOver = true;
    this.activeState = ActiveState.ACTIVE;
    this.start();
  }

  private onMouseLeave() {
    this.reset();
    this.mouseOver = false;
    this.activeState = ActiveState.INACTIVE;
  }

  private onMouseDown() {
    this.mouseDown = true;
  }

  private onMouseUp() {
    if (!this.mouseDown) {
      return;
    }

    this.mouseDown = false;
    this.start();
  }

  private onKeyDown(event: KeyboardEvent) {
    const { key, keyCode } = event;

    if (key != 'Tab' && keyCode != 9) {
      return;
    }

    setTimeout(() => {
      const element = this.document.activeElement;
      const active = (this.container == element || this.container.contains(element as HTMLElement));
      if (active) {
        this.stop();
      }
      this.activeState = (active) ? ActiveState.ACTIVE : ActiveState.INACTIVE;
    }, 0);
  }

  set activeState(value: ActiveState) {
    if (this.mouseDown) {
      value = ActiveState.ACTIVE;
    }

    if (value == this.data.activeState) {
      return;
    }

    this.data.activeState = value;
    this.sendNotification(NotificationName.APP_EVENT, { type: PlayerEvent.ACTIVE_CHANGE, data: { value } });
  }

  get activeState(): ActiveState {
    return this.data.activeState;
  }
}
