import { FileExtensionToMimeType } from "../enum/FileExtensionToMimeType";
import { TextTrackKind } from '../enum/TextTrackKind';
import { ArrayLike } from "../iface/ArrayLike";
import { Entry } from "../iface/Entry";
import { EventPromiseMap } from '../iface/EventPromiseMap';
import { LanguageTagInterface } from "../iface/LanguageTagInterface";
import { QualityInterface } from '../iface/QualityInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from "../iface/StrAnyDict";
import { TextCuepointInterface } from '../iface/TextCuepointInterface';


export class Util {

    protected constructor() { }

    private static dre: RegExp = /([a-z\d])([A-Z])/g;
    private static cdr: RegExp = /\W+/g;

    static camelToDash(term: string) {
        let d = "-";

        return (term.replace(Util.cdr, d).replace(Util.dre, '$1-$2')).toLowerCase();
    }

    /**
     * Returns an 8 character random string id
     */
    static uid8(): string {
        let s4 = function () {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        };

        return s4() + s4();
    }

    static isEmpty(obj: any): boolean {
        return obj === null || obj === undefined ||
            (Array.isArray(obj) && obj.length === 0) ||
            Util.isObject(obj) && Object.keys(obj).length === 0 ||
            Util.isString(obj) && obj === '';
    }

    static isFunction(obj: any): boolean {
        return typeof obj === 'function';
    }

    static isString(obj: any): boolean {
        return typeof obj === 'string';
    }

    static isUndefined(obj: any): boolean {
        return typeof obj === 'undefined';
    }

    static isNumber(obj: any): boolean {
        return typeof obj === 'number';
    }

    static isBoolean(obj: any): boolean {
        return typeof obj === 'boolean';
    }

    static isObject(obj: any): boolean {
        return typeof obj === 'object';
    }

    /**
     * Sidefill for ES6 `Array.prototype.includes` and `String.prototype.includes` functions. 
     * See https://tc39.es/ecma262/#sec-array.prototype.includes
     *     https://tc39.es/ecma262/#sec-string.prototype.includes
     */
    static includes(search: Array<any> | string, item: any): boolean {
        return search.indexOf(item) > -1;
    }

    /**
     * Sidefill for ES6 `Object.assign` function. See https://tc39.es/ecma262/#sec-object.assign
     */
    static assign(tgt: StrAnyDict, ...args: StrAnyDict[]): StrAnyDict {
        return Object.assign(tgt || {}, ...args);
    }

    /**
     * Sidefill for ES6 `Object.values` function. See https://tc39.es/ecma262/#sec-object.values
     */
    static values<T>(obj: Record<string, T>): T[] {
        return Object.keys(obj).reduce((a, k, i) => (a[i] = obj[k], a), []);
    }

    /**
     * Sidefill for ES6 `Object.entries` function. See https://tc39.es/ecma262/#sec-object.entries
     */
    static entries(obj: any): Entry[] {
        return Object.keys(obj).reduce((a, k, i) => (a[i] = [k, obj[k]], a), []);
    }

    /**
     * Sidefill for ES6 `Object.fromEntries` function. See https://tc39.es/ecma262/#sec-object.fromentries
     */
    static fromEntries(entries: Entry[]): StrAnyDict {
        return entries.reduce((o, [k, v]) => (o[k] = v, o), {} as StrAnyDict);
    }

    /**
     * Sidefill for ES6 `String.prototype.padStart` function. See https://tc39.es/ecma262/#sec-string.prototype.padstart
     */
    static padStart(str: string, targetLength: number, padString: string = ' '): string {
        targetLength = targetLength | 0;
        const len = str.length;

        if (len > targetLength) {
            return str;
        }

        targetLength = targetLength - len;
        if (targetLength > padString.length) {
            padString += Util.repeat(padString, targetLength / padString.length); //append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + str;
    }

    /**
     * Sidefill for ES6 `String.prototype.repeat` function. See https://tc39.es/ecma262/#sec-string.prototype.repeat
     */
    static repeat(str: string, count: number = 0) {
        count = count | 0;
        let i = 0, result = "";
        while (i++ < count) {
            result += str;
        }

        return result;
    }

    /**
     * Merge the properties of a list of object. Similar to Object.assign, with the following exceptions:
     * - Performs a deep merge
     * - `undefined` values will not override defined values.
     */
    static merge(...args: any[]): any {
        const override = (base: any, overrides: any): any => {
            if (base == null) {
                return overrides;
            }
            else if (overrides == null) {
                return base;
            }

            Object.getOwnPropertyNames(overrides).forEach(key => {
                const value = overrides[key];
                const baseValue = base[key];
                const type = typeof value;

                if (type === "undefined") {
                    return;
                }
                else if (baseValue == null || !Util.isObject(baseValue) || Array.isArray(value)) {
                    base[key] = value;
                }
                else if (type === "object") {
                    base[key] = override(baseValue, value);
                }
            });

            return base;
        };

        return args.reduce((acc, obj) => override(acc, obj), {});
    }

    /**
     * Returns formatted time; e.g, 00:04:20;
     * 
     * @param seconds   The number of seconds to display
     * @param max       The maximum number of seconds. Dictates how many number groups to display.
     */
    static secToHms(seconds: number, max: number = 61): string {
        // default
        if (seconds == null || isNaN(seconds)) {
            return "00:00";
        }

        // seconds. Use bitwise OR operator for fast number truncation
        seconds = seconds | 0;
        let time: string = Util.zeroFill(seconds % 60);

        // minutes
        seconds = seconds / 60 | 0;
        time = Util.zeroFill(seconds % 60) + ":" + time;

        // hours
        seconds = seconds / 60 | 0;
        if (seconds > 0) {
            time = Util.zeroFill(seconds) + ":" + time;
        }

        if (max >= 3600 && time.length === 5) {
            time = "00:" + time;
        }

        return time;
    }

    static msToHms(ms: number): string {
        return Util.formatTime(new Date(ms), "HH:mm:ss:sss", { offset: 0 });
    }

    static zeroFill(time: number, len: number = 2): string {
        return Util.padStart(String(time), len, "0");
    }

    /**
     * Converts timecode to seconds.
     *
     * @param   timeCode   A SMTP formatted string.
     * @param   framerate  The frame rate. Used to calculate milliseconds.
     * @return             The number of seconds represented by the time code
     */
    static hmsToSec(timeCode: string, framerate = 30) {
        if (!timeCode) {
            return NaN;
        }
        const pieces = timeCode.split(":");
        let ms = 0;
        if (pieces.length === 4) {
            ms = parseInt(pieces.pop()) / framerate;
        }
        else if (pieces.length === 3) {
            // sometimes ms separator is a comma
            pieces[2] = pieces[2].replace(",", ".");
            if (pieces[2].indexOf(".") !== -1) {
                let parts = pieces[2].split(".");
                if ((parts != null ? parts.length : void 0) > 1) {
                    pieces[2] = parts[0];
                    ms = parseInt(parts[1]) / 1000;
                }
            }
        }
        let time = parseInt(pieces.pop());
        while (pieces.length > 0) {
            time += Math.pow(60, pieces.length) * parseInt(pieces.shift());
        }
        return time + ms;
    }

    /**
     * Converts a date into a time string based on a ISO 8601 formatted time string.
     */
    static formatTime(date: Date, format = "h:mm:ss A", tz: { timezone?: string, offset: number; } = { timezone: "", offset: -((new Date()).getTimezoneOffset() / 60) }): string {
        if ((tz.offset != null) && tz.offset !== 0) {
            date = new Date(date.getTime() + Math.round(tz.offset * 60 * 60 * 1000));
        }
        if (isNaN(date.getTime())) {
            return "";
        }
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        const milliseconds = date.getUTCMilliseconds();
        const twelve = hours % 12 || 12;
        const a = hours < 12 ? "am" : "pm";
        const replace = {
            hh: Util.zeroFill(twelve),
            h: twelve,
            HH: Util.zeroFill(hours),
            H: hours,
            mm: Util.zeroFill(minutes),
            m: minutes,
            sss: Util.zeroFill(milliseconds, 3),
            ss: Util.zeroFill(seconds),
            s: seconds,
            a: a,
            A: a.toUpperCase(),
            z: tz.timezone
        } as StrAnyDict;
        for (let key in replace) {
            format = format.replace(key, replace[key]);
        }
        return format;
    }

    /**
     * Sidefill for ES6 `Array.prototype.findIndex` function. See https://tc39.es/ecma262/#sec-array.prototype.findindex
     */
    static findIndex<T>(arr: ArrayLike<T>, predicate: (item: T, index?: number, arr?: ArrayLike<T>) => boolean): number {
        if (!Util.isFunction(predicate)) {
            throw new TypeError('predicate must be a function');
        }

        const len = arr.length >>> 0;
        let i = 0;

        while (i < len) {
            if (predicate(arr[i], i, arr)) {
                return i;
            }
            i++;
        }

        return -1;
    }

    /**
     * Sidefill for ES6 `Array.prototype.find` function. See https://tc39.es/ecma262/#sec-array.prototype.find
     */
    static find<T>(arr: ArrayLike<T>, predicate: (item: T, index?: number, arr?: ArrayLike<T>) => boolean): T | undefined {
        if (!Util.isFunction(predicate)) {
            throw new TypeError('predicate must be a function');
        }

        const index = Util.findIndex(arr, predicate);
        return (index > -1) ? arr[index] : undefined;
    }

    static template(input: string, context: any, open = "{{", close = "}}"): string {
        if (!input) {
            return input;
        }
        const regex: RegExp = new RegExp(`${open}((?:(?!(${open}|${close})).)+)${close}`, "g");
        return input.replace(regex, (match, token) => (!Util.isUndefined(context?.[token])) ? context[token] : match);
    }

    static toArray<T>(arr: ArrayLike<T>): Array<T> {
        return Array.prototype.slice.call(arr);
    }

    static forEach<T>(list: ArrayLike<T>, func: (item: T, index: number, array: ArrayLike<T>) => any): void {
        if (!list || !list.length || !Util.isFunction(func)) {
            return;
        }

        for (let i = 0, len = list.length; i < len; i++) {
            func(list[i], i, list);
        }
    }

    static forEachReverse<T>(list: ArrayLike<T>, func: (item: T, index: number, array: ArrayLike<T>) => any): void {
        if (!list || !list.length || !Util.isFunction(func)) {
            return;
        }

        for (let i = list.length - 1; i > -1; i--) {
            func(list[i], i, list);
        }
    }

    static debounce(func: Function, delay: number): () => void {
        let timeout: number;
        const pending = () => timeout != null;
        const cancel = () => {
            clearTimeout(timeout);
            timeout = null;
        };
        const run = (...args: any[]) => {
            cancel();
            timeout = setTimeout(func, delay, ...args);
        };
        run.cancel = cancel;
        run.flush = func;
        run.pending = pending;
        return run;
    }

    static clampValue(value: number, min: number, max: number): number {
        return Math.max(min, Math.min(max, value));
    }

    static inRange(value: number, lower: number, upper: number): boolean {
        return Util.isNumber(value) && value >= lower && value <= upper;
    }

    static mapToRange(value: number, fromMin: number, fromMax: number, toMin: number = 0, toMax: number = 1): number {
        return (value - fromMin) * (toMax - toMin) / (fromMax - fromMin) + toMin;
    }

    static base64DecodeUint8Array(input: string): Uint8Array {
        const raw = atob(input);
        const rawLength = raw.length;
        const array = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }

    static base64EncodeUint8Array(input: Uint8Array) {
        const str = String.fromCharCode.apply(null, input);
        return btoa(str);
    }

    static xmlToJson(xml: StrAnyDict): StrAnyDict {
        var obj: any = {};

        // Node types:
        //   1: ELEMENT_NODE
        //   3: TEXT_NODE

        // Elements and attributes.
        if (xml.nodeType === 1 && xml.attributes.length > 0) {
            for (var j = 0, k = xml.attributes.length; j < k; j++) {
                var attribute = xml.attributes.item(j);

                //@ts-ignore
                obj[attribute.nodeName] = attribute.nodeValue;
            }
        }
        // #text elements.
        else if (xml.nodeType === 3) {
            obj = xml.nodeValue;
        }

        // Special caption <p> elements.
        if (xml.nodeName === 'p') {
            obj.text = '';

            xml.childNodes.forEach((node: any) => {

                //Skip <metadata> tags.
                if (node.nodeName === 'metadata') {
                    return;
                }

                const text = (new XMLSerializer()).serializeToString(node);

                // Element nodes.
                if (node.nodeType === 1) {
                    // Line breaks get ignored by the VTTCue object, so we
                    // convert them here to newline characters.
                    obj.text += node.tagName === 'br' ? '\n' : text;
                }

                // Text nodes.
                if (node.nodeType === 3) {
                    obj.text += text;
                }

            });
        }

        // Child nodes.
        if (xml.hasChildNodes()) {
            for (var i = 0, h = xml.childNodes.length; i < h; i++) {
                var item = xml.childNodes.item(i),
                    nodeName = item.nodeName;

                if (Util.isUndefined(obj[nodeName])) {
                    obj[nodeName] = Util.xmlToJson(item);
                    continue;
                }

                // If more than one child, create an array
                if (Util.isUndefined(obj[nodeName].push)) {
                    var old = obj[nodeName];
                    obj[nodeName] = [];
                    obj[nodeName].push(old);
                }

                obj[nodeName].push(Util.xmlToJson(item));
            }
        }

        return obj;
    }

    static getNumLines(text: string): number {
        return text && ((text.match(/\n/g) || []).length + 1);
    }

    static roundTo(num: number, len: number) {
        return Math.round(num * Math.pow(10, len)) / Math.pow(10, len);
    }

    /**
     * Utility method that constructs the url by prepending the protocol based 
     * on the ssl boolean and the location. Ex: "https://www.cbssports.com/live"
     * 
     * @param ssl 
     * @param location 
     * @returns string the url
     */
    static makeUrl(ssl: boolean, location: string): string {
        return (ssl ? 'https:' : 'http:') + '//' + location;
    }

    /**
     * Parse a IETF BCP 47 language tag
     */
    static parseLanguageTag(tag: string = ''): LanguageTagInterface {
        const regex = /^([a-zA-Z]{2,3})(?:[_-]+([a-zA-Z]{3})(?=$|[_-]+))?(?:[_-]+([a-zA-Z]{4})(?=$|[_-]+))?(?:[_-]+([a-zA-Z]{2}|[0-9]{3})(?=$|[_-]+))?/;
        const match = tag.match(regex) || [];
        return {
            tag: tag,
            language: match[1] || '',
            extended: match[2] || '',
            script: match[3] || '',
            region: match[4] || '',
            toString(): string {
                return this.tag;
            }
        } as LanguageTagInterface;
    }

    /**
     * Extract file extension from URI.
     */
    static getFileExtension(uri: string): string {
        return uri.replace(/\?.*/, '').replace(/#.*/, '').split('.').pop();
    }

    /**
     * Determine mime type from file extension.
     */
    static getMimeType(uri: string): string {
        if (!uri) {
            return '';
        }

        const ext = Util.getFileExtension(uri).toUpperCase();

        return FileExtensionToMimeType[ext];
    }

    static clearCue(textTrack: TextTrack, time: number): void {
        try {
            const Cue = VTTCue || TextTrackCue;
            textTrack.addCue(new Cue(time - 0.1, time + 0.1, " "));
        }
        catch (error) {
            // Do nothing
        }
    }

    static eventsToPromise<T = any>(success: EventPromiseMap[], failure: EventPromiseMap[], timeout: number = NaN): Promise<T> {
        return new Promise((resolve, reject) => {
            let timeoutId: number;
            const undo: Function[] = [];
            const cleanUp = () => undo.forEach(u => u());
            const apply = (action: Function) => ({ target, events }: EventPromiseMap) => {
                const t = target as any;
                const on = t.on ? 'on' : 'addEventListener';
                const off = t.off ? 'off' : 'removeEventListener';
                const complete = (e: T, d?: T) => {
                    clearTimeout(timeoutId);
                    cleanUp();
                    action(d || e);
                };
                events.forEach(event => {
                    t[on](event, complete);
                    undo.push(() => t[off](event, complete));
                });
            };

            success.forEach(apply(resolve));
            failure.forEach(apply(reject));

            if (timeout > -1) {
                timeoutId = setTimeout(() => {
                    cleanUp();
                    reject(new Error("timeout"));
                }, timeout);
            }
        });
    }

    static bufferToString(buffer: ArrayBuffer) {
        return String.fromCharCode.apply(null, new Uint8Array(buffer));
    }

    static arrayToString(array: Uint16Array) {
        const uint16array = new Uint16Array(array.buffer);
        return String.fromCharCode.apply(null, uint16array);
    }

    static stringToArray(string: string): Uint16Array {
        const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
        const array = new Uint16Array(buffer);

        for (let i = 0, strLen = string.length; i < strLen; i++) {
            array[i] = string.charCodeAt(i);
        }

        return array;
    }

    static getResourceMimeType(resource: ResourceConfigurationInterface) {
        const override = resource.overrides?.mimeType || '';
        return override.split(';').shift() || Util.getMimeType(resource.location.mediaUrl);
    }

    static normalizeQuality(item: any, index: number): QualityInterface {
        return {
            index,
            bitrate: item.bitrate,
            height: item.height,
            width: item.width,
            codec: item.codec || item.codecs
        };
    }

    static getIndexForBitrate(list: StrAnyDict[], bitrate: number, isMinLookup: boolean): number {
        /*
         * | --400--- | --800--- | --1000--- | --1500--- | --2500--- |    minify true should result in 1000
         *               <----  900 is bitrate ------->
         * | --400--- | --800--- | --1000--- | --1500--- | --2500--- |    minify undefined or false should result in 800
         */
        if (!list) {
            return 0;
        }

        let i = list.length - 1;
        if (bitrate >= list[i].bitrate) {
            return i; //bit rate is out of range - upper bounds
        }

        while (i--) {
            const rate = list[i].bitrate;
            if (isMinLookup) {
                if (bitrate > rate) {
                    return i + 1;
                }
            }
            else {
                if (bitrate >= rate) {
                    return i;
                }
            }
        }

        return 0; //bit rate is out of range - lower bounds
    }

    static findDefaultTrack<T extends { language: string; default?: boolean; }>(tracks: T[], language: string): T {
        let regex = new RegExp(language, "i");
        let track = Util.find(tracks, t => regex.test(t.language));
        if (!track) {
            const short = Util.parseLanguageTag(language).language;
            if (short != language) {
                regex = new RegExp(short, "i");
                track = Util.find(tracks, t => regex.test(t.language));
            }
        }

        return track || Util.find(tracks, t => t.default) || tracks[0];
    }

    static dedupeCues(track: TextTrack): TextCuepointInterface[] {
        const map: Record<string, any> = {};
        const cues: TextCuepointInterface[] = [];

        Util.forEach(track.activeCues, (cue: any): void => {
            try {
                if (!cue) {
                    return;
                }

                const { text, startTime, endTime } = cue;

                if (!text) {
                    return;
                }

                const mapped = map[text];

                if (!mapped) {
                    map[text] = cue;
                }
                else if (mapped.startTime === startTime && mapped.endTime === endTime) {
                    track.removeCue(cue);
                    return;
                }

                cues.push(cue);
            }
            catch (error) {
                // Ignore errors to avoid interupting playback
            }
        });

        return cues;
    }

    static isTextTrack(kind: any) {
        return kind === TextTrackKind.CAPTIONS || kind === TextTrackKind.SUBTITLES;
    }
}
