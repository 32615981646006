/**
 * Player events
 * See [[VideoPlayerInterface.on]] and [[VideoPlayerInterface.off]] for addition and 
 * removal, respectively, of event listeners.
 * 
 * An event listener has this signature
 * ```typescript
 *  (e: EventInterface) => void;
 * ```
 * i.e., the function accepts a single argument (**e**), an event object.
 * 
 * An event object has the following general form:
 * ```typescript
 * EventInterface {
 *   data?: object; // an optional event-specific data object, may be null
 *   target: EmitterInterface; // The emitting object
 *   timeStamp: number; // millisecond time stamp; when the event was emitted
 *   type: string; // the event type, an avia.PlayerEvent enum member
 * }
 * ```
 * 
 * Note that most events don't carry a **data** payload.
 * 
 * ---
 * 
 * For more info see [Event Handling](../../guides/events)
 * 
 */
export enum PlayerEvent {

    /**
     * Emitted when an accessibility hotkey is pressed for 
     * any of the following operations:
     * Seek (Fwd or Back)
     * Mute/Unmute
     * Change volume
     * 
     * Event data will contain (depending on the action):
     * 
     * `action` - an AccessibilityAction enum member
     * `position`- number in seconds, for seek operations, the time value jumped (signed)
     * `duration`- number in seconds, for seek operations, the content duration (may be null for DVR)
     * `volume` - number between 0-1, indicating current volume 
     * `muted` - boolean indicating whether player is currently muted
     *  
     *  A primary use case for handling at least a subset of these events
     *  is to display accessibility-related UI.
     */
    HOTKEY_ACTION = 'hotkeyaction',

    /**
     * Emitted when the player is ready for external interactions.
     */
    READY = 'ready',

    /**
     * Emitted when the ad break schedule is known; the **data** property
     * of the event will be an array of ad break start times, in seconds. Note that these 
     * times correspond to **content time**; this is the case for DAI (in-stream) ads as well.
     * 
     * Sample **data** payload: 
     * ```javascript
     * [0, 300, 900] //(A pre-roll and 2 mid-rolls)
     * ```
     */
    AD_CUEPOINTS_AVAILABLE = 'adcuepointsavailable',

    /**
     * Emitted at the start of an advertisement break. Completion of 
     * the break will be signaled with [[AD_BREAK_COMPLETE]]. Data for the break
     * is not available until [[AD_BREAK_METADATA]] is emitted.
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[AD_BREAK_COMPLETE]]
     */
    AD_BREAK_START = 'adbreakstart',

    /**
     * Emitted when metadata for the ad break becomes available.
     * 
     * The payload for this event carries an `adBreakInfo` property, 
     * with data pertaining to the break.
     * @see [[AdBreakInfoInterface]]
     */
    AD_BREAK_METADATA = 'adbreakmetadata',


    /**
     * Emitted at the end of an advertisement break. 
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[AD_BREAK_START]]
     */
    AD_BREAK_COMPLETE = 'adbreakcomplete',

    /**
     * Emitted every time an advertisement clip has fully buffered and begins playback.
     * 
     * The payload for this event carries an `adInfo` property,
     * with data pertaining to the ad. 
     * @see [[AdItemInterface]]
     * @see [[AD_COMPLETE]]
     */
    AD_START = 'adstart',

    /**
     * Emitted after each advertisement completes playback.
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[AD_START]]
     */
    AD_COMPLETE = 'adcomplete',

    /**
     * Emitted after 25 percent of the advertisement has been played.
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[AD_MIDPOINT]]
     * @see [[AD_THIRD_QUARTILE]]
     */
    AD_FIRST_QUARTILE = 'adfirstquartile',

    /**
     * Emitted after 50 percent of the advertisement has been played.
     * 
     * **Note** this event does not carry a payload.
     *
     * @see [[AD_FIRST_QUARTILE]]
     * @see [[AD_THIRD_QUARTILE]]
     */
    AD_MIDPOINT = 'admidpoint',

    /**
     * Emitted after 75 percent of the advertisement has been played. 
     * - see [[AD_COMPLETE]] for 100 percent.
     * 
     * **Note** this event does not carry a payload.
     *
     * @see [[AD_FIRST_QUARTILE]]
     * @see [[AD_MIDPOINT]]
     * @see [[AD_COMPLETE]]
     */
    AD_THIRD_QUARTILE = 'adthirdquartile',

    /**
     * Emitted when an error occurs attempting to play an ad, or during ad playback.
     * The event object received will be an AdErrorEvent, which has additional properties
     * to convey information about the error.
     * 
     * See under [Event Handling]
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      code: string,
     *      message: string
     * }
     * ```
     */
    AD_ERROR = 'aderror',

    /**
     * Emitted when a playing or paused ad is clicked by the user.
     */
    AD_CLICK = 'adclick',

    /**
     * Emitted when an ad segment has been encountered. 
     */
    AD_SEGMENT_START = 'adsegmentstart',


    /**
     * Emitted when an ad segment has ended. 
     */
    AD_SEGMENT_END = 'adsegmentend',

    /**
     * Emitted when a "skippable" ad is skipped by the user.
     * Applies to skippable IMA ads; emitted when the IMA SDK
     * determines that the user has elected to skip an ad.
     *
     * **Note** this event does not carry a payload.
     */
    AD_SKIPPED = 'adskipped',

    /**
     * Emitted continuously during advertisement playback. 
     * 
     * **Note** this event carries a payload:
     * ```typescript
     * {
     *      currentTime: number,
     *      duration: number
     * }
     * ```
     * The times conveyed in the payload relate only to the playing ad, i.e, a
     * progress event for a 30 second ad will have a duration of 30 and currentTime
     * will be be in the range 0-30.
     */
    AD_PROGRESS = 'adprogress',

    /**
     * Emitted when a playing ad has been paused.
     * 
     * **Note** this event does not carry a payload.
     */
    AD_PAUSED = 'adpaused',

    /**
     * Emitted when a paused ad has resumed playing.
     * 
     * **Note** this event does not carry a payload.
     */
    AD_PLAYING = 'adplaying',

    /**
     * Emitted when a non-paused ad fails to progress for 
     * more than 5 seconds, i.e., if the ad is "stalled". Applies to IMA ads only.
     * 
     * In this case the ad break must be discarded and content play continues.
     * 
     */
    AD_STALLED = 'adstalled',

    /**
     * Emitted if an ad break is aborted for any reason. Applies to IMA ads only.
     * 
     * **Note** this event does not carry a payload.
     */
    AD_BREAK_DISCARDED = 'adbreakdiscarded',

    /**
     * Emitted when there is a changed in the information found in [[AudioTracksInterface]]
     *
     * **Note** this event does not carry a payload.
     * 
     * @see [[VideoPlayerInterface.audioTrackInfo]]
     */
    AUDIO_TRACK_INFO_CHANGE = 'audiotrackinfochange',

    /**
     * Emitted after the audio track has changed. Either at startup when 
     * initially setting the default audio track or after calling api [[VideoPlayerInterface.audioTrack]]
     * Once this event is emitted, it is safe to change audio tracks again.
     *
     * **Note** this event does not carry a payload.
     * 
     * @see [[VideoPlayerInterface.audioTrack]]
     */
    AUDIO_TRACK_CHANGE = 'audiotrackchange',

    /**
     * Emitted when an adaptive quality stream has changed qualities, 
     * either invoked from auto quality switching logic or by a manual 
     * switch request when setting a new value on [[VideoPlayerInterface.bitrate]].
     * 
     * **Note** this event does not carry a payload. 
     * 
     * * @see [[VideoPlayerInterface.bitrate]]
     */
    QUALITY_CHANGE = 'qualitychange',

    /**
     * Emitted after manifest parsing is complete. 
     * 
     * Emitted Again, when a change to bitrate restriction API such as [[VideoPlayerInterface.minBitrate]], 
     * [[VideoPlayerInterface.maxBitrate]] or if [[ResourceConfigurationInterface.playback]] capQualityToScreenSize
     * is `true`, and the player size changes enough to restrict a certain resolution.
     * 
     * Every time this is emitted, after the initial event, available qualities in 
     * [[VideoPlayerInterface.qualityInfo]] have changed.
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * interface QualityInfoInterface
     *    mode: string;
     *    quality: QualityInterface;
     *    qualities: QualityInterface[];
     * }
     * ```
     * @see [[VideoPlayerInterface.qualityInfo]]
     */
    QUALITY_INFO_CHANGE = 'qualityinfochange',

    /**
     * Emitted if the content delivery network (CDN) changes.  
     * This is applicable to multi-CDN scenarios.
     *
     *  * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      cdn: string,
     * }
     * ```
     */
    CDN_CHANGE = 'cdnchange',

    /**
     * Emitted when content video has **first** started to play. 
     * 
     * **Note** this event does not carry a payload.
     */
    CONTENT_START = 'contentstart',

    /**
     * Emitted when the content duration is known. The timing of this 
     * event may vary depending on whether client-side or in-stream ads accompany the 
     * presentation. 
     * 
     * This event carries a single property, `contentDuration`.
     * 
     * ```javascript
     * function handleEvent(e) {
     *      if (e.type === avia.PlayerEvent.CONTENT_DURATION_AVAILABLE) {
     *          console.log(`Content duration = ${e.data.contentDuration}`);
     *      }
     * }
     * ```
     */
    CONTENT_DURATION_AVAILABLE = 'contentdurationavailable',

    /**
     * Emitted when any content segment has begun playback;
     * any presentation with ad breaks will have content segments, as 
     * shown below, where 'ad' is an ad break with 1 or more ads.
     * ```
     *   ad    content segment    ad
     * |-----|==================|-----|
     * ```
     * **Note** this event does not carry a payload.
     */
    CONTENT_SEGMENT_START = 'contentsegmentstart',

    /**
     * Emitted when content has fully buffered and playback has started.  
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[CONTENT_PROGRESS]]
     */
    CONTENT_PLAYING = 'contentplaying',

    /**
     * Emitted continuously when content is playing and time is moving forward. 
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      contentTime: number,
     *      streamTime: number,
     *      contentDuration: number,
     *      streamDuration: number,
     * }
     * ```
     * - **contentTime** - current playback time of content portion of stream not including advertisement breaks.  
     * - **streamTime** - current playback time of the stream including content and all advertisement breaks.
     * - **contentDuration** - duration of the content portion of the stream not including advertisement breaks.
     * - **streamDuration** - total duration of the stream including content and all advertisement breaks.
     */
    CONTENT_PROGRESS = 'contentprogress',

    /**
     * Emitted when content has been paused.
     * 
     * **Note** this event does not carry a payload.
     */
    CONTENT_PAUSED = 'contentpaused',

    /**
     * Emitted when any content segment ends See also [[CONTENT_SEGMENT_START]]. 
     * 
     * **Note** this event does not carry a payload.
     */
    CONTENT_SEGMENT_END = 'contentsegmentend',

    /**
     * Emitted when the content has completed fully; the resource may have post-roll ads
     * to play at this point, so 'content complete' is not necessarily synonymous with 'resource end'.
     * 
     * **Note** this event does not carry a payload.
     */
    CONTENT_COMPLETE = 'contentcomplete',

    /**
     * Emitted when the content is playing at the live edge.
     * 
     * @see [[VideoPlayerInterface.isPlayingLive]]
     */
    CONTENT_IS_PLAYING_LIVE = 'contentisplayinglive',

    /**
     * Emitted when the stream type changes.
     * 
     * @see [[StreamType]]
     * @see [[VideoPlayerInterface.streamType]]
     */
    STREAM_TYPE_CHANGE = 'streamtypechange',

    /**
     * Emitted when the stream's metadata is loaded, before playback begins.
     * 
     * @see [[StreamMetadataInterface]]
     */
    STREAM_METADATA = 'streammetadata',

    /**
     * Applies to SSAI streams that have a 'stream ID"; emitted if and when the ID becomes 
     * available.
     */
    STREAM_ID_AVAILABLE = 'streamidavailable',

    /**
     * Used mainly for tracking and diagnostics. 
     * This event's data payload will be a string with the DRM key system in use. 
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      keysystem: string, //DrmType.ts
     * }
     * ```
     */
    DRM_KEYSYSTEM_CREATED = 'drmkeysystemcreated',

    /**
     * Emitted for any **fatal** content playback error.  Note that this event will be emitted 
     * whenever a [[RESOURCE_ERROR]], [[VIDEO_START_ERROR]], or [[VIDEO_PLAYBACK_ERROR]] occurs.  
     * You may decide to **either** listen to these latter three events separately, **or** handle 
     * only the FATAL_ERROR event, which serves as a "catch-all" for all three. Listening for 
     * FATAL_ERROR in conjunction with any of those three fatal errors can lead to handling code 
     * being triggered twice for the same error, and should be avoided. 
     * 
     * See also [Event Handling](../../guides/events)
     * 
     */
    FATAL_ERROR = 'fatalerror',

    /**
     * Emitted for all player errors. Listening for the `ERROR` event in conjunction with any of 
     * the other error events can lead to handling code being triggered twice for the same error, 
     * and should be avoided. 
     * 
     * See also [Event Handling](../../guides/events)
     * 
     * @see [[ErrorInfoInterface]]
     */
    ERROR = 'error',

    /**
     * Emitted each time the playlist has advanced.
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      resource: ResourceConfigurationInterface,
     *      currentIndex: number     
     * }
     * ```
     * - **resource** - current configuration for playback
     * - **currentIndex** - current index of playlist's new item. 
     */
    PLAYLIST_ADVANCED = 'playlistadvanced',

    /**
     * Emitted when the playlist attempts to advance beyond the last item.
     * 
     * **Note** this event does not carry a payload.
     */
    PLAYLIST_COMPLETE = 'playlistcomplete',

    /**
     * Emitted when the playlist has been modified via item addition or removal.
     * See [[PlaylistInterface.addResources]], [[PlaylistInterface.removeResourceAtIndex]], [[PlaylistInterface.clear]]
     * 
     * **Note** this event does not carry a payload.
     */
    PLAYLIST_CHANGE = 'playlistchange',

    /**
     * Emitted after the playlist has been cleared via a call to [[PlaylistInterface.clear]]
     * 
     * The payload (event.data) conveys the resource that was playing when the list was cleared, or null.
     * 
     * ```javascript
     * event.data = {
     *     interruptedResource: ...
     * }
     * ```
     */
    PLAYLIST_CLEARED = 'playlistcleared',

    /**
     * Emitted if the playlist has been moved to an index less than 0.
     * if the playlist is moved beyond the right-most bound, a PLAYLIST_COMPLETE event is emitted.
     * 
     * **Note** this event does not carry a payload.
     */
    PLAYLIST_OUT_OF_RANGE = 'playlistoutofrange',

    /**
     * Emitted if the current item is removed from the playlist via [[PlaylistInterface.removeResourceAtIndex]]
     * 
     * The payload (event.data) conveys an object with the resource that was playing:
     * 
     * ```javascript
     * event.data = {
     *     interruptedResource: ...
     * }
     * ```
     */
    PLAYLIST_CURRENT_RESOURCE_CLEARED = 'playlistcurrentresourcecleared',

    /** 
     * Emitted when a poster click occurs prior to receiving a resource to play.
     * The POSTER_CLICK event handler may then provide a resource to the Player.
     * 
     * **Note** this event does not carry a payload.
    */
    POSTER_CLICK = 'posterclick',

    /**
     * Event emitted following a call to `player.suspendPlayback()`.
     * Indicates that the tracking session will end (if playback is resumed, 
     * a new tracking session is started).
     * 
     * @see [[VideoPlayerInterface.suspendPlayback]]
     */
    PLAYBACK_SUSPENDED = 'playbacksuspended',

    /**
     * Event emitted if playback is resumed (via `player.resumePlayback()` or 
     * `player.play()`) from a 'suspended' state.
     * @see [[PLAYBACK_SUSPENDED]]
     */
    PLAYBACK_RESUMED = 'playbackresumed',

    /**
     * Emitted when the resource presentation starts. This occurs when all required data
     * is at hand, and just prior to any attempt to either play content or a pre-roll ad.
     * As such, this event can not be used as an indication that any video is currently playing.
     * 
     * This event is primarily of use for tracking, to signal the start of a new tracking session.
     * 
     * **Note** this event does not carry a payload.
     */
    RESOURCE_START = 'resourcestart',

    /**
     * Emitted when the resource has ended. This occurs when the content  
     * and any post-roll ads, have have naturally completed, or if playback is 
     * interrupted at a time greater than or equal to 95% of the content duration.
     * 
     * **Note** this event does not carry a payload.
     */
    RESOURCE_END = 'resourceend',

    /**
     * Emitted when the resource has been interrupted. This occurs playback if is cancelled 
     * any time prior to completion of all content and any scheduled post-roll ads.
     * 
     * **Note** this event carries a payload indicating the current content time, the content duration,
     * and whether an ad was playing when playback was interrupted.
     * ```javascript
     * contentTime: number
     * contentDuration: number
     * adInterrupted: boolean
     * ```
     */
    RESOURCE_INTERRUPTED = 'resourceinterrupted',

    /**
     * Emitted when the resource cannot be processed for play. This can
     * occur for an invalid configuration, or if an error occurs 
     * attempting to retrieve content metadata.  The latter case could be 
     * the result of a network error, a server error, an uninterpretable response,
     * or an explicit error response from the server.  Additionally, a resource 
     * error can occur if a valid response is received has invalid or missing data.
     * 
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      code: string,
     *      message: string
     * }
     * ```
     */
    RESOURCE_ERROR = 'resourceerror',

    /**
     * Emitted when the resource has started to buffer
     */
    RESOURCE_BUFFERING = 'resourcebuffering',

    /**
     * Emitted when a seek request has been fulfilled. The playhead time will 
     * be updated after this event has been emitted.
     * 
     * **Note** this event does not carry a payload.
     * 
     * @see [[SEEK_START]]
     */
    SEEK_COMPLETE = 'seekcomplete',

    /**
     * Emitted at the start of a seek request. The playhead time will
     * not be updated until [[SEEK_COMPLETE]]
     * 
     * **Note** this event does not carry a payload.
     *
     * @see [[SEEK_COMPLETE]]
     */
    SEEK_START = 'seekstart',

    /**
     * Emitted when a user-initiated seek would move the playhead 
     * past an unplayed ad break (which must be played). For SSAI streams particularly,
     * this can result in the playhead jumping back to the ad break position.
     * The SEEK_REDIRECT_START start event can alert user code to ignore (if desired)
     * any subsequent content progress events until the ad break has completed. 
     * 
     * **Note** 
     *
     * @see [[SEEK_REDIRECT_COMPLETE]]
     */
    SEEK_REDIRECT_START = 'seekredirectstart',

    /**
     * Emitted just prior to issuing a "pending" seek to the playhead, 
     * and is only relevant in the context described for SEEK_REDIRECT_START
     * 
     * **Note** 
     *
     * @see [[SEEK_REDIRECT_START]]
     */
    SEEK_REDIRECT_COMPLETE = 'seekredirectcomplete',

    /**
     * Emitted when ID3 data is detected and the ID3 tag "owner" matches 
     * any **id3OwnerIds** provided in the resource configuration.
     * 
     * See also [[ResourceConfigurationInterface]].
     * 
     * **Note** this event carries different payload structures so inspect the data
     * object of the event.
     */
    METADATA_CUEPOINT = 'metadatacuepoint',

    /**
     * Emitted only when:
     * - Player option ```renderTextTrackNatively``` is set to false.
     * - There is at least one valid text track in the presentation.
     * - the [[VideoPlayerInterface.contentTime]] has reached the start time of the cue. 
     * 
     * event.data = {
     *    activeCues: cueList
     * }
     */
    TEXT_CUEPOINT = 'textcuepoint',

    /**
     * Emitted once a text track has changed to the new track. This will happen 
     * at startup, if text is detected, while setting the default track. 
     * It will also be emitted after each track switch via [[VideoPlayerInterface.textTrack]]
     * 
     * **Note** This event passes the new track info as the payload.
     * @see [[VideoPlayerInterface.textTrack]]
     */
    TEXT_TRACK_CHANGE = 'texttrackchange',

    /**
     * Emitted when the list of available text tracks has changed. 
     * It will also be emitted after each track switch via [[VideoPlayerInterface.textTrack]]
     * 
     * @see [[VideoPlayerInterface.textTrack]]
     * @see [[VideoPlayerInterface.textTrackInfo]]
     */
    TEXT_TRACK_INFO_CHANGE = 'texttrackinfochange',

    /**
     * Emitted each time [[VideoPlayerInterface.textTrackEnabled]] is called and the mode 
     * has changed. This is useful when rendering text externally. Typically only 
     * used when player option ```renderTextTrackNatively``` is set to false.
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * { enabled: boolean }
     * ```
     * @see [[VideoPlayerInterface.textTrackEnabled]]
     */
    TEXT_TRACK_DISPLAY_MODE_CHANGE = 'texttrackdisplaymodechange',

    /**
     * Emitted when there is valid text track. Can be used as an indication 
     * to enable UI controls for caption button or subtitles menu. 
     * 
     * **Note** this event does not carry a payload.
     */
    TEXT_TRACK_AVAILABLE = 'texttrackavailable',

    /**
     * Emitted when there is valid thumbnail track. Can be used as an indication 
     * to enable UI seek preview scrubber. 
     * 
     * **Note** this event does not carry a payload.
     */
    THUMBNAIL_TRACK_AVAILABLE = 'thumbnailtrackavailable',

    /**
     * Emitted when there is an issue starting the video. Typically triggered by 
     * failures with manifest loading / parsing or user gesture issues for click to play. 
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      code: string,
     *      message: string
     * }
     * ```
     */
    VIDEO_START_ERROR = 'videostarterror',

    /**
     * Emitted when there is an issue playing back the video.  Typically triggered by an issue 
     * with network download or media parsing, decoding or rendering.
     * 
     * **Note** this event carries a data payload:
     * ```typescript
     * {
     *      code: string,
     *      message: string
     * }
     * ```
     */
    VIDEO_PLAYBACK_ERROR = 'videoplaybackerror',

    /**
     * Emitted after the video has loaded and the HTML5 CAN_PLAY_THROUGH 
     * event has be emitted by the HTML5 video element. 
     * 
     * **Note** this event does not carry a payload.
     */
    VIDEO_LOAD_COMPLETE = 'videoloadcomplete',

    /**
     * Applies only when using Avia with CVUI; emitted before the fade-in transition starts.
     */
    BEFORE_CONTROLS_VISIBLE = 'beforecontrolsvisible',

    /**
     * Applies only when using Avia with CVUI; emitted before the fade-out transition starts.
     */
    BEFORE_CONTROLS_HIDDEN = 'beforecontrolshidden',

    /**
     * Applies only when using Avia with CVUI; emitted when UI controls are fully visible.
     */
    CONTROLS_VISIBLE = 'controlsvisible',

    /**
     * Applies only when using Avia with CVUI; emitted when UI controls are fully hidden.
     */
    CONTROLS_HIDDEN = 'controlshidden',

    /**
     * Applies to live (linear) streams only; if the presentation is 'stopped' by the user 
     * video playback ceases, the current resource is discarded and the tracking session ends.
     */
    LIVE_PRESENTATION_STOPPED = 'livepresentationstopped',

    /**
     * Emitted when a change in presentation size (width and height) is detected.
     */
    PRESENTATION_SIZE_CHANGE = 'presentationsizechange',

    /**
     * Emitted when Fullscreen mode is entered
     */
    FULLSCREEN_ENTER = 'fullscreenenter',

    /**
     * Emitted when Fullscreen mode is exited
     */
    FULLSCREEN_EXIT = 'fullscreenexit',

    /**
     * Applies only when using Avia with CVUI; emitted (desktop only) when the cursor enters the video presentation area.
     */
    MOUSE_ENTER_PRESENTATION = 'mouseenterpresentation',

    /**
     * Applies only when using Avia with CVUI; emitted (desktop only) when the cursor leaves the video presentation area.
     */
    MOUSE_LEAVE_PRESENTATION = 'mouseleavepresentation',

    /**
     * Applies only when using Avia with CVUI; emitted when content video 
     * is paused by the user (via a click on the UI). It is not emitted during
     * ad play.
     */
    USER_PAUSE = 'userpause',

    /**
     * Applies only when using Avia with CVUI; emitted when the user
     * clicks/taps the Settings button.  This button is only available
     * when the UI is configured to include the settings button:
     * 
     * ```js
     * uiConfig: {
     *     include: [cvui.ControlName.SETTINGS_BUTTON]
     * }
     * ```
     * 
     * A plugin or other code must handle this event in order for anything
     * to happen. Although the player has no built-in behavior for user settings,
     * a VTG plugin is available to display multi-language text and audio track menus, 
     * offering users a means to switch to their preferred language.
     * 
     * @deprecated
     */
    USER_SETTINGS = 'usersettings',

    /**
     * Emitted for all log statements originating from a Player instance, regardless of the 
     * log level specified in PlayerOptions. This event is primarily useful for plugin 
     * development.  The payload is a LogEventInterface:
     * 
     * ```typescript
     * interface LogEventInterface {
     *    item: any;
     *    id: string;
     *    level: LogLevel;
     *    label: string;
     * }
     * ```
     * `item` is the subject of the log statement; it may be of any type (object, string, etc). 
     * `id` is the ID of the Player an object, the `label` 
     * If `item` is an object, `label` may convey the name or role of the object
     * `level` is the developer-assigned level for the log statement. 
     */
    LOG_EVENT = 'logevent',

    /**
     * Emitted from Plugins via the Player.  Plugin events 
     * behave like any other player event, but their `data` payload
     * is determined by the given plugin that initiates event emission.
     */
    PLUGIN_EVENT = 'pluginevent',

    /**
     * Emitted when the language is changed.
     */
    LANGUAGE_CHANGE = 'languagechange',

    /**
     * Emitted if a keyboard command has been registered and the user
     * invokes the keypress(es) associated with the command. 
     * Note that this event is not emitted for Accessibility key presses,
     * but for user-registered commands only.
     */
    USER_KEYBOARD_COMMAND = 'userkeyboardcommand',

    /**
     * Emitted if autoplay is blocked by the browser.
     */
    AUTOPLAY_BLOCKED = 'autoplayblocked',

    /**
     * Emitted when the player volume changes
     */
    VOLUME_CHANGE = 'volumechange',

    /**
     * Emitted when the player is muted/unmuted
     */
    MUTE_CHANGE = 'mutechange',

    /**
     * Emitted when the user starts/stops interacting with the player
     */
    ACTIVE_CHANGE = 'activechange',

    /**
     * Emitted when the the playback state changes.
     * 
     * @see [[PlaybackState]]
     */
    PLAYBACK_STATE_CHANGE = 'playbackstatechange',

    /**
     * A generic metric event
     */
    METRIC = 'metric',

    /**
     * Emitted when a new video element is added to the player DOM. 
     */
    VIDEO_ELEMENT_ADDED = 'videoelementadded',

    /**
     * Emitted when an existing video element is removed to the player DOM.
     */
    VIDEO_ELEMENT_REMOVED = 'videoelementremoved',
}
