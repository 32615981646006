import { LogAwareSimpleCommand } from "./LogAwareSimpleCommand";
import { NotificationInterface, PresentationMediatorInterface } from '../iface';
import { MediatorName } from '../enum/MediatorName';

import { PresentationStateInterface } from '../iface/PresentationStateInterface';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ProxyName } from "../enum/ProxyName";



export class SuspendPlaybackCommand extends LogAwareSimpleCommand {
    
    execute(notification: NotificationInterface) {
        const presoState = <PresentationStateInterface>this.getModel(ModelName.PresentationState);
        const pm = <PresentationMediatorInterface>this.facade.retrieveMediator(MediatorName.PRESENTATION_MEDIATOR);

        pm.suspend();
        presoState.suspended = true;

        const cps  = <ContentPlaybackStateInterface>this.getProxy(ProxyName.ContentPlaybackStateProxy);
        this.sendNotification(NotificationName.PLAYBACK_SUSPENDED, {contentTime: cps.model.time});
    }
}
