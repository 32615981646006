import { LogLevel } from '../../enum/LogLevel';
import { MediatorName } from '../../enum/MediatorName';
import { FacadeInterface, NotificationInterface } from '../../iface';
import { TimerMediator } from '../../view/TimerMediator';
import { LogAwareSimpleCommand } from '../LogAwareSimpleCommand';


export class PrepTimerCommand extends LogAwareSimpleCommand {

    execute(notification: NotificationInterface) {
        const f: FacadeInterface = this.facade;

        f.registerMediator(new TimerMediator(MediatorName.TIMER_MEDIATOR));

        this.log(LogLevel.DEBUG, 'Timer mediator registered');
    }
}
