import { AppResources } from '../app/AppResources';
import { buildInfo } from '../core/BuildInfo';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { UiMediatorInterface } from '../deprecated/cvui/UiMediatorInterface';
import { ErrorCode } from '../enum/ErrorCode';
import { LogLevel } from '../enum/LogLevel';
import { MediatorName } from '../enum/MediatorName';
import { ModelName } from '../enum/ModelName';
import { NotificationName } from '../enum/NotificationName';
import { PlaybackState } from '../enum/PlaybackState';
import { ProxyName } from '../enum/ProxyName';
import { ServiceName } from '../enum/ServiceName';
import { StreamType } from '../enum/StreamType';
import { MediatorInterface, NotificationInterface, PlayerDomProxyInterface, SystemServiceInterface } from '../iface';
import { AdCuePointInterface } from '../iface/AdCuePointInterface';
import { ContentPlaybackStateInterface } from '../iface/ContentPlaybackStateInterface';
import { ErrorInfoInterface } from '../iface/ErrorInfoInterface';
import { OverridesInterface } from '../iface/OverridesInterface';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { QualityInterface } from '../iface/QualityInterface';
import { AbstractPresentationMediator } from './AbstractPresentationMediator';

type TimeSpent = {
    startTime: number;
    lastTime: number;
    sessionTime: number;
    playbackTime: number;
    elapsedTime: number;
};

// provides base impl for abstract methods of AbstractPresentationMediator
export class CommonPresentationMediator extends AbstractPresentationMediator {

    private uim: UiMediatorInterface = null;
    private currentRect: ClientRect;
    protected contentDurationReleased: boolean = false;
    protected resumeTimeMaxProximityToAdBreak: number = 5.0;
    protected isClickToPlay: boolean = false;
    protected fullscreenRestrictedDuringAdPlay: boolean = false;
    protected hasContent: boolean = true;
    protected contentComplete: boolean = false;
    protected timeSpent = {
        startTime: null as any,
        lastTime: 0,
        sessionTime: 0,
        playbackTime: 0,
        elapsedTime: 0,
    };

    override onRemove() {
        this.uim = null;
        super.onRemove();
    }

    start() {
        const mUrl = this.resourceProxy.location.mediaUrl;
        this.hasContent = Util.isString(mUrl) && !Util.isEmpty(mUrl);
    }

    close(): Promise<void> {
        if (this.closing) {
            return this.closing;
        }
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy?.model;
        const contentTime = cps?.time || 0;
        const contentDuration = cps?.duration || 0;
        const adPlaying = this.isAdPlaying();

        this.respondToPlaybackStateChange(PlaybackState.STOPPED);
        this.contentPlaybackStateProxy.isReady = false;

        return this.closing = this.adapterTask
            .then(() => {
                this.pause();
                this.log(LogLevel.INFO, 'Closing Presentation');

                this.closeAds();
            })
            .then(() => (this.adapter) ? this.adapter.destroy() : null)
            .then(() => {
                if (adPlaying || (this.hasContent && !this.isContentComplete())) {
                    this.sendNotification(NotificationName.RESOURCE_INTERRUPTED, {
                        contentTime,
                        contentDuration,
                        adInterrupted: adPlaying
                    });
                }
                else {
                    this.notify(NotificationName.RESOURCE_END);
                }
                /////////////////////////////////////////////////////////////////////
                // Note - No code may come after the INTERRUPTED or END notification
                /////////////////////////////////////////////////////////////////////
            })
            .catch((e) => {
                Logger.error(e);
                throw e;
            })
            .then(() => {
                this.closing = null;
            });
    }

    setAdPlaying() {
        if (this.uiMediator) {
            this.uiMediator.hideClickCatcher(true);
            this.fullscreenRestrictedDuringAdPlay && this.uiMediator.disableFullscreen();
        }
    }

    protected getPlayerVersionInfo() {
        return `${buildInfo.playerName}_${buildInfo.playerVersion}`;
    }

    protected startContentSegment() {
        if (this.uiMediator && !this.contentPlaybackStateProxy.model.liveStreamInfo) {
            this.uiMediator.setSeekable(true);
        }

        if (this.fullscreenRestrictedDuringAdPlay && this.uiMediator) {
            this.uiMediator.setFullScreenAccessRestricted(false);
            this.uiMediator.enableFullscreen();
        }
    }

    // TODO: Move this to an AdPresentationMediator base class
    closeAds(): void {
        // no-op
    }

    beforePlayOnUserGesture() {
        // no-op
    }

    playOnUserGesture(): void {
        this.uiMediator && this.uiMediator.displayPoster(false);
        this.notify(NotificationName.VIDEO_LOAD_START);

        this.prepareForPlayback(true);
    }

    mute(flag: boolean): void {
        this.muteVideo(flag);
        this.uiMediator && this.uiMediator.setMuteState(flag);
    }

    seek(position: number): void {
        this.seekVideo(position);
    }

    override setVolume(value: number): void {
        this.domProxy && this.domProxy.setVideoVolume(value);
        super.setVolume(value);
    }

    getAdBreakTimes(): AdCuePointInterface[] {
        return [];
    }

    protected releaseContentDuration(duration: number) {
        this.contentDurationReleased = true;
        this.notify(NotificationName.CONTENT_DURATION_AVAILABLE, {
            contentDuration: duration
        });
    }

    protected updateTimeSpent(): TimeSpent {
        const now = Date.now();

        // Set the start the fist time this function is called
        if (this.timeSpent.startTime === null) {
            this.timeSpent.startTime = now;
        }

        // Reset the last time after pausing
        if (this.timeSpent.lastTime === null) {
            this.timeSpent.lastTime = now;
        }

        const elapsed = now - this.timeSpent.startTime;
        this.timeSpent.elapsedTime = elapsed;
        this.timeSpent.sessionTime = elapsed / 1000;

        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        if (cps.state !== PlaybackState.PAUSED) {
            this.timeSpent.playbackTime += (now - this.timeSpent.lastTime) / 1000;
            this.timeSpent.lastTime = now;
        }

        return this.timeSpent;
    }

    protected adjustStartTimeForAdBreakProximity(requestedStartTime: number, breaks: AdCuePointInterface[]): number {
        for (let i = 0, n = breaks.length; i < n; i++) {
            const bs = breaks[i].startTime,
                diff = bs - requestedStartTime;

            if (diff >= 0 && diff < this.resumeTimeMaxProximityToAdBreak) {
                return requestedStartTime - this.resumeTimeMaxProximityToAdBreak;
            }
        }

        return requestedStartTime;
    }

    protected get uiMediator(): UiMediatorInterface {
        return this.uim;
    }

    protected get domProxy(): PlayerDomProxyInterface {
        return <PlayerDomProxyInterface>this.facade.retrieveProxy(ProxyName.PlayerDomProxy);
    }

    protected override muteVideo(flag: boolean) {
        super.muteVideo(flag);
        this.domProxy && this.domProxy.muteVideo(flag);
    }

    protected override respondToPlaybackStateChange(playbackState: PlaybackState): void {
        super.respondToPlaybackStateChange(playbackState);
        this.timeSpent.lastTime = null;
    }

    protected respondToFullscreenChange(state: boolean) {
        this.checkSize();
        this.notify(state ? NotificationName.FULLSCREEN_ENTER : NotificationName.FULLSCREEN_EXIT);
    }

    protected respondToVideoPaused(): void {
        this.respondToPlaybackStateChange(PlaybackState.PAUSED);
        this.notify(NotificationName.RESOURCE_PAUSED);
    }

    protected respondToVideoPlaying(): void {
        if (!this.presoModel.started) {
            this.presoModel.started = true;

            if (this.uiMediator) {
                this.uiMediator.displayPoster(false);
                this.uiMediator.hideClickCatcher(true);
                if (this.presoModel.isMuteAtPlayStart && !this.presoModel.userHasUnmuted) {
                    this.uiMediator.showUnmutePrompt();
                }
                this.setTransportType();
            }
            this.notify(NotificationName.ENABLE_UI);
        }

        this.respondToPlaybackStateChange(PlaybackState.PLAYING);
        this.notify(NotificationName.RESOURCE_PLAYING);
    }

    protected respondToVideoSeeking(): void {
        this.notify(NotificationName.CONTENT_SEEKING);
    }

    protected respondToVideoSeeked(): void {
        this.notify(NotificationName.CONTENT_SEEKED);
    }

    protected respondToVideoProgress(): void {
        this.notify(NotificationName.VIDEO_PROGRESS);
    }

    protected respondToQualityChange(quality: QualityInterface = null): void {
        this.notify(NotificationName.QUALITY_CHANGE, quality);
    }

    protected respondToError(data: ErrorInfoInterface) {
        this.uiMediator && this.uiMediator.displaySpinner(false);

        // TODO: Replace with Short-Circuiting Assignment Operator ||= once upgrade to TS 4.0 is complete
        if (!data.message) {
            data.message = AppResources.messages.UNSPECIFIED_ERROR;
        }

        if (!data.code) {
            data.code = ErrorCode.UNSPECIFIED_VIDEO_PLAYBACK_ERROR;
        }

        this.sendErrorNotification(NotificationName.VIDEO_PLAYBACK_ERROR, data);
    }

    /*
        Resource end only applies when the resource has completed
    */
    protected respondToVideoEnd(): void {
        this.notify(NotificationName.DISABLE_UI);
        this.notify(NotificationName.RESOURCE_COMPLETE);
        ///////////////////////////////////////////////////////////
        // Note - No code may come after the RES_END notification
        ///////////////////////////////////////////////////////////
    }

    protected respondToBufferingStatusCheck(count: number): void {
        const t = this.presoModel.streamTime;
        !isNaN(t) && t > 0 && this.checkVideoBuffering(count);
    }

    protected respondToId3Data(d: any): void {
        this.notify(NotificationName.METADATA_CUEPOINT, d);
    }

    protected respondToVideoTimeUpdate(streamTime: number): void {
        const contentPlaybackState: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        const { duration, liveStreamInfo, streamType, time } = contentPlaybackState;

        this.updateTimeSpent();

        const data: Record<string, number> = {
            contentTime: time,
            contentDuration: duration,
            streamTime,
            streamDuration: this.presoModel.streamDuration,
            sessionTime: this.timeSpent.sessionTime,
            playbackTime: this.timeSpent.playbackTime,
        };

        if (streamType !== StreamType.VOD) {
            const { relativeTime, relativeDuration } = liveStreamInfo;
            data.contentTime = relativeTime;
            data.contentDuration = relativeDuration;
            data.elapsedTimeMs = this.timeSpent.elapsedTime;
            contentPlaybackState.time = relativeTime;
            contentPlaybackState.duration = relativeDuration;
        }

        this.notify(NotificationName.CONTENT_TIME_UPDATE, data);
    }

    protected respondToDurationChange(dur: number): void {
        // no op
    }

    protected respondToSizeChange(): void {
        if (!this.adapter) {
            return;
        }

        this.adapter.resize();
    }

    protected respondToTextTrackModeChange(enabled: boolean): void {
        if (this.uiMediator) {
            this.uiMediator.setClosedCaptionState(enabled);
        }
    }

    // helpers
    protected checkSize(): void {
        if (!this.domProxy) return;

        const rect = this.domProxy.getPresentationRect();

        if (rect.width !== this.currentRect.width || rect.height !== this.currentRect.height) {
            this.currentRect = rect;
            this.respondToSizeChange();

            this.notify(NotificationName.PRESENTATION_SIZE_CHANGE, {
                width: this.currentRect.width,
                height: this.currentRect.height,
            });
        }
    }

    // for now, hard-coded for DVR threshold of 30 minutes (1800 sec)
    protected setTransportType(streamType = this.contentPlaybackStateProxy.model.streamType): void {
        const seekable = streamType != StreamType.LIVE;

        if (this.uiMediator) {
            this.uiMediator.setSeekable(seekable);
            this.uiMediator.setTransportType(streamType);
            this.uiMediator.setIsPlayingLive(this.isPlayingLive);
        }
    }

    protected setForClickToPlay(): void {
        if (this.uiMediator) {
            this.uiMediator.displayPoster(true);
            this.uiMediator.activatePoster();
        }
        else {
            const po = <PlayerOptionsInterface>this.getModel(ModelName.PlayerOptions);
            if (po.useNativeControls) {
                const m = this.domProxy.getMain();
                const lr = () => {
                    m.removeEventListener('click', lr);
                    this.notify(NotificationName.PLAY_ON_USER_GESTURE);
                };
                m.addEventListener('click', lr);
            }
        }
    }

    protected setPausedState() {
        if (this.uiMediator) {
            this.uiMediator.setClickCatcherState(PlaybackState.PAUSED);
            this.uiMediator.hideClickCatcher(false);
        }
    }

    protected setPlayingState() {
        if (this.uiMediator) {
            this.uiMediator.setClickCatcherState(PlaybackState.PLAYING);
            this.uiMediator.hideClickCatcher(false);
        }
    }

    protected isContentComplete(): boolean {
        if (this.contentComplete) {
            return true;
        }
        const cps: ContentPlaybackStateInterface = this.contentPlaybackStateProxy.model;
        return cps.streamType != StreamType.LIVE && cps.streamType != StreamType.DVR && !(isNaN(cps.time) || cps.time < cps.duration);
    }

    // TODO: Move this to an AdPresentationMediator base class
    protected isAdPlaying(): boolean {
        return this.presoModel.isCurrentVideoAd;
    }

    override handleNotification(notification: NotificationInterface): void {
        super.handleNotification(notification);
    }

    override listNotificationInterests(): string[] {
        return super.listNotificationInterests().concat([]);
    }

    override onRegister(): void {
        super.onRegister();

        const o: OverridesInterface = this.playerOptions.overrides;

        if (o.resumeTimeMaxProximityToAdBreak) {
            this.resumeTimeMaxProximityToAdBreak = o.resumeTimeMaxProximityToAdBreak;
        }

        this.isClickToPlay = !this.presoModel.isAutoplay;

        const uim: MediatorInterface = this.facade.retrieveMediator(MediatorName.UI);
        uim && (this.uim = <UiMediatorInterface>uim);

        const sys = <SystemServiceInterface>this.facade.retrieveService(ServiceName.System);

        this.fullscreenRestrictedDuringAdPlay = sys.isIos || sys.isAndroid;
        if (this.uim) {
            this.uim.disableFullscreen();
            this.uim.setFullScreenAccessRestricted(this.fullscreenRestrictedDuringAdPlay);
        }

        this.setTransportType(StreamType.VOD);

        if (this.domProxy) {
            this.currentRect = this.domProxy.getPresentationRect();
        }
    }
}
