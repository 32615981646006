import { Emitter } from '../../core/Emitter';
import { Util } from '../../core/Util';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackEvent } from '../../enum/TextTrackEvent';
import { TextTrackKind } from '../../enum/TextTrackKind';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { SystemServiceInterface } from '../../iface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { VideoSurfaceConfigInterface } from '../../iface/VideoSurfaceConfigInterface';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { TextTrackType } from '../enum/TextTrackType';
import { SmpteToVttCueConverter } from '../util/SmpteToVttCueConverter';

export class TextTrackSurface extends Emitter {
    private logger: LoggerInterface;
    private video: HTMLVideoElement;
    private system: SystemServiceInterface;
    private config: VideoSurfaceConfigInterface;

    private pTextTracks: TextTrackList = null;
    private currentTextTrack: TextTrack = null;
    private currentTextTrackMode: TextTrackMode = TextTrackMode.DISABLED;
    private allowTextTrackCueDispatch: boolean = true;
    private existingTrack: Array<TextTrack> = [];

    constructor(config: VideoSurfaceConfigInterface) {
        super();
        this.system = config.system;
        this.config = config;
        this.video = config.video;
        this.pTextTracks = this.video.textTracks;

        this.currentTextTrackMode = config.textTrackSettings.mode;
        this.allowTextTrackCueDispatch = !config.textTrackSettings.native;

        // These functions have the potential to be called multiple times in rapid succession. 
        // Debounce to allow multiple changes to be applied in a single pass.
        this.processTracks = Util.debounce(this.processTracks.bind(this), 100);
        this.addTracks = Util.debounce(this.addTracks.bind(this), 25);

        this.addEvents();

        this.logger = config.logger;
        this.logger.log(LogLevel.INFO, 'TextTrackSurface created');
    }

    override destroy() {
        this.removeEvents();
        Util.forEach(this.pTextTracks, (t) => this.cleanupTrack(t));
        Util.forEach(this.video.querySelectorAll('track'), (element) => this.video.removeChild(element));
        super.destroy();
    }

    clearCue() {
        Util.clearCue(this.currentTextTrack, this.video.currentTime);
    }

    private cleanupTrack(track: TextTrack) {
        if (!this.isTextTrack(track.kind)) {
            return;
        }

        function cleanupCue(cue: TextTrackCue) {
            try {
                if (cue) {
                    track.removeCue(cue);
                }
            }
            catch (error) {
                // ignore errors and continue cleanup
            }
        }

        // Cues must be cleaned up in reverse order. Otherwise half of the cues will be left behind.
        Util.forEachReverse(track.cues, cleanupCue);
        Util.forEachReverse(track.activeCues, cleanupCue);

        //@ts-ignore
        track.expired = true;

        //hls.js disablement
        //@ts-ignore
        track.textTrack1 = true;
        //@ts-ignore
        track.textTrack2 = true;

        //dashjs disablement                    
        //@ts-ignore
        track.isTTML = true; //forces dash.js to use the new track it creates for vtt or ttml sideload on next load.  
        //@ts-ignore
        track.isEmbedded = false;

        //general disablement
        try {
            //@ts-ignore
            track.mode = TextTrackMode.DISABLED;
        } catch(error) {
            // ignore errors and continue disabling
        }
    }

    /**
     * Sidecar use only
     */
    set timeTextSrc(url: string) {
        const isVtt = url.indexOf('.vtt') >= 0;
        if (isVtt) {
            this.createVttTextTrack(this.video, url);
        }
        else {
            this.processSmpteTimedText(url);
        }
    }

    set textTrackMode(mode: TextTrackMode) {
        if (this.currentTextTrack) {
            this.setTrackMode(mode);
        }
        else {
            this.logger.log(LogLevel.WARN, `No text track detected`);
        }
    }

    set textTrack(newTrack: TextTrack) {
        if (!this.isValidTrack(newTrack) || newTrack == this.currentTextTrack) {
            return;
        }

        // disable old track
        if (this.currentTextTrack && this.currentTextTrack.mode !== TextTrackMode.DISABLED) {
            this.currentTextTrack.mode = TextTrackMode.DISABLED;
        }

        this.currentTextTrack = newTrack;

        // re-apply the track mode to the new text track
        this.setTrackMode(this.currentTextTrackMode).then(() => {
            this.logger.log(LogLevel.INFO, `${newTrack.language} is being set as the current text track`);
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_CHANGE, newTrack);
        });
    }

    get textTrack(): TextTrack {
        return this.currentTextTrack;
    }

    get textTracks(): TextTrack[] {
        return Util.toArray(this.pTextTracks).filter(t => this.isValidTrack(t));
    }

    private setTrackMode(mode: TextTrackMode): Promise<void> {
        return new Promise((resolve, reject) => {
            const modeChanged = (mode != this.currentTextTrackMode);
            const applyMode = (): void => {
                // mode is typed as number with string enum reserve mapped so even though you can set string, TS wants int.
                //@ts-ignore
                this.currentTextTrack.mode = mode;

                if (modeChanged) {
                    // Dispatch the event after the promise resolves
                    setTimeout(() => this.emit(TextTrackSurfaceEvents.TEXT_TRACK_DISPLAY_MODE_CHANGE, { mode }), 0);
                }

                // Logging
                let msg: string = TextTrackMode.DISABLED;
                mode === TextTrackMode.HIDDEN && (msg = 'enabled for event driven external custom rendering');
                mode === TextTrackMode.SHOWING && (msg = 'enabled for native rendering by the user agent');
                this.logger.log(LogLevel.INFO, `The ${this.currentTextTrack.kind} track for language code ${this.currentTextTrack.language} is being ${msg} `);

                resolve();
            };

            this.currentTextTrackMode = mode;

            // HACK: FF has issue with setting showing from disabled need to set to hidden then showing with timeout. 
            if (mode === TextTrackMode.SHOWING &&
                this.currentTextTrack.mode === TextTrackMode.DISABLED) {

                // Temporarily set to hidden to get around FF issue
                this.currentTextTrack.mode = TextTrackMode.HIDDEN;
                setTimeout(applyMode, 10);
            }
            else {
                applyMode();
            }
        });
    }

    // Events
    private onVideoTextTrackAdded = (e: TrackEvent): void => {
        // hlsjs reuses tracks for 608/708, so remove the expired flag.
        const track = e.track;
        //@ts-ignore
        track.expired = false;

        this.onTextTrackAdded(e);
    };

    private onTextTrackAdded = (e: TrackEvent): void => {
        const track = e.track;

        // VTG-2215 - hlsjs creates an empty "subtitles" track. Ignore it.
        const isEmpty = track.kind == TextTrackKind.SUBTITLES && !track.language && !track.label;
        const isMetadata = track.kind == TextTrackKind.METADATA;

        if (isMetadata || isEmpty) {
            return;
        }

        this.addTracks();
    };

    private onTextTrackChange = (e: Event): void => {
        this.processTracks();
    };

    private onCueChange = (e: Event): void => {
        const track = e.target as TextTrack;

        switch (track.kind) {
            case TextTrackType.CAPTIONS:
            case TextTrackType.SUBTITLES:
                const activeCues = Util.dedupeCues(track);
                if (this.allowTextTrackCueDispatch && track.mode === TextTrackMode.HIDDEN) {
                    this.emit(TextTrackSurfaceEvents.TEXT_CUEPOINT, { activeCues });
                }
                break;
        }
    };

    private addTrack(track: TextTrack): void {
        track.addEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange);

        this.logger.log(LogLevel.INFO, `A ${track.kind} text track was added`);
        this.emit(TextTrackSurfaceEvents.TEXT_TRACK_ADDED, track);
    }

    private addTracks(): void {
        Util.forEach(this.pTextTracks, (t) => {
            const invalid = this.isExpired(t) || this.isDuplicateTrack(t);
            t.mode = (!invalid) ? TextTrackMode.HIDDEN : TextTrackMode.DISABLED;

            if (invalid) {
                return;
            }

            this.addTrack(t);
        });

        // If this is the first time through, select the best track from the list
        if (!this.currentTextTrack) {
            this.textTrack = Util.findDefaultTrack(this.textTracks, this.config.textTrackSettings.language);
            if (!this.textTrack) {
                return;
            }
            this.textTrack.mode = this.config.textTrackSettings.mode;
            this.emit(TextTrackSurfaceEvents.TEXT_TRACK_AVAILABLE);
        }

        // Native hls playback in Safari won't trigger a text track change event, so do it manually
        this.processTracks();
    }

    private processTracks(): void {
        const { enabled, native, enabledMode } = this.config.textTrackSettings;

        // Only search valid tracks
        const tracks = this.textTracks;

        // hlsjs sometimes enables expired tracks. Ensure all expired tracks are disabled.
        Util.forEach(this.pTextTracks, t => {
            if (this.isExpired(t) && t.mode != TextTrackMode.DISABLED) {
                t.mode = TextTrackMode.DISABLED;
            }
        });

        // Handle non-native text rendering separately
        if (!native) {
            if (enabled) {
                // Streaming libraries sometimes set the mode to "showing"
                const track = Util.find(tracks, t => t.mode == TextTrackMode.SHOWING);
                if (track) {
                    track.mode = enabledMode;
                }
            }
            else {
                // Streaming libraries sometimes set the mode to "hidden"
                const track = Util.find(tracks, t => t.mode != TextTrackMode.DISABLED);
                if (track) {
                    track.mode = TextTrackMode.DISABLED;
                }
            }
            return;
        }

        // Check for change to the text track settings via native UIs or DOM APIs
        const track = Util.find(tracks, t => t.mode == TextTrackMode.SHOWING);

        if (enabled) {
            // no change
            if (track == this.currentTextTrack) {
                return;
            }

            // If no enabled track was found, then the mode has changed
            if (!track) {
                this.textTrackMode = TextTrackMode.DISABLED;
            }
            else {
                // Otherwise, the a different track was enabled
                this.textTrack = track;
            }
        }
        else {
            // no change
            if (!track) {
                return;
            }

            // Update the track if it has changed
            if (track != this.currentTextTrack) {
                this.textTrack = track;
            }
            // Update the mode 
            this.textTrackMode = TextTrackMode.SHOWING;
        }
    }

    private addEvents(): void {
        this.pTextTracks.addEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
        this.pTextTracks.addEventListener(TextTrackEvent.CHANGE, this.onTextTrackChange);

        // HACK: Workaround for a bug in hlsjs where the `addtrack` event is dispatched from
        // the video element instead of the text track list when switching to a resource
        // with 608/708 captions.
        (<any>this.video).addEventListener(TextTrackEvent.ADD_TRACK, this.onVideoTextTrackAdded);
    }

    private removeEvents(): void {
        this.pTextTracks.removeEventListener(TextTrackEvent.ADD_TRACK, this.onTextTrackAdded);
        this.pTextTracks.removeEventListener(TextTrackEvent.CHANGE, this.onTextTrackChange);

        // HACK: hlsjs 608 workaround
        (<any>this.video).removeEventListener(TextTrackEvent.ADD_TRACK, this.onVideoTextTrackAdded);

        Util.forEach(this.pTextTracks, (t) => t.removeEventListener(TextTrackEvent.CUE_CHANGE, this.onCueChange));
    }

    // Util
    private isDuplicateTrack(t: TextTrack): boolean {
        // Tracks that have been previously processed are not duplicates
        if (Util.includes(this.existingTrack, t)) {
            return false;
        }

        // Check for duplicate tracks generated by dashjs when switching periods on a stream with 608 captions
        const result = this.existingTrack.some(track => t.language == track.language && t.label == track.label && t.kind == track.kind);
        if (!result) {
            this.existingTrack.push(t);
        }
        return result;
    }

    private isTextTrack(type: string) {
        return type === TextTrackType.CAPTIONS || type === TextTrackType.SUBTITLES;
    }

    private isExpired(track: TextTrack): boolean {
        //@ts-ignore
        return track.expired;
    }

    private isValidTrack(track: TextTrack): boolean {
        if (!track) {
            return false;
        }

        if (!this.isTextTrack(track.kind)) {
            return false;
        }

        if (this.isExpired(track)) {
            return false;
        }

        return true;
    }

    // TODO: move strings into enum or props?
    private createVttTextTrack(el: HTMLVideoElement, src: string): void {
        // For Src to load and parse VTT must be this way. 
        const t = document.createElement('track');
        t.kind = TextTrackType.CAPTIONS;
        t.label = 'English';
        t.srclang = 'en';
        t.id = 'sidecar-vtt';
        t.src = src;
        el.appendChild(t);

        // Safari native will set the mode to "showing" causing a flicker
        t.track.mode = TextTrackMode.DISABLED;
    }

    private processSmpteTimedText(url: string) {
        const converter = new SmpteToVttCueConverter(url, this.system);
        converter.convert().then(result => {
            this.createSmpteTextTrack(this.video, result);
            this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation successful');
        }).catch(e => {
            this.logger.log(LogLevel.INFO, 'Smpte XML conversion and text track creation error', e.message);
        });
    }

    private createSmpteTextTrack(el: HTMLVideoElement, cues: Array<VTTCue>): void {
        try {
            const t = el.addTextTrack(TextTrackType.CAPTIONS, 'English', 'en');
            Util.forEach(cues, (item) => t.addCue(item));
        } catch (error) {
            this.logger.log(LogLevel.INFO, error);
        }
    }
};
