import { Emitter } from '../../core/Emitter';
import { LogLevel } from '../../enum/LogLevel';
import { TextTrackMode } from '../../enum/TextTrackMode';
import { EventInterface } from '../../iface/EventInterface';
import { LoggerInterface } from '../../iface/LoggerInterface';
import { StrAnyDict } from '../../iface/StrAnyDict';
import { VideoSurfaceConfigInterface } from '../../iface/VideoSurfaceConfigInterface';
import { VideoSurfaceInterface } from '../../iface/VideoSurfaceInterface';
import { waitForEvent } from '../../util/Async';
import { TextTrackSurfaceEvents } from '../enum/TextTrackSurfaceEvents';
import { VideoSurfaceEvents } from '../enum/VideoSurfaceEvents';
import { TextTrackSurface } from './TextTrackSurface';


export class Html5VideoSurface extends Emitter implements VideoSurfaceInterface {

    private logger: LoggerInterface;
    private pVideo: HTMLVideoElement;
    private timeAtLastBufferCheck: number = NaN;
    private textTrackSurface: TextTrackSurface = null;

    private pBuffering: boolean = false;
    private pSeeking: number = NaN;
    private pVideoEventHandler = (e: Event) => this.onVideoElementEvent(e);
    private pTextTrackEventHandler = (e: EventInterface) => this.onTextTrackSurfaceEvent(e);

    constructor(config: VideoSurfaceConfigInterface, useTextTrackSurface: boolean = true) {
        super();

        this.pVideo = config.video;
        this.logger = config.logger;
        if (useTextTrackSurface) {
            this.textTrackSurface = new TextTrackSurface(config);
        }
        this.logger.log(LogLevel.INFO, 'Html5VideoSurface created');
    }

    override destroy() {
        this.removeEvents();
        if (this.textTrackSurface) {
            this.textTrackSurface.destroy();
        }
        this.textTrackSurface = null;
        this.pVideo = null;
        super.destroy();
    }

    async load(startTime?: number) {
        await waitForEvent(this, VideoSurfaceEvents.LOADED_METADATA);

        if (!startTime) {
            return;
        }

        await this.seek(startTime);
    }

    async play(): Promise<void> {
        try {
            await this.pVideo.play();
        }
        catch (error) {
            this.logger.warn('Exception caught in play() Promise: ' + error.message, LogLevel.WARN);
            this.emit(VideoSurfaceEvents.AUTOPLAY_BLOCKED, error);
        }
    }

    pause(): void {
        this.pVideo && this.pVideo.pause();
    }

    async seek(value: number): Promise<void> {
        this.pVideo.currentTime = value;
        await waitForEvent(this, VideoSurfaceEvents.SEEKED);
    }

    addEvents(): void {
        for (let item in VideoSurfaceEvents) {
            this.pVideo.addEventListener((<StrAnyDict>VideoSurfaceEvents)[item], this.pVideoEventHandler);
        }

        if (!this.textTrackSurface) {
            return;
        }

        for (let item in TextTrackSurfaceEvents) {
            this.textTrackSurface.on((<StrAnyDict>TextTrackSurfaceEvents)[item], this.pTextTrackEventHandler);
        }
    }

    clearCue(): void {
        this.textTrackSurface?.clearCue();
    }

    get video(): HTMLVideoElement {
        return this.pVideo;
    }

    set src(value: string) {
        this.pVideo && (this.pVideo.src = value);
    }

    set volume(value: number) {
        this.pVideo && (this.pVideo.volume = value);
    }
    get volume(): number {
        return this.pVideo ? this.pVideo.volume : null;
    }

    set muted(value: boolean) {
        this.pVideo && (this.pVideo.muted = value);
    }
    get muted(): boolean {
        return this.pVideo ? this.pVideo.muted : null;
    }

    get paused(): boolean {
        return this.pVideo ? this.pVideo.paused : null;
    }

    get time(): number {
        return this.pVideo ? this.pVideo.currentTime : null;
    }

    get duration(): number {
        return this.pVideo ? this.pVideo.duration : null;
    }

    get state(): string {
        return ''; //TODO add playback state trap on event and return string here.
    }

    set textTrackMode(mode: TextTrackMode) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.textTrackMode = mode;
    }

    set textTrack(track: TextTrack) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.textTrack = track;
    }

    get textTrack(): TextTrack {
        return this.textTrackSurface.textTrack;
    }

    set textTrackSrc(url: string) {
        if (!this.textTrackSurface) {
            return;
        }

        this.textTrackSurface.timeTextSrc = url;
    }

    get textTracks(): TextTrack[] {
        return this.textTrackSurface.textTracks;
    }

    get bufferLength(): number {
        let n = 0;

        if (this.pVideo && this.pVideo.buffered.length > 0) {
            n = this.pVideo.buffered.end(this.pVideo.buffered.length - 1) - this.pVideo.currentTime;
        }

        return n;
    }

    get seeking(): boolean {
        return !isNaN(this.pSeeking);
    }

    get buffering(): boolean {
        if (!this.paused && this.pVideo) {
            const threshold = (this.timeAtLastBufferCheck + 0.001);
            this.timeAtLastBufferCheck = this.pVideo.currentTime;
            const stalled = this.pVideo.currentTime < threshold;
            this.pBuffering = this.pVideo.readyState >= 1 && stalled && !this.seeking;
        }

        return this.pBuffering;
    }

    get framerate(): number {
        //@ts-ignore  // does not cast
        const frames = this.pVideo.webkitDecodedFrameCount || this.pVideo.mozPresentedFrames || Number.NaN;
        return isNaN(frames) ? frames : frames / this.pVideo.currentTime;
    }

    get metrics(): StrAnyDict {
        let result = {
            droppedVideoFrames: Number.NaN,
            totalVideoFrames: Number.NaN
        };

        if (!this.pVideo) {
            return result;
        }

        const hasWebKit = ('webkitDroppedFrameCount' in this.pVideo) && ('webkitDecodedFrameCount' in this.pVideo);
        const hasQuality = ('getVideoPlaybackQuality' in this.pVideo);

        if (hasQuality) {
            result = this.pVideo.getVideoPlaybackQuality();
        }
        else if (hasWebKit) {
            result = {
                //@ts-ignore  // does not cast
                droppedVideoFrames: this.pVideo.webkitDroppedFrameCount,
                //@ts-ignore  // does not cast
                totalVideoFrames: this.pVideo.webkitDroppedFrameCount + this.pVideo.webkitDecodedFrameCount
            };
        }

        return result;
    }

    // From HTML5 video element 
    private onVideoElementEvent(event: Event): void {
        // modified seeking/seeked behavior to avoid buffering oscillations after seeking
        const { type } = event;
        switch (type) {
            case VideoSurfaceEvents.SEEKING:
                // ignore double seeking events
                if (this.seeking) {
                    return;
                }
                this.pSeeking = this.pVideo.currentTime + 0.001;
                break;

            case VideoSurfaceEvents.SEEKED:
                if (!this.pVideo.paused) {
                    // ignore the normal seeked event if not paused
                    return;
                }
                else {
                    // allow the normal seeked event to proceed if paused
                    this.pSeeking = NaN;
                }
                break;

            case VideoSurfaceEvents.TIME_UPDATE:
                // generate a synthetic seeked event when the playhead advances
                if (this.pSeeking <= this.pVideo.currentTime) {
                    this.pSeeking = NaN;
                    this.emit(VideoSurfaceEvents.SEEKED, event);
                }
                break;
        }

        this.emit(event.type, event);
    }

    //From emitter-based TextTrackSurface
    private onTextTrackSurfaceEvent(e: EventInterface): void {
        this.emit(e.type, e.data);
    }

    private removeEvents(): void {
        for (let item in VideoSurfaceEvents) {
            this.pVideo.removeEventListener((<StrAnyDict>VideoSurfaceEvents)[item], this.pVideoEventHandler);
        }

        if (!this.textTrackSurface) {
            return;
        }

        for (let item in TextTrackSurfaceEvents) {
            this.textTrackSurface.on((<StrAnyDict>TextTrackSurfaceEvents)[item], this.pTextTrackEventHandler);
        }
    }
}
