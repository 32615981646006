import { AutoplayInfoInterface } from '..';
import { Logger } from '../core/Logger';
import { Util } from '../core/Util';
import { Utilities } from '../core/Utilities';
import { ServiceName } from '../enum/ServiceName';
import { MediaCapabilitiesServiceInterface, ServiceCollection, SystemServiceInterface } from '../iface';
import { DeepPartial } from '../iface/DeepPartial';
import { PlayerOptionsInterface } from '../iface/PlayerOptionsInterface';
import { ResourceConfigurationInterface } from '../iface/ResourceConfigurationInterface';
import { StrAnyDict } from '../iface/StrAnyDict';
import { SystemInfoApiInterface } from '../iface/SystemInfoApiInterface';
import { SystemInfoInterface } from '../iface/SystemInfoInterface';
import { VideoPlayerInterface } from '../iface/VideoPlayerInterface';
import { VideoPlayerReadyCallback } from '../iface/VideoPlayerReadyCallback';
import * as sdk from '../sdk';
import { AutoplayCapabilitiesService } from '../service/AutoplayCapabilitiesService';
import { MediaCapabilitiesService } from '../service/MediaCapabilitiesService';
import { SystemService } from '../service/SystemService';
import { System } from '../util/System';
import { ResourceConfiguration } from './ResourceConfiguration';
import { VideoPlayer } from './VideoPlayer';

interface PlayerRequestObject {
    options: PlayerOptionsInterface;
    callback: VideoPlayerReadyCallback;
}

class Shell {
    private static serviceCollection: ServiceCollection = null;
    private static playerCollection: Record<string, VideoPlayerInterface> = {};
    private static initialized: boolean = false;
    private static initializing: boolean = null;
    private static pendingPlayerRequests: PlayerRequestObject[] = [];
    private static pendingPlayerDestroys: Record<string, Promise<void>> = {};

    protected constructor() { }

    static createVideoPlayer(options: PlayerOptionsInterface, callback: VideoPlayerReadyCallback): void {
        if (this.initialized) {
            this.createVideoPlayerApp({ options, callback });
            return;
        }
        else {
            this.pendingPlayerRequests.push({ options, callback });
        }

        if (this.initializing === null) {
            this.initialize(options);
        }
    }

    static createVideoPlayerApp(pro: PlayerRequestObject) {
        const c = pro.options.container,
            sys = <SystemServiceInterface>this.serviceCollection[ServiceName.System];

        let ok = false;

        if (sys.isWebMaf) {
            ok = true;
        }
        else if (
            c !== null && c !== undefined &&
            (
                c.constructor === HTMLDivElement ||
                (typeof c == 'object' && typeof c.getBoundingClientRect === 'function') ||
                (typeof c == 'string' && document.querySelector(c) != null)
            )
        ) {
            ok = true;
        }

        if (!ok) {
            const msg = 'Invalid player configuration: Missing presentation container.';
            Logger.error(msg);
            pro.callback(null, { message: msg });
        }

        try {
            const vp = new VideoPlayer({
                globalServices: this.serviceCollection,
                playerOptions: pro.options
            });

            this.playerCollection[vp.appId] = vp;

            vp
                .initialize()
                .then(api => pro.callback(api, null))
                .catch(error => pro.callback(null, error));
        }
        catch (error) {
            if (error.key) {
                error.message = `A player with id "${error.key}" a already exists`;
            }
            pro.callback(null, error);
        }
    }

    static retrieveVideoPlayer(playerId: string): Promise<VideoPlayerInterface | null> {
        const player = this.playerCollection[playerId];

        if (player) {
            return Promise.resolve(player);
        }

        const destroy = this.pendingPlayerDestroys[playerId];
        if (destroy) {
            return destroy.then(() => null);
        }

        const pending = Util.find(this.pendingPlayerRequests, (request) => request.options.id == playerId);
        if (pending) {
            return new Promise((resolve, reject) => {
                const callback = pending.callback;
                pending.callback = (api, error) => {
                    callback(api, error);
                    if (error) {
                        reject(error);
                    }
                    else {
                        resolve(api);
                    }
                };
            });
        }

        return Promise.resolve(null);
    }

    static removeVideoPlayer(playerId: string): Promise<void> {
        const pending = this.pendingPlayerDestroys[playerId];
        if (pending) {
            return pending;
        }

        const p = this.playerCollection[playerId];
        if (!p) {
            return Promise.resolve();
        }

        delete this.playerCollection[playerId];
        return this.pendingPlayerDestroys[playerId] = p
            .destroy()
            .then(() => {
                delete this.pendingPlayerDestroys[playerId];
                Logger.log(`[Avia] Player with id "${playerId}" removed.`);
            });
    }

    static createResourceConfig(config?: DeepPartial<ResourceConfigurationInterface>): ResourceConfigurationInterface {
        return new ResourceConfiguration(config);
    }

    static getSysInfoForUser(): SystemInfoApiInterface {
        if (this.serviceCollection === null) {
            return null;
        }

        const info = Util.assign({}, System.info),
            mc = (<MediaCapabilitiesServiceInterface>this.serviceCollection[ServiceName.MediaCapabilities]).capabilities;

        info.hasMediaSource = mc.hasMediaSource;
        info.supportsNativeHls = mc.supportsNativeHls;

        return <SystemInfoApiInterface>info;
    }

    static getPlatformInfo(): Promise<SystemInfoInterface | null> {
        return new Promise((resolve, reject) => {
            const s = SystemService.getInstance();
            if (s?.info) {
                resolve(s.info);
            }
            else {
                reject(null);
            }
        });
    }

    private static processPending(): void {
        const n = this.pendingPlayerRequests.length;

        for (let i = 0; i < n; i++) {
            this.createVideoPlayerApp(this.pendingPlayerRequests[i]);
            this.pendingPlayerRequests[i] = null;
        }
        this.pendingPlayerRequests = [];
    }

    private static initialize(options: PlayerOptionsInterface) {
        this.initializing = true;

        const systemService = SystemService.getInstance();
        systemService.appNamespace = appNamespace;

        const mediaCapabilities = MediaCapabilitiesService.getInstance();
        const autoplayCapabilities = AutoplayCapabilitiesService.getInstance();

        const overrides = options.overrides;
        const deferAutoplayDetection = overrides?.deferAutoplayDetection === true;

        return Promise
            .all([
                mediaCapabilities.detectCapabilities(),
                !deferAutoplayDetection && autoplayCapabilities.detectCapabilities(overrides?.blankVideoUrl),
            ])
            .then(() => {
                this.serviceCollection = {
                    [ServiceName.System]: systemService,
                    [ServiceName.MediaCapabilities]: mediaCapabilities,
                    [ServiceName.AutoplayCapabilities]: autoplayCapabilities,
                };

                this.initialized = true;
                this.initializing = false;
                this.processPending();
            });
    }
}

export function createVideoPlayer(options: PlayerOptionsInterface, callback?: VideoPlayerReadyCallback): Promise<VideoPlayerInterface> {
    return new Promise((resolve, reject) => {
        Shell.createVideoPlayer(options, (player: VideoPlayerInterface, error: StrAnyDict) => {
            if (Utilities.isFunction(callback)) {
                callback(player, error);
            }

            if (error != null) {
                reject(error);
            }
            else {
                resolve(player);
            }
        });
    });
}

export function retrieveVideoPlayer(playerId: string): Promise<VideoPlayerInterface | null> {
    return Shell.retrieveVideoPlayer(playerId);
}

export function removeVideoPlayer(playerId: string): Promise<void> {
    return Shell.removeVideoPlayer(playerId);
}

export function createResourceConfig(config?: DeepPartial<ResourceConfigurationInterface>): ResourceConfigurationInterface {
    return Shell.createResourceConfig(config);
}

export function getSystemInfo(): SystemInfoApiInterface | null {
    return Shell.getSysInfoForUser();
}

export function getPlatformInfo(): Promise<SystemInfoInterface | null> {
    return Shell.getPlatformInfo();
}

export function getAutoplayCapabilities(url?: string): Promise<AutoplayInfoInterface> {
    return AutoplayCapabilitiesService.getInstance().detectCapabilities(url);
}

const appNamespace = {
    ...sdk,
    createVideoPlayer,
    retrieveVideoPlayer,
    removeVideoPlayer,
    createResourceConfig,
    getSystemInfo,
    getPlatformInfo,
    getAutoplayCapabilities,
};
