/* Copyright(c) 2006-2018 Futurescale, Inc. */
/* TypeScript port by Video Technology Group, CBSi Inc. */

import { NotificationInterface } from '../iface';
import { StrAnyDict } from '../iface/StrAnyDict';


export class Notification implements NotificationInterface {

    private readonly pName: string | null;
    private readonly pBody: StrAnyDict | null;
    private readonly pType: string | null;

    constructor(name: string, body?: StrAnyDict, type?: string) {
        this.pName = name;
        this.pBody = body;
        this.pType = type;
    }

    get name(): string | null {
        return this.pName || null;
    }

    get body(): StrAnyDict | null {
        return this.pBody || null;
    }

    get type(): string | null {
        return this.pType || null;
    }

    toString(): string {
        let bdy = `\nBody: ${this.pBody == null ? 'null' : JSON.stringify(this.body)}`,
            typ = `\nType: ${this.pType == null ? 'null' : this.type}`;

        return `Notification Name: ${this.name + bdy + typ}`;
    }
}
