
export enum PlayerDom {
    MAIN_CONTAINER = 'main',
    VIDEO_EL = 'video',
    VIDEO_CONTAINER = 'videoContainer',
    CC_CONTAINER = 'ccContainer',
    AD_CONTAINER = 'adContainer',
    PANEL_CONTAINER = 'panelContainer',
    AD_CLICK_EL = 'adClickEl',
}
